'use strict';

var tooltip = require('../tooltip');

var options = {};
var buffer = {};

function triggerReactWebviewEvent() {
	if ('ReactNativeWebView' in window) {
		window.ReactNativeWebView.postMessage(JSON.stringify({ action: 'order-page' }));
	}
}

function initializePriceSpiderOrderTracking() {
	$(document).on('DataLayer.OrderComplete', function (event, dataLayer) {
		var psrid = sessionStorage.getItem('psrid');
		if (psrid && dataLayer && 'event' in dataLayer && dataLayer.event == 'purchase') {	
			var itemsTitle = [];
			var itemsPrice = [];
			var itemsQuantity = [];
			var itemsSku = [];
			var itemsManufacturer = [];
			var itemElements = dataLayer.items;
			for (var i = 0; i < itemElements.length; i++) {
				itemsTitle.push(itemElements[i].item_name);
				itemsQuantity.push(itemElements[i].quantity);
				itemsPrice.push(itemElements[i].price);
				itemsSku.push(itemElements[i].item_id);
				itemsManufacturer.push(itemElements[i].item_brand);
			}
			var order = pswtb.purchaseTracking.createOrder();
			order.currencyCode = dataLayer.currency;
			order.purchaseTotal = dataLayer.value;
			var itemsDetails = [];
			for (var y = 0; y < itemsTitle.length; y++) {
				itemsDetails.push({
					"productName": itemsTitle[y],
					"quantity": itemsQuantity[y],
					"unitPrice": itemsPrice[y] / itemsQuantity[y],
					"manufacturer": itemsManufacturer[y],
					"sku": itemsSku[y]
				});
			}
			order.details = itemsDetails;
			order.redirectToken = psrid;
			order.submit();
		}
	});
}

function initializeVWOOrderTracking() {
	buffer['items'] = null;
	if (SitePreferences.VWO_ENABLED && SitePreferences.VWO_PURCHASE_TRACKING_ENABLED) {
		buffer['vwoLoadedInterval'] = setInterval(triggerVWOOrderTracking, 100);

		$(document).on('DataLayer.OrderComplete', function (event, dataLayer) {
			var items = [];
			var paymentMethods = '';
			if (pageContext.PaymentMethods) {
				paymentMethods = pageContext.PaymentMethods.join(' | ');
			}
			$('.productCardMetadata').each(function() {
				var $this = $(this);
				var itemDataLayer;
				try {
					itemDataLayer = JSON.parse($this.attr('data-product-ga4-json'));
				} catch(exception) {}
				if (itemDataLayer) {
					var revenue = itemDataLayer.price;
					var price = revenue;
					if (itemDataLayer.discount) {
						price += itemDataLayer.discount;
					}
					items.push({
						"price": price.toFixed(2),
						"item": itemDataLayer.item_id,
						"paymentMethod": paymentMethods,
						"registeredUser": Customer.registered,
						"revenue": revenue
					});
				}
			});

			if (items && items.length > 0) {
				buffer['items'] = items;
				triggerVWOOrderTracking();
			}
		});
	}
}

function triggerVWOOrderTracking() {
	if (buffer['items'] && window.VWO && window.VWO.event && typeof window.VWO.event == 'function') {
		clearInterval(buffer['vwoLoadedInterval']);
		for (var itemIndex = 0; itemIndex < buffer['items'].length; itemIndex++) {
			var item = buffer['items'][itemIndex];
			VWO.event("purchase", item);
		}
	}
}

exports.init = function (optionsParameter) {
	options = optionsParameter;

	tooltip.init({
		items: '.tooltip'
	});
	triggerReactWebviewEvent();
	initializePriceSpiderOrderTracking();
	initializeVWOOrderTracking();
}