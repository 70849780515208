'use strict';

module.exports = {
	
	init: function (options) {
		if ($('.rotate-3d__img').length) {
			$('.rotate-3d__container').prepend('<a href="#" class="rotate-3d__rotate rotate-3d__rotateLeft">&nbsp;</a><a href="#" class="rotate-3d__rotate rotate-3d__rotateRight">&nbsp;</a>');
			$('.rotate-3d__rotateLeft').on('click', function (e) {
				e.preventDefault();
				$('.rotate-3d__img').trigger("stepLeft");
			});
			$('.rotate-3d__rotateRight').on('click', function (e) {
				e.preventDefault();
				$('.rotate-3d__img').trigger("stepRight");
			});
		}
		return this;
	},

};
