'use strict';

var _ = require('lodash');
var TemplateConstants = require('../../../../bc_sheplersbootbarn_core/cartridge/js/TemplateConstants');

exports.initialize = initialize;
exports.render = render;

var data;
var loaded;
var options;
var $container;

function initialize($containerObject, optionsObject) {
	if (Urls && Urls.getSameDayShippingCountdownData && Urls.getSameDayShippingCountdownContentSlot) {
		$container = $containerObject;
		options = optionsObject;
		data = {};
		loaded = {
			ContentSlot: false,
			Data: false
		}
		
		$(document).off('SameDayShippingCountdown.Update').on('SameDayShippingCountdown.Update', update);
		
		$.ajax({
			method: 'POST',
			url: Urls.getSameDayShippingCountdownData,
			dataType: 'json',
			success: function (response) {
				loaded.Data = true;
				data.Response = response;
				render();
			}
		});
		
		$.ajax({
			method: 'POST',
			url: Urls.getSameDayShippingCountdownContentSlot,
			dataType: 'html',
			success: function (html) {
				loaded.ContentSlot = true;
				data.Html = html;
				render();
			}
		});
	}
}

function render() {
	if (loaded && loaded.ContentSlot && loaded.Data && data) {
		var countdownText = '';
		
		if (data.Response && data.Response.TimeLeft) {
			if (data.Response.TimeLeft.Hours > 0) {
				countdownText += data.Response.TimeLeft.Hours + ' ' + (data.Response.TimeLeft.Hours > 1 ? Resources.HOURS_ABBREVIATED : Resources.HOUR_ABBREVIATED);
			}
			
			if (data.Response.TimeLeft.Minutes > 0) {
				if (countdownText != '') {
					countdownText += ' ';
				}
				
				countdownText += data.Response.TimeLeft.Minutes + ' ' + (data.Response.TimeLeft.Minutes > 1 ? Resources.MINUTES_ABBREVIATED : Resources.MINUTE_ABBREVIATED);
			}
		}

		if (data.Html) {
			$container.html(data.Html).find('input[name="initTime"]').remove();
		}
		
		if (countdownText) {
			$container.html(_.template($container.html(), TemplateConstants.Lodash.Settings)({
				countdown: countdownText
			}));
			
			update();
		} else {
			$(document).off('SameDayShippingCountdown.Update');
		}
	}
};

function update() {
	if ($container.html().replace(/<!--([^\d]*)-->/g, '').trim().length > 0) {
		var $selectedVariation = $('.product-variations .attribute-size .select-text [same-day-shipping]');
		
		if ($selectedVariation.length > 0) {
			if ($selectedVariation.attr('same-day-shipping').toLowerCase() == 'true') {
				$container.show();
			} else {
				$container.hide();
			}
		} else if (parseFloat(options.percentageOfVariantsFromWarehouse) >= parseFloat(options.sameDayShippingWindowWarehousePercentage)) {
			$container.show();
		} else {
			$container.hide();
		}
	}
}