'use strict';

var loadingIndicator = require('./LoadingIndicator');
var ReturnCodes = require('../../../../int_svs/cartridge/scripts/GiftCardConstants').ReturnCodes;
var ValidationHelper = require('../../../../bc_sheplersbootbarn_core/cartridge/scripts/util/ValidationHelper');
var ValidationRules = require('../../../../bc_sheplersbootbarn_core/cartridge/js/ValidationConstants').Rules;

exports.initialize = initialize;
exports.remove = remove;

var giftCardBalanceInquiryValidator;

function initialize($container, optionsParameter) {
	var options = $.extend({
		successCallback: null,
		errorCallback: null
	}, optionsParameter);
	
	var $giftCardBalanceInquiryComponent = $container.find('.gift-card-balance-inquiry-component');
	
	var $giftCardBalanceInquiryForm = $giftCardBalanceInquiryComponent.find('form');
	var $giftCardBalanceInquiryResult = $giftCardBalanceInquiryComponent.find('.balance-inquiry-result');
	var $giftCardBalanceInquiryResultAmount = $giftCardBalanceInquiryComponent.find('.amount');
	var $giftCardBalanceInquiryCardNumber = $giftCardBalanceInquiryForm.find('input[name$="giftCard_number"]');
    var $giftCardBalanceInquiryPinNumber = $giftCardBalanceInquiryForm.find('input[name$="giftCard_pin"]');
    var $giftCardBalanceInquiryErrorMessage = $giftCardBalanceInquiryForm.find('span.error');
	
    $container.attr('balance-inquiry-state', 'form');
	
    $giftCardBalanceInquiryComponent.off('click.apply').on('click.apply', '.btn.balance-inquiry:not([loading])', function (event) {
		event.preventDefault();

		$giftCardBalanceInquiryErrorMessage.html('').hide();
        giftCardBalanceInquiryValidator = $giftCardBalanceInquiryForm.validate();
		$.validator.addMethod('giftCardNumberLength', ValidationHelper.ValidateGiftCardNumberLength, Resources.Validation.Messages.GiftCard.Number.Length);
		$.validator.addMethod('giftCardNumberValues', ValidationHelper.ValidateGiftCardNumberValues, Resources.Validation.Messages.GiftCard.Number.Values);
		$giftCardBalanceInquiryCardNumber.rules('add', ValidationRules.GiftCard.Number);
		$giftCardBalanceInquiryPinNumber.rules('add', ValidationRules.GiftCard.Pin);
        giftCardBalanceInquiryValidator.form();

        if (Urls && Urls.GiftCard.BalanceInquiry && giftCardBalanceInquiryValidator.valid()) {
            var $this = $(this);

            $this.loading({
                IsImageShown: false,
                Label: Resources.Loading.Loading
            });

			var giftCardBalanceInquiryCardNumber = $giftCardBalanceInquiryCardNumber.val().match(/(\d)/g);
			if (giftCardBalanceInquiryCardNumber && giftCardBalanceInquiryCardNumber.length) {
				giftCardBalanceInquiryCardNumber = giftCardBalanceInquiryCardNumber.join('');
			}

            $.ajax({
                method: 'POST',
                url: Urls.GiftCard.BalanceInquiry,
                contentType: 'application/json',
                data: JSON.stringify({
                    cardNumber: giftCardBalanceInquiryCardNumber,
                    pin: $giftCardBalanceInquiryPinNumber.val()
                }),
                dataType: 'json',
                success: function (data) {
                    $this.loading('destroy');

                    var inquiryResult;
                    if (data && data.ServiceSuccessfullyCalled && data.InquiryResult) {
                        inquiryResult = data.InquiryResult;
                    }

                    if (inquiryResult && inquiryResult.ReturnCode.ReturnCode == ReturnCodes.Approval) {
                        $container.attr('balance-inquiry-state', 'result');
                        
                        $giftCardBalanceInquiryResultAmount.text(inquiryResult.BalanceAmount.AmountFormatted);
                        
                        if (options.successCallback) {
                        	options.successCallback(inquiryResult);
                        }
                    } else {
                    	if (inquiryResult.ReturnCode.ReturnDescription == 'Invalid Message') {
                    		inquiryResult.ReturnCode.ReturnDescription = Resources.Error.Generic;	
                    	}
                    	
                        $giftCardBalanceInquiryErrorMessage.html(inquiryResult.ReturnCode.ReturnDescription || Resources.Error.Generic).show();
                        
                        if (options.errorCallback) {
                        	options.errorCallback();
                        }
                    }
                }
            });
		}
	});
    
    $giftCardBalanceInquiryCardNumber.off('keydown.GiftCardBalanceInquiry.ValidationClear, change.GiftCardBalanceInquiry.ValidationClear').on('keydown.GiftCardBalanceInquiry.ValidationClear, change.GiftCardBalanceInquiry.ValidationClear', function (event) {
		if ($container.attr('balance-inquiry-state') === 'result') {
			$container.attr('balance-inquiry-state', 'form');
        	$giftCardBalanceInquiryPinNumber.val('');
        	$giftCardBalanceInquiryResultAmount.text('');
        }
    });
    
    $(document).off('mousedown.GiftCardBalanceInquiry.ValidationClear').on('mousedown.GiftCardBalanceInquiry.ValidationClear', function (event) {
        if (giftCardBalanceInquiryValidator && $(event.target).closest('.gift-card-balance-inquiry-component').parent().length == 0) {
            giftCardBalanceInquiryValidator.resetForm();
            $giftCardBalanceInquiryErrorMessage.html('').hide();
        }
    });
}

function remove() {
	giftCardBalanceInquiryValidator.destroy();
}