'use strict';

exports.VerifyAddress = verifyAddress;
exports.AutocompleteAddress = autocompleteAddress;
exports.SetAddressFromAddressVerificationDialog = setAddressFromAddressVerificationDialog;
exports.BuildAddressObjectFromCheckoutForm = buildAddressObjectFromCheckoutForm;
exports.BuildAddressObjectFromAddressAutocompleteSuggestion = buildAddressObjectFromAddressAutocompleteSuggestion;
exports.BuildAddressObjectFromAddressVerifyResponse = buildAddressObjectFromAddressVerifyResponse;
exports.CompareAddressObjects = compareAddressObjects;

var dataBuffer = {};

function verifyAddress($form, options) {
	return new Promise(function(resolve, reject) {
		var addressVerificationPayload = {
			primary_line: $form.find('input[name$="_address1"]').val() || '',
			secondary_line: $form.find('input[name$="_address2"]').val() || '',
			city: $form.find('input[name$="_city"]').val() || '',
			state: $form.find('select[name$="_state"]').val() || '',
			zip_code: $form.find('input[name$="postal"]').val() || ''
		};
		addressVerificationPayload[options.CSRFToken.Name] = options.CSRFToken.Token;

		$.ajax({
			method: 'POST',
			url: Urls.Lob.AddressVerify,
			contentType: 'application/json',
			data: JSON.stringify(addressVerificationPayload),
			dataType: 'json',
			success: function(data) {
				if (!data || !data.ServiceSuccessfullyCalled) {
					resolve(data);
				} else {
					data.Status = 'deliverable';
					data.Match = true;
					if (data.InquiryResult) {
						data.AddressOriginal = buildAddressObjectFromCheckoutForm($form);
						data.AddressVerified = buildAddressObjectFromAddressVerifyResponse(data);
						data.AddressComparison = compareAddressObjects(data.AddressOriginal, data.AddressVerified);
						data.Match = data.AddressComparison.Match;
						data.Status = data.InquiryResult.deliverability;
					}

					if (data.Status == 'deliverable' && data.Match) {
						resolve(data);
					} else {
						reject(data);
					}
				}
			},
			error: function(data) {
				resolve(data);
			},
		});
	});
}

function autocompleteAddress($form, $field, options) {
	return new Promise(function(resolve, reject) {
		var addressAutocompletePayload = {
			address_prefix: $form.find('input[name$="_address1"]').val() || '',
			city: $form.find('input[name$="_city"]').val() || '',
			state: $form.find('select[name$="_state"]').val() || '',
			zip_code: $form.find('input[name$="postal"]').val() || ''
		};
		addressAutocompletePayload[options.CSRFToken.Name] = options.CSRFToken.Token;

		if (dataBuffer && dataBuffer['AutocompleteAddressAjax'] && dataBuffer['AutocompleteAddressAjax'].readyState !== 4) {
			dataBuffer['AutocompleteAddressAjax'].abort();
		}

		dataBuffer['AutocompleteAddressAjax'] = $.ajax({
			method: 'POST',
			url: Urls.Lob.AddressAutocomplete,
			contentType: 'application/json',
			data: JSON.stringify(addressAutocompletePayload),
			dataType: 'json',
			success: function(data) {
				if (!data || !data.ServiceSuccessfullyCalled) {
					resolve(data);
				} else {
					data.$form = $form;
					data.$field = $field;

					resolve(data);
				}
			}
		})
	});
}

function setAddressFromAddressVerificationDialog($form, addressData) {
	if (addressData.FirstName) {
		$form.find('input[name$="_firstName"]').val(addressData.FirstName);
	}
	if (addressData.LastName) {
		$form.find('input[name$="_lastName"]').val(addressData.LastName);
	}
	$form.find('input[name$="_address1"]').val(addressData.PrimaryLine);
	$form.find('input[name$="_address2"]').val(addressData.SecondaryLine);
	$form.find('input[name$="_city"]').val(addressData.City);
	$form.find('select[name$="_state"]').val(addressData.StateCode);
	$form.find('input[name$="_postal"]').val(addressData.PostalCode);
	if (addressData.CountryCode) {
		$form.find('select[name$="_countryCode"]').val(addressData.CountryCode);
	}
	if (addressData.Phone) {
		$form.find('input[name$="phone"]').val(addressData.Phone);
	}
}

function buildAddressObjectFromCheckoutForm($form) {
	var addressObject = {};
	
	addressObject.PrimaryLine = $form.find('input[name$="_address1"]').val();
	addressObject.SecondaryLine = $form.find('input[name$="_address2"]').val();
	addressObject.City = $form.find('input[name$="_city"]').val();
	addressObject.StateCode = $form.find('select[id$="_state"]').val();
	addressObject.PostalCode = $form.find('input[name$="_postal"]').val();

	return addressObject;
}

function buildAddressObjectFromAddressAutocompleteSuggestion(data) {
	var addressObject = {};

	if (data) {
		addressObject.PrimaryLine = data.primary_line || '';
		addressObject.SecondaryLine = data.secondary_line || '';
		addressObject.StateCode = data.state || '';
		addressObject.PostalCode = data.zip_code || '';
		addressObject.City = data.city || '';
	}

	return addressObject;
}

function buildAddressObjectFromAddressVerifyResponse(data) {
	var addressObject = {};

	if (data && data.InquiryResult) {
		addressObject.PrimaryLine = data.InquiryResult.primary_line || '';
		addressObject.SecondaryLine = data.InquiryResult.secondary_line || '';
		addressObject.StateCode = data.InquiryResult.components.state || '';
		addressObject.PostalCode = data.InquiryResult.components.zip_code || '';
		addressObject.City = data.InquiryResult.components.city || '';
	}

	return addressObject;
}

function compareAddressObjects(addressOriginal, addressVerified) {
	var addressObject = {};

	if (addressOriginal && addressVerified) {
		addressObject.PrimaryLine = compareAddressElement(addressOriginal.PrimaryLine, addressVerified.PrimaryLine);
		addressObject.SecondaryLine = compareAddressElement(addressOriginal.SecondaryLine, addressVerified.SecondaryLine);
		addressObject.StateCode = compareAddressElement(addressOriginal.StateCode, addressVerified.StateCode);
		addressObject.PostalCode = compareAddressElement(addressOriginal.PostalCode, addressVerified.PostalCode);
		addressObject.City = compareAddressElement(addressOriginal.City, addressVerified.City);
	}

	var fieldsDifferent = [];
	for (var addressElement in addressObject) {
		if (!addressObject[addressElement]) {
			fieldsDifferent[fieldsDifferent.length] = addressElement;
		}
	}
	addressObject.FieldsDifferent = fieldsDifferent;
	addressObject.Match = fieldsDifferent.length == 0;

	return addressObject;
}

function compareAddressElement(addressElementOriginal, addressElementVerified) {
	addressElementOriginal = addressElementOriginal || '';
	addressElementVerified = addressElementVerified || '';

	return addressElementOriginal.trim().toLowerCase() == addressElementVerified.trim().toLowerCase();
}