'use strict';

var carouselVideo = require('../CarouselVideo');

// Global vars
var options;
var isMobile = window.innerWidth <= 767;

exports.initialize = initialize;

function initialize(params) {
	if (params) {
		options = params;
	}
	var thumbGallerySwiper, largeSwiper,
		thumbGalleryBreakpoints = options && options.thumbGalleryBreakpoints ? options.thumbGalleryBreakpoints : null,
		thumbGalleryBreakpointSettings = thumbGalleryBreakpoints ? (isMobile ? thumbGalleryBreakpoints['0'] : thumbGalleryBreakpoints['768']) : null,
		enableThumbGalleryForCurrentBreakpoint = thumbGalleryBreakpointSettings && thumbGalleryBreakpointSettings.enabled,
		isThumbnailsElm = $('.thumbs-product-swiper').length > 0;

	// Check to see if we have any thumbnails at all
	if (isThumbnailsElm) {
		// Initialize thumbnails gallery and assign instance to 'thumbGallerySwiper'
		if (enableThumbGalleryForCurrentBreakpoint) {
			thumbGallerySwiper = thumbGallerySwiperInit(options);
			// Thumbnail slider modifications - can only make mods after thumbs gallery slider has been initialized.
			// No default arrow navigation exists for thumbnails gallery in swiper, so we add our own.
			thumbGalleryNavigation(options, thumbGallerySwiper, thumbGalleryBreakpointSettings);
			if (isMobile) {
				$('.product-image-container-mobile').addClass('swiper-thumbs-mobile-edits');
			}
		} else {
			$('.thumbs-product-swiper').addClass('d-none');
		}
		// Initialize PDP main image swiper and pass in thumbnails gallery if it exists
		largeSwiper = largeSwiperInit(options, thumbGallerySwiper);
	} else {
		largeSwiper = largeSwiperInit(options, null);
	}

	if (options.zoom) {
		$('#pdpMain').off('click.Variant.Swiper.Zoom.In').on('click.Variant.Swiper.Zoom.In', '.custom.leaflet-control-zoom-in', function (e) {
			largeSwiper.zoom.in();
		});
		
		$('#pdpMain').off('click.Variant.Swiper.Zoom.Out').on('click.Variant.Swiper.Zoom.Out', '.custom.leaflet-control-zoom-out', function (e) {
			largeSwiper.zoom.out();
		});
	}

	carouselVideo.init();
}

function thumbGallerySwiperInit(options) {
	// Thumbnails swiper options
	var thumbnailsSwiperOptions = {
		direction: isMobile ? 'horizontal' :'vertical',
		slidesPerView: 7,
		slidesPerGroup: 7,
		loop: false,
		slideToClickedSlide: true,
		allowTouchMove: true
	};

	if (options.thumbGalleryScrollDirection) {
		thumbnailsSwiperOptions.direction = isMobile ? 'horizontal' : options.thumbGalleryScrollDirection;
	}

	// Initiate thumbnails swiper
	return new Swiper ('.thumbs-product-swiper', thumbnailsSwiperOptions);
}

function largeSwiperInit(options, thumbnailsSwiper) {
	var largeSwiperOptions;
	var mobileDots = options.breakpoints && options.breakpoints['0'] && options.breakpoints['0'].dots ? options.breakpoints['0'].dots : true;
	var desktopDots = options.breakpoints && options.breakpoints['768'] && options.breakpoints['768'].dots ? options.breakpoints['768'].dots : false;

	// Large product swiper options
	largeSwiperOptions = {
		slidesPerView: 1,
		centeredSlides: true,
		loop: false,
		breakpoints: {
			0: {
				pagination: {
					enabled: mobileDots,
					el: '.swiper-pagination',
					clickable: true
				}
			},
			768: {
				pagination: {
					enabled: desktopDots,
					el: '.swiper-pagination',
					clickable: true
				}
			}
		}
	};

	if (desktopDots || mobileDots) {
		var modifierClass;
		if (!desktopDots && mobileDots) {
			modifierClass = 'swiper-paginated-mobile-';
		}
		if (desktopDots && !mobileDots) {
			modifierClass = 'swiper-paginated-desktop-';
		}
		if (desktopDots && mobileDots) {
			modifierClass = 'swiper-paginated-both-';
		}
		if (modifierClass) {
			largeSwiperOptions.containerModifierClass = modifierClass;
		}
	}

	if (options.zoom) {
		largeSwiperOptions.zoom = {
			maxRatio: 3,
			minRatio: 1
		}
	}

	if (thumbnailsSwiper) {
		largeSwiperOptions.thumbs = {
			swiper: thumbnailsSwiper
		}
	}

	// Initiate large product swiper
	return new Swiper ('.large-product-swiper', largeSwiperOptions);
}

function thumbGalleryNavigation(options, swiperInstance, breakpointSettings) {
	var $swiperThumbnailsDomElement = $('.thumbs-product-swiper');
	if (!$swiperThumbnailsDomElement || !swiperInstance || !breakpointSettings || $swiperThumbnailsDomElement.find('.swiper-slide').length < 8) {
		return;
	} else {
		var addNavigation = breakpointSettings ? (breakpointSettings.arrows ? breakpointSettings.arrows : false) : null,
			isVertical = $swiperThumbnailsDomElement.hasClass('swiper-vertical'),
			isHorizontal = $swiperThumbnailsDomElement.hasClass('swiper-horizontal'),
			thumbGallerySlides = $swiperThumbnailsDomElement.find('.swiper-slide'),
			thumbsDirectionClass = isVertical ? 'thumbs-vertical' : 'thumbs-horizontal', 
			$thumbnailsNavigation = $('<div />', { 'class': 'thumbs-product-navigation' }),
			carets;

			if (addNavigation) {
				if (isVertical) {
					carets = '<i class="fa-solid fa-angle-up d-none"></i><i class="fa-solid fa-angle-down"></i>';
				}
				if (isHorizontal) {
					carets = '<i class="fa-solid fa-angle-left d-none"></i><i class="fa-solid fa-angle-right"></i>';
				}
				$thumbnailsNavigation.html(carets).addClass(thumbsDirectionClass).insertBefore($swiperThumbnailsDomElement);
				var prevIcons = '.thumbs-product-navigation .fa-angle-up, .thumbs-product-navigation .fa-angle-left';
				var nextIcons = '.thumbs-product-navigation .fa-angle-down, .thumbs-product-navigation .fa-angle-right';
				$(document).on('click', prevIcons, function() {
					swiperInstance.slidePrev(500);
					var prevIconsFirstVisibleIndex = thumbGallerySlides.index($swiperThumbnailsDomElement.find('.swiper-slide-visible:first'));
					var prevIconsLastVisibleIndex = thumbGallerySlides.index($swiperThumbnailsDomElement.find('.swiper-slide-visible:last'));
					if (prevIconsFirstVisibleIndex == 0) {
						$(prevIcons).addClass('d-none');
					} else {
						$(prevIcons).removeClass('d-none');
					}
					if (prevIconsLastVisibleIndex < 7) {
						$(nextIcons).removeClass('d-none');
					} else {
						$(nextIcons).addClass('d-none');
					}
				});
				$(document).on('click', nextIcons, function() {
					swiperInstance.slideNext(500);
					var nextIconsLastVisibleIndex = thumbGallerySlides.index($swiperThumbnailsDomElement.find('.swiper-slide-visible:last'));
					if (nextIconsLastVisibleIndex == (thumbGallerySlides.length - 1)) {
						$(nextIcons).addClass('d-none');
					} else {
						$(nextIcons).removeClass('d-none');
					}
					if (nextIconsLastVisibleIndex > 6) {
						$(prevIcons).removeClass('d-none');
					} else {
						$(prevIcons).addClass('d-none');
					}
				});	
			}
	}
}
