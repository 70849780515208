'use strict';

var Promise = require('promise');

exports.initialize = initialize;
exports.verify = verify;

function initialize() {
	window.recaptchaLoaded = function () {
		$(document).trigger('Recaptcha.Loaded');
	};

	window.emailSignup = function (token) {
		return verifyAndTriggerEvent(token, 'Global.EmailSignup');
	};

	window.accountLogin = function (token) {
		return verifyAndTriggerEvent(token, 'Account.SubmitLogin');
	};

	window.resetPassword = function (token) {
		return verifyAndTriggerEvent(token, 'Account.ResetPassword');
	};

	window.submitOPCPayment = function (token) {
		return verifyAndTriggerEvent(token, 'Checkout.SubmitPayment');
	};
	
	window.submitOPCPaymentPrivateLabel = function (token) {
		return verifyAndTriggerEvent(token, 'Checkout.SubmitPaymentPrivateLabel');
	};

	window.submitMerryMail = function (token) {
		return verifyAndTriggerEvent(token, 'MerryMail.Send');
	};
	
	window.submitRewardsCredit = function (token) {
		return verifyAndTriggerEvent(token, 'RewardsCredit.Add');
	};
	
	window.submitLoyaltyCredit = function (token) {
		return verifyAndTriggerEvent(token, 'LoyaltyCredit.Add');
	};
	
	window.submitGiftCard = function (token) {
		return verifyAndTriggerEvent(token, 'GiftCard.Apply');
	};

	window.submitPaymentVoucher = function (token) {
		return verifyAndTriggerEvent(token, 'PaymentVoucher.Apply');
	};
};

function verifyAndTriggerEvent(token, eventName) {
	return $.when(verify(token)).then(function(data, textStatus, xmlHttpRequest) {
		return new Promise(function(resolve, reject) {
			if (data.success) {
				$(document).trigger(eventName);
				resolve();	
			} else {
				reject();
			}
		});
	});	
}

function verify(token) {
	return $.ajax({
		method: 'POST',
		url: Urls.verifyRecaptcha,
		contentType: 'application/json',
		data: JSON.stringify({
			token: token
		})
	});
}