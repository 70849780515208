'use strict';

var _ = require('lodash');
var dialog = require('../../dialog');
var loadingIndicator = require('../../components/LoadingIndicator');
var TemplateConstants = require('../../../../../bc_sheplersbootbarn_core/cartridge/js/TemplateConstants');
var util = require('../../util');

exports.initialize = initialize;

var options;
var dataBuffer;

var $container;
var StepsConfiguration;

function initialize($containerParameter, optionsParameter) {
	dataBuffer = {};
	options = $.extend({
		successCallback: null,
		errorCallback: null
	}, optionsParameter);
	
	$container = $containerParameter;

	if (options && options.Bopis && options.Bopis.Enabled) {
		if (options.Bopis.SelectedLocations && options.Bopis.StepConfiguration) {
			StepsConfiguration = options.Bopis.StepConfiguration;
			
			if (!options.Bopis.IsLocationSearchFiltered && window.location.search.indexOf('prefn2') < 0) {
				$container.addClass('open');
				$('body').addClass('scroll-lock');
			}
			
			buildStepPlaceholders(0);
			if (StepsConfiguration[0].Type == 'Category') {
				buildStepWithCategories(0);
			} else if (StepsConfiguration[0].Type == 'Refinement') {
				buildStepWithSizes(0);
			}

			$(document).off('click.SelectNewFilters').on('click.SelectNewFilters', '.callout-selected-location .select-new-filters', function(event) {
				$container.addClass('open');
				$('body').addClass('scroll-lock');
			});
			
			$container.off('click.SeeMoreSizes').on('click.SeeMoreSizes', '[step] a.see-more-sizes', function(event) {
				event.preventDefault();
				$container.find('[step][refinement="size"] .refinementValues').toggleClass('hidden');
			});
			
			$container.off('click.SearchCategory').on('click.SearchCategory', '[step] a.selectable:not(.placeholder)', function(event) {
				event.preventDefault();
				
				var $this = $(this);
				var step = parseInt($this.closest('[step]').attr('step'), 10);
				var stepConfiguration = StepsConfiguration[step + 1];
				var categoryId;
				if ($this.attr('category-id')) {
					categoryId = $this.attr('category-id');
				}
				
				$container.find('[step="' + step + '"] a').removeClass('selected');
				if ((step + 2) <= StepsConfiguration.length) {
					for (var stepIndex = step + 2; stepIndex < StepsConfiguration.length; stepIndex++) {
						buildStepPlaceholder(stepIndex);
					}
				}
				$this.addClass('selected');
				
				var selectedCategory = $container.find('.steps a[category-id].selected').last().attr('category-id');
				var selectedSize = $container.find('.steps a[refinement-value].selected').last().attr('refinement-value');
				var selectedLength = $container.find('.steps a[refinement-length].selected').last().attr('refinement-length');
				var selectedWidth = $container.find('.steps a[refinement-width].selected').last().attr('refinement-width');
				
				if (categoryId) {
					if (stepConfiguration.Type == 'Category') {
						buildStepWithCategories(step + 1, categoryId);
					} else if (stepConfiguration.Type == 'Refinement') {
						buildStepWithSizes(step + 1, categoryId);
					}
				} else if (selectedLength && selectedWidth) {
					updateProductCountForCategoryAndSize(step + 1, selectedCategory, selectedLength + ' ' + selectedWidth);
				} else if (selectedLength && dataBuffer['WidthsByLength'] && dataBuffer['WidthsByLength'][selectedLength]) {
					var selectedSizes = '';
					for (var widthForLengthKey in dataBuffer['WidthsByLength'][selectedLength]) {
						var widthForLength = dataBuffer['WidthsByLength'][selectedLength][widthForLengthKey];
						
						if (selectedSizes) {
							selectedSizes += '|';
						}
						
						selectedSizes += selectedLength + ' ' + widthForLength;
					}
					updateProductCountForCategoryAndSize(step + 1, selectedCategory, selectedSizes);
				} else if (selectedSize) {
					$container.find('[step][refinement="width"]').remove();
					updateProductCountForCategoryAndSize(step + 1, selectedCategory, selectedSize);
				}
				
				$container.find('.btn.reset').removeAttr('disabled');
				var allStepsHaveSelectedValue = true;
				$container.find('[step]').each(function() {
					if ($(this).find('.selectable.selected').length < 1) {
						allStepsHaveSelectedValue = false;
					}
				});
				if (allStepsHaveSelectedValue) {
					$container.find('.btn.apply').removeAttr('disabled');
				} else {
					$container.find('.btn.apply').attr('disabled', 'disabled');
				}
			});
			
			$container.off('click.SearchLength').on('click.SearchLength', '[step] a.selectable[refinement-length]:not(.placeholder)', function(event) {
				event.preventDefault();
				
				var $this = $(this);
				var step = parseInt($this.closest('[step]').attr('step'), 10);
				var stepConfiguration = StepsConfiguration[step + 1];
				var selectedCategory = $container.find('.steps a[category-id].selected').last().attr('category-id');
				var selectedLength = $container.find('.steps a[refinement-length].selected').last().attr('refinement-length');
				
				buildStepWithWidths(step + 1, selectedCategory, selectedLength);
			});
			
			$container.off('click.Apply').on('click.Apply', '.btn.apply', function(event) {
				$container.removeClass('open');
				
				$('body').loading({
					Content: '<h6>Hold Your Horses!</h6><p>Your selections are loading...</p>',
					CssClass: 'bootbarnexpress',
					BackgroundColor: 'transparent'
				});
				
				var $selectedCategory = $container.find('.steps a[category-id].selected').last();
				var selectedCategory = $selectedCategory.attr('category-id');
				var selectedCategoryUrl = $selectedCategory.attr('category-url');
				var selectedSize = $container.find('.steps a[refinement-value].selected').last().attr('refinement-value');
				var selectedLength = $container.find('.steps a[refinement-length].selected').last().attr('refinement-length');
				var selectedWidth = $container.find('.steps a[refinement-width].selected').last().attr('refinement-width');
				var selectedLocation = options.Bopis.SelectedLocations[0].StoreId;
				
				if (selectedLocation && selectedCategoryUrl) {
					var url = window.location.origin + selectedCategoryUrl;
					url = util.appendParamToURL(url, 'prefn1', 'availableStores');
					url = util.appendParamToURL(url, 'prefv1', selectedLocation);
				
					if (selectedLength && selectedWidth) {
						url = util.appendParamToURL(url, 'prefn2', 'size');
						url = util.appendParamToURL(url, 'prefv2', selectedLength + ' ' + selectedWidth);
					} else if (selectedLength && dataBuffer['WidthsByLength'] && dataBuffer['WidthsByLength'][selectedLength]) {
						var selectedSizes = '';
						for (var widthForLengthKey in dataBuffer['WidthsByLength'][selectedLength]) {
							var widthForLength = dataBuffer['WidthsByLength'][selectedLength][widthForLengthKey];
							
							if (selectedSizes) {
								selectedSizes += '|';
							}
							
							selectedSizes += selectedLength + ' ' + widthForLength;
						}
						
						url = util.appendParamToURL(url, 'prefn2', 'size');
						url = util.appendParamToURL(url, 'prefv2', selectedSizes);
					} else if (selectedSize) {
						url = util.appendParamToURL(url, 'prefn2', 'size');
						url = util.appendParamToURL(url, 'prefv2', selectedSize);
					}
					
					window.location.href = url;
				}
			});
			
			$container.off('click.Reset').on('click.Reset', '.btn.reset', function(event) {
				$container.find('.steps').html('');
				buildStepPlaceholders(0);
				buildStepWithCategories(0);
				$container.find('.btn.apply, .btn.reset').attr('disabled', 'disabled');
			});
			
			$container.off('click.Close').on('click.Close', '.close', function(event) {
				$container.removeClass('open');
				$('body').removeClass('scroll-lock');
			});
		}
	}
}

function buildStepWithCategories(step, categoryIdParameter) {
	var categoryId = categoryIdParameter || 'root';
	$container.find('.product-count-container').loading({
		IsImageShown: false
	});
	var $step = $container.find('[step="' + step + '"]');
	if ($step.length < 1) {
		$container.find('.steps').append('<div step="' + step + '"></div>');
		$step = $container.find('[step="' + step + '"]');
	}
	$step.loading({
		IsImageShown: false,
        Content: '<p>Your selections are loading...</p>',
		CssClass: 'bootbarnexpress',
		BackgroundColor: 'transparent'
	});
	if ($step.find('h5').length > 0) {
		$step.find('.loading-indicator-container').css('margin-top', $step.find('h5').innerHeight());
	}

	$.ajax({
        method: 'GET',
        url: Urls.BootBarnExpress.GetSubCategoriesForCategoryId,
        contentType: 'application/json',
        data: {
	        cgid: categoryId,
            prefn1: 'availableStores',
            prefv1: options.Bopis.SelectedLocations[0].StoreId
	    },
        dataType: 'json',
        success: function(data) {
            $container.attr('state', 'result');
            
            if (data && data.ProductCount) {
            	$container.find('.product-count').html(data.ProductCount.toLocaleString('en'));
            }
            
            if (data && data.Categories) {
            	var stepHtml;
            	if (StepsConfiguration[step] && StepsConfiguration[step].Header) {
            		stepHtml = '<h5>' + StepsConfiguration[step].Header + '</h5>';
            	} else {
            		stepHtml = '';
            	}
            	for (var categoryKey in data.Categories) {
            		var category = data.Categories[categoryKey];		
            		stepHtml += '<a category-id="' + category.Id + '" category-url="' + category.Url + '" class="selectable">' + category.Name + '</a>';
            	}
            	
        		$step.html(stepHtml).show().loading('destroy');
        		scrollToStep($step);
            	
                if (options.successCallback) {
                	options.successCallback(inquiryResult);
                }
            } else {
                $container.attr('result-count', '0');
                $step.show().loading('destroy');
                
                if (options.errorCallback) {
                	options.errorCallback();
                }
            }
            
            $container.find('.product-count-container').loading('destroy');
        },
        error: function() {
            if (options.errorCallback) {
            	options.errorCallback();
            }
            
            $step.show().loading('destroy');
            $container.find('.product-count-container').loading('destroy');
        }
    });	
}

function buildStepWithSizes(step, categoryIdParameter) {
	var categoryId = categoryIdParameter || 'root';
	$container.find('.product-count-container').loading({
		IsImageShown: false
	});
	var $step = $container.find('[step="' + step + '"]');
	if ($step.length < 1) {
		$container.find('.steps').append('<div step="' + step + '"></div>');
		$step = $container.find('[step="' + step + '"]');
	}
	$step.loading({
		IsImageShown: false,
        Content: '<p>Your selections are loading...</p>',
		CssClass: 'bootbarnexpress',
		BackgroundColor: 'transparent'
	});
	if ($step.find('h5').length > 0) {
		$step.find('.loading-indicator-container').css('margin-top', $step.find('h5').innerHeight());
	}
	dataBuffer['WidthsByLength'] = {};

	$.ajax({
		method: 'GET',
		url: Urls.BootBarnExpress.GetSortedRefinementValuesForRefinementId,
		contentType: 'application/json',
		data: {
            cgid: categoryId,
            refinementId: StepsConfiguration[step - 1].Refinement || 'size',
            prefn1: 'availableStores',
            prefv1: options.Bopis.SelectedLocations[0].StoreId
		},
		dataType: 'json',
		success: function(data) {
            $container.attr('state', 'result');
            
            if (data && data.ProductCount) {
            	$container.find('.product-count').html(data.ProductCount.toLocaleString('en'));
            }
            
            if (data && data.RefinementValues) {
            	var popularSizesHtml = '';
				var hideRefinementValues = '';
				var hasWidths = false;
            	if (data.PopularSizes && data.PopularSizes.length > 0) {
            		popularSizesHtml = '<h6>Most Popular</h6>';
            		
            		var parsedPopularSizeRefinementValues = parseSizeRefinementValuesIntoLengthAndWidth(data.PopularSizes);
            		if (parsedPopularSizeRefinementValues && parsedPopularSizeRefinementValues.Lengths && parsedPopularSizeRefinementValues.Lengths.length > 0) {
            			for (var refinementKey in parsedPopularSizeRefinementValues.Lengths) {
		            		var refinementValue = parsedPopularSizeRefinementValues.Lengths[refinementKey];
							var refinementAttribute;
		            		if (parsedPopularSizeRefinementValues.WidthsByLength[refinementValue]) {
								refinementAttribute = 'refinement-length';
								hasWidths = true;
		            		} else {
								refinementAttribute = 'refinement-value';
							}
		            		
		            		popularSizesHtml += '<a ' + refinementAttribute + '="' + refinementValue + '" class="selectable">' + refinementValue + '</a>';
		            	}
            		} else {
		            	for (var refinementKey in data.PopularSizes) {
		            		var refinementValue = data.PopularSizes[refinementKey];
		            		popularSizesHtml += '<a refinement-value="' + refinementValue.value + '" class="selectable">' + refinementValue.displayValue + '</a>';
		            	}
            		}
	            	popularSizesHtml = '<div class="popularSizes">' + popularSizesHtml + '</div>' + '<a class="see-more-sizes">See More Sizes</a>';
	            	hideRefinementValues = ' hidden';
            	}
            	var sizesHeaderHtml;
            	var refinementValuesHtml = '';
            	
            	var parsedSizeRefinementValues = parseSizeRefinementValuesIntoLengthAndWidth(data.RefinementValues);
            	if (parsedSizeRefinementValues && parsedSizeRefinementValues.Lengths && parsedSizeRefinementValues.Lengths.length > 0) {
            		sizesHeaderHtml = '<h5>Filter By Size</h5>';
            		
            		if (parsedSizeRefinementValues.WidthsByLength && parsedSizeRefinementValues.WidthsByLength.length > 0) {
            			dataBuffer['WidthsByLength'] = parsedSizeRefinementValues.WidthsByLength;
            		}
            		
            		for (var refinementKey in parsedSizeRefinementValues.Lengths) {
            			var refinementValue = parsedSizeRefinementValues.Lengths[refinementKey];
            			var refinementAttribute = 'refinement-length';
            			if (parsedSizeRefinementValues.WidthsByLength[refinementValue] && parsedSizeRefinementValues.WidthsByLength[refinementValue].length == 1) {
	            			refinementValue += ' ' + parsedSizeRefinementValues.WidthsByLength[refinementValue][0];
							refinementAttribute = 'refinement-value';
	            		} else if (parsedSizeRefinementValues.WidthsByLength[refinementValue]) {
							refinementAttribute = 'refinement-length';
							hasWidths = true;
						} else {
							refinementAttribute = 'refinement-value';
						}
            			
	            		refinementValuesHtml += '<a ' + refinementAttribute + '="' + refinementValue + '" class="selectable">' + refinementValue + '</a>';
	            	}
            	} else {
            		sizesHeaderHtml = '<h5>Filter By Size</h5>';
            		
	            	for (var refinementKey in data.RefinementValues) {
	            		var refinementValue = data.RefinementValues[refinementKey];
	            		refinementValuesHtml += '<a refinement-value="' + refinementValue.value + '" class="selectable">' + refinementValue.displayValue + '</a>';
	            	}
            	}
            	
            	var stepHtml = sizesHeaderHtml + popularSizesHtml + '<div class="refinementValues' + hideRefinementValues + '">' + refinementValuesHtml + '</div>';

				if (hasWidths) {
					$container.find('[step][refinement="width"]').show();
				} else {
					$container.find('[step][refinement="width"]').hide();
				}

        		$step.html(stepHtml).show().loading('destroy');
        		$step.attr('refinement', 'size');
        		scrollToStep($step);
            	
                if (options.successCallback) {
                	options.successCallback(inquiryResult);
                }
            } else {
                $container.attr('result-count', '0');
                
                if (options.errorCallback) {
                	options.errorCallback();
                }
            }
            
            $container.find('.product-count-container').loading('destroy');
        },
        error: function() {
            if (options.errorCallback) {
            	options.errorCallback();
            }
            
            $container.find('.product-count-container').loading('destroy');
        }
    });
}

function buildStepWithWidths(step, categoryIdParameter, lengthParameter) {
	var categoryId = categoryIdParameter || 'root';
	$container.find('.product-count-container').loading({
		IsImageShown: false
	});
	var $step = $container.find('[step="' + step + '"]');
	if ($step.length < 1) {
		$container.find('.steps').append('<div step="' + step + '"></div>');
		$step = $container.find('[step="' + step + '"]');
	}
	
	var refinementValuesHtml = '';
	var widthType;
	for (var refinementValueKey in dataBuffer['WidthsByLength'][lengthParameter]) {
		var refinementValue = dataBuffer['WidthsByLength'][lengthParameter][refinementValueKey];
		var refinementValueDisplay = refinementValue == 'D' ? refinementValue + '&nbsp;<span class="width-note">(REG)</span>' : refinementValue;
		refinementValuesHtml += '<a refinement-width="' + refinementValue + '" class="selectable">' + refinementValueDisplay + '</a>';
		
		if (!widthType) {
			var widthParsedAsInteger = parseInt(refinementValue, 10);
			
			if (widthParsedAsInteger == refinementValue) {
				widthType = 'pants';
			}
		}
	}

	var widthsHeaderHtml;
	var widthTypeLabel;
	if (widthType == 'pants') {
		widthTypeLabel = 'Inseam';
	} else {
		widthTypeLabel = 'Width';
	}
	widthsHeaderHtml = '<h5>Filter By ' + widthTypeLabel + '</h5>';
	var stepHtml = widthsHeaderHtml + '<div class="refinementValues">' + refinementValuesHtml + '</div>';
	
	$step.html(stepHtml).show();
	$step.attr('refinement', 'width');
	scrollToStep($step);
}

function buildStepPlaceholders(step) {
	for (var stepIndex = step; stepIndex < options.Bopis.StepConfiguration.length; stepIndex++) {
		var $step = $container.find('[step="' + stepIndex + '"]');
		if ($step.length < 1) {
			$container.find('.steps').append('<div step="' + stepIndex + '"></div>');
			$step = $container.find('[step="' + stepIndex + '"]');
		}
		buildStepPlaceholder(stepIndex, $step);
	}
}

function buildStepPlaceholder(step, $step) {
	var stepConfiguration = options.Bopis.StepConfiguration[step];

	if (!$step) {
		var $step = $container.find('[step="' + step + '"]');
		if ($step.length < 1) {
			$container.find('.steps').append('<div step="' + step + '"></div>');
			$step = $container.find('[step="' + step + '"]');
		}
	}

	if (stepConfiguration.Refinement) {
		$step.attr('refinement', stepConfiguration.Refinement);
	}

	if (stepConfiguration && stepConfiguration.Placeholder) {
		var stepHeaderHtml = '';
		if (stepConfiguration.Placeholder.Header) {
			stepHeaderHtml = '<h5 class="placeholder">' + stepConfiguration.Placeholder.Header + '</h5>';
		} else if (stepConfiguration.Header) {
			stepHeaderHtml = '<h5 class="placeholder">' + stepConfiguration.Header + '</h5>';
		}

		var refinementValuesHtml = '';
		if (stepConfiguration.Placeholder.Values) {
			for (var refinementKey in stepConfiguration.Placeholder.Values) {
				refinementValuesHtml += '<a refinement-value="' + stepConfiguration.Placeholder.Values[refinementKey] + '" class="placeholder selectable">' + stepConfiguration.Placeholder.Values[refinementKey] + '</a>';
			}
		} else if (stepConfiguration.Placeholder.Text) {
			refinementValuesHtml += '<p class="placeholder">' + stepConfiguration.Placeholder.Text + '</p>';
		}
		
		if (stepConfiguration.Refinement == 'size') {
			refinementValuesHtml = '<div class="popularSizes"><h6 class="placeholder">Most Popular</h6>' + refinementValuesHtml + '</div><a class="see-more-sizes placeholder">See More Sizes</a>';
		} else {
			refinementValuesHtml = '<div class="refinementValues">' + refinementValuesHtml + '</div>';
		}

		$step.html(stepHeaderHtml + refinementValuesHtml);
	} else {
		$step.html('').hide();
	}
}

function updateProductCountForCategoryAndSize(step, selectedCategory, selectedSize) {
	$container.find('.product-count-container').loading({
		IsImageShown: false
	});
	
	$.ajax({
        method: 'GET',
        url: Urls.BootBarnExpress.GetSortedRefinementValuesForRefinementId,
        contentType: 'application/json',
        data: {
            cgid: selectedCategory,
            prefn1: 'availableStores',
            prefv1: options.Bopis.SelectedLocations[0].StoreId,
            prefn2: 'size',
            prefv2: selectedSize
        },
        dataType: 'json',
        success: function(data) {
            if (data && data.ProductCount) {
            	$container.find('.product-count').html(data.ProductCount.toLocaleString('en'));
            }
            
			if (options.successCallback) {
            	options.successCallback(inquiryResult);
            }
            
            $container.find('.product-count-container').loading('destroy');
        },
        error: function() {
        	if (options.errorCallback) {
            	options.errorCallback();
            }
        	
        	$container.find('.product-count-container').loading('destroy');
        }
    });
}

function parseSizeRefinementValuesIntoLengthAndWidth(refinementValues) {
	var sizeRefinementValuesLengths = [];
	var sizeRefinementValuesWidths = [];
	var sizeRefinementValuesWidthsByLength = [];
	
	if (refinementValues) {
		for (var refinementKey in refinementValues) {
			var refinementValue = refinementValues[refinementKey];
			
			var splitRefinementValue = refinementValue.value.split(' ');
			if (splitRefinementValue && 'length' in splitRefinementValue && splitRefinementValue.length > 1) {
				var sizeRefinementValueWidth = splitRefinementValue.pop();
				var sizeRefinementValueLength = splitRefinementValue.join(' ');
				
				if (!sizeRefinementValuesWidthsByLength[sizeRefinementValueLength]) {
					sizeRefinementValuesWidthsByLength[sizeRefinementValueLength] = [];
				}
				sizeRefinementValuesWidthsByLength[sizeRefinementValueLength].push(sizeRefinementValueWidth);
				
				if (sizeRefinementValuesLengths.indexOf(sizeRefinementValueLength) < 0) {
					sizeRefinementValuesLengths.push(sizeRefinementValueLength);
				}
				
				if (sizeRefinementValuesWidths.indexOf(sizeRefinementValueWidth) < 0) {
					sizeRefinementValuesWidths.push(sizeRefinementValueWidth);
				}
			} else {
				sizeRefinementValuesLengths.push(refinementValue.value);
			}
		}
	}
	
	var payload = {
		Lengths: sizeRefinementValuesLengths,
		Widths: sizeRefinementValuesWidths,
		WidthsByLength: sizeRefinementValuesWidthsByLength
	}
	
	return payload;
}

function scrollToStep($step) {
	$container.find('.modal-body').animate({
		scrollTop: $step.position().top
	});
}