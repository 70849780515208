'use strict';

exports.initialize = initialize;

function initialize() {
	$(document).off('click.HeaderBanner.Close').on('click.HeaderBanner.Close', '.header-banner .close', function(event) {
		event.preventDefault();
		var $this = $(this);
		
		var contentAssetId = $this.closest('[content-asset-id]').attr('content-asset-id');
		if (contentAssetId) {
			acknowledge(contentAssetId, 'Yes', function() {
				$('.header-banner').hide();
				$(document).trigger('app.resize');
			});
		}
	});

	$(document).off('click.CookieNotification.Yes').on('click.CookieNotification.Yes', '.notification.cpra-cookie-notification .actions .yes', function(event) {
		event.preventDefault();
		var $this = $(this);
		
		$this.closest('.notification').addClass('hidden');
		acknowledge('Cookies', 'Yes');
	});
	
	$(document).off('click.CookieNotification.No').on('click.CookieNotification.No', '.notification.cpra-cookie-notification .actions .no', function(event) {
		event.preventDefault();
		var $this = $(this);
		
		window.location.href = $this.attr('href');
		acknowledge('Cookies', 'No');
	});
}

function acknowledge(notification, acknowledgement, callbackSuccessParameter, callbackErrorParameter) {
	var callbackSuccess = callbackSuccessParameter || function() { };
	var callbackError = callbackErrorParameter || function() { };

	if (notification && acknowledgement) {
		$.ajax({
			url: Urls.CookieNotification.Acknowledge,
			type: 'POST',
			data: {
				Notification: notification,
				Acknowledgement: acknowledgement
			},
			dataType: 'json',
			success: callbackSuccess,
			error: callbackError
		});
	}
}