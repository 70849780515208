'use strict';

var render = function(styleCountCache) {
	if (styleCountCache) {
		$('[data-link-category]').each(function() {
			var categoryId = $(this).data('link-category');
			
			if (categoryId) {
				var categoryRefinements = styleCountCache.CategoryLinks[categoryId];
				if (categoryRefinements && categoryRefinements.count > 0) {
					var $styleCount = $(this).find('.style-number'); 
					var messageTemplate = categoryRefinements.count > 1 ? Resources.STYLE_PLURAL : Resources.STYLE_SINGULAR;
					var message = messageTemplate.replace('{0}', categoryRefinements.count);
					$styleCount.addClass('visible').text(message);

					return;
				}	
			}

			$(this).closest('li').remove();
		});

		$('[data-link-attribute-id]').each(function() {
			var categoryId = $(this).data('link-cgid'),
			refinementId = $(this).data('link-attribute-id'),
			refinementValue = $(this).data('link-attribute-value');

			var categoryRefinements = styleCountCache.RefinementLinks[categoryId];
			if (categoryRefinements) {
				var attributeRefinements = categoryRefinements[refinementId];
				if (attributeRefinements) {
					var attributeRefinement = attributeRefinements[refinementValue];
					
					if (attributeRefinement && attributeRefinement.count > 0) {
						var $styleCount = $(this).find('.style-number');
						var messageTemplate = attributeRefinement.count > 1 ? Resources.STYLE_PLURAL : Resources.STYLE_SINGULAR;
						var message = messageTemplate.replace('{0}', attributeRefinement.count);
						$styleCount.addClass('visible').text(message);
						
						return;
					}

				}         		
			}
			
			$(this).closest('li').remove();
		});
	}
}

module.exports.render = render;