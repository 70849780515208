'use strict';

/**
 * @function
 * @description Initializes the tooltip-content and layout
 */
exports.init = function(optionsParameter, $containerParameter) {
	var defaultOptions = {
        items: '.tooltip',
        track: true,
        content: function () {
            return $(this).find('.tooltip-content').html();
        }
    };
	
	var options;
	if (optionsParameter) {
		options = $.extend(defaultOptions, optionsParameter);
	} else {
		options = defaultOptions;
	}
	
	var $container;
	if ($containerParameter) {
		$container = $containerParameter;
	} else {
		$container = $(document);
	}
	
    $container.tooltip(options);
    
    $(document).off('click', '.tooltip').on('click', '.tooltip', function(e) {
    	e.preventDefault();
    });
    
    $(document).off('click', '.share-link').on('click', '.share-link', function(e) {
        e.preventDefault();
        var target = $(this).data('target');
        if (target) {
            $(target).toggleClass('active');
        }
    });
};
