'use strict';

module.exports = {
	
	elementPrefix: 'shipping-restrictions-popup',
	element: null,
	defaultTitle: '',
	defaultContent: '',
	
	init: function (options) {
		if (!$( '.' + this.elementPrefix ).length) {
			$('body').append('<div class="' + this.elementPrefix + '"><div class="' + this.elementPrefix + '__popup"><h2 class="' + this.elementPrefix + '__h2"></h2><a class="' + this.elementPrefix + '__close" href="#">&times;</a><div class="' + this.elementPrefix + '__content"></div></div></div>');
			this.element = $( '.' + this.elementPrefix );
			var el = this;
			this.element.find('.' + this.elementPrefix + '__close').on('click', function (e) {
				el.hidePopup(e);
			});
		}
		this.element.find('.' + this.elementPrefix + '__h2').html(options.title || this.defaultTitle);
		this.element.find('.' + this.elementPrefix + '__content').html(options.content || this.defaultContent);
		return this;
	},
	
	showPopup: function () {
    	this.element.addClass(this.elementPrefix + '__visible');
    	return this;
    },
    
    hidePopup: function (e) {
    	e.preventDefault();
    	this.element.removeClass(this.elementPrefix + '__visible');
    },
};
