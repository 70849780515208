'use strict';

/* -------------------------
 * ADA Slick Mods
 * -------------------------
 */

exports.init = function init () {
    $(document).off('SlickSliderAccessibilityMods').on('SlickSliderAccessibilityMods', function(event) {
        $('.slick-slider .slick-slide, .slick-slider .slick-active').removeAttr('aria-describedby role tabindex');			
        $('.slick-slider .slick-dots button').each(function() {
            var buttonVal = $(this).html();
            var addSpan = '<span class="visually-hidden">' + buttonVal + '</span>';
            $(this).html(addSpan);
        });
    });
};