'use strict';
var direction, videoCount, width, height, videoCounter = 0, posValue = 0, lastCounter = 0, arrowPos;

var setCarousel = function(){
	videoCount = $('.video-container ul li').length;
	width = $('.video-container').width();
	height = width * 315 / 420;
	$('.youtube-video iframe').css({'width': width + 'px','height': height + 'px'});
	width += 40;
	arrowPos = height / 2 - 20;
	$('.video-container .arrows .arrow').css('top', arrowPos + 'px');
};

var arrowClick = function(arrow){
	direction = arrow.hasClass('left') ? 'left' : 'right';
	lastCounter = videoCounter;
	if(direction == 'right'){
		if(videoCounter == (videoCount - 1)){
			posValue = 0;
			videoCounter = 0;
		}
		else{
			videoCounter++;
			posValue = videoCounter * width;
		}
	}
	else{
		if(videoCounter == 0){
			posValue = (videoCount - 1) * width;
			videoCounter = videoCount - 1;
		}
		else{
			videoCounter--;
			posValue = videoCounter * width;
		}
	}
	$('.video-container ul').animate({'left':'-' + posValue + 'px'}, 'fast');
	var iframe = $('.youtube-video iframe:eq('+ lastCounter +')');
	$(iframe).attr('src', $(iframe).attr('src'));
};

module.exports = function () {
	setCarousel();
    $('.video-container .arrows .arrow').on('click', function(){
    	arrowClick($(this));
    });
};