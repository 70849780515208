'use strict';

var SiteConstants = require('../../../bc_sheplersbootbarn_core/cartridge/js/SiteConstants');

var siteName;
var $win = $(window);


/**
     * @function
     * @description Configures parameters and required object instances
     */
function init () {
    siteName = window.pageContext.site || '';

    $('.top-banner .header-search button').on('click', function(event) {
        event.preventDefault();

        var $this = $(this);
        var $searchForm = $this.closest('form');
        var $searchInput = $searchForm.find('[name="q"]');
        var action = $searchForm.attr('action');
        var keyword = $searchInput.val();
        var url = action + "?q=" + keyword;

        if (keyword && keyword != $searchInput.attr('placeholder')) {
            $searchForm.attr('action', url);
            $searchForm.submit();
        }
    });

    if (siteName == SiteConstants.SiteIds.BootBarnUS) {
        $('.header-search').on('click',function() {
            $('.header-search').addClass('active');
        });

        $('.header-search input#q').focusout(function() {
            $('.header-search').removeClass('active');
        });
    }
    if (SitePreferences.PROMOTED_SEARCHES) {
        $(document).on('focus click keydown', '.header-search input#q, .header-search input#mobile-q', function() {
            promotedSuggestions();
            // TO-DO: When ticket pops up for suggested searches, create another preference and add the IF statement here
        });

        $(document).on('mouseleave', '.search-suggestions-container, .header-search [name="q"]', function(e) {
            var searchHeader = document.querySelector('.header-search');
            if (!searchHeader.contains(e.relatedTarget) && $('.search-suggestions-container').find(e.relatedTarget).length < 1) {
                $('.search-suggestions-container').hide();
            }
        });

        $(document).on('click', '.close-search-suggestions', function() {
            $('.search-suggestions-container').hide();
        });  
        
        $(document).off('SearchBar.Expand').on('SearchBar.Expand', function() {
            expand();
        });

        $(document).off('SearchBar.ScrollTop').on('SearchBar.ScrollTop', function() {
            scrollTop();
        });

        $(document).off('SearchBar.Hide').on('SearchBar.Hide', function() {
            hide();
        });
    }
}

/**
 * @function
 * @description
 */
function expand () {
    $('.top-banner').toggleClass('search-enabled');
    var $target = $('.header-search');
    if (siteName == SiteConstants.SiteIds.CountryOutfittersUS) {
        $target = $('.search-holder');
    }
            
    if ((siteName == SiteConstants.SiteIds.SheplersUS || siteName == SiteConstants.SiteIds.CountryOutfittersUS) && $('html').hasClass('size-mobile')) {
        $target.css('top', $('.top-banner-bar').outerHeight() + $('.top-banner-inner').outerHeight() - $win.scrollTop());
    } else {
        $target.css('top', '');
    }
            
    if (siteName == SiteConstants.SiteIds.SheplersUS) {
        $target.find('input[type="text"]').focus();
    }
            
    if ($('.top-banner').hasClass('search-enabled') && $('html').hasClass('size-mobile') && $('.top-banner-bar').attr('data-header-for') == 'bootbarn_us') {
        $('#search #q').focus();
    }
}

/**
 * @function
 * @description
 */
function scrollTop () {
    if (siteName == SiteConstants.SiteIds.SheplersUS || siteName == SiteConstants.SiteIds.CountryOutfittersUS) {
        var $target = $('.header-search');
        if (siteName == SiteConstants.SiteIds.CountryOutfittersUS) {
            $target = $('.search-holder');
        }
        
        if ($('html').hasClass('size-mobile')) {
            $target.css('top', $('.top-banner-bar').outerHeight() + $('.top-banner-inner').outerHeight() - $win.scrollTop());
        } else {
            $target.css('top', '');
        }
    }
}

/**
 * @function
 * @description
 */
function hide () {
    if($('.header-search').hasClass('show')) {
        $('.header-search').removeClass('show');
        if (siteName != SiteConstants.SiteIds.BootBarnRspUS) {
            $('.top-banner').removeClass('search-enabled');
        }
    }
}

/**
 * @function
 * @description trigger promoted suggestions for site search
 */
function promotedSuggestions () {
    var isContentPopulated = $('.search-suggestions-container > div').length > 0;
    if ($('.search-suggestions-container').is(':hidden') && isContentPopulated) {
        $('.search-suggestions-container').show();
    }
}

exports.init = init;
