'use strict';

var _ = require('lodash');
var TemplateConstants = require('../../../../bc_sheplersbootbarn_core/cartridge/js/TemplateConstants');

$.widget('bootbarn.loading', {
    options: {
        IsImageShown: true,
        IsLabelShown: true,
        Label: Resources.Loading.Loading,
        Content: '',
        CssClass: '',
        BackgroundColor: null
    },

    _create: function() {
        this.element.attr('loading', 'loading');

        var loadingIndicatorTemplate = _.template($('#template-loading-indicator').html(), TemplateConstants.Lodash.Settings);
        var $loadingIndicatorContainer = $(loadingIndicatorTemplate({
        	Content: this.options.Content
        }));
        var $loadingIndicator = $loadingIndicatorContainer.find('.loading-indicator');
        $loadingIndicatorContainer.css('background-color', this.options.BackgroundColor ? this.options.BackgroundColor : this.element.css('background-color'));

        if (this.options.CssClass) {
            $loadingIndicatorContainer.addClass(this.options.CssClass);
        }
        
        if (this.options.IsLabelShown && !this.options.Content) {
            $loadingIndicator.addClass('label');
            $loadingIndicator.attr('label', this.options.Label);
        }

        if (this.options.IsImageShown) {
            $loadingIndicator.addClass('image');
        }

		$loadingIndicatorContainer.appendTo(this.element);
	},
    
    _destroy: function() {
		this.element.removeAttr('loading').find('.loading-indicator-container').remove();
    }
});
