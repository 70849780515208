'use strict';

var	util = require('../util');
var page = require('../page');
var SiteConstants = require('../../../../bc_sheplersbootbarn_core/cartridge/js/SiteConstants');

function initializeEvents(){
	
	if (pageContext.site === SiteConstants.SiteIds.BootBarnUS && 'pagePath' in pageContext && (pageContext.pagePath == 'BRewardedBalanceCheck' || pageContext.pagePath == 'AccountView' || pageContext.pagePath == 'RewardsProgram')) {
    	passBreadSetup();
    }
};

function passBreadSetup() {
	var rewardsNumber = $('.customer-rewards-number').text();

	BreadPayments.setup({
		loyaltyID: rewardsNumber,
		storeNumber: util.isMobile() ? SitePreferences.PLCC_STORE_NUMBER_MOBILE : SitePreferences.PLCC_STORE_NUMBER_WEB,
		existingCardHolder: false,
		env: SitePreferences.PLCC_ENVIRONMENT,
	});

	BreadPayments.registerPlacements([landingPlacement]);
}

exports.init = function () {
    initializeEvents();    
};
