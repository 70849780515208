'use strict';

var LocationSearch = require('../../../../app_storefront_core/cartridge/js/bopis/components/LocationSearch');

exports.init = function (options) {
	var loadingIndicatorSettings = {
		IsLabelShown: false,
		CssClass: 'fullscreen',
		BackgroundColor: 'transparent'
	};

	if ($('.pt_storefront .search-stores').length > 0) {
		var locationSearch = new LocationSearch();
		$(document).off('click.Storefront.StoreSearch').on('click.Storefront.StoreSearch', '.pt_storefront .search-stores:not(.disabled)', function(event) {
			event.preventDefault();

			locationSearch.initialize($('.location-search-dialog'), options);
			$('.location-search-dialog').trigger('LocationSearch.FormSubmit');
		});
	}

	$('.pt_storefront .section .btn:not(.search-stores)').off('click.LoadingIndicator').on('click.LoadingIndicator', function() {
		$('body').loading(loadingIndicatorSettings);
	});
};