'use strict';

var page = require('./page');

var isTouchMoved = false;
var isCurrentTargetSwatch = false;

function initializeEvents() {
	$(document).off('click.Swatch.Menu.Close').on('click.Swatch.Menu.Close', function (event) {
		var $currentTarget = $(event.currentTarget);
		
		if ($currentTarget.closest('.slide-down-swatch').length == 0) {
			$('.slide-down-swatch ul').slideUp();
		}
	});
	
	$(document).off('touchstart.Swatch.Menu.Close').on('touchstart.Swatch.Menu.Close', function (event) {
		var $currentTarget = $(event.currentTarget);
		
		isTouchMoved = false;
		isCurrentTargetSwatch = $currentTarget.closest('.slide-down-swatch').length > 0;
	});
	
	$(document).off('touchmove.Swatch.Menu.Close').on('touchmove.Swatch.Menu.Close', function (event) {
		isTouchMoved = true;
	});
	
	$(document).off('touchend.Swatch.Menu.Close').on('touchend.Swatch.Menu.Close', function (event) {
		if (!isCurrentTargetSwatch && !isTouchMoved) {
			$('.slide-down-swatch ul').slideUp();
		}
	});
	
	$('.swatches.size > li > a.slide-down').on('click', function(event) {
		event.preventDefault();
		event.stopPropagation();
				
		$(this)
		.next('ul')
		.stop()
		.slideToggle()
		.parent()
		.siblings()
		.children('ul')
		.slideUp()
		.prev('.slide-down');
	});

	// Default selected value as slide down
	$('.swatches.size > li > a.slide-down')
	.next('ul')
	.find('.current')
	.closest('ul')
	.prev('.slide-down')
	.addClass('current')
	.closest('li').addClass('active');

	$('.refinements .swatches.size > li.active > ul').slideDown();

	$('.swatches > li.slide-down-swatch > ul').off('click');

	// Remove empty tiles 
	$('.swatches.size > li > a.slide-down').next('ul').each(function() {
		if ($(this).find('li').length === 0) {
			$(this).closest('li').remove();
		}
	});
}

var sizeRefinements = {
	init: function() {
		initializeEvents();
	}
};

module.exports = sizeRefinements;