'use strict';

exports.GetPosition = getPosition;

function getPosition() {
	return new Promise(function(resolve) {
		if (navigator.geolocation) {
			var geolocationSuccess = function(position) {	
				resolve(position);
			};

			var geolocationError = function() {
				resolve();
			};

			navigator.geolocation.getCurrentPosition(geolocationSuccess, geolocationError);
		} else {
			resolve();
		}
	});
}