'use strict';

exports.Validate = validate;

function validate(action) {
	return new Promise(function(resolve, reject) {
		$.ajax({
			method: 'post',
			url: Urls.Request.Validate,
			contentType: 'application/json',
			data: JSON.stringify({
				Action: action
			}),
			dataType: 'json',
			success: function(data) {
				if (data) {
					resolve(data);
				} else {
					reject(data);
				}
			},
			error: function(data) {
				if (data.status == 302 && data.responseText) {
					location.href = data.responseText;
				} else {
					reject(data);
				}
			}
		});
	});
}