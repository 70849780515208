'use strict';

/* -------------------------
 * LOGIN
 * -------------------------
 */

var loadingIndicator = require('./components/LoadingIndicator');
var dialog = require('./dialog');
var loginDialog = require('./loginDialog');
var recaptcha = require('./components/Recaptcha');
var util = require('./util'); //util reference
var	validator = require('./validator');

var GoogleTagManagerClient = require('../../../bc_sheplersbootbarn_core/cartridge/js/util/GoogleTagManagerClient');
var SiteConstants = require('../../../bc_sheplersbootbarn_core/cartridge/js/SiteConstants');
var TPromise = require('promise');

var dataBuffer = {};
var options = {};
var loaded = {};
var loadingIndicatorSettings = {
    IsLabelShown: false,
    CssClass: 'fullscreen',
    BackgroundColor: 'transparent'
};
var opcLoggedInCustomerAddresses = [];

/**
 * @private
 * @function
 * @description init events for the loginPage
 */
function initLoginEvents() {
    //toggle the value of the rememberme checkbox
    $(document).on('change', '.login-rememberme input', function () {
        if ($(this).prop('checked')) {
            $('#rememberme').val('true');
        } else {
            $('#rememberme').val('false');
        }
    });
    $(document).on('click', '#optin-privacy-policy', function(e) { 
        e.preventDefault(); 
        location.href = Urls.privacyPage; 
    });
    $(document).on('click', '#reward-learn-more', function(e) { 
        e.preventDefault(); 
        location.href = Urls.learnFAQ; 
    });
    $(document).on('click', '.movetoaddr', function(e){
        e.preventDefault();
        $('html, body').animate({ 
            scrollTop: $('.billing-address-form').eq(0).offset().top
        }, 1000);
    });
    $(document).on('click', '#attach-rewards-form label', function() {
        $('#attach-rewards-form input').removeAttr('checked');
        $(this).siblings('[name="attach-rewards-options"]').attr('checked', 'checked');
    });
}

function validateLoginForm($form, checkFields) {	
    if (!$form || !checkFields) { return false }

    var $emailField = $form.find('input[name*="_username"]');	
    var $passwordField = $form.find('input[name*="_password"]');
    var $passcodeField = $form.find('input[name*="_passcode"]');
    var emailValue = $emailField.length > 0 ? $emailField.val().trim() : null;
	var passwordValue = $passwordField.length > 0 ? $passwordField.val().trim() : null;
    var passcodeValue = $passcodeField.length > 0 ? $passcodeField.val().trim() : null;
	var formValidation = true;

    for (var i = 0; i < checkFields.length; i++) {
        var currentField = checkFields[i];
                
        if (currentField.toUpperCase() == 'EMAIL' && (!emailValue || emailValue.length == 0)) {
            formValidation = false;
            $form.find('input[name*="_username"]').addClass('error');
        }
        if (currentField.toUpperCase() == 'PASSWORD' && (!passwordValue || passwordValue.length == 0)) {
            formValidation = false;
            $form.find('input[name*="_password"]').addClass('error');
        }
        if (currentField.toUpperCase() == 'PASSCODE' && (!passcodeValue || passcodeValue.length == 0)) {
            formValidation = false;
            $form.find('input[name*="_passcode"]').addClass('error');
        }
    }
			
	if (!formValidation) {
        $form.find('.login-dialog-error .invalid-missing-fields').removeClass('d-none');
        return false;
    } else {
        $form.find('.login-dialog-error span').addClass('d-none');
        return true;
    }
}

function errorHandling(data, dialogTitle, dialogHtml) {
    if (data && typeof data === 'object' && 'message' in data) {
        console.log(data.message);
    }
    dialog.open({
        html: dialogHtml,
        options: {
            title: dialogTitle,
            draggable: false,
            open: function(event, ui) {                                    
                $('body').addClass('dialog-open');
            },
            close: function(event, ui) {
                $('body').removeClass('dialog-open');
            }
        }
    });
}

// Cordial tracking for loginPage and loginDialog
function loginCordialAccountEvent($form, isLoginDialog) {
    var username = $form.find('input[name*="_username"]').val().trim();
        
    var auth_data = {
        email: username
    }
        
    var contact_data = {
        'channels': {
            'email': {
                'address': username      
            }
        }
    };
    
    var properties = {
        "email": username,
        "loginType": isLoginDialog ? "login-dialog" : "login"
    };
        
    if (typeof crdl !== 'undefined' && typeof crdl === 'function') {
        crdl([				
            ['contact', auth_data, contact_data],
            ['event', 'login', properties]
        ]);
    }
}

// Cordial password reset - no account event
function passwordResetNoAccountCordialEvent(container) {
    if (container && $(container).find('.reset-password .no-account').length > 0) {
        var email = $('.no-account').text();
	
		var auth_data = {
			email: email
		}
		var contact_data = {
			'channels': {
			    'email': {
			        'address': email
			    }
			}
		};
			
		var properties = {
			"email": email
		};

		if (typeof crdl !== 'undefined' && typeof crdl === 'function') {
			crdl([
				['contact', auth_data, contact_data],
				['event', 'reset-password-no-account', properties]
			]);
		}
	}
}

function initializeRecaptchaForLogin() {
	renderRecaptchaForLogin();
	$(document).on('Account.SubmitLogin', function(event) {
		$('#accountLoginButton').click();
	});
}

function renderRecaptchaForLogin() {
	if (loaded.Recaptcha && $('#accountLoginButton').length > 0 && options && options.Recaptcha && options.Recaptcha.EnabledForAccountLogin && options.Recaptcha.GoogleSiteKey) {
		dataBuffer.LoginRecaptcha = grecaptcha.render('accountLoginButton', {
	        'sitekey': options.Recaptcha.GoogleSiteKey,
	        'callback': accountLogin,
	        'badge': 'bottomleft'
	    });
	}
}

function renderRecaptchaForPasswordReset() {
	if (loaded.Recaptcha && $('#request-password-send-btn').length > 0 && options && options.Recaptcha && options.Recaptcha.EnabledForPasswordReset && options.Recaptcha.GoogleSiteKey) {
		if (dataBuffer.ResetPasswordRecaptcha) {
			grecaptcha.reset(dataBuffer.ResetPasswordRecaptcha);
		}

		dataBuffer.ResetPasswordRecaptcha = grecaptcha.render('request-password-send-btn', {
	        'sitekey': options.Recaptcha.GoogleSiteKey,
	        'callback': window.resetPassword,
	        'badge': 'bottomleft'
	    });
	}
}

var updateCustomerDetails = function (form, name) {
    var $form = $(form);
 
    return TPromise.resolve($.ajax({
        type: 'POST',
        url: util.ajaxUrl(Urls.onePageLogin),
        data: $form.serialize() + '&' + name + '=' + name
    }));
 }

function showNotification(text, selector, msgType) {
    var $selector = $(selector);
	if ($selector.length == 0) {
        var $form = $('form.opc-login'),
            $selector = $('<span/>', {'class': 'login-notification', 'tabindex': '0'}).wrap('<div class="formfield clearfix error-box"><div class="form-row required"><div class="field-wrapper"></div></div></div>');

        $selector.parent().parent().parent().insertBefore('form.opc-login .form-row-button');
        $selector = $(selector);
    }

    $selector.removeClass('success error').addClass(msgType).text(text).focus();
}
 
function clearNotification(selector) {
     if(selector.length){
        $('.formfield.error-box').remove();
		selector.removeClass('success error');
     }
}
 
// GTM and Cordial tagging for cart opc-login
function opcLoginTagging($form, callback) {
    var email =  $form.find('input[name*="username"]').val();
    var auth_data = {
        email: email
    }
    var contact_data = {
        'channels': {
            'email': {
                'address': email      
            }
        }
    };
    var properties = {
        "email": email
    };
    GoogleTagManagerClient.PushDataLayerForDataLayerType('CustomerLogin', { method: 'cart' }).then(function() {
        if (SitePreferences.CORDIAL_ENABLED) {
            if (typeof crdl !== 'undefined' && typeof crdl === 'function') {
                crdl([
                    ['contact', auth_data, contact_data],['event', 'login', properties]
                ]);
            }
        }
        if (callback) callback();
    });
}

function passwordlessLoginSequence(isCheckoutLogin) {
    if (!isCheckoutLogin) {
        $('#otp-toggle-button').hide();
    }
    $('.login-dialog-signin-container, .login-dialog-register-message, #toggle-registration').hide();
    $('.login-dialog-passcode-container').addClass('passcode-form-show');
    $('.otp-passcode-field-container, .otp-resend-code-container').hide();
    $('.otp-email-field-container').show();
    $('.login-dialog-passcode-container input[name*="dwfrm_otplogin"]').val(''); // clear previous form input values
}

/**
 * Login reporting - increments counters in custom object
 * @param {String} type - Either 'normal' for traditional login type, or 'passwordless' for new login type
 * @param {String} source - Source of login, which can be original, cart, cart-modal, or wishlist-modal
 */
function trackLoginActions(type, source) {
    if (!type || !source) {
        return;
    }

    $.ajax({
        type: 'POST',
        url: Urls.loginTracking,
        contentType: 'application/json',
        data: JSON.stringify({
            type: type,
            source: source
        }),
        error: function(jqXHR, textStatus, errorThrown) {
            console.log('Login-TrackActions: Error in incrementing login action counters. ERROR TEXT: '+textStatus+', ERROR THROWN: '+errorThrown+'.');
        },
        success: function(response) {
            if (response) {}
        }
    });
}

/**
 * @private
 * @function
 * @description init login button event for the loginPage, loginDialog and checkout login
 */
function initLoginButtons() {
	$(document).on('click', '#accountLoginButton', function(event) {
		event.preventDefault();
        var dialogHtml = '<p style="padding: 10px;">Oops, something went wrong with login. Please try again later.</p>';
        var $accountLoginButton = $(this);
        var $accountLoginForm = $accountLoginButton.closest('form#dwfrm_login');
        var formRememberMe = $accountLoginForm.find('input[name*="dwfrm_login_rememberme"]').is(':checked');
        var formUsername = formRememberMe ? '=' + $accountLoginForm.find('input[name*="dwfrm_login_username"]').val() : '';
        var formUrl = $accountLoginForm.attr('action');

        // Clear previous form errors
        $accountLoginForm.find('input').removeClass('error');
        $accountLoginForm.find('.login-dialog-error span').addClass('d-none');

        var isValidForm = validateLoginForm($accountLoginForm, ['email','password']);
        var isLoginDialog = formUrl.indexOf('loginDialog') >= 0;
        var formData = $accountLoginForm.serialize();

        if (!isLoginDialog) {
            $('[data-login-source]').attr('data-login-source', 'original'); // For reporting 
        }
		
        // Using AJAX instead of default HTML button submit for login page and dialog
        if (isValidForm) {        
            $('body').loading(loadingIndicatorSettings);
            $.ajax({
                type: 'POST',
                url: formUrl,
                data: formData,
                success: function(data, textStatus, jqXHR) {
                    if (typeof data === 'object' && 'result' in data) {
                        if (data.result == 'error') {
                            $('body').loading('destroy');
                            if (isLoginDialog) {
                                $accountLoginForm.find('.login-dialog-error .invalid-login').removeClass('d-none');
                            } else {
                                window.location.href = data.loginDialogContinueUrl + '?retry';
                            }
                        } else if (data.result == 'success' || data.result == 'complete') {
                            $('body').loading('destroy');
                            // 'validateCustomer' property only returns in the data if this is a dialog. Otherwise, rewards validation happens through the URL using loginDialogContinueUrl. 
                            // If this is a rewards customer, and the cusomter account requires validation...
                            if ('validateCustomer' in data && data.validateCustomer) {
                                if (SitePreferences.CORDIAL_ENABLED) {
                                    loginCordialAccountEvent($accountLoginForm, isLoginDialog);
                                }
                                var newAttributes = {
                                    "data-modal-type": "login",
                                    "data-continue-url": 'loginDialogContinueUrl' in data ? data.loginDialogContinueUrl : '',
                                    "data-first-name": 'customerFirstName' in data ? data.customerFirstName : '',
                                    "data-store-number": 'storeFromProfile' in data ? data.storeFromProfile : '',
                                    "data-cart-quantity": 'cartQuantity' in data ? data.cartQuantity : ''
                                }
                                $('.login-dialog-validate-customer').html(data.validateCustomer);
                                $('.login-dialog-validate-customer').addClass('validate-customer-show follow-account-dialog-path').attr(newAttributes);
                                // Do this in two places: 
                                // (1) for login-dialog here because the element is only loaded into page via AJAX, 
                                // (2) init of page load for regular rewards verification pages through normal login flow
                                initAddRulesForCodeInput();
                            } else {
                                // Fire the cordial login event
                                if (SitePreferences.CORDIAL_ENABLED) {
                                    loginCordialAccountEvent($accountLoginForm, isLoginDialog);
                                }
                                trackLoginActions('password', $('[data-login-source]').attr('data-login-source'));

                                // Handle follow-up actions accordingly
                                $(document).trigger('PostAjaxAuthenticationActions.Initialize', { results: data, isAccountDialog: isLoginDialog, displayMessage: 'login' });
                            }
                        } else {
                            $('body').loading('destroy');
                            errorHandling(data, 'Login - Error', dialogHtml);
                        }
                    } else {
                        $('body').loading('destroy');
                        if (isLoginDialog) {
                            errorHandling(data, 'Login - Error', dialogHtml);
                        } else {
                            window.location.href = location.href.split('?')[0]+'?retry';
                        }
                    }
                },
                error: function(jqXHR, textStatus, errorThrown) {
                    $('body').loading('destroy');
                    if (!isLoginDialog) {
                        window.location.href = location.href.split('?')[0]+'?retry';
                    }
                }
            });
        }  
	});

    $(document).on('click', '.opc-login-submit-button', function(event) {
        event.preventDefault();
        var $opcLoginButton = $(this);
        var $opcLoginForm = $opcLoginButton.closest('form.opc-login');
        var notificationSelector = $('.login-notification');

        $('body').loading(loadingIndicatorSettings);

        $('[data-login-source]').attr('data-login-source', 'cart'); // For reporting
        $opcLoginForm.validate().form();
        clearNotification(notificationSelector);
        updateCustomerDetails($opcLoginForm, $(this).attr('name')).then(function (response) {
            if (typeof response != 'object') {
                try {
                    response = JSON.parse(response);
                } catch(e) {
                    console.log(e);
                    response = {};
                }
            }
            if ('viewModel' in response && 'Customer' in response.viewModel && response.viewModel.Customer.authenticated == true) {
                Customer.authenticated = response.viewModel.Customer.authenticated;
                opcLoggedInCustomerAddresses = response.viewModel.Addresses;
                if ('validateCustomer' in response) {
                    $.ajax({
                        type: 'GET',
                        url: Urls.renderDialogTemplate,
                        error: function(jqXHR, textStatus, errorThrown) {
                            $('body').loading('destroy');
                            console.log(errorThrown);
                        },
                        success: function(data) {
                            if (data) {
                                $('body').loading('destroy');
                                opcLoginTagging($opcLoginForm);
                                var opcLoginContinueUrl = $('#cart-items-form').length ? Urls.onePage : Urls.account;

                                dialog.open({
                                    html: data,
                                    options: {
                                        dialogClass: 'login-dialog',
                                        draggable: false,
                                        height: 600,
                                        open: function() {
                                            $('body').addClass('dialog-open');
                                            $(this).scrollTop(0);
                                            if (SitePreferences.CORDIAL_ENABLED) {
                                                loginCordialAccountEvent($opcLoginForm, false);
                                            }

                                            $('.ui-dialog.login-dialog').css('height', '430px');
                                            $('.ui-dialog.login-dialog .login-dialog-forms-container').css('margin', '0 -8px');
                                            $('.login-dialog-passcode-container, .login-dialog-register-container').hide();

                                            var newAttributes = {
                                                "data-modal-type": "login",
                                                "data-continue-url": opcLoginContinueUrl,
                                                "data-first-name": 'customerFirstName' in response ? response.customerFirstName : '',
                                                "data-store-number": 'storeFromProfile' in response ? response.storeFromProfile : '',
                                                "data-cart-quantity": 'cartQuantity' in response ? response.cartQuantity : '',
                                                "data-opc-login": true
                                            }
                                            $('.login-dialog-validate-customer').html(response.validateCustomer);
                                            $('.login-dialog-validate-customer').addClass('validate-customer-show follow-account-dialog-path').attr(newAttributes);

                                            initAddRulesForCodeInput();

                                            var $passwordReset = $(this).find('.login-dialog-forms-container #password-reset-button');
                                            $passwordReset.attr('data-target', $passwordReset.attr('data-target') + '?loginDialog=true');
                                        },
                                        close: function() {
                                            $('body').removeClass('dialog-open');
                                        }
                                    }
                                });
                            }
                        }
                    });
                } else {
                    $('body').loading('destroy');
                    opcLoginTagging($opcLoginForm);
                    trackLoginActions('password', $('[data-login-source]').attr('data-login-source'));
                    location.href = Urls.onePage;
                }
            } else {
                $('body').loading('destroy');
                $('.opc-login .field-wrapper input').addClass('error');
                showNotification(Resources.PWD_EMAIL_ERROR, '.login-notification', 'error');
            }
        }.bind(this));    
    });

    $(document).on('click', '#otp-toggle-button', function(e) {
		e.preventDefault();
        var isAccountDialog = $(this).closest('.login-dialog-forms-container').length;
        var isCheckoutLogin = $(this).hasClass('opc-passwordless-login');
        if (isAccountDialog) {
            passwordlessLoginSequence(false);
        } else if (isCheckoutLogin) {
            loginDialog.show(function() {
                passwordlessLoginSequence(true);
                $('.login-dialog-forms-container [data-login-source]').attr('data-login-source', 'cart-modal'); 
                $('.login-dialog-forms-container [data-passwordless-login-source]').attr('data-passwordless-login-source', 'cart-modal');
            });
        } else {
            window.location.href = Urls.passwordlessLogin;
        }
	});

    $(document).on('click', '#otpCancelButton', function(e) {
		e.preventDefault();
        var isAccountDialog = $(this).closest('.login-dialog-forms-container').length;
        var isCheckout = $('[data-login-source]').attr('data-login-source') != undefined && $('[data-login-source]').attr('data-login-source').indexOf('cart') > -1;
        if (isAccountDialog) {
            if (isCheckout) {
                dialog.close();
            } else {
                var $otpHeader = $('form#dwfrm_otplogin .profile-header');
                var $otpInstructions = $('form#dwfrm_otplogin .otp-instructions');
                $('.login-dialog-signin-container, .login-dialog-register-message, #otp-toggle-button, #toggle-registration').show();
                $('.login-dialog-passcode-container').removeClass('passcode-form-show');
                $otpHeader.html($otpHeader.attr('data-email'));
                $otpInstructions.html($otpInstructions.attr('data-email'));
                $('#dwfrm_otplogin_username').show(); // don't clear previous email value for form validation
                $('#otpContinueButton').show();
                $('#dwfrm_otplogin_passcode').hide().val(''); // clear previous passcode value for form
                $('#otpVerifyButton').hide();
            }
        } else {
            window.location.href = Urls.account;
        }
	});

    $(document).on('click', '#otpContinueButton, .otp-resend-code', function(event) {
        event.preventDefault();
        var errorMessage = '<p style="padding: 10px;">Sorry, we were unable to send a one-time passcode at this time. Please login using your credentials instead.</p>';
        var $otpContinueButton = $(this);
        var $otpLoginForm = $otpContinueButton.closest('form#dwfrm_otplogin');
        var isResend = $(this).hasClass('otp-resend-code');

        // Clear previous form errors
        $otpLoginForm.find('input').removeClass('error');
        $otpLoginForm.find('.login-dialog-error span').addClass('d-none');

        var isValidForm = validateLoginForm($otpLoginForm, ['email']);
        var emailAddress = $otpLoginForm.find('input[name*="_username"]').val();

        $('.login-dialog-passcode-container, .otp-login-form-container').attr('data-email', emailAddress);

        // Using AJAX instead of default HTML button submit for login page and dialog
        if (isValidForm) {
            if (isResend) {
                $('body').loading(loadingIndicatorSettings); 
            }     
            $.ajax({
                type: 'POST',
                url: Urls.getPasscode,
                data: {
                    emailAddress: emailAddress
                },
                success: function(data, textStatus, jqXHR) {
                    if (typeof data === 'string' && data.length == 0) { 
                        if (isResend) {
                            $('body').loading('destroy'); 
                        } 
                        errorHandling(data, 'Passwordless Login - Error', errorMessage);
                    } else {
                        if (isResend) {
                            $('body').loading('destroy'); 
                        }  
                        if ((textStatus && textStatus != 'success') || (data && 'status' in data && data.status == 'ERROR')) {
                            errorHandling(data, 'Passwordless Login - Error', errorMessage);
                        }
                        if (data && 'status' in data && (data.status == 'OK' ||  data.status == 'NOT_FOUND')) {
                            $otpLoginForm.attr('data-usid', data.usid);
                            var $otpHeader = $otpLoginForm.find('.profile-header');
                            var $otpInstructions = $otpLoginForm.find('.otp-instructions');
                            $otpHeader.html($otpHeader.attr('data-verify'));
                            $otpInstructions.html($otpInstructions.attr('data-verify'));
                            $otpInstructions.append('<span>'+emailAddress+'</span>');
                            $('.otp-email-field-container, #otpContinueButton').hide();
                            $('.otp-passcode-field-container').css('display', 'flex');
                            $('#dwfrm_otplogin_passcode').show().val('').removeAttr('placeholder').attr('inputmode','numeric'); // clear previous passcode value from form
                            $('#otpVerifyButton, .otp-resend-code-container').show();
                        }    
                    }
                },
                error: function(jqXHR, textStatus, errorThrown) {
                    errorHandling(data, 'Passwordless Login - Error', errorMessage);
                }
            });
        }
    });

    $(document).on('click', '#otpVerifyButton', function(event) {
        event.preventDefault();
        var errorMessage = '<p style="padding: 10px;">Sorry, we were unable to send a passcode at this time. Please try again later.</p>';
        var $otpVerifyButton = $(this);
        var $otpLoginForm = $otpVerifyButton.closest('form#dwfrm_otplogin');
        var formUrl = $otpLoginForm.attr('action');

        // Clear previous form errors
        $otpLoginForm.find('input').removeClass('error');
        $otpLoginForm.find('.login-dialog-error span').addClass('d-none');

        var isLoginDialog = formUrl.indexOf('loginDialog') >= 0;
        var isValidForm = validateLoginForm($otpLoginForm, ['email','passcode']);
        var formData = $otpLoginForm.serialize();
        formData += '&usid=' + $otpLoginForm.attr('data-usid');
        formData += '&' + $otpVerifyButton.attr('name') + '=' + $otpVerifyButton.attr('name');

        // Using AJAX instead of default HTML button submit for login page and dialog
        if (isValidForm) {   
            $('body').loading(loadingIndicatorSettings);  
            $.ajax({
                type: 'POST',
                url: formUrl,
                data: formData,
                success: function(data, textStatus, jqXHR) {
                    $('body').loading('destroy');
                    if (typeof data === 'object' && data.status != 'ERROR') {
                        // 'validateCustomer' property only returns in the data if this is a dialog. Otherwise, rewards validation happens through the URL using loginDialogContinueUrl. 
                        // If this is a rewards customer, and the cusomter account requires validation...
                        if ('validateCustomer' in data && data.validateCustomer) {
                            if (SitePreferences.CORDIAL_ENABLED) {
                                loginCordialAccountEvent($otpLoginForm, isLoginDialog);
                            }
                            var newAttributes = {
                                "data-modal-type": "login",
                                "data-continue-url": 'loginDialogContinueUrl' in data ? data.loginDialogContinueUrl : '',
                                "data-first-name": 'customerFirstName' in data ? data.customerFirstName : '',
                                "data-store-number": 'storeFromProfile' in data ? data.storeFromProfile : '',
                                "data-cart-quantity": 'cartQuantity' in data ? data.cartQuantity : ''
                            }
                            $('.login-dialog-validate-customer, .otp-login-form-container').html(data.validateCustomer);
                            $('.login-dialog-validate-customer, .otp-login-form-container').addClass('validate-customer-show follow-account-dialog-path').attr(newAttributes);
                        } else {
                            // Fire the cordial login event
                            if (SitePreferences.CORDIAL_ENABLED) {
                                loginCordialAccountEvent($otpLoginForm, isLoginDialog);
                            }
                            trackLoginActions('passwordless', $('[data-passwordless-login-source]').attr('data-passwordless-login-source'));

                            // Pass this value to PostAjaxAuthenticationActions.Initialize for the isAccountDialog due to scope of BOOT-6100.
                            // We are not using this ticket to prevent cart refresh for modal. Will need additional ticket with scope to change cart behavior.
                            var isCartPasswordless = $('[data-passwordless-login-source]').attr('data-passwordless-login-source') == 'cart-modal' ? false : isLoginDialog;

                            // Handle follow-up actions accordingly
                            $(document).trigger('PostAjaxAuthenticationActions.Initialize', { results: data, isAccountDialog: isCartPasswordless, displayMessage: 'login' });
                        }    
                    } else {
                        var errorsFound;
						if (data.validationErrors) {
							errorsFound = JSON.parse(data.validationErrors);
						}
						if (errorsFound && Object.keys(errorsFound).length > 0) {
							for (var fieldName in errorsFound) {
								$(errorsFound[fieldName]).removeClass('d-none');
							}
						} else {
                            if (data.responseData && data.responseData.ErrorCode == 401) {
                                $('.invalid-wrong-passcode').removeClass('d-none');
                            } else {
							    errorHandling(data, 'Passwordless Login - Error', errorMessage);
                            }
						}
                    }
                },
                error: function(jqXHR, textStatus, errorThrown) {
                    $('body').loading('destroy');
                    errorHandling(data, 'Passwordless Login - Error', errorMessage);
                }
            });
        }
    });
}

function callNextController(url, callback) {
    $.ajax({
        type: 'GET',
        url: url,
        contentType: 'application/json',
        error: function(jqXHR, textStatus, errorThrown) {
        },
        success: function(response) {
            if (response) {
                if (callback) callback(response);
            }
        }
    });	
}

/**
 * @private
 * @function
 * @description register on-click event for rewards validation > send code button
 * @notes changed the button.on('click') to a document.on('click') for HTML that is dynamically loaded in page with AJAX well after initial page load
 */
function initValidateRewardsAccount() {	
	$(document).on('click', '#validate-account button#send-code', function(event) {
		event.preventDefault();

        var $validateAccountForm = $('#validate-account');
        var isAccountDialog = $('.login-dialog-validate-customer').hasClass('follow-account-dialog-path');
        var sendType = $validateAccountForm.find('input[name=validate-account]:checked').attr('id').indexOf('none') > -1 ? 'none' : 'other';

		if (sendType == "none") {				
			$.ajax({
				type: 'post',
				url: Urls.createRewardsAccount,
				contentType: 'application/json',
				data: JSON.stringify({
					firstName: $('.customer-account .first-name').text(),
					lastName: $('.customer-account .last-name').text(),
					emailAddress: $('.customer-account .email-address').text(),
					phoneNumber: $('.customer-account .phone-number').text(),
					zipCode: $('.customer-account .zip-code').text()
				}),
				success: function (data) {
					console.log('Rewards account created.');
					initVerificationDirection();
				}
			});			
		} else {
			$.ajax({
				url: Urls.sendValidationCode,
				dataType: 'JSON',
				method: 'POST',
				data: {
					'sendMethod': $validateAccountForm.find('input[name=validate-account]:checked').val(),
					'sendTo': $validateAccountForm.find('input[name=validate-account]:checked').attr('data-value')
				},
				success : function(data) {
					var resultStatus = 'status' in data && data.status != 'pending' ? true : 'result' in data && 'status' in data.result && data.result.status != 'pending' ? true : false;
					if (('error' in data && data.error) || resultStatus) {
						$('.further-info').hide();
						$('.verify-form').hide();
						$('.verify-fail').removeClass('hidden');
					}	
					$('.account-recognized').hide();
					if (isAccountDialog) {
						$('.verify-account').removeClass('hidden').addClass('validate-customer-show');	
					} else {
						$('.verify-account').removeClass('hidden');	
					}			
					$('.' + sendType + '-method').removeClass('hidden');
				},
				complete: function(data, status, xhr) {
					if (data.status == 302 && data.responseText) {
						location.href = data.responseText;
					}
				}
			});
		}
	});
	
    $(document).on('click', '#attach-rewards-button', function(event) {
        event.preventDefault();
        var errorMessage = '<p style="padding: 10px;">Sorry, we were unable to finalize your rewards account updates.</p>';
        var $attachRewardsForm = $('#attach-rewards-form');
        var $selectedOption = $attachRewardsForm.find('input[name=attach-rewards-options]:checked');
        var submittedEmail = $('.login-dialog-passcode-container, .otp-login-form-container').attr('data-email');

        if (!$selectedOption) {
            $('.attach-rewards-form-errors').removeClass('d-none');
            return;
        }

        $.ajax({
            url: Urls.attachRewards,
            dataType: 'JSON',
            method: 'POST',
            data: {
                'option': $selectedOption.val(),
                'email': submittedEmail
            },
            success : function(data) {
                if (data && data.result && data.result == 'success') {
                    console.log('Rewards attachment complete.');
                    initVerificationDirection();
                } else {
                    errorHandling(data, 'Rewards Account - Error', errorMessage);
                }
            },
            error: function(jqXHR, textStatus, errorThrown) {
                errorHandling(data, 'Rewards Account - Error', errorMessage);
            }
        });
    });
}

/**
 * @private
 * @function
 * @description register on-click event for rewards validation > verify code button
 */
function initVerifyCodeSent() {   
    $(document).on('click', '#verify-code button#verify-user', function(event) {
        event.preventDefault();

        var $verifyCodeForm = $('#verify-code');
        if (!$verifyCodeForm.valid()) {
            return false;
        }

        $.ajax({
            url: Urls.verifySubmittedCode,
            dataType: 'JSON',
            method: 'POST',
            data: {
                'code':  $verifyCodeForm.find('input[name=enter-code]', '#verify-code').val(),
                'sendTo': $('#validate-account').find('input[name=validate-account]:checked').attr('data-value')
            },
            success : function(data) {
                if (('error' in data && data.error) || ('status' in data && data.status != 'approved')) {
                    $('.further-info').hide();
                    $('.verify-form').hide();
                    $('.verify-fail').removeClass('hidden');							
                } else {
                    console.log('Rewards verification complete.');
                    initVerificationDirection();
                }
            },
            complete: function(data, status, xhr) {
                if (data.status == 302 && data.responseText) {
                    location.href = data.responseText;
                }
            }
        });
    });
}

/**
 * @private
 * @function
 * @description directs the page load or the page redraw after rewards verification screen
 */
function initVerificationDirection() {
    var $validateDialog = $('.login-dialog-validate-customer');
    var isAccountDialog = $('.login-dialog-validate-customer').hasClass('follow-account-dialog-path');
    var dataModalType, dataNextControllerUrl, dataContinueUrl, dataFirstName, dataStore, dataCartQuantity, isOpcLogin;

    if ($validateDialog.length) {
        dataModalType = $validateDialog.attr('data-modal-type');
        dataNextControllerUrl = $validateDialog.attr('data-next-controller-url');
        dataContinueUrl = $validateDialog.attr('data-continue-url');
        dataFirstName = $validateDialog.attr('data-first-name');
        dataStore = $validateDialog.attr('data-store-number');
        dataCartQuantity = $validateDialog.attr('data-cart-quantity');
        isOpcLogin = $validateDialog.attr('data-opc-login') == 'true';

        if (isOpcLogin) {
            dialog.close();
            location.href = Urls.onePage;
        }
        if (isAccountDialog) {
            if (dataNextControllerUrl) {
                callNextController(dataNextControllerUrl, function(response) {
                    if (response && 'result' in response && (response.result == 'success' || response.result == 'complete')) {
                        response.customerFirstName = dataFirstName;
                        response.storeFromProfile = dataStore;
                        response.cartQuantity = dataCartQuantity;
                        // Handle follow-up actions accordingly
                        $(document).trigger('PostAjaxAuthenticationActions.Initialize', { results: response, isAccountDialog: isAccountDialog, displayMessage: dataModalType });
                    }
                });
            } else {
                if (dataContinueUrl) {
                    location.href = dataContinueUrl;
                } else {
                    location.href = Urls.account;
                }
            }
        }
    } else {
        location.href = Urls.account;
    }
}

/**
 * @private
 * @function
 * @description add rules to code input field
 */
function initAddRulesForCodeInput() {
    $('form#verify-code').validate();
    $('#verify-code #enter-code').rules('add', {
        required: true,
        digits: true,
        minlength: 6,
        messages: {
            required: Resources.VALIDATE_CODE,
            minlength: Resources.VALIDATE_CODE
        },
        normalizer: function(value) {
            return $.trim(value);
        }
    });
}

/**
 * @private
 * @function
 * @description register on-click event for rewards validation > verify code > resend code link
 */
function initResendValidationCode() {
    $(document).on('click', '.verify-account a.resend-code', function(event) {
        event.preventDefault();		
        if ($('.login-dialog-validate-customer').hasClass('follow-account-dialog-path')) {
            $('.verify-account, .further-info span').addClass('hidden');	
            $('.account-recognized').css('left','-9999px').removeClass('hidden').show().addClass('validate-customer-show');			
        } else {
            location.reload();
        }
    });
}

/**
 * @private
 * @function
 * @description register on-click events for reset-password flow
 */
function initResetPasswordEvents() {	
    // Show reset password form
	$(document).on('click', '#password-reset-button', function(event) {
        event.preventDefault();
        var $resetPasswordButton = $(this);
        var resetDialogUrl = $resetPasswordButton.attr('data-target');
        var isLoginDialog = resetDialogUrl.indexOf('loginDialog') >= 0;

        if (isLoginDialog) {
            $('body').loading(loadingIndicatorSettings);
            var dialogHtml = '<p style="padding: 10px;">Oops, something went wrong with Password Reset. Please try again later.</p>';

            $.ajax({
                type: 'GET',
                url: resetDialogUrl,
                success: function(data, textStatus, jqXHR) {
                    if (typeof data === 'object' && 'result' in data) {
                        if (data.result == 'complete') {
                            $('body').loading('destroy');
                            // If we received HTML in the data for displaying the reset password form, then display it...
                            if ('resetPasswordForm' in data && data.resetPasswordForm) {
                                $('.login-dialog-reset-password').html(data.resetPasswordForm);
                                $('.login-dialog-reset-password').addClass('reset-password-show');
                                $('.login-dialog-reset-password').find('#PasswordResetForm').attr('action', $('.login-dialog-reset-password').find('#PasswordResetForm').attr('action') + '?loginDialog=true');
								renderRecaptchaForPasswordReset();
                            } else {
                                $('body').loading('destroy');
                                errorHandling(data, 'Reset Password - Error', dialogHtml);
                            }
                        } else {
                            $('body').loading('destroy');
                            errorHandling(data, 'Reset Password - Error', dialogHtml);
                        }
                    } else {
                        $('body').loading('destroy');
                        if (isLoginDialog) {
                            errorHandling(data, 'Reset Password - Error', dialogHtml);
                        } else {
                            window.location.href = location.href;
                        }
                    }
                },
                error: function(jqXHR, textStatus, errorThrown) {
                    $('body').loading('destroy');
                    if (!isLoginDialog) {
                        window.location.href = location.href;
                    }
                }
            });
        } else if (SitePreferences.RESET_POPUP_ENABLED) {
			dialog.open({
	            url: resetDialogUrl,
	            options: {
	            	dialogClass: 'resetpwdpopup',
	            	width: 385,
	                open: function () {
						renderRecaptchaForPasswordReset();
	                    $(".reset-password-heading-close-btn, .ui-widget-overlay").on('click',function(){
	                        $("button.ui-dialog-titlebar-close").click();
	                    });
	                }
	            }
			});
        } else {
            window.location.href = resetDialogUrl;
        }
	});
	
    // Submit reset password form
    $(document).on('click.ResetPassword', '#request-password-send-btn, [name$="_requestpassword_send"]', function(event) {
    	if (!options || !options.Recaptcha || !options.Recaptcha.EnabledForPasswordReset) {
			resetPassword(event, $(this));
		}
	});
    
    $(document).on('Account.ResetPassword', function (event, data) {
		resetPassword(event, null, true);
	});
}

function resetPassword(event, $resetPasswordSendButton, isRecaptchaEvent) {
	if (!$resetPasswordSendButton) {
		$resetPasswordSendButton = $('#request-password-send-btn');
	}

	var $resetPasswordSendForm = $resetPasswordSendButton.closest('form#PasswordResetForm');
	var isLoginDialog = $resetPasswordSendForm.attr('action').indexOf('loginDialog') >= 0;

	// Try to prevent the form submission as quickly as possible before doing all of the other logic
	if (isLoginDialog || (SitePreferences.RESET_POPUP_ENABLED && dialog.isActive())) {
		event.preventDefault();
	}

	validator.init();
	var isValidForm = $resetPasswordSendForm.valid();

	if (isValidForm) {   
		if (isLoginDialog) {
			$('body').loading(loadingIndicatorSettings);
			var dialogHtml = '<p style="padding: 10px;">Oops, something went wrong with Password Reset. Please try again later.</p>';
			var formData = $resetPasswordSendForm.serialize();
			formData += '&' + $resetPasswordSendButton.attr('name') + '=' + $resetPasswordSendButton.attr('name');  

			$.ajax({
				type: 'POST',
				url: $resetPasswordSendForm.attr('action'),
				data: formData,
				success: function(data, textStatus, jqXHR) {
					if (typeof data === 'object' && 'result' in data) {
						if (data.result == 'complete') {
							$('body').loading('destroy');
							// If reset password email was sent, show confirmation within the dialog
							if ('resetPasswordConfirmation' in data && data.resetPasswordConfirmation) {
								$('.login-dialog-reset-password').html(data.resetPasswordConfirmation);
								$('.login-dialog-reset-password').addClass('reset-password-show');
								passwordResetNoAccountCordialEvent('.login-dialog-reset-password');
							} else {
								$('body').loading('destroy');
								errorHandling(data, 'Reset Password - Error', dialogHtml);
							}
						} else {
							$('body').loading('destroy');
							errorHandling(data, 'Reset Password - Error', dialogHtml);
						}
					} else {
						$('body').loading('destroy');
						if (isLoginDialog) {
							errorHandling(data, 'Reset Password - Error', dialogHtml);
						} else {
							window.location.href = location.href;
						}
					}
				},
				error: function(jqXHR, textStatus, errorThrown) {
					$('body').loading('destroy');
					if (!isLoginDialog) {
						window.location.href = location.href;
					}
				}
			});
		} else if (SitePreferences.RESET_POPUP_ENABLED && dialog.isActive()) {
			dialog.submit($resetPasswordSendButton.attr('name'), function() {
				passwordResetNoAccountCordialEvent('.resetpwdpopup');
			});
		} else if (isRecaptchaEvent) {
			$resetPasswordSendForm.append('<input type="hidden" name="' + $resetPasswordSendButton.attr('name') + '" value="' + $resetPasswordSendButton.val() + '"/>')
			$resetPasswordSendForm.submit();
		}
	}
}

module.exports = {
    init: function (optionsParameter) {
		options = optionsParameter;

        initLoginEvents();
		if (options && options.Recaptcha && (options.Recaptcha.EnabledForAccountLogin || options.Recaptcha.EnabledForPasswordReset)) {
			$(document).on('Recaptcha.Loaded', function(event) {
				if (!loaded.Recaptcha) {
					loaded.Recaptcha = true;
					renderRecaptchaForLogin();
					renderRecaptchaForPasswordReset();
				}
			});

			recaptcha.initialize();
			initializeRecaptchaForLogin();
			renderRecaptchaForPasswordReset();
		}
		initLoginButtons();
        initValidateRewardsAccount();
        initVerifyCodeSent();
        initResendValidationCode();
        initAddRulesForCodeInput();
        initResetPasswordEvents();
    }
};