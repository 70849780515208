'use strict';

var quickview = require('../quickview');
var bonusProductsView = require('../bonus-products-view');
var TPromise = require('promise');
var util = require('../util');
var addresses = SitePreferences.ENABLE_ENHANCED_CHECKOUT ? require('./checkout/opcaddress_enhanced') : require('./checkout/opcaddress');
var formPrepare = require('./checkout/formPrepare');
var cart = require('./cart');
var loadingIndicator = require('../components/LoadingIndicator');
var GiftCard = require('./checkout/GiftCardPaymentPanel');
var GoogleTagManagerClient = require('../../../../bc_sheplersbootbarn_core/cartridge/js/util/GoogleTagManagerClient');
var recaptcha = require('../components/Recaptcha');
var RewardsCredit = require('./checkout/RewardsCredit');
var LoyaltyCredit = require('./checkout/LoyaltyCredit');
var CheckoutConstants = require('../../../../bc_sheplersbootbarn_core/cartridge/js/CheckoutConstants');
var PaymentMethodConstants = require('../../../../bc_sheplersbootbarn_core/cartridge/js/PaymentInstrumentConstants').PaymentMethods;
var SiteConstants = require('../../../../bc_sheplersbootbarn_core/cartridge/js/SiteConstants');

var cartItemsFormSelector = 'form#cart-items-form';
var billingAddressFormSelector = '#billingAddressForm';
var $billingAddressForm = $(billingAddressFormSelector);
var shippingAddressFormSelector = '#shippingAddressForm';
var $shippingAddressForm = $(shippingAddressFormSelector);
var paymentSectionSelector = '.form-checkout.payment-section';
var $paymentSection = $(paymentSectionSelector);
var paymentFormSelector = '#paymentSection';
var $paymentForm = $(paymentFormSelector);
var creditCardListSelector = '#creditCardList';
var $creditCardList = $(creditCardListSelector);
var submitPaymentSelector = '.payment-section-footer button[name*="_submitPayment"]:not(#submitOPCPaymentPrivateLabel)';
var $submitPayment = $(submitPaymentSelector);
var submitPrivateLabelPaymentSelector = '#submitOPCPaymentPrivateLabel';
var $submitPrivateLabelPayment = $(submitPrivateLabelPaymentSelector);
var basketContainerSelector = '.basket-container';
var $basketContainer = $(basketContainerSelector);

var placeOrderRecaptcha;
var placeOrderRecaptchaPaypal;
var placeOrderRecaptchaAffirm;
var placeOrderRecaptchaPrivateLabel;
var options = {};
var loaded = {};

/**
 * @description Events should be divided to modules. This allows reinitialize events by sections after partial reload
 * @private
 * @function
 * @description Initializes events for the gift registration
 */
function initializeEvents(options) {
	initializeCartEvents(options);
	initializePaymentMethodsEvents();
	
	renderRecaptcha();
	$(document).on('Recaptcha.Loaded', function(event) {
		if (!loaded.Recaptcha) {
			loaded.Recaptcha = true;
	        renderRecaptcha();
	    }
	});
}

function initializeCartEvents(options) {
    $(document).on('click', 'a.add-to-wishlist', function (e) {
    	/*remove next line to use default dw wishlist functionality*/
    	return false;
    	e.preventDefault();

    	if(Customer.authenticated == true){
    		//post form here
    	} else {
    		$('html, body').animate({ scrollTop: $('.login-form').eq(0).offset().top}, 1000);
    	}
    });
    
    if (SitePreferences.ALTERNATE_PICKUP_ENABLED) {
		renderAlternatePickup();
	}

	$paymentForm.on('change', '[name$="_creditCard_type"]', function() {
		setPlaceOrderButtonsDisabled(false);
		$(this).closest('div.form-row').find('div.field-wrapper.error').remove();
	});

	$submitPayment.on('click', function(e) {
		e.preventDefault();
		
		if (!options || !options.Recaptcha || !options.Recaptcha.EnabledForPlaceOrder) {
			submitPayment();
		}
    });

	$(document).on('Checkout.SubmitPayment', function () {
		if (options && options.Recaptcha && options.Recaptcha.EnabledForPlaceOrder) {
			submitPayment();
		}
	});
	
    $submitPrivateLabelPayment.on('click', function(e){
    	e.preventDefault();
    	
    	if (!options || !options.Recaptcha || !options.Recaptcha.EnabledForPlaceOrder) {
			submitPaymentPrivateLabel();	
		}
    });
    
    $(document).on('Checkout.SubmitPaymentPrivateLabel', function () {
    	if (options && options.Recaptcha && options.Recaptcha.EnabledForPlaceOrder) {
			submitPaymentPrivateLabel();
		}
	});
    
    var getCreditCardInfo = function (id) {
        return TPromise.resolve($.ajax({
            type: 'GET',
            url: util.ajaxUrl(Urls.billingSelectCC),
            data: {ccID: id}
        }));
    };

    $creditCardList.on('change', function() {
    	var ccID = $(this).val();
    	if (ccID.length <= 0) {
    		return;
    	}
    	getCreditCardInfo(ccID).then(function (response) {
			var data = response;
			if (data.hasOwnProperty('maskedNumber')) {
				$('#dwfrm_billing_paymentMethods_creditCard_owner').val(data.holder).valid();
				$('#dwfrm_billing_paymentMethods_creditCard_type[value="'+data.type+'"]')
					.first()
					.prop('checked', true)
					.trigger('change');
				$("[id^='dwfrm_billing_paymentMethods_creditCard_number']").first().val(data.maskedNumber).valid();
				$('#dwfrm_billing_paymentMethods_creditCard_expiration_month').val(data.expirationMonth).trigger('change');
				$('#dwfrm_billing_paymentMethods_creditCard_expiration_year').val(data.expirationYear).trigger('change');
				$('input[name^=dwfrm_billing_paymentMethods_creditCard_cvn]').val('');
			}
		}.bind(this));

    });

    //preselect default credit card
	if($creditCardList.length > 0 && $('.pt_onepcheckout input[name*="_selectedPaymentMethodID"]').val() == PaymentMethodConstants.CreditCard) {
		$creditCardList.find('option').eq(1).prop('selected', true);
		$creditCardList.trigger('change');
	}

    var getPrivateLabelCardInfo = function (id) {
        return TPromise.resolve($.ajax({
            type: 'GET',
            url: util.ajaxUrl(Urls.billingSelectPrivateLabel),
            data: {cardId: id}
        }));
    };

    var $privateLabelCardList = $('#privateLabelCardList');
    $privateLabelCardList.on('change', function() {
    	var cardId = $('#privateLabelCardList').val();
    	if (cardId.length <= 0) {
    		return;
    	}
    	getPrivateLabelCardInfo(cardId).then(function (response) {
			var data = response;
			if (data.hasOwnProperty('maskedNumber')) {
				$("[id^='dwfrm_billing_paymentMethods_privateLabelCard_number']").first().val(data.maskedNumber).valid();
			}
		}.bind(this));

    });

	// preselect default private label card
	if ($privateLabelCardList.length > 0) {
		$privateLabelCardList.find('option').eq(1).prop('selected', true);
		$privateLabelCardList.trigger('change');
	}

    addresses.init(options);
    cart.init(options);
}

function renderAlternatePickup() {
	$(document).off('click', 'button.alt-pickup-button').on('click', 'button.alt-pickup-button', function (e) {
    	e.preventDefault();
		$('#alt-pickup-modal').addClass('active-modal').show();
		$('fieldset span').removeClass('error-msg');
		$('.one-required').removeClass('error');
    });
    
    $(document).off('click', '#alt-pickup-modal .close-modal').on('click', '#alt-pickup-modal .close-modal', function (e) {
    	e.preventDefault();
		$('#alt-pickup-modal').removeClass('active-modal').hide();
    });
    
    $(document).on('focus', 'input[name="firstName"]', function () {
		$('.firstname-error').removeClass('error-msg');
    });
    
    $(document).on('focus', 'input[name="lastName"]', function () {
		$('.lastname-error').removeClass('error-msg');
    });
    
    $(document).on('focus', 'input[name="email"]', function () {
		$('.email-error').removeClass('error-msg');
		$('.one-required').removeClass('error');
    });
    
    $(document).on('focus', 'input[name="phone"]', function () {
		$('.phone-error').removeClass('error-msg');
		$('.one-required').removeClass('error');
    });
    
    $(document).off('click', 'button#altPickupSubmit').on('click', 'button#altPickupSubmit', function (e) {
    	e.preventDefault();
		
    	var isFirstNameValid = true;
		var firstName = $('input[name="firstName"]').val();
		var regexName = /^[a-z ,.'-]+$/i;
		if (firstName.length == 0 || firstName.match(regexName) == null) {
			isFirstNameValid = false;
			$('.firstname-error').addClass('error-msg');
		}
		
		var isLastNameValid = true;		
		var lastName =$('input[name="lastName"]').val();
		if (lastName.length == 0 || lastName.match(regexName) == null) {
			isLastNameValid = false;
			$('.lastname-error').addClass('error-msg');
		}
		
		var isEmailValid = true;		
		var email = $('input[name="email"]').val();
		var regexEmail = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/g;
		if (email.length > 0 && email.match(regexEmail) == null) {
			isEmailValid = false;
			$('.email-error').addClass('error-msg');
		}

		var isPhoneValid = true;
		var phone = $('input[name="phone"]').val().replace(/\D/g, '');
		var regexPhone = /^\+?\(?([0-9][0-8][0-9])\)?[\-\. ]?([2-9][0-9]{2})[\-\. ]?([0-9]{4})(\s*x[0-9]+)?$/g;
		if (phone.length > 0 && phone.match(regexPhone) == null) {
			isPhoneValid = false;
			$('.phone-error').addClass('error-msg');
		}
		
    	if (email.length == 0 && phone.length == 0) {
    		$('.one-required').addClass('error');
    		isEmailValid = false;
    		isPhoneValid = false;
    	}
    	
    	if (!isEmailValid || !isPhoneValid) {
    		return;
    	}
    	
		if (isFirstNameValid && isLastNameValid && (isEmailValid || isPhoneValid)) {
			var cleanPhone = phone.replace(/\D/g, '');
			var shipmentID = $('.alternate-pickup').closest("form").attr('shipment');
			
			$.ajax({
				url: Urls.setAlternatePickup,
				type: "get",
				data: {
					shipmentID: shipmentID,
					altPickupFirstName: firstName,
					altPickupLastName: lastName,
					altPickupEmail: email,
					altPickupPhone: cleanPhone
				},
				success: function (response) {
					var data = JSON.parse(response);
						
					if (data.status == 'success') {
						$('span.alt-person').attr('data-firstname', firstName).attr('data-lastname', lastName).attr('data-email', email).attr('data-phone', cleanPhone);
						displayAlternatePickup (firstName + ' ' + lastName)
						$('#alt-pickup-modal').removeClass('active-modal').hide();
					}
				},
				error: function(jqXHR, textStatus, errorThrown) {
					console.log(textStatus, errorThrown);
				}
			});
		}
    });
}

function displayAlternatePickup (fullName) {
	$('.alternate-pickup span.alt-person').text(fullName);
	$('span.alt-header').show();
	$('button.alt-pickup-button').text('UPDATE');
}

function renderRecaptcha() {
	if (loaded.Recaptcha && options && options.Recaptcha && options.Recaptcha.EnabledForPlaceOrder && options.Recaptcha.GoogleSiteKey) {
		if ($('#submitOPCPayment').length > 0) {
			placeOrderRecaptcha = grecaptcha.render('submitOPCPayment', {
		        sitekey: options.Recaptcha.GoogleSiteKey,
		        callback: submitPayment,
		        badge: 'bottomleft'
		    });
		}
		
		if ($('#submitOPCPaymentPaypal').length > 0) {
			placeOrderRecaptchaPaypal = grecaptcha.render('submitOPCPaymentPaypal', {
		        sitekey: options.Recaptcha.GoogleSiteKey,
		        callback: submitPayment,
		        badge: 'bottomleft'
		    });
		}
		
		if ($('#submitOPCPaymentForAffirm').length > 0) {
			placeOrderRecaptchaAffirm = grecaptcha.render('submitOPCPaymentForAffirm', {
		        sitekey: options.Recaptcha.GoogleSiteKey,
		        callback: submitPayment,
		        badge: 'bottomleft'
		    });
		}
		
		if ($('#submitOPCPaymentPrivateLabel').length > 0) {
			placeOrderRecaptchaPrivateLabel = grecaptcha.render('submitOPCPaymentPrivateLabel', {
		        sitekey: options.Recaptcha.GoogleSiteKey,
		        callback: submitPaymentPrivateLabel,
		        badge: 'bottomleft'
		    });
		}
	}
}

function loyaltyOptin() {
	if (SitePreferences.LOYALTY_ENABLED) {
		var opt_email = $('.billing-email-address').text();
		var opt_status = $('#loyalty_optin').prop('checked');
		var dw_customer_id = (Customer.authenticated == true) ? $('.section-checkout').attr('data-customerid') : '';
		
		if (SitePreferences.CORDIAL_ENABLED) {
			if (opt_status == false) {
				// track Cordial loyalty optout event
				var auth_data = {
					email: opt_email
				}
				
				var contact_data = {
				    'channels': {
				        'email': {
				            'address': opt_email         
				        }
				    }
				};
				
				var properties = {
				    'email': opt_email
				};
				
				if (typeof crdl !== 'undefined' && typeof crdl === 'function') {
					crdl([
						['contact', auth_data, contact_data],
						['event', 'loyalty_optout', properties]
					]);	
				}
			}
		}
		
		return TPromise.resolve($.ajax({
			url: Urls.loyaltyOptin,
			dataType: 'JSON',
			method: 'POST',
			data: {
				'email': opt_email,
				'dwCustomerID': dw_customer_id,
				'optStatus': opt_status
			},						
			success : function(data) {
				if (('error' in data && data['error'])) {
					console.log(data.message);
				}
			}
		}));
	} else {
		TPromise.resolve();
	}
}

function submitPayment() {
	var balanceDue = parseFloat($paymentSection.attr('balance-due'));
	$submitPayment.filter(':visible').loading({
		IsImageShown: false,
		Label: Resources.Loading.Checkout.PlacingOrder
	});
	
	setPlaceOrderButtonsDisabled(true);
	
	$billingAddressForm.validate().form();
	$shippingAddressForm.validate().form();
	
	var $creditCardType = $paymentForm.find('[name$="_creditCard_type"]:checked').val();
	if (balanceDue > 0) {
		$paymentForm.validate().form();
		
		if (typeof $creditCardType === 'undefined') {
    		$paymentForm.find('[name$="_creditCard_type"]').closest('div.form-row').find('div.field-wrapper.error').remove();
    		setTimeout(function(){
    			$paymentForm.find('[name$="_creditCard_type"]').closest('div.form-row').append('<div class="field-wrapper error" style="height:0px;"><span class="error">' + Resources.REQUIRED_FIELD + '</span></div>');
    		}, 50);
    	}
	}
	
	//check first if addresses are valid
	var isBillingAddressValid = false;
	var isShippingAddressValid = false;
	
	$billingAddressForm.validate().settings.ignore = [];
	$shippingAddressForm.validate().settings.ignore = [];
	
	if ($billingAddressForm.valid()) {
		isBillingAddressValid = true;
	} else {
		$('.billing-address-data.form-info').hide();
		
		$billingAddressForm.show();
		$billingAddressForm.find('input.required.field.error').eq(0).focus();
	}
	
	if ($shippingAddressForm.valid()) {
		isShippingAddressValid = true;
	} else {
		$('.shipping-address-data.form-info').hide();
		
		$shippingAddressForm.show();
		$shippingAddressForm.find('input.required.field.error').eq(0).focus();
	}
	
	if (isBillingAddressValid && isShippingAddressValid && $('#shipping-method-list input:checked').length && (balanceDue == 0 || $paymentForm.valid())) {
		$('.payment-declined').remove();
		
		var actionName = $submitPayment.filter(':visible').attr('name');
		setOPCPaymentData($paymentForm, actionName).then(function (response) {
			var data;
            try {
				data = JSON.parse(response);
			} catch (e) {
				data = response;
			}

			if (data.hasOwnProperty('status') && data.status === 'success') {
				GoogleTagManagerClient.PushDataLayerForDataLayerType('AddPaymentInfo').then(loyaltyOptin).then(function() {
					if (options.CheckoutMode == 'MobileApp') {
						window.location.href = Urls.confirmationPageForMobileApp;
					} else {
						window.location.href = Urls.confirmationPage;
					}
				}).catch(function() {
					if (options.CheckoutMode == 'MobileApp') {
						window.location.href = Urls.confirmationPageForMobileApp;
					} else {
						window.location.href = Urls.confirmationPage;
					}
				});
			} else if (data.hasOwnProperty('status') && data.status === 'affirmRedirect') {
				var _affirm_config = {
					public_api_key: data.affirm.PublicApiKey,
				    script: data.affirm.Script
				};
				(function(l,g,m,e,a,f,b){var d,c=l[m]||{},h=document.createElement(f),n=document.getElementsByTagName(f)[0],k=function(a,b,c){return function(){a[b]._.push([c,arguments])}};c[e]=k(c,e,"set");d=c[e];c[a]={};c[a]._=[];d._=[];c[a][b]=k(c,a,b);a=0;for(b="set add save post open empty reset on off trigger ready setProduct".split(" ");a<b.length;a++)d[b[a]]=k(c,e,b[a]);a=0;for(b=["get","token","url","items"];a<b.length;a++)d[b[a]]=function(){};h.async=!0;h.src=g[f];n.parentNode.insertBefore(h,n);delete g[f];d(g);l[m]=c})(window,_affirm_config,"affirm","checkout","ui","script","ready");
				
				affirm.checkout(JSON.parse(data.affirm.AffirmCheckout));
				affirm.checkout.post();
				affirm.ui.ready(
				    function() {
				        affirm.ui.error.on("close", function(){
				            // window.location.replace(data.affirm.RedirectUrl);
				        });
				    }
				);
			} else if (data.hasOwnProperty('status') && data.status === 'shippingRestrictionsError'){
				var popup = require('../popup');
				var payload = JSON.parse(data.payload);

				var list = $.map(payload.list, function (el) {
					return '<li>' + el.name + ':<br>' + el.message + '</li>';
				}).join('');
				
				popup.init({
					title: payload.title,
					content: payload.message + '<ul class="shipping-restrictions-popup__list">' + list + '</ul>',
				}).showPopup();
			} else if (data.hasOwnProperty('status') && data.status === 'BillingAddressError') {
				var message = data.hasOwnProperty('message') ? data.message : Resources.Checkout.Confirm.Error.Generic;
				$submitPayment.after('<div id="errorBoxOPC" class="payment-declined p-2 mt-2"><i class="fa fa-exclamation-triangle"></i> <b>Billing Address Error:</b> ' + message + '</div>');
			} else if (data.hasOwnProperty('status') && data.status === 'ShippingAddressError') {
				var message = data.hasOwnProperty('message') ? data.message : Resources.Checkout.Confirm.Error.Generic;
				$submitPayment.after('<div id="errorBoxOPC" class="payment-declined p-2 mt-2"><i class="fa fa-exclamation-triangle"></i> <b>Shipping Address Error:</b> ' + message + '</div>');
			} else if (data.hasOwnProperty('status') && data.status === 'ValidationError') {
				var message = data.hasOwnProperty('message') ? data.message : Resources.Checkout.Confirm.Error.Generic;
				$submitPayment.after('<div id="errorBoxOPC" class="payment-declined p-2 mt-2"><i class="fa fa-exclamation-triangle"></i> <b>Validation Error:</b> ' + message + '</div>');
			} else {
				var message = data.hasOwnProperty('message') ? data.message : Resources.Checkout.Confirm.Error.Generic;
				$submitPayment.after('<div id="errorBoxOPC" class="payment-declined p-2 mt-2" role="alert" tabindex="0"><i class="fa fa-exclamation-triangle"></i> <b>Payment Declined:</b> ' + message + '</div>');
			}
			
			$submitPayment.filter(':visible').loading('destroy');
			setPlaceOrderButtonsDisabled(false);
			if (placeOrderRecaptcha != undefined) {
				grecaptcha.reset(placeOrderRecaptcha);
			}
			
			if (placeOrderRecaptchaPaypal != undefined) {
				grecaptcha.reset(placeOrderRecaptchaPaypal);
			}
			
			if (placeOrderRecaptchaAffirm != undefined) {
				grecaptcha.reset(placeOrderRecaptchaAffirm);
			}
		}).catch(function(response) {
			if (response.status == 302 && response.responseText) { 
				location.href = response.responseText;
			}
		});
	} else {
		$submitPayment.filter(':visible').loading('destroy');
		setPlaceOrderButtonsDisabled(false);
		if (placeOrderRecaptcha != undefined) {
			grecaptcha.reset(placeOrderRecaptcha);
		}
		
		if (placeOrderRecaptchaPaypal != undefined) {
			grecaptcha.reset(placeOrderRecaptchaPaypal);
		}
		
		if (placeOrderRecaptchaAffirm != undefined) {
			grecaptcha.reset(placeOrderRecaptchaAffirm);
		}
	}
}

function submitPaymentPrivateLabel() {
	var balanceDue = parseFloat($paymentForm.find('[balance-due]').attr('balance-due'));
	$submitPrivateLabelPayment.loading({
		IsImageShown: false,
		Label: Resources.Loading.Checkout.PlacingOrder
	});
	
	setPlaceOrderButtonsDisabled(true);
	
	var isBillingAddressValid = false;
	var isShippingAddressValid = false;
	
	$billingAddressForm.validate().settings.ignore = [];
	$shippingAddressForm.validate().settings.ignore = [];
	
	if ($billingAddressForm.valid()) {
		isBillingAddressValid = true;
	} else {
		$('.billing-address-data.form-info').hide();
		
		$billingAddressForm.show();
		$billingAddressForm.find('input.required.field.error').eq(0).focus();
	}
	
	if ($shippingAddressForm.valid()) {
		isShippingAddressValid = true;
	} else {
		$('.shipping-address-data.form-info').hide();
		
		$shippingAddressForm.show();
		$shippingAddressForm.find('input.required.field.error').eq(0).focus();
	}
	
	if (isBillingAddressValid && isShippingAddressValid && $('#shipping-method-list input:checked').length && (balanceDue == 0 || $paymentForm.valid())) {
		$('.payment-declined').remove();
		
		var actionName = $('#submitOPCPaymentPrivateLabel').attr('name');
		setOPCPaymentData($paymentForm, actionName).then(function (response) {
			var data = JSON.parse(response);

			if (data.hasOwnProperty('status') && data.status === 'success') {
				console.log('payment datalayer');
				GoogleTagManagerClient.PushDataLayerForDataLayerType('AddPaymentInfo').then(function() {
					if (options.CheckoutMode == 'MobileApp') {
						window.location.href = Urls.confirmationPageForMobileApp;
					} else {
						window.location.href = Urls.confirmationPage;
					}
				}).catch(function() {
					if (options.CheckoutMode == 'MobileApp') {
						window.location.href = Urls.confirmationPageForMobileApp;
					} else {
						window.location.href = Urls.confirmationPage;
					}
				});
			} else if (data.hasOwnProperty('status') && data.status === 'shippingRestrictionsError'){
				var popup = require('../popup');
				var payload = JSON.parse(data.payload);

				var list = $.map(payload.list, function (el) {
					return '<li>' + el.name + ':<br>' + el.message + '</li>';
				}).join('');

				popup.init({
					title: payload.title,
					content: payload.message + '<ul class="shipping-restrictions-popup__list">' + list + '</ul>',
				}).showPopup();
			} else {
				var message = Resources.Checkout.Confirm.Error.Generic;
				if (data.hasOwnProperty('errorMessage')) {
					message = data.errorMessage;
				} else if(data.hasOwnProperty('message')) {
					message = data.message;
				}
                $('#submitOPCPaymentPrivateLabel').after('<div id="errorBoxOPC" class="payment-declined p-2 mt-2" role="alert" tabindex="0"><i class="fa fa-exclamation-triangle"></i> <b>Payment Declined:</b> ' + message + '</div>');
			}
			$submitPrivateLabelPayment.loading('destroy');
			setPlaceOrderButtonsDisabled(false);
			if (placeOrderRecaptchaPrivateLabel != undefined) {
				grecaptcha.reset(placeOrderRecaptchaPrivateLabel);
			}
		}.bind(this));
	} else {
		$submitPrivateLabelPayment.loading('destroy');
		setPlaceOrderButtonsDisabled(false);
		if (placeOrderRecaptchaPrivateLabel != undefined) {
			grecaptcha.reset(placeOrderRecaptchaPrivateLabel);
		}
	}
}

function initializePaymentMethodsEvents () {
	var ccListSelector = '#opc-cc-list input[type="radio"]',
		ccNumberSelector = 'input[name^="dwfrm_billing_paymentMethods_creditCard_number"]';

	$(ccNumberSelector).parent().append('<div class="cc-image"></div>');
	$('input[name^="dwfrm_billing_paymentMethods_privateLabelCredit_number"]').parent().append('<div class="cc-image"></div>');
	
	$paymentForm.attr('selected-payment-method', $('.input-radio.payment-method-selector:checked').val());

	$(document).on('change', ccListSelector, function () {
		$(ccNumberSelector)
			.parent()
			.find('.cc-image')
			.css('background-image', 'url(' + $(this).next().attr('src') + ')');
	});

	if ($(ccListSelector + ':checked').val()) {
		$(ccListSelector + ':checked').trigger('change');
	}
	
	$paymentForm.on('click', '.payment-method-selector', function (e) {
	    var paymentMethodId = ('' + $(this).attr('id')).replace('is-','');
	    
	    if (paymentMethodId) {
	    	$('.pt_onepcheckout input[name*="_selectedPaymentMethodID"]').val(paymentMethodId);
			$paymentForm.attr('selected-payment-method', paymentMethodId);
	    	
		    $paymentForm.find('.payment-method').each(function() {
		        $(this).removeClass('payment-method-expanded');
		        
		        if ($(this).attr('data-method') == paymentMethodId) {
		            $(this).addClass('payment-method-expanded');
		        }
		    });
		    
		    var $paymentMethodPanel = $('.payment-method[data-method="' + paymentMethodId + '"]');
		    if ($paymentMethodPanel.attr('payment-card-image')) {
		    	$paymentMethodPanel.find('.cc-image').css('background-image', 'url(' + $paymentMethodPanel.attr('payment-card-image') + ')');
	    	}
	    }
	    
	    if (window.innerWidth <= 767) {
			$([document.documentElement, document.body]).animate({
				// scrollTop: $('.payment-method-expanded').closest('.payment-method-option').offset().top - $('.top-banner').height() + 20
				scrollTop: $('#paymentSection').offset().top - $('.top-banner').height() - 15
			}, 250);
	    }
	    
	    addresses.updateSummary();
	});

	$(ccNumberSelector).on('keydown', function(e) {
		$creditCardList.val('');
	});
	
	$(ccNumberSelector).on('keyup', function(e){
		var creditCardNumber = $(this).val();
		
		var selectedCard = util.getCreditCardType(creditCardNumber);
		if (selectedCard) {
			$(ccListSelector).filter("[value='"+ selectedCard.PaymentCardDisplayKey +"']").eq(0).trigger('click');
		} else {
			$('.cc-cardlist-select :input').removeAttr('checked').removeAttr('selected').val('');
			$(ccListSelector).prop('checked', false);
			$paymentForm.find('.payment-method[data-method="' + PaymentMethodConstants.CreditCard + '"] .cc-image').css('background-image', '');
		}
	});

	$('.add-new-card').on('click', function(e){
		e.preventDefault();

		$('input[name^="dwfrm_billing_paymentMethods_creditCard_owner"]').val('');
		$('input[name^="dwfrm_billing_paymentMethods_creditCard_number"]').val('');
		$('input[name^="dwfrm_billing_paymentMethods_creditCard_cvn"]').val('');
		$('input[name^="dwfrm_billing_paymentMethods_creditCard_expiration_month"]').val('');
		$('input[name^="dwfrm_billing_paymentMethods_creditCard_expiration_year"]').val('');
		$('#opc-cc-list :input').removeAttr('checked');
		$('.cc-cardlist-select :input').removeAttr('checked').removeAttr('selected').val('');
		$('.cc-image').css('background-image', 'none');
	});
}

function setPlaceOrderButtonsDisabled(isDisabled) {
	$submitPayment.attr('disabled', isDisabled);	
	$('#submitOPCPaymentPrivateLabel').attr('disabled', isDisabled);	
}

/**
 * @description Make the AJAX request to set cart data
 * @param {Element} form The form element that present address object
 * @param {Element} Action name to apply to form
 * @returns {Promise}
 */
var setOPCPaymentData = function (form, name) {
    var $form = $(form);

    return TPromise.resolve($.ajax({
        type: 'POST',
        url: util.ajaxUrl(Urls.setOpcAddress),
        data: $form.serialize() + '&' + name + '=' + name
    }));
};

exports.init = function (optionsParameter) {
	options = optionsParameter;
	
	if (options && options.Recaptcha && (options.Recaptcha.EnabledForPlaceOrder || options.Recaptcha.EnabledForRewardsCredit || options.Recaptcha.EnabledForGiftCards)) {
    	recaptcha.initialize();
	}
	GiftCard.initialize(options);
	RewardsCredit.initialize(options);
	LoyaltyCredit.initialize(options);
    initializeEvents(options);
};