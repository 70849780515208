'use strict';

var AddressAutocompleteDialog;
var AddressVerificationDialog;
var AddressVerificationHelper = require('../../../../bc_sheplersbootbarn_core/cartridge/js/AddressVerification/AddressVerificationHelper');
var	dialog = require('../dialog');
var	findMoreStores = require('../findMoreStores');
var	orderStatusForm = require('../components/OrderStatusForm');
var	giftcert = require('../giftcert');
var	page = require('../page');
var recaptcha = require('../components/Recaptcha');
var	tooltip = require('../tooltip');
var	util = require('../util');
var	validator = require('../validator');

var PaymentMethodConstants = require('../../../../bc_sheplersbootbarn_core/cartridge/js/PaymentInstrumentConstants').PaymentMethods;
var SiteConstants = require('../../../../bc_sheplersbootbarn_core/cartridge/js/SiteConstants');

var dataBuffer = {};
var options = {};
var loaded = {};

/**
 * @function
 * @description Initializes the events on the address form (apply, cancel, delete)
 * @param {Element} form The form which will be initialized
 */
function initializeAddressForm() {
    var $addressEditForm = $('#edit-address-form');
	$addressEditForm.on('click', '.submit-shipping', function (e) {
        e.preventDefault();

		var addressType = $addressEditForm.attr('address-type') ? $addressEditForm.attr('address-type') : 'saved';
		var addressDeliverable = function () {
			$addressEditForm.submit();
		}
		var addressNotDeliverable = function (addressVerifyData) {
			AddressVerificationDialog.initialize(options, addressType, addressVerifyData);			
		}
		var country = $addressEditForm.find('[id*="_country"]').val();
		if (options && options.Lob && options.Lob.AddressVerification.Enabled && country.toUpperCase() == 'US' && $addressEditForm.find('.submit-shipping').attr('verified') != 'true') {
			AddressVerificationHelper.VerifyAddress($addressEditForm, options).then(addressDeliverable, addressNotDeliverable);
		} else {
			addressDeliverable();
		}
    });

	if (options && options.Lob && options.Lob.AddressAutocomplete.Enabled) {
		$(document).on('keyup.AddressAutocomplete', '#edit-address-form input[type="text"]', function (event) {
			var $this = $(this);
			var formValidator = $addressEditForm.validate();

			if (event.keyCode == 8 || event.keyCode == 46) {
				$this.parent().find('.address-autocomplete-dialog').remove();
			} else if (!formValidator.checkForm() && ($this.attr('id').indexOf('_address1') >= 0 || $this.attr('id').indexOf('_address2') >= 0 || $this.attr('id').indexOf('_city') >= 0 || $this.attr('id').indexOf('_postal') >= 0)) {
				var addressAutocompleteHandler = function (addressAutocompleteData) {
					AddressAutocompleteDialog.initialize(options, addressAutocompleteData);
				}
			
				AddressVerificationHelper.AutocompleteAddress($addressEditForm, $this, options).then(addressAutocompleteHandler);
			} else {
				$this.parent().find('.address-autocomplete-dialog').remove();
			}
		});
		
		$(document).on('SetAddressFromAddressAutocomplete', function (event, data) {
			AddressVerificationHelper.SetAddressFromAddressVerificationDialog(data.$form, data.Address);
		});
	}

	if (options && options.Lob && options.Lob.AddressVerification.Enabled) {
		$(document).on('SetAddressFromAddressVerification', function (event, addressData) {
			AddressVerificationHelper.SetAddressFromAddressVerificationDialog($addressEditForm, addressData);
			$addressEditForm.submit();
		});
	}

    validator.init();
}
/**
 * @private
 * @function
 * @description Toggles the list of Orders
 */
function toggleFullOrder () {
    $('.order-items')
        .find('li.hidden:first')
        .prev('li')
        .append('<a class="toggle">View All</a>')
        .children('.toggle')
        .click(function () {
            $(this).parent().siblings('li.hidden').show();
            $(this).remove();
        });
}
/**
 * @private
 * @function
 * @description Binds the events on the address form (edit, create, delete)
 */
function initAddressEvents() {
    $(document).ready(function(){
    	if ( $('.address-field-container form select').length > 0 ) {
        	$('.address-field-container form select').each(function(){
        		$(this).selectmenu({});
        	});
        }
    	
    	$("#dwfrm_profile_customer_berewarded").on("click",function(){
        	if ( $(this).is(':checked') ) {
        		$('.rewardsNumberContainer').slideDown();
        	} else {
        		$('.rewardsNumberContainer').slideUp();
        	}
    	});

		if ($('#edit-address-form').length > 0) {
			initializeAddressForm();
		}
    });
    
    $(window).on('load', function() {
    	$('#primary #edit-address-form input[name="dwfrm_profile_address_remove"]').off('click');
    	$('#primary #edit-address-form input[name="dwfrm_profile_address_remove"]').on('click', function(e){
    		e.preventDefault();
    		var $form = $(this).parents('form');
            if (window.confirm(String.format(Resources.CONFIRM_DELETE, Resources.TITLE_ADDRESS))) {
                var url = util.appendParamsToUrl(Urls.deleteAddress, {
                    AddressID: $form.find('#addressid').val(),
                    format: 'ajax'
                });
                $.ajax({
                    url: url,
                    method: 'POST',
                    dataType: 'json'
                }).done(function (data) {
                    if (data.status.toLowerCase() === 'ok') {
                        dialog.close();
                        var qParams = util.getQueryStringParams(window.location.search.substr(1));
                        if(qParams.cid !== null && qParams.cid == 'billing'){
                        	location.href = Urls.paymentsList;
                        } else {
                        	location.href = Urls.addressesList;
                        }                      
                    } else if (data.message.length > 0) {
                        window.alert(data.message);
                        return false;
                    } else {
                        dialog.close();
                        location.href = Urls.addressesList;
                    }
                });
            }
    	});
    });
}
/**
 * @private
 * @function
 * @description Binds the events of the payment methods list (delete card)
 */
function initPaymentEvents() {
	var $creditCardTypeSelect = $('select[id*="creditcard_type"]');
	
	// Remove dialog payment add/edit implementation to use page redirect instead
    /*$('.add-card').on('click', function (e) {
        e.preventDefault();
        dialog.open({
            url: $(e.target).attr('href')
        });
    });*/

	$creditCardTypeSelect.off('change.CreditCardTypeDetection').on('change.CreditCardTypeDetection', function (e) {
		var $this = $(this);
		var paymentInstrumentType = $this.val();
		var $allianceDataFieldDelta = $('input[name*="_owner"], select[name*="_month"], select[name*="_year"], label.expiration-date');
		var $allianceDataDisplayDelta = $('label.expiration-date');
		
	    switch (paymentInstrumentType) {
			case PaymentMethodConstants.AllianceData:
				$allianceDataFieldDelta.removeClass('required').closest('.formfield').hide();
				$allianceDataDisplayDelta.hide();
				break;
				
			default:
				$allianceDataFieldDelta.addClass('required').closest('.formfield').show();
				$allianceDataDisplayDelta.show();
				break;
		}
	});
	
	$('.cc-number-container input[type="text"]').off('keyup.CreditCardTypeDetection').on('keyup.CreditCardTypeDetection', function (e) {
		var selectedCreditCardType = util.getCreditCardType($(this).val());
		
		if (selectedCreditCardType) {
			$creditCardTypeSelect.val(selectedCreditCardType.PaymentCardDisplayKey);
		} else {
			$creditCardTypeSelect.val('');
		}
		
		$creditCardTypeSelect.trigger('change.CreditCardTypeDetection');
	});
    
    $creditCardTypeSelect.trigger('change.CreditCardTypeDetection');
	
	var paymentList = $('.payment-list');
    if (paymentList.length === 0) { return; }
	
    $('form[name="payment-remove"] button[type="submit"]').on('click', function (e) {
        e.preventDefault();
        
        // override form submission in order to prevent refresh issues
        var $button = $(e.target);
        
        // confirm delete
        if ($button.hasClass('delete')) {
        	if (!window.confirm(String.format(Resources.CONFIRM_DELETE, Resources.TITLE_CREDITCARD))) {
        		return false;
        	}
        }
        
        var $form = $(this).closest('form'); 
        
        $('<input/>').attr({
            type: 'hidden',
            name: $button.attr('name'),
            value: $button.attr('value') || 'delete card'
        }).appendTo($form);
        var data = $form.serialize();
        $.ajax({
            type: 'POST',
            url: $form.attr('action'),
            data: data
        })
        .done(function () {
            page.redirect(Urls.paymentsList);
        });
    });
}
/**
 * @private
 * @function
 * @description init events for the loginPage
 */
function initLoginPage() {
    //o-auth binding for which icon is clicked
    $('.oAuthIcon').bind('click', function () {
        $('#OAuthProvider').val(this.id);
    });
    $('.btn-lookup-order').on('click', function (e) {
        e.preventDefault();
        $('div.check-order-content').slideDown();
        $(this).slideUp();
    });
    $('#password-reset-rewards').on('click', function (e) {
        e.preventDefault();
        dialog.open({
            url: $(e.target).attr('href'),
            target : $(".password-reset-dialog-container"),
            options: {
            	dialogClass: 'select-rewards-number-popup',
            	width: 385,
                open: function () {
                    validator.init();
                    var $requestPasswordForm = $('[name$="_requestpassword"]'),
                        $submit = $requestPasswordForm.find('[name$="_requestpassword_send"]'),
                    	$resetPasswordForm = $('[name$="_resetpassword"]');
                    $($submit).on('click', function (e) {
                        if (!$requestPasswordForm.valid()) {
                            return;
                        }
                    });
                    if ( $resetPasswordForm.length > 0 ) {
                    	$resetPasswordForm.find("button[type=submit]").on('click', function (e) {
                    		e.preventDefault();
                    		var nameAttr = $(this).attr('name');
                    		dialog.submit(nameAttr);
                    	});
                    }
                    $(".reset-password-heading-close-btn").on('click',function(){
                        $("button.ui-dialog-titlebar-close").click();
                    });
                    execBarCodeGeneration();
                }
            }
        });
    });
    if ( $('#password-reset-rewards').length > 0 ) {
    	var ct = 0;
    	while(ct < 2) {
    		ct++;
    		$('#password-reset-rewards').click();
    	}
    }
}

/**
 * @private
 * @function
 * @description Binds the events of the order, address and payment pages
 */
function initializeEvents() {
    toggleFullOrder();
    initAddressEvents();
    initPaymentEvents();
    initLoginPage();
	initCreateAccountEventHandlers();
    if (util.isMobile()) {
    	util.limitInputToLength('#CreditCardForm input[name*="_number"]', 16);
    }

	var blurFields = "#dwfrm_profile_customer_email, #dwfrm_profile_customer_emailconfirm, input[id*=dwfrm_profile_login_password_], input[id*=dwfrm_profile_login_passwordconfirm_]";
    $(blurFields).blur(function(){
        if ( $(this).val() == '' ) {
            if ( $(this).parents('.form-row').find( '.form-caption.error-message' ).length ) {
                $(this).parents('.form-row').find( '.form-caption.error-message' ).html('');
            }
        }
    });
}

function execBarCodeGeneration() {
	if ($('.rewardsregistrationconfirmation').length > 0) {
		var barcodeNumber = ""+$('.rewardsregistrationconfirmation').find("[data-rewardsnumber]").data('rewardsnumber');
		var barcodeType = 'code128';
		
		$("#barcodeTarget").barcode(barcodeNumber, barcodeType, {
			barHeight:'75',
			barWidth:'2',
			fontSize:'14',
			fontWeight:'bold',
			output:'bmp'
		});
	}
}

function initRewardsPage() {
	$(window).on('load', function() {
		execBarCodeGeneration();
	});
}

$(window).on("message", function(e) {
    var data = e.originalEvent.data;
    if(data == "error"){
    	$(".menu-utility-user").addClass("loginError");
    }
});

function renderOptions(optionParent, optionsStoreId) {
	var options = '';
	var statesList = JSON.parse($(optionsStoreId + ' option:selected').data('options').replace(/\'/g, '\"'));
	
	statesList.forEach(function(state){
		options += '<option class="select-option" label="'+state.label+'" value="'+state.code+'">'+state.label+'</option>';
	});
	$(optionParent).empty().append(options);
	
	if (statesList.length <= 1) {
		$(optionParent).parent().parent().parent().hide();
		$(optionParent + '_sbm').val($(optionParent).val()).attr('disabled', false);
	} else {
		$(optionParent).parent().parent().parent().show();
		$(optionParent + '_sbm').attr('disabled', true);
	}
}

function initRewardRegisterForm() {
	$(document).ready(function() {
		var $rewardRegisterForm = $('#dwfrm_rewardsregistration');
		if ($rewardRegisterForm.length > 0) {
			$rewardRegisterForm.find('#dwfrm_rewardsregistration_rewardscustomer_phone').rules('add', {
				phone: true
			});
			$rewardRegisterForm.find('#dwfrm_rewardsregistration_rewardscustomeraddress_zipcode').rules('add', {
				postal: true
			});
			$rewardRegisterForm.find('#dwfrm_rewardsregistration_rewardscustomer_email').rules('add', {
				requireEmailExtension: true
			});
			$rewardRegisterForm.find('#dwfrm_rewardsregistration_rewardscustomer_emailconfirm').rules('add', {
				requireEmailExtension: true,
				equalTo: '#dwfrm_rewardsregistration_rewardscustomer_email'
			});

			$rewardRegisterForm.on('keyup', '#dwfrm_rewardsregistration_rewardscustomer_email', function(event) {
				var serverErrorMessage = $(event.target).closest('.form-row').find('.error-message');
				if (serverErrorMessage.length > 0) {
					serverErrorMessage.remove();
				}
			});
		}
	});
}

function initRewardsAttached() {
	$(window).on('load', function() {
		if ($('#rewardsCheckBalance #fieldRewardsNumber').val() != '') {
			$('#rewardsCheckBalance input[type="submit"]').trigger('click');
		}
	});
}

function passBreadSetup(rewardsNumber) {
	BreadPayments.setup({
		loyaltyID: rewardsNumber,
		storeNumber: util.isMobile() ? SitePreferences.PLCC_STORE_NUMBER_MOBILE : SitePreferences.PLCC_STORE_NUMBER_WEB,
		existingCardHolder: false,
		env: SitePreferences.PLCC_ENVIRONMENT,
	});
}

function initDisplayRewardsData() {
	$.ajax({
		url: Urls.getRewardInfoForRegistered,
		dataType: 'JSON',
		method: 'POST',
		data : {
			'csrf_token':  $('.account-overview input[name="csrf_token"]').val(),
			'rewardsNumber': $('.attached-rewards-account .rewards-number').text(),			
			'email': $('.attached-rewards-account.email-address').text(),
			'zipcode': $('.attached-rewards-account.zip-code').text(),
			'phone': $('.attached-rewards-account.phone-number').text()
		},
		success : function(data) {
			if ('error' in data && data['error']) {
				$('.myaccount-contact-custserv').removeClass('hidden');				
			} else {			
				if (data.securityRisk) {					
					$('.myaccount-contact-custserv').removeClass('hidden');
				} else {
					$('#active-rewards').removeClass('hidden');
					$('.attached-rewards-account.available-rewards').html("$" + parseFloat(data.creditAmount).toFixed(0));
					$('.attached-rewards-account.current-points').html(data.pointBalance);
				}		
			}
			if ($('#active-rewards').length >= 1) {
				passBreadSetup(data.rewardsNumber);
				BreadPayments.registerPlacements([dashboardPlacement]);
			}
		},
		complete: function(data, status, xhr) {
			if (data.status == 302 && data.responseText) {
				location.href = data.responseText;
			}
		}
	});
}

function initRewardCheckForm() {
	$(document).ready(function() {
		$('#rewardsSelect1').selectmenu();
		$('#rewardsSelect2').selectmenu();
		$('body').on('click', '#rewardsSelect1-menu .ui-menu-item-wrapper', function(){
			var select1text = $(this).text();
			
			if (select1text == "B Rewarded" || select1text == "Rewards Number" ) {
				$('#rewardsCheckBalance .brewarded-number').removeClass('hidden');
				$('#rewardsCheckBalance .rewards-number').removeClass('hidden');
				$('#rewardsCheckBalance .email-address').addClass('hidden');
				$('#rewardsSelect1-button').addClass('hidden');
			}
			
			if (select1text == "Email Address") {
				$('#rewardsCheckBalance .email-address').removeClass('hidden');
				$('#rewardsCheckBalance .brewarded-number').addClass('hidden');
				$('#rewardsCheckBalance .rewards-number').addClass('hidden');
				$('#rewardsSelect1-button').addClass('hidden');
			}	
		});
		
		$('body').on('click', '#rewardsSelect2-menu .ui-menu-item-wrapper', function(){
			var select2text = $(this).text();
			
			if (select2text == "Zip Code") {
				$('#rewardsCheckBalance .zip-code').removeClass('hidden');
				$('#rewardsCheckBalance .phone-number').addClass('hidden');
				$('#rewardsSelect2-button').addClass('hidden');
			}
			
			if (select2text == "Phone Number") {
				$('#rewardsCheckBalance .phone-number').removeClass('hidden');
				$('#rewardsCheckBalance .zip-code').addClass('hidden');
				$('#rewardsSelect2-button').addClass('hidden');
			}	
		});
		
		var $rewardCheckForm = $('#rewardsCheckBalance');
		if ($rewardCheckForm.length > 0) {
			$rewardCheckForm.find('#fieldRewardsNumber').rules('add', {
				positivenumber: true
			});
			$rewardCheckForm.find('#fieldRewardsEmail').rules('add', {
				requireEmailExtension: true
			});
			$rewardCheckForm.find('#fieldRewardsZipcode').rules('add', {
				postal: true
			});
			$rewardCheckForm.find('#fieldRewardsPhone').rules('add', {
				phone: true
			});
			
			$('#rewardsCheckBalance #resetRewardsInquiry').on('click',  function(event) {
				event.preventDefault();
				$('.ui-selectmenu-text').text('Select One');
				$('#rewardsSelect1-button').removeClass('hidden');
				$('#rewardsSelect2-button').removeClass('hidden');
				$('#rewardsCheckBalance .form-row').addClass('hidden').find('input.input-text').val('');
				$('#sendRewardsInquiry').attr('disabled', true);
				$('.rewards-results').html('');
			});
			
			$('#rewardsCheckBalance').on('change', 'input.input-text', function() {
				if ( $('#fieldRewardsNumber').val() || $('#fieldRewardsEmail').val() ) {
					if ( $('#fieldRewardsZipcode').val() || $('#fieldRewardsPhone').val() ){					
						$('#sendRewardsInquiry').removeAttr('disabled');
					}
				}
			});

			$rewardCheckForm.find('input#sendRewardsInquiry').on('click', function(event) {
				event.preventDefault();
				var $resultsContainer = $('.rewards-results');

				$resultsContainer.html('');

				if (!$rewardCheckForm.valid()) {
					return false;
				}
				
				if (pageContext.site === SiteConstants.SiteIds.BootBarnUS) {
					$.ajax({
						url: Urls.getRewardInfo,
						dataType: 'JSON',
						method: 'POST',
						data: {
							'csrf_token':  $rewardCheckForm.find('input[name="csrf_token"]').val(),
							'rewardsNumber': $rewardCheckForm.find('#fieldRewardsNumber').val(),
							'email': $rewardCheckForm.find('#fieldRewardsEmail').val(),
							'zipcode': $rewardCheckForm.find('#fieldRewardsZipcode').val(),
							'phone': $rewardCheckForm.find('#fieldRewardsPhone').val()
						},
						success : function(data) {
							if ('error' in data && data['error']) {
								$resultsContainer.append('<p class="error-block">'+data['message']+'</p>');
								$('.rewards-contact-custserv').removeClass('hidden');
							} else {							
								$('#rewards-dashboard').show();
								if (data.securityRisk) {
									$('.rewards-contact-custserv').removeClass('hidden');
								} else {
									$('.display-rewards').removeClass('hidden');
									if ( $('#goToCreditTwo').length < 1 ) {
										renderRewardCheckResults(data, $resultsContainer);
									}
								}
								$('.rewards-details').hide();
							}
						},
						complete: function(data, status, xhr) {
							if (data.status == 302 && data.responseText) {
								location.href = data.responseText;
							}
						}
					});
				}
			});
		}
	});
}

function renderRewardCheckResults(data, $resultsContainer) {
	var rewardsCreditAmount = $('.available-rewards').data('credit');
	var pointsRequired = $('.available-rewards').data('required');
	var pointsNeeded = pointsRequired-data.pointBalance;
	var pointsText = data.pointBalance == 1 ? ' PT ' : ' PTS ';
	
	$('#rewards-number').text("#" + data.rewardsNumber).removeClass('loader');
	$('#rewards-earned').text("$" + (Math.floor(parseFloat(data.pointsEarnedInLastYear/pointsRequired))*15).toFixed(2));
	$('.rewards-message div').removeClass('loader');
	$('#rewards-credit').show().html("$" + parseFloat(data.creditAmount).toFixed(0)).removeClass('loader');
	if (data.creditAmount != 0){		
		$('#rewards-expiration').show().find('span').text(data.creditExpirationDate);		
	} else {
		$('.rewards-zero-message').show();
	}
	$('.available-points div').removeClass('loader');
	$('.points-accrued').removeClass('loader').html(data.pointBalance);
	
	passBreadSetup(data.rewardsNumber);
	BreadPayments.registerPlacements([loyaltyPlacement]);
}

function initDisplayLoyaltyData() {
	if ($('.pt_account').length > 0) {
		$.ajax({
			url: Urls.loyaltyCheck,
			dataType: 'JSON',
			method: 'POST',
			data: {
				'loyaltyNumber': $('.settings--loyalty').attr('data-number'),
				'loyaltyZip': $('.settings--loyalty').attr('data-zip')
			},						
			success : function(data) {
				if (('error' in data && data['error'])) {
					$('.loyalty-error').text(data.message);
				} else {
					renderLoyaltyInfo(data.status);
				}
			},
			complete: function(data, status, xhr) {
				if (data.status == 302 && data.responseText) {
					location.href = data.responseText;
				}
			}
		});
	}
}

function renderLoyaltyInfo(data) {
	var optinStatus = data.optIn;
	
	if (optinStatus){
		$('.loyalty-number span.value').text(data.loyaltyMemberNumber);
		$('.current-points span.value').text(Math.floor(data.points));
		$('.available-rewards span.value').text("$" + data.totalRewards);
	} else {
		$('.settings--loyalty').hide();
	}
}

function clearLoyaltyForm() {
	$('.check-my-balance').show();
	$('.my-balance').hide();
	$('.ui-selectmenu-text').text('Select One');
	$('#loyaltySelect1-button').removeClass('hidden');
	$('#loyaltySelect2-button').removeClass('hidden');
	$('#loyaltyCheckBalance .form-row').addClass('hidden').find('input.input-text').val('');
	$('#sendLoyaltyInquiry').attr('disabled', true);
	$('.loyalty-results span').text('');
	$('.loyalty-error').html('');
}

function initLoyaltyCheckForm() {
	if ($('.pt_loyalty').length > 0) {
		$('#loyaltySelect1').selectmenu();
		$('#loyaltySelect2').selectmenu();
		
		$('body').on('click', '#loyaltySelect1-menu .ui-menu-item-wrapper', function(){
			var select1text = $(this).text();
			
			if (select1text == "Loyalty Number" ) {
				$('#loyaltyCheckBalance .brewarded-number').removeClass('hidden');
				$('#loyaltyCheckBalance .loyalty-number').removeClass('hidden');
				$('#loyaltyCheckBalance .email-address').addClass('hidden');
				$('#loyaltySelect1-button').addClass('hidden');
			}
			
			if (select1text == "Email Address") {
				$('#loyaltyCheckBalance .email-address').removeClass('hidden');
				$('#loyaltyCheckBalance .brewarded-number').addClass('hidden');
				$('#loyaltyCheckBalance .loyalty-number').addClass('hidden');
				$('#loyaltySelect1-button').addClass('hidden');
			}	
		});
		
		$('body').on('click', '#loyaltySelect2-menu .ui-menu-item-wrapper', function(){
			var select2text = $(this).text();
			
			if (select2text == "Zip Code") {
				$('#loyaltyCheckBalance .zip-code').removeClass('hidden');
				$('#loyaltyCheckBalance .phone-number').addClass('hidden');
				$('#loyaltySelect2-button').addClass('hidden');
			}
			
			if (select2text == "Phone Number") {
				$('#loyaltyCheckBalance .phone-number').removeClass('hidden');
				$('#loyaltyCheckBalance .zip-code').addClass('hidden');
				$('#loyaltySelect2-button').addClass('hidden');
			}	
		});
		
		var $loyaltyCheckForm = $('#loyaltyCheckBalance');
		if ($loyaltyCheckForm.length > 0) {
			$loyaltyCheckForm.find('#fieldLoyaltyNumber').rules('add', {
				positivenumber: true
			});
			$loyaltyCheckForm.find('#fieldLoyaltyEmail').rules('add', {
				requireEmailExtension: true
			});
			$loyaltyCheckForm.find('#fieldLoyaltyZipcode').rules('add', {
				postal: true
			});
			$loyaltyCheckForm.find('#fieldLoyaltyPhone').rules('add', {
				phone: true
			});
			
			$('#loyaltyCheckBalance #resetLoyaltyInquiry').on('click',  function(event) {
				event.preventDefault();
				clearLoyaltyForm();			
			});
			
			var scrollMinus = 0;
			if ($('.voucher').length > 8 ){
				scrollMinus = 100;
			}
			if ($(window).width() < 767) {
				scrollMinus = 190;
			}		
			
			$('.my-balance #startOverLoyalty').on('click',  function() {						
				clearLoyaltyForm();
				if (scrollMinus > 0) {
					$('html,body').animate({scrollTop: $(".tab").offset().top - scrollMinus}, 200);
				}
			});
			
			$('#loyaltyCheckBalance').on('change', 'input.input-text', function() {
				if ( $('#fieldLoyaltyNumber').val() || $('#fieldLoyaltyEmail').val() ) {
					if ( $('#fieldLoyaltyZipcode').val() || $('#fieldLoyaltyPhone').val() ){					
						$('#sendLoyaltyInquiry').removeAttr('disabled');
					}
				}
			});
	
			$loyaltyCheckForm.find('input#sendLoyaltyInquiry').on('click', function(event) {
				event.preventDefault();
	
				if (!$loyaltyCheckForm.valid()) {
					return false;
				}
				
				$.ajax({
					url: Urls.loyaltyCheck,
					dataType: 'JSON',
					method: 'POST',
					data: {
						'loyaltyNumber': $loyaltyCheckForm.find('#fieldLoyaltyNumber').val(),
						'loyaltyEmail': $loyaltyCheckForm.find('#fieldLoyaltyEmail').val(),
						'loyaltyZip': $loyaltyCheckForm.find('#fieldLoyaltyZipcode').val(),
						'loyaltyPhone': $loyaltyCheckForm.find('#fieldLoyaltyPhone').val()
					},						
					success : function(data) {
						if (('error' in data && data['error'])) {
							$('.loyalty-error').text(data.message);
						} else {													
							renderLoyaltyCheckResults(data.status);
						}
					},
					complete: function(data, status, xhr) {
						if (data.status == 302 && data.responseText) {
							location.href = data.responseText;
						}
					}
				});
			});
		}
	}
}

function renderLoyaltyCheckResults(data) {
	var optinStatus = data.optIn;
	var currentSite = '';
	if (pageContext.site === SiteConstants.SiteIds.SheplersUS) {
    	currentSite = Resources.LoyaltyCredit.NotOptedInSHEP;
    }
	if (pageContext.site === SiteConstants.SiteIds.CountryOutfittersUS) {
    	currentSite = Resources.LoyaltyCredit.NotOptedInCO;
    }
	
	if (optinStatus){
		$('.check-my-balance').hide();
		$('.my-balance').removeClass('hidden').show();
		$('.loyalty-number span').text(data.loyaltyMemberNumber);
		$('.current-points span').text(Math.floor(data.points));
		$('.available-rewards span').text("$" + data.totalRewards);
		var details = '';
		var vouchers = data.rewardInfo;
		
		vouchers.forEach(function(voucher){
			details += '<div class="voucher"><span class="reward-amount">Reward Amount: <b>$'+voucher.rewardAmount+'</b></span><span class="expiration">Expiration Date: <b>'+voucher.expirationDate+'</b></span></div>';
		});
		
		$('.details').html(details);
	} else {
		$('.loyalty-error').text(currentSite);
	}
}

function initLoyaltySocial() {
	var handle = '';
	
	$('body').on('focus', '#fieldLoyaltySocialHandleInstagram', function() {
		$('.handle-error').text('');
	});

	$('body').on('click', '#updateInstagramHandle', function(){
		$('.handle-display').removeClass('show-handle').addClass('show-form');
		$('.form-display').removeClass('show-handle').addClass('show-form');
	});
	
	$('body').on('click', '#addInstagramHandle', function(){
		event.preventDefault();
		
		var formValidation = true;
		var handle = $('#fieldLoyaltySocialHandleInstagram').val();
		var regexHandle = /^@([a-zA-Z0-9]|[._](?![._])){2,30}$/;
		if (String(handle).match(regexHandle) == null ) {
			formValidation = false;
		}

		if (formValidation) {	
			$.ajax({
				url: Urls.loyaltyOptin,
				dataType: 'JSON',
				method: 'POST',
				data: {
					'email': $('.settings--loyalty').attr('data-email'),
					'dwCustomerID': $('.settings--loyalty').attr('data-dwid'),
					'instaHandle': handle,
					'optStatus': $('.settings--loyalty').attr('data-optin')
				},						
				success : function(data) {
					if (('error' in data && data['error'])) {
						$('.handle-error').text(data.message);
					} else {													
						$('.handle-display').removeClass('show-form').addClass('show-handle');
						$('.handle-display .value').text(handle);
						$('.form-display').removeClass('show-form').addClass('show-handle');
					}
				}
			});
		} else {
			$('.handle-error').text('Please enter a valid social handle.');
		}
	});
}

function initAddressFormEvents() {
	$(document).ready(function(){
		$(document).on('change', '#dwfrm_profile_address_country', function(){
			renderOptions('#dwfrm_profile_address_states_state', '#dwfrm_profile_address_country');
			var $initialState = $('#dwfrm_profile_address_states_state_sbm');
			
			if ($initialState.length > 0 && $initialState.val() != '') {
				$('#dwfrm_profile_address_states_state').val($initialState.val());
			}
		});
		$('#dwfrm_profile_address_country').trigger('change');
		
		$(document).on('click', '.find-more-stores a', function () {
			findMoreStores.initialize({ view: 'account' });
		});
		
		$(document).on('opc.SetShippingAddress', function (e, address) {
			var $closestStores = $('#closestStores');
			var $makeThisMystore = $('#storeControls').find('[data-action="makethismystore"]');
			
			$closestStores.find('option.find-more-stores').remove();
			if ($closestStores.find('option[value="' + address.storeId + '"]').length == 0) {
				$closestStores.find('option').first().after('<option class="find-more-stores" value="' + address.storeId + '">' + address.firstName + ' - ' + address.labelText + '</option>');
			}
			
			$closestStores.val(address.storeId);
			$closestStores.trigger('change');
			if ($makeThisMystore.length > 0) {
				$makeThisMystore.trigger('click');
			}
		});

		$(document).on('click', '.address-tile .address-delete', function() {
			if (!confirm(String.format(Resources.CONFIRM_DELETE, Resources.TITLE_ADDRESS))) {
				return false;
			}
		});
	});
}

function verifyAccountCode() {  
	// 'enter-code' rules will be applied to rewards verification page with this code. See loginDialog.js for the rules added after account dialog is opened.	
	$('#verify-code').find('#enter-code').rules('add', {
		required: true,
		digits: true,
		minlength: 6,
		messages: {
			required: Resources.VALIDATE_CODE,
			minlength: Resources.VALIDATE_CODE
		},
		normalizer: function(value) {
			return $.trim(value);
		}
	});
}

/* On the Account registration (aka settings) page, hook up the event handler to update the hidden form field
 * for 'My Store Number' when the (dynamically populated) Closest Stores drop down is changed. 
 * Also, set the value of the selected option in the drop down, or the value in 'My Store Number'
 * appropriately on load (based on if creating an Account or editing one. 
 */
function initCreateAccountEventHandlers(){
	var $closestStores = $('#closestStores');
	var $storeControls = $('#storeControls');
	if ($closestStores != undefined && $closestStores != null){
		var lengthStores = $closestStores.find('option').length;
		if (lengthStores > 0) {
			var storedValue = $('#dwfrm_profile_customer_myStoreNumber').val();
			$closestStores.on('change', function(e) {
				var newValue = $(this).val();
				$('#dwfrm_profile_customer_myStoreNumber').val(newValue);
				updateSelectedStorePreview($(e.target).find(':selected'));

				if (storedValue !== newValue) {
					$storeControls.removeClass('hidden');
				} else {
					$storeControls.addClass('hidden');
				}
			});
			
			setSelectedStoreDropdownOrMyStoreNumber();
		}
		$storeControls.on('click', 'a', function(e) {
			e.preventDefault();
			var storeId = $('#dwfrm_profile_customer_myStoreNumber').val();
			$.ajax({
				url : Urls.assignStoreId,
				data : { store_id : storeId },
				method : "POST",
				dataType : "JSON",
				success : function(data) {
					if ( 'success' in data && data['success'] == true ) {
						location.reload();
					}
				}
			});
		});
	}
}

/* Check and see if the profile has a value for 'My Store Number', which it might if on the Account Edit page.
 * If so, then set the store drop down to the store on the profile.
 * If not, then set the hidden form field for 'My Store Number' to the selected store in the drop down, which will be the first one when on the Create Account page.  
 */
function setSelectedStoreDropdownOrMyStoreNumber() {
	var $myStoreNumberSelected = $('#dwfrm_profile_customer_myStoreNumber');
	if ($myStoreNumberSelected != undefined && $myStoreNumberSelected != null){
		var currentMyStoreValue = $myStoreNumberSelected.val(); 
		if (currentMyStoreValue == undefined || currentMyStoreValue == null || currentMyStoreValue.length == 0){
			$myStoreNumberSelected.val($('#closestStores').val());	
		} else {
			$('#closestStores option[value=' + currentMyStoreValue + ']').attr('selected','selected');
			updateSelectedStorePreview($('#closestStores option[value=' + currentMyStoreValue + ']'));
		}
        $('#storeSection').removeClass('hidden');
	}
}

function updateSelectedStorePreview($store) {
	if ($('#selectedStore').length > 0) {
		$('#selectedStore .name').text($store.data('name'));
		$('#selectedStore .address1').text($store.data('address1'));
		if ($store.data('address2') && $store.data('address2').length > 0) {
			$('#selectedStore .address2').text($store.data('address2')).removeClass('hidden');
		} else {
			$('#selectedStore .address2').text('').addClass('hidden');
		}
		
		$('#selectedStore .city').text($store.data('city'));
		$('#selectedStore .stateCode').text($store.data('statecode'));
		$('#selectedStore .postalCode').text($store.data('postalcode'));
		$('#selectedStore .countryCode').text($store.data('countrycode'));
		$('#selectedStore .phone').text($store.data('phone'));
	}
}



module.exports = {
    init: function (optionsParameter) {
		options = optionsParameter;

		if (options && options.Lob && options.Lob.AddressVerification.Enabled) {
			AddressVerificationDialog = require('../Lob/AddressVerification/AddressVerificationDialog');
		}

		if (options && options.Lob && options.Lob.AddressAutocomplete.Enabled) {
			AddressAutocompleteDialog = require('../Lob/AddressAutocomplete/AddressAutocompleteDialog');
		}

        initializeEvents();
        giftcert.init();
        initAddressFormEvents();
        initRewardRegisterForm();
        initRewardCheckForm();
        initLoyaltyCheckForm();
        if (pageContext.site === SiteConstants.SiteIds.BootBarnUS) {
        	initDisplayRewardsData();
        }
        if ((pageContext.site === SiteConstants.SiteIds.CountryOutfittersUS || pageContext.site === SiteConstants.SiteIds.SheplersUS) && SitePreferences.LOYALTY_ENABLED) {
        	initDisplayLoyaltyData();
        	initLoyaltySocial();
        }
        initRewardsAttached();
		verifyAccountCode();
        orderStatusForm.initialize($('#dwfrm_ordertrack'));      
    },
    initCartLogin: function () {
        initLoginPage();
        initRewardsPage();
    }
};