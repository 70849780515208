'use strict';

exports.ValidateProductLineItems = validateProductLineItems;

var options;
var buffer = {};

function validateProductLineItems($bopisProductLineItems, optionsParameter) {
	if (optionsParameter) {
		options = optionsParameter;
	}
	
	buffer['TotalCount'] = $bopisProductLineItems.length;
	buffer['LoadedCount'] = 0;
	buffer['ProductLineItems'] = {};
	
	$bopisProductLineItems.each(function(index, element) {
		var $this = $(this);
		var uuid = $this.attr('uuid');
		var productId = $this.attr('sku');
		var storeId = $this.attr('store');
		var quantityRequested = $this.closest('.order-item').find('.qty-edit input[type="text"]').val();
		
		$.ajax({
            method: 'POST',
            url: Urls.Bopis.GetStoreInventoryForProductForStore,
            contentType: 'application/json',
            async: true,
            data: JSON.stringify({
                ProductId: productId,
                StoreId: storeId
            }),
            dataType: 'json',
            success: function(data) {
                var inquiryResult;
                if (data && data.ServiceSuccessfullyCalled && data.InquiryResult && data.InquiryResult.data[0].inventory_supplies) {
                    inquiryResult = data.InquiryResult.data[0].inventory_supplies;
                }
                
                if (inquiryResult && inquiryResult && inquiryResult.length > 0) {
                	buffer['ProductLineItems'][uuid] = {
                		QuantityRequested: parseInt(quantityRequested, 10),
                		InquiryResult: inquiryResult
                	};
                	
                    buffer['LoadedCount'] += 1;
                    validateProductLineItemsSuccess();
                } else {
                	$result.html('');
                    
                    if (options && options.ErrorCallback) {
                    	options.errorCallback();
                    }
                }
            },
            error: function() {
                if (options && options.ErrorCallback) {
                	options.errorCallback();
                }
            }
        });
	});
	
	return false;
}

function validateProductLineItemsSuccess() {
	if (buffer['LoadedCount'] == buffer['TotalCount']) {
		var isBopisInventoryValid = true;
		for (var productLineItemUUID in buffer['ProductLineItems']) {
			var productLineItem	= buffer['ProductLineItems'][productLineItemUUID];
			
			if (productLineItem.QuantityRequested > productLineItem.InquiryResult[0].detail.quantity) {
				isBopisInventoryValid = false;
			}
		}
		
		if (options && options.SuccessEvent) {
			$(document).trigger(options.SuccessEvent, {
				SubmitPaymentForBopis: true,
				IsBopisInventoryValid: isBopisInventoryValid
			});
		}
	}
}