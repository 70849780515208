'use strict';

var _ = require('lodash'),
	dialog = require('./dialog');

var $dialogContent,
	state = {},
	ready = {},
	templates = {},
	html = {};

var templateSettings = { interpolate: /{{([\s\S]+?)}}/g };

function initialize(options) {
	state['options'] = options || {};
	
	loadTemplates();
	loadStores();
	loadStates();
	
	openDialog();	
}

function openDialog() {
	ready['dialog'] = false;
	
	dialog.open({
		html: '<div class="dialog-loading"></div>',
		options: {
			title: Resources.FIND_MORE_STORES,
			dialogClass: 'dialog-find-more-stores',
			resizable: false,
			draggable: false,
			open: function() {
				$dialogContent = $(this);
				ready['dialog'] = true;
				
				events();
				render();
			}
		}
	});
}

function events() {
	$dialogContent.off('click.findMoreStores.go').on('click.findMoreStores.go', '.search button', function () {
		loadStores(true);
	});
	
	$dialogContent.off('click.findMoreStores.submit').on('click.findMoreStores.submit', '.submit', function () {
		var selectedStore = $dialogContent.find('.stores-list input:checked').val();
		
		if (selectedStore) {
			$(document).trigger('opc.SetShippingAddress', state['stores'].addresses[selectedStore]);
			dialog.close();
		}
	});
}

function loadStates() {
	ready['states'] = false;
	
	$.ajax({ method: 'POST', url: Urls.getCountryStates, data: 'countryCode=US', dataType: 'json', success: function (data) {		
		state['states'] = JSON.parse(data.statesList);
		ready['states'] = true;
				
		render();
	}});
}

function renderStates() {
	html['states'] = '<option class="placeholder"></option>';
	
	for (var stateElement in state['states']) {
		var stateOption = state.states[stateElement];
		
		html['states'] += '<option value="' + stateOption.label + '">' + stateOption.label + '</option>';
	}
	
	$dialogContent.find('select[name="StateCode"]').html(html['states']);
}

function loadStores(update) {
	ready['stores'] = false;
	
	var payload = {};
	
	if ($dialogContent) {
		payload.PostalCode = $dialogContent.find('.search [name="PostalCode"]').val();
		payload.StateCode = $dialogContent.find('.search [name="StateCode"]').val();
	}
	
	payload.storeQuery = state.options['storeQuery'] ? state.options['storeQuery'] : {};
	
	$.ajax({ method: 'POST', url: Urls.getMoreStoresAddressList, data: JSON.stringify(payload), contentType: 'application/json', dataType: 'json', success: function (data) {		
		state['stores'] = data;
		ready['stores'] = true;
		
		if (update) {
			renderStores();
		} else {
			render();
		}
	}});
}

function renderStores() {
	html['stores'] = '';
	
	if (state['stores'].addresses.length > 0) {
		var index = 0;
		for (var storeAddressesElement in state['stores'].addresses) {
			var storeAddress = state['stores'].addresses[storeAddressesElement];
			
			var streetAddress = storeAddress.address1;
			if (storeAddress.address2) {
				streetAddress += storeAddress.address2;
			}
			
			html['stores'] += templates['location']({
				id: index,
				name: storeAddress.fullName + ' ' + storeAddress.lastName,
				address: streetAddress,
				city: storeAddress.city,
				state: storeAddress.stateCode,
				postal: storeAddress.postalCode,
				phone: storeAddress.phone
			});
			
			index++;
		}
		
		$dialogContent.find('.no-results').hide();
		$dialogContent.find('.stores-list').html(html['stores']).show();
	} else {
		$dialogContent.find('.no-results').show();
		$dialogContent.find('.stores-list').hide();
	}
	
	
}

function loadTemplates() {
	templates = {};
	
	$.ajax({ method: 'POST', url: Urls.getMoreStoresDialogTemplate, dataType: 'html', success: function (data) {
		templates['dialog'] = _.template(data, templateSettings);
		render();
	}});
	
	$.ajax({ method: 'POST', url: Urls.getMoreStoresLocationTemplate, dataType: 'html', success: function (data) {
		templates['location'] = _.template(data, templateSettings);
		render();
	}});
}

function render(update) {
	if (ready['dialog'] && ready['stores'] && ready['states'] && templates['dialog'] && templates['location']) {
		$dialogContent.html(templates['dialog']());
		
		if (state.options && state.options.view) {
			$dialogContent.attr('view', state.options.view);
		} else {
			$dialogContent.removeAttr('view');
		}
		
		renderStores();
		renderStates();
	}	
}

exports.initialize = initialize;