'use strict';

var _ = require('lodash');
var GoogleTagManagerClient = require('../../../../bc_sheplersbootbarn_core/cartridge/js/util/GoogleTagManagerClient');
var loadingIndicator = require('../components/LoadingIndicator');
var TemplateConstants = require('../../../../bc_sheplersbootbarn_core/cartridge/js/TemplateConstants');
var util = require('../util');
var ValidationSettings = require('../../../../bc_sheplersbootbarn_core/cartridge/js/ValidationConstants').Settings;
var ValidationRules = require('../../../../bc_sheplersbootbarn_core/cartridge/js/ValidationConstants').Rules;
var ValidationHelper = require('../../../../bc_sheplersbootbarn_core/cartridge/scripts/util/ValidationHelper');

var options;
var dataBuffer;
var $container;
var $step;
var templates;
var loadingIndicatorSettings;

exports.init = function (optionsParameter) {
	if (optionsParameter) {
		options = optionsParameter;
	} else {
		options = {};
	}
	$container = $('.bootfinder');
	$step = $container.find('[step]');
	dataBuffer = {
		step: 0,
		stepData: {}
	};
	templates = {
		sectionContainer: _.template($('#template-bootfinder-section-container').html(), TemplateConstants.Lodash.Settings),
		section: _.template($('#template-bootfinder-section').html(), TemplateConstants.Lodash.Settings),
		option: _.template($('#template-bootfinder-option').html(), TemplateConstants.Lodash.Settings),
		optionImage: _.template($('#template-bootfinder-option-image').html(), TemplateConstants.Lodash.Settings),
		results: _.template($('#template-bootfinder-results').html(), TemplateConstants.Lodash.Settings)
	}
	loadingIndicatorSettings = {
		IsLabelShown: false
	};
	
	$container.off('click.BootFinderStepDelta').on('click.BootFinderStepDelta', '[step-delta]', function(event) {
		var $this = $(this);
		var stepDelta = parseInt($this.attr('step-delta'), 10);
		dataBuffer.stepDelta = stepDelta;
		var step = dataBuffer.step + dataBuffer.stepDelta;
		if (step >= 0 && step <= options.Configuration.Steps.length) {
			dataBuffer.step = step;
		}
		render();
	});

	// $container.off('click.BootFinderStepIndex').on('click.BootFinderStepIndex', '[step-index]', function(event) {
	// 	var $this = $(this);
	// 	var step = parseInt($this.attr('step-index'), 10);
	// 	if (step >= 0 && step <= options.Configuration.Steps.length) {
	// 		dataBuffer.step = step;
	// 	}
	// 	render();
	// });

	$container.off('click.BootFinderStepOptions').on('click.BootFinderStepOptions', '[step] .options .option', function(event) {
		var $this = $(this);
		var sectionIndex = parseInt($this.closest('[section]').attr('section'), 10);
		if ($this.hasClass('selected') && !options.Configuration.Steps[dataBuffer.step].Sections[sectionIndex].SelectRequired) {
			$this.removeClass('selected');
			if (dataBuffer.stepData[dataBuffer.step] && dataBuffer.stepData[dataBuffer.step][sectionIndex]) {
				var selectedIndex = dataBuffer.stepData[dataBuffer.step][sectionIndex].indexOf($this.attr('value'));
				if (selectedIndex > -1) {
					dataBuffer.stepData[dataBuffer.step][sectionIndex].splice(selectedIndex, 1);
				}
			}
			if (options.Configuration.Steps[dataBuffer.step].Sections[sectionIndex].Refinement == 'size' || options.Configuration.Steps[dataBuffer.step].Sections[sectionIndex].Refinement == 'width') {
				updateSizes(sectionIndex, options.Configuration.Steps[dataBuffer.step].Sections[sectionIndex]);
			}
		} else {
			if (!options.Configuration.Steps[dataBuffer.step].Sections[sectionIndex].SelectMultiple) {
				$this.closest('.options').find('.option').removeClass('selected');
			}
			if (options.Configuration.Steps[dataBuffer.step].Sections[sectionIndex].SelectReset && (dataBuffer.step + 1) <= options.Configuration.Steps.length) {
				for (var stepIndex = (dataBuffer.step + 1); stepIndex <= options.Configuration.Steps.length; stepIndex++) {
					dataBuffer.stepData[stepIndex] = {};
				}
			}
			$this.addClass('selected');
			if (!dataBuffer.stepData[dataBuffer.step]) {
				dataBuffer.stepData[dataBuffer.step] = {}
			}
			if (!dataBuffer.stepData[dataBuffer.step][sectionIndex] || !options.Configuration.Steps[dataBuffer.step].Sections[sectionIndex].SelectMultiple) {
				dataBuffer.stepData[dataBuffer.step][sectionIndex] = [];
			}
			var sectionData = dataBuffer.stepData[dataBuffer.step][sectionIndex];
			sectionData[sectionData.length] = $this.attr('value');
			if (options.Configuration.Steps[dataBuffer.step].Sections[sectionIndex].Refinement == 'size' || options.Configuration.Steps[dataBuffer.step].Sections[sectionIndex].Refinement == 'width') {
				updateSizes(sectionIndex, sectionData);
			}
		}

		if (!options.Configuration.Steps[dataBuffer.step].Sections[sectionIndex].SelectMultiple && $this.closest('.options').find('.selected').length > 0 && dataBuffer.step < (options.Configuration.Steps.length - 1)) {
			dataBuffer.stepDelta = 1;
			dataBuffer.step += dataBuffer.stepDelta;
			render();
		}
	});

	$container.off('click.BootFinderSendResultsEmail').on('click.BootFinderSendResultsEmail', '.send-results-email', function(event) {
		event.preventDefault();
		
		var $this = $(this);
		var $form = $this.closest('form');
		if (dataBuffer.$emailForm && dataBuffer.$emailForm.valid()) {
			var url = buildUrl();
			url = util.appendParamToURL(url, 'bootfinderemail');

			var payload = {
				email: $form.find('input[type="email"]').val(),
				url: url
			}
			$step.loading($.extend(loadingIndicatorSettings, {
				IsLabelShown: true,
				Content: 'Sending Email...'
			}));
			$.ajax({
				type: 'post',
				data: payload,
				url: buildUrl(Urls.BootFinder.SendResultsEmail),
				success: function(data) {
					if (data && data.success) {
						$step.find('.loading-indicator').find('.content').html('Email Sent!<br/>Loading Results...');
						window.location = $('.view-results').attr('href');
					} else {
						$container.find('.validation-error-container').html('<span class="error">An error occurred sending the email. Please try again.</span>');
					}
				}
			});
		}
	});

	$container.off('click.BootFinderViewResults').on('click.BootFinderViewResults', '.view-results', function(event) {
		if (!$step.attr('loading')) {
			$step.loading($.extend(loadingIndicatorSettings, {
				Content: 'Loading Results...'
			}));
			var url = buildUrl();
			url = util.appendParamToURL(url, 'bootfinderviewresults');
			window.location = url;
		}
	});

	$(window).off('load.BootFinder resize.BootFinder').on('load.BootFinder resize.BootFinder', function(event) {
		if ($(window).outerWidth() <= 768) {
			$container.css('min-height', $(window).outerHeight() - $('.header-wrapper').height());
		} else {
			$container.css('min-height', 0);
		}
	});

	var stepListHtml = '';
	for (var stepIndex in options.Configuration.Steps) {
		stepListHtml += '<a step-index="' + stepIndex + '"></a>';
	}
	$container.find('.step-list').html(stepListHtml);
	render();
};

function render() {
	$step.height($step.outerHeight());
	$step.html('');
	$step.loading(loadingIndicatorSettings);
	$('html').animate({ scrollTop: 0 }, 250);
	if (dataBuffer.step == (options.Configuration.Steps.length - 1)) {
		$container.find('.next').hide();
		$container.find('.view-results').show();
	} else {
		$container.find('.next').show();
		$container.find('.view-results').hide();
	}
	renderStep();
	sendDataLayer();
	if ($container.hasClass('results')) {
		$container.removeClass('results');
	}

	$container.find('.step-list a').removeClass('active');
	$container.find('.step-list a:nth-child(' + (dataBuffer.step + 1) + ')').addClass('active');
	$container.find('[step]').attr('step', dataBuffer.step);
}

function updateSizes(sectionIndex, sectionData) {
	return getRefinementCountsForSection(sectionIndex, sectionData).then(function(data) {
		if (options.Configuration.Steps[dataBuffer.step].Sections[sectionIndex] && options.Configuration.Steps[dataBuffer.step].Sections[sectionIndex].Refinement == 'size') {
			var $selectedLengths = $('[section="' + sectionIndex + '"] .options').find('.option.selected');
			var $widths = $('[section="' + (sectionIndex + 1) + '"] .options .option');
			if ($selectedLengths && $selectedLengths.length > 0) {
				$widths.addClass('disabled');
				$selectedLengths.each(function() {
					var selectedLength = $(this).attr('value');
					$widths.each(function() {
						var width = $(this).attr('value');
						var size = selectedLength + ' ' + width;
						if (dataBuffer.Refinements && dataBuffer.Refinements.size && dataBuffer.Refinements.size[size]) {
							$widths.filter('[value="' + width + '"]').removeClass('disabled');
						}
					});
				});
			} else {
				$widths.removeClass('disabled');
			}
		} else if (options.Configuration.Steps[dataBuffer.step].Sections[sectionIndex] && options.Configuration.Steps[dataBuffer.step].Sections[sectionIndex].Refinement == 'width') {
			var $selectedWidths = $('[section="' + sectionIndex + '"] .options').find('.option.selected');
			var $lengths = $('[section="' + (sectionIndex - 1) + '"] .options .option');
			if ($selectedWidths && $selectedWidths.length > 0) {
				$lengths.addClass('disabled');
				$selectedWidths.each(function() {
					var selectedWidth = $(this).attr('value');
					$lengths.each(function() {
						var length = $(this).attr('value');
						var size = length + ' ' + selectedWidth;
						if (dataBuffer.Refinements && dataBuffer.Refinements.size && dataBuffer.Refinements.size[size]) {
							$lengths.filter('[value="' + length + '"]').removeClass('disabled');
						}
					});
				});
			} else {
				$lengths.removeClass('disabled');
			}
		}
	});
}

function renderStep() {
	var step = options.Configuration.Steps[dataBuffer.step];
	var stepData;
	if (dataBuffer.stepData && dataBuffer.stepData[dataBuffer.step]) {
	 	stepData = dataBuffer.stepData[dataBuffer.step];
	}
	dataBuffer.sectionRenderCount = 0;
	for (var sectionIndex in step.Sections) {
		var section = step.Sections[sectionIndex];
		var columns = 1;
		if (section.Columns) {
			columns = section.Columns;
		}
		$container.find('[step]').append(templates.sectionContainer({
			Section: sectionIndex,
			Columns: columns
		}));

		var optionsHtml = '';
		if (section.Options) {
			var sectionOptions;
			if (typeof section.Options == 'object' && section.OptionsRelatedSelectionStep != undefined && section.OptionsRelatedSelectionSection != undefined && dataBuffer.stepData && dataBuffer.stepData[section.OptionsRelatedSelectionStep] && dataBuffer.stepData[section.OptionsRelatedSelectionStep][section.OptionsRelatedSelectionSection]) {
				var optionsRelatedSelectionValue = dataBuffer.stepData[section.OptionsRelatedSelectionStep][section.OptionsRelatedSelectionSection];
				sectionOptions = section.Options[optionsRelatedSelectionValue];
			} else {
				sectionOptions = section.Options;
			}
			for (var optionIndex in sectionOptions) {
				var option = sectionOptions[optionIndex];
				if (option.Text) {
					var cssClass = '';
					if (stepData && stepData[sectionIndex] && 'indexOf' in stepData[sectionIndex] && stepData[sectionIndex].indexOf(option.Text) > -1) {
						cssClass = 'selected';
					}

					optionsHtml += templates.option({
						Image: '',
						Text: option.Text,
						Value: option.Text,
						CssClass: cssClass
					});
				}
			}
			renderSection(sectionIndex, section.Header, section.Text, optionsHtml);
		} else if (section.Refinement && section.Refinement != 'width') {
			getRefinementCountsForSection(sectionIndex, section).then(function(data) {
				var optionsHtmlInner = '';
				if (data.Section.Refinement == 'size') {
					var lengths = [];
					var widths = [];
					var widthRegex = / [\w\/]+/gi;
					for (var refinementKey in data.Refinements) {
						var refinementCount = data.Refinements[refinementKey];
						if (refinementCount) {
							var widthRegexResult = refinementKey.match(widthRegex);
							var length;
							var width;
							if (widthRegexResult && widthRegexResult.length) {
								width = widthRegexResult[widthRegexResult.length - 1].trim();
							}
							if (width) {
								length = refinementKey.replace(width, '').trim();
							}
							if (length && lengths.indexOf(length) < 0) {
								lengths[lengths.length] = length;
							}
							if (width && widths.indexOf(width) < 0) {
								widths[widths.length] = width;
							}
						}
					}
					lengths.sort(function(left, right) {
						var leftValue = left.replace(/ *1\/2/, '.5');
						var rightValue = right.replace(/ *1\/2/, '.5');
						return parseFloat(leftValue) - parseFloat(rightValue);
					});

					widths.sort(function(left, right) {
						var leftValue = parseInt(left, 10);
						var rightValue = parseInt(right, 10);
						if (leftValue) {
							leftValue = ''.padStart(leftValue, left.replace(/\d/g, '')[0]);
						} else {
							leftValue = left;
						}
						if (rightValue) {
							rightValue = ''.padStart(rightValue, right.replace(/\d/g, '')[0]);
						} else {
							rightValue = right;
						}

						var leftWidthDescription;
						if (options.WidthDescriptionConfiguration && options.WidthDescriptionConfiguration[dataBuffer.stepData[0][0]] && options.WidthDescriptionConfiguration[dataBuffer.stepData[0][0]]['Boot'] && options.WidthDescriptionConfiguration[dataBuffer.stepData[0][0]]['Boot'][left]) {
							leftWidthDescription = options.WidthDescriptionConfiguration[dataBuffer.stepData[0][0]]['Boot'][left];
						}

						var rightWidthDescription;
						if (options.WidthDescriptionConfiguration && options.WidthDescriptionConfiguration[dataBuffer.stepData[0][0]] && options.WidthDescriptionConfiguration[dataBuffer.stepData[0][0]]['Boot'] && options.WidthDescriptionConfiguration[dataBuffer.stepData[0][0]]['Boot'][right]) {
							rightWidthDescription = options.WidthDescriptionConfiguration[dataBuffer.stepData[0][0]]['Boot'][right];
						}

						if (leftWidthDescription == 'Medium' && rightWidthDescription != 'Medium') {
							return -1;
						} else if (leftWidthDescription != 'Medium' && rightWidthDescription == 'Medium') {
							return 1;
						}
						if (leftWidthDescription == 'Wide' && rightWidthDescription != 'Wide') {
							return -1;
						} else if (leftWidthDescription != 'Wide' && rightWidthDescription == 'Wide') {
							return 1;
						}
						return leftValue.localeCompare(rightValue);
					});

					for (var lengthIndex in lengths) {
						var length = lengths[lengthIndex];
						var cssClass = '';
						if (stepData && stepData[data.SectionIndex] && 'indexOf' in stepData[data.SectionIndex] && stepData[data.SectionIndex].indexOf(length) > -1) {
							cssClass = 'selected';
						}
						optionsHtmlInner += templates.option({
							Image: '',
							Text: length,
							Value: length,
							CssClass: cssClass
						});
					}
					dataBuffer.lengths = lengths;
					renderSection(data.SectionIndex, data.Section.Header, data.Section.Text, optionsHtmlInner);

					var widthsHtml = '';
					var widthSectionIndex = parseInt(data.SectionIndex, 10) + 1;
					for (var widthIndex in widths) {
						var width = widths[widthIndex];
						var cssClass = '';
						if (stepData && stepData[widthSectionIndex] && 'indexOf' in stepData[widthSectionIndex] && stepData[widthSectionIndex].indexOf(width) > -1) {
							cssClass = 'selected';
						}
						var widthDescription = '';
						if (options.WidthDescriptionConfiguration && options.WidthDescriptionConfiguration[dataBuffer.stepData[0][0]] && options.WidthDescriptionConfiguration[dataBuffer.stepData[0][0]]['Boot'] && options.WidthDescriptionConfiguration[dataBuffer.stepData[0][0]]['Boot'][width]) {
							widthDescription = ' (' + options.WidthDescriptionConfiguration[dataBuffer.stepData[0][0]]['Boot'][width] + ')';
						}
						widthsHtml += templates.option({
							Image: '',
							Text: width + widthDescription,
							Value: width,
							CssClass: cssClass
						});
					}
					dataBuffer.widths = widths;
					$container.find('[section="' + widthSectionIndex + '"] .options').html(widthsHtml);
					updateSizes(sectionIndex, options.Configuration.Steps[dataBuffer.step].Sections[sectionIndex]);
				} else {
					for (var refinementKey in data.Refinements) {
						var refinementCount = data.Refinements[refinementKey];
						if (refinementCount) {
							var refinementText;
							if (dataBuffer.DisplayValues[data.Section.Refinement] && dataBuffer.DisplayValues[data.Section.Refinement][refinementKey]) {
								refinementText = dataBuffer.DisplayValues[data.Section.Refinement][refinementKey];
							} else {
								refinementText = refinementKey;
							}

							var cssClass = '';
							if (stepData && stepData[data.SectionIndex] && 'indexOf' in stepData[data.SectionIndex] && stepData[data.SectionIndex].indexOf(refinementKey) > -1) {
								cssClass = 'selected';
							}

							var sectionImages;
							if (typeof section.Images == 'object' && section.ImagesRelatedSelectionStep != undefined && section.ImagesRelatedSelectionSection != undefined && dataBuffer.stepData && dataBuffer.stepData[section.ImagesRelatedSelectionStep] && dataBuffer.stepData[section.ImagesRelatedSelectionStep][section.ImagesRelatedSelectionSection]) {
								var imagesRelatedSelectionValue = dataBuffer.stepData[section.ImagesRelatedSelectionStep][section.ImagesRelatedSelectionSection];
								sectionImages = section.Images[imagesRelatedSelectionValue];
							} else {
								sectionImages = section.Images;
							}
							var imageHtml = '';
							if (sectionImages && sectionImages[refinementKey]) {
								imageHtml = templates.optionImage({
									ImageUrl: sectionImages[refinementKey]
								});
							}							
							optionsHtmlInner += templates.option({
								Image: imageHtml,
								Text: refinementText,
								Value: refinementKey,
								CssClass: cssClass
							});
						}
					}
					renderSection(data.SectionIndex, data.Section.Header, data.Section.Text, optionsHtmlInner);
				}
			}).catch();
		} else {
			renderSection(sectionIndex, section.Header, section.Text, optionsHtml);
		}
	}
}

function renderSection(index, header, textParameter, sectionOptions) {
	dataBuffer.sectionRenderCount++;
	var text = '';
	if (textParameter) {
		text = '<p>' + textParameter + '</p>';
	}
	$container.find('[section="' + index + '"]').html(templates.section({
		Header: header,
		Text: text,
		Options: sectionOptions
	}));

	if (dataBuffer.sectionRenderCount == options.Configuration.Steps[dataBuffer.step].Sections.length) {
		if ($step.attr('loading')) {
			$step.css('height', '');
			$step.loading('destroy');
			$('html').animate({ scrollTop: 0 }, 250);
		}
		$container.find('[step-delta]').removeClass('disabled');
		if (options.Configuration.Steps[dataBuffer.step].Sections[index].SelectRequired && $('.options').length != $('.options').find('.selected').length) {
			$container.find('.next').addClass('disabled');
		}	
		if (dataBuffer.step == 0) {
			$container.find('.back').addClass('disabled');
		}
		if ($container.find('.option').length == 0) {
			if (dataBuffer.step < (options.Configuration.Steps.length - 1)) {
				dataBuffer.step += dataBuffer.stepDelta;
				render();
			} else {
				$('section header h2').text('No more options to choose from, click the View Results button!')
			}
		}
	}
}

function renderResults() {
	var url = buildUrl();
	url = util.appendParamToURL(url, 'bootfinderviewresults');

	$container.find('[step]').html(templates.results({
		Url: url
	}));

	dataBuffer.$emailForm = $container.find('form.email');
	dataBuffer.emailValidator = dataBuffer.$emailForm.validate(ValidationSettings.Global);
	dataBuffer.$emailForm.find('input[name="email"]').rules('add', $.extend(ValidationRules.Email, {
		required: true,
		messages: {
			required: $.validator.format('Please enter an email address.')
		}
	}));
	dataBuffer.$emailForm.find('input[name="useragreement"]').rules('add', {
		required: true,
		messages: {
			required: $.validator.format('Please agree to the terms and conditions.')
		}
	});

	if ($step.attr('loading')) {
		$step.css('height', '');
		$step.loading('destroy');
		$('html').animate({ scrollTop: 0 }, 250);
	}
	$container.find('[step-delta]').removeClass('disabled');
	$container.find('.next').addClass('disabled');
}

function buildUrl(urlParameter, includeAdditionalData) {
	var url;
	if (dataBuffer && dataBuffer.stepData[options.Configuration.CategorySelectionStep] && dataBuffer.stepData[options.Configuration.CategorySelectionStep][options.Configuration.CategorySelectionSection]) {
		var categorySelectionSection = options.Configuration.Steps[options.Configuration.CategorySelectionStep].Sections[options.Configuration.CategorySelectionSection];
		var optionRelatedSelection = dataBuffer.stepData[categorySelectionSection.OptionsRelatedSelectionStep][categorySelectionSection.OptionsRelatedSelectionSection][0];
		var categorySelection = dataBuffer.stepData[options.Configuration.CategorySelectionStep][options.Configuration.CategorySelectionSection][0];

		var categorySelectionIndex;
		var optionRelatedSelectionOptionIndex = 0;
		var optionRelatedSelectionOptions = categorySelectionSection.Options[optionRelatedSelection];
		while (!categorySelectionIndex && optionRelatedSelectionOptionIndex <= optionRelatedSelectionOptions.length) {
			if (optionRelatedSelectionOptions[optionRelatedSelectionOptionIndex] && optionRelatedSelectionOptions[optionRelatedSelectionOptionIndex].Text == categorySelection) {
				categorySelectionIndex = optionRelatedSelectionOptionIndex;
			}
			optionRelatedSelectionOptionIndex++;
		}

		var categorySelectionData = categorySelectionSection.Options[optionRelatedSelection][categorySelectionIndex];
		if (urlParameter) {
			url = urlParameter;
		} else {
			url = categorySelectionData.Url;
		}
		var parameterIndex = 1;
		for (var categorySelectionDataKey in categorySelectionData) {
			var categorySelectionDataValue = categorySelectionData[categorySelectionDataKey];
			if (urlParameter && categorySelectionDataKey == 'cgid') {
				url = util.appendParamToURL(url, 'cgid', categorySelectionDataValue);
			} else if (categorySelectionDataKey != 'Text' && categorySelectionDataKey != 'Url' && categorySelectionDataKey != 'cgid') {
				url = util.appendParamToURL(url, 'prefn' + parameterIndex, categorySelectionDataKey);
				url = util.appendParamToURL(url, 'prefv' + parameterIndex, categorySelectionDataValue);
				parameterIndex++;
			}
		}

		for (var stepIndexInner in options.Configuration.Steps) {
			if (stepIndexInner <= dataBuffer.step) {
				var stepInner = options.Configuration.Steps[stepIndexInner];
				for (var sectionIndexInner in stepInner.Sections) {
					var sectionInner = stepInner.Sections[sectionIndexInner];
					if (sectionInner.Refinement && sectionInner.Refinement != 'width') {
						var refinementValues;
						if (dataBuffer.stepData && dataBuffer.stepData[stepIndexInner] && dataBuffer.stepData[stepIndexInner][sectionIndexInner]) {
							refinementValues = dataBuffer.stepData[stepIndexInner][sectionIndexInner];
						} else {
							refinementValues = [];
						}

						if (sectionInner.Refinement == 'size') {
							var widthSectionIndex = parseInt(sectionIndexInner, 10) + 1;
							var selectedWidths = dataBuffer.stepData[stepIndexInner][widthSectionIndex];
							var isDatabufferWidths = false;
							if (!selectedWidths || !selectedWidths.length) {
								selectedWidths = dataBuffer.widths;
								isDatabufferWidths = true;
							}
							var isDatabufferLengths = false;
							if (!refinementValues || !refinementValues.length) {
								refinementValues = dataBuffer.lengths;
								isDatabufferLengths = true;
							}
							var selectedSizes = [];
							if (!isDatabufferWidths || !isDatabufferLengths) {
								for (var refinementIndex in refinementValues) {
									var selectedLength = refinementValues[refinementIndex];
									for (var widthIndex in selectedWidths) {
										var selectedWidth = selectedWidths[widthIndex];
										var selectedSize = selectedLength + ' ' + selectedWidth;
										if (selectedSizes.indexOf(selectedSize) < 0) {
											selectedSizes[selectedSizes.length] = selectedSize;
										}
									}
								}
							}
							refinementValues = selectedSizes;
						}

						if (refinementValues && refinementValues.length > 0) {
							if (sectionInner.Refinement == 'Price') {
								if (dataBuffer.Thresholds && dataBuffer.Thresholds[sectionInner.Refinement] && dataBuffer.Thresholds[sectionInner.Refinement][refinementValues]) {
									var threshold = dataBuffer.Thresholds[sectionInner.Refinement][refinementValues[0]];
									url = util.appendParamToURL(url, 'pmin', threshold.valueFrom);
									url = util.appendParamToURL(url, 'pmax', threshold.valueTo);	
								}
							} else {
								url = util.appendParamToURL(url, 'prefn' + parameterIndex, sectionInner.Refinement);
								url = util.appendParamToURL(url, 'prefv' + parameterIndex, refinementValues.join('|'));
								parameterIndex++;
							}
						}
					}
				}
			}
		}
		if (includeAdditionalData) {
			url = util.appendParamToURL(url, 'displayvalues', 'Price');
			url = util.appendParamToURL(url, 'thresholds', 'Price');
		}
	}

	return url;
}

function getRefinementCountsForSection(sectionIndex, section) {
	var promise = new Promise(function(resolve, reject) {
		$.ajax({
			type: 'get',
			url: buildUrl(Urls.getRefinementCounts, true),
			success: function(data) {
				dataBuffer = $.extend(dataBuffer, data);

				var payload = {
					SectionIndex: sectionIndex,
					Section: section
				};
				if (section && section.Refinement) {
					payload.Refinements = data.Refinements[section.Refinement];
				}
				resolve(payload);
			}
		});
	});

	return promise;
}

function sendDataLayer() {
	GoogleTagManagerClient.PushDataLayerForEvent('bootfinderquiz', {
		step: dataBuffer.step,
		stepData: JSON.parse(JSON.stringify(dataBuffer.stepData))
	}, null, 'GoogleAnalytics4');
}