'use strict';

var _ = require('lodash');
var loadingIndicator = require('../../components/LoadingIndicator');
var GeolocationHelper = require('../../../../../bc_sheplersbootbarn_core/cartridge/js/Geolocation/GeolocationHelper');
var SiteConstants = require('../../../../../bc_sheplersbootbarn_core/cartridge/js/SiteConstants');
var TemplateConstants = require('../../../../../bc_sheplersbootbarn_core/cartridge/js/TemplateConstants');
var ValidationSettings = require('../../../../../bc_sheplersbootbarn_core/cartridge/js/ValidationConstants').Settings;
var ValidationRules = require('../../../../../bc_sheplersbootbarn_core/cartridge/js/ValidationConstants').Rules;
var ValidationHelper = require('../../../../../bc_sheplersbootbarn_core/cartridge/scripts/util/ValidationHelper');

module.exports = LocationSearch;

function LocationSearch() {
	this.$container;
	this.$form;
	this.dataBuffer;
	this.options;
	this.validator;
	
	this.resultContainerTemplate = _.template($('#template-bopis-result-container').html(), TemplateConstants.Lodash.Settings);
	this.resultItemTemplate = _.template($('#template-bopis-result-item').html(), TemplateConstants.Lodash.Settings);

	this.$result;
	this.$formInputPostalCode;
	this.$storeSearchButton;
	this.$storeSelectButton;
	this.$bopisButton;
	this.$errorMessage;
	
	this.initialize = function ($container, options) {
		var self = this;
		this.$container = $container;
		this.$form = $container.find('form');
		this.options = $.extend({
			successCallback: null,
			errorCallback: null
		}, options);
		this.dataBuffer = {
			limit: this.options && this.options.Bopis && this.options.Bopis.StoreSearchLimit ? this.options.Bopis.StoreSearchLimit : 5
		};
		this.$result = this.$container.find('.result');
		this.$formInputPostalCode = this.$form.find('input[name$="_postalCode"]');
		this.$storeSearchButton = this.$container.find('.btn.store-search');
		this.$storeSelectButton = this.$container.find('.btn.store-select');
		this.$bopisButton = this.$container.find('.btn.bopis');
	    this.$errorMessage = this.$form.find('span.error');
		$('html').addClass('scroll-lock');

		if (this.options.DefaultZipCode) {
			this.$formInputPostalCode.val(this.options.DefaultZipCode);
		}

	    this.$container.attr('state', 'form');
	    this.validator = this.$form.validate(ValidationSettings.Global);
		$.validator.addMethod('zipCode', ValidationHelper.ValidateZipCode, Resources.INVALID_POSTAL_CODE);
	    this.$formInputPostalCode.rules('add', ValidationRules.ZipCode);
	
	    if (this.validator.checkForm() && this.$container.attr('state') === 'form' && !this.$storeSearchButton.hasClass('ready')) {
			this.$storeSearchButton.addClass('ready').removeAttr('disabled');
	    }
		this.$container.show();
	    
	    this.$form.off('click.Apply').on('click.Apply', '.btn.store-search:not([loading])', '.btn.store-search:not([loading])', function (event) {
	    	event.preventDefault();
	    	$('.bopis-store-search').attr('state', 'form');
	    	self.$container.trigger('LocationSearch.FormSubmit');
	    });
	    
	    this.$container.off('click.DialogClose').on('click.DialogClose', '.close', function (event) {
	    	event.preventDefault();
			$('html').removeClass('scroll-lock');
	    	$('.bopis-store-search').attr('state', 'form');
			$('.location-search-dialog').hide();
	    });
	    
	    this.$container.off('LocationSearch.FormSubmit').on('LocationSearch.FormSubmit', function (event) {
			event.preventDefault();

	        self.$errorMessage.html('').hide();
	        if (Urls && Urls.Bopis.GetLocationsForPostalCode && self.validator.valid() && self.$formInputPostalCode.val()) {
				self.dataBuffer.loadMore = 'postalCode';
				self.dataBuffer.offset = 0;
				self.$storeSearchButton.loading();
				self.$result.html('');
				self.$result.loading();
				self.getStoreListForPostalCode(self.$formInputPostalCode.val());
			}
		});
	    
	    this.$container.off('click.SelectStore').on('click.SelectStore', '.btn.select-store:not([loading])', function (event) {
			$('html').removeClass('scroll-lock');
			$(this).loading({
				Content: '<h6>Hold Your Horses!</h6><p>Your selections are loading...</p>',
				CssClass: 'bootbarnexpress',
				BackgroundColor: 'transparent'
			});
	    });

		this.$container.off('click.SelectStore').on('click.SelectStore', '.btn.set-my-store:not([loading])', function (event) {
			var $this = $(this);
			var $storeInput = $this.closest('li').find('input');
			var storeId;
			$('html').removeClass('scroll-lock');
			if ($storeInput && $storeInput.length > 0) {
				storeId = $storeInput.val();
			}

			if (storeId) {
				self.updateStoreDetails(storeId);
			}
	    });
	    
	    this.$formInputPostalCode.off('keypress.LocationSearch.FormSubmit').on('keypress.LocationSearch.FormSubmit', function (event) {
	    	if (event.key == 'Enter') {
	    		self.$container.trigger('LocationSearch.FormSubmit');
	    		return false;
	    	}
	    });
	    
	    this.$formInputPostalCode.off('keydown.LocationSearch.ValidationCheck').on('keydown.LocationSearch.ValidationCheck', function (event) {
	    	if (self.$formInputPostalCode.val().length == 5 && parseInt(event.key, 10) == event.key && !window.getSelection().toString()) {
	    		event.preventDefault();
	    	}
	    	
	    	$(document).find('.bopis-store-search').attr('state', 'form');
	    });
	    
	    
	    this.$formInputPostalCode.off('change.LocationSearch.ValidationCheck').on('change.LocationSearch.ValidationCheck', function (event) {
	    	if (self.$formInputPostalCode.val().length > 5) {
	    		self.$formInputPostalCode.val(self.$formInputPostalCode.val().substr(0, 5));
	    	}
	    	
	    	$(document).find('.bopis-store-search').attr('state', 'form');
	    });
	    
	    this.$formInputPostalCode.off('keyup.LocationSearch.ValidationCheck, change.LocationSearch.ValidationCheck').on('keyup.LocationSearch.ValidationCheck, change.LocationSearch.ValidationCheck', function (event) {
	    	event.stopImmediatePropagation();
	    	
			if ($container.attr('state') === 'form') {
				if (self.validator.checkForm() && !self.$storeSearchButton.hasClass('ready')) {
					self.$storeSearchButton.addClass('ready').removeAttr('disabled');
				} else if (!self.validator.checkForm() && self.$storeSearchButton.hasClass('ready')) {
					self.$storeSearchButton.removeClass('ready').attr('disabled', 'disabled');
				}
			}
	    });
	    
	    $(document).off('mousedown.LocationSearch.ValidationClear').on('mousedown.LocationSearch.ValidationClear', function (event) {
	        if (self.validator && $(event.target).closest(self.$container).length == 0) {
	            self.$errorMessage.html('').hide();
	        }
	    });
	 
	    this.$container.off('click.LocationSearch.Reset').on('click.LocationSearch.Reset', '.reset', function (event) {
	    	self.$formInputPostalCode.val('');
	        self.$container.attr('state', 'form');
	        self.$storeSearchButton.removeClass('ready').attr('disabled', 'disabled');
	        $('.product-add-to-cart button').removeAttr('disabled');
	        self.$bopisButton.removeClass('ready');
	    });
   
	    this.$container.off('click.LocationSearch.Selection').on('click.LocationSearch.Selection', 'input[name="Bopis_StoreSearch_StoreId"]', function (event) {
	        var bopisStore = $('input[name="Bopis_StoreSearch_StoreId"]:checked').val();
	        
	        if (!self.$bopisButton.hasClass('ready')) {
	        	self.$bopisButton.addClass('ready');
	        }

			if (self.$storeSelectButton.length > 0) {
				self.$storeSelectButton.attr('href', Urls.storeLandingPage + '?Store=' + bopisStore).addClass('ready');; 
			}
	        
	        $('.product-add-to-cart button').attr('disabled', 'disabled');
	        
	        if (bopisStore) {
	        	self.$bopisButton.attr('state', 'pickup');
	        } else {
	        	self.$bopisButton.attr('state', 'ship');
	        }
	    });

		this.$container.off('click.UseMyLocation').on('click.UseMyLocation', '.use-my-location', function (event) {
			event.preventDefault();
			self.geolocationSearch();
		});

		$(document).off('LocationSearch.GeolocationSearch').on('LocationSearch.GeolocationSearch', function (event) {
			self.geolocationSearch();
		});

		this.$container.off('click.LoadMore').on('click.LoadMore', '.load-more', function (event) {
			event.preventDefault();

			$(this).loading({ IsLabelShown: false });
			self.dataBuffer.offset += self.dataBuffer.limit;
			if (self.dataBuffer.loadMore == 'geolocationPosition') {
				self.getStoreListForGeolocationPosition(self.dataBuffer.geolocationPosition, true);
			} else if (self.dataBuffer.loadMore == 'postalCode') {
				self.getStoreListForPostalCode(self.$formInputPostalCode.val(), true);
			}
		});
	}

	this.geolocationSearch = function() {
		var self = this;
		self.dataBuffer.loadMore = 'geolocationPosition';
		self.dataBuffer.offset = 0;
		this.$result.html('');
		this.$result.loading();
		GeolocationHelper.GetPosition().then(function(position) {
			self.dataBuffer.geolocationPosition = position;
			self.getStoreListForGeolocationPosition(self.dataBuffer.geolocationPosition);
		}).catch(function() {
			if (self.$result.attr('loading')) {
				self.$result.loading('destroy');
			}
		});
	}

	this.remove = function() {
		this.validator.destroy();
		$('.product-add-to-cart button').removeAttr('disabled');
	}
	
	this.getStoreListForGeolocationPosition = function(position, isLoadMore) {
		var self = this;
		var geolocationPosition;
		if (position && position.coords) {
			geolocationPosition = {
				coords: {
					accuracy: position.coords.accuracy,
					latitude: position.coords.latitude,
					longitude: position.coords.longitude
				},
				timestamp: position.timestamp
			};
		}
		$.ajax({
			method: 'POST',
			url: Urls.Bopis.GetLocationsForGeolocationPosition,
			contentType: 'application/json',
			data: JSON.stringify({
				Channel: this.options.Channel,
				Source: this.options.Source,
				GeolocationPosition: geolocationPosition,
				Radius: 3000,
				Limit: self.dataBuffer.limit,
				Offset: self.dataBuffer.offset
			}),
			dataType: 'json',
			success: function(data) {
				self.renderStoreList(data, isLoadMore);
			},
			error: function() {
				
			}
		});		
	}

	this.getStoreListForPostalCode = function(postalCode, isLoadMore) {
		var self = this;
		$.ajax({
			method: 'POST',
			url: Urls.Bopis.GetStoresByCityStatePostalSearchKeyAjax,
			contentType: 'application/json',
			data: JSON.stringify({
				Channel: self.options.Channel,
				Source: self.options.Source,
				SearchKey: postalCode,
				Limit: self.dataBuffer.limit,
				Offset: self.dataBuffer.offset
			}),
			dataType: 'json',
			success: function(data) {
				self.renderStoreList(data, isLoadMore);
			},
			error: function() {
				if (self.options.errorCallback) {
					self.options.errorCallback();
				}
			}
		});
	}

	this.renderStoreList = function(data, isLoadMore) {
		var locationData;
		if (data && data.ServiceSuccessfullyCalled && data.InquiryResult && data.InquiryResult.data && data.InquiryResult.data.length > 0) {
			locationData = data.InquiryResult.data;
		}

		this.$container.attr('state', 'result');
		if (locationData && locationData.length > 0) {
			var locationsDisplayed = 0;
			locationData = locationData.sort(function(left, right) {
				return left.distance - right.distance;
			});
			
			this.dataBuffer['StoresDisplayed'] = [];
			this.$container.attr('result-count', locationData.length);
			
			var storeListHtml = '';
			for (var locationElement in locationData) {
				var location = locationData[locationElement];
				
				if (location && !this.dataBuffer['StoresDisplayed'][location.code]) {
					var storePhone = '';
					var storeOpenUntil = '';
					if (location.StoreInfo) {
						if (location.StoreInfo.Phone) {
							storePhone = location.StoreInfo.Phone;
						}
						if (location.StoreInfo.OpenUntil) {
							storeOpenUntil = location.StoreInfo.OpenUntil;
						}
					}

					var locationName;
					if (this.options && this.options.StoreUIVersion == '2024Redesign') {
						locationName = '<strong>' + location.StoreInfo.Name + ' - ' + location.StoreInfo.StoreId + '</strong>';
					} else {
						locationName = '<strong>' + location.StoreInfo.Name + '</strong>';
					}
					if (this.options && this.options.AgentStoreID == location.code) {
						locationName += '<br/>(Your Store)';
					}
					var html = locationName + '<br/>' +
								location.StoreInfo.Address1 + '<br />' + 
								location.StoreInfo.City + ', ' + location.StoreInfo.StateCodeParsed + ' ' + location.StoreInfo.PostalCode.substr(0, 5) + '<br />' +
								storePhone;
					if (pageContext.siteId == SiteConstants.BootBarnRspUS && storeOpenUntil) {
						html += '<br />' + storeOpenUntil;
					}

					storeListHtml += this.resultItemTemplate({
						StoreId: location.code,
						Distance: location.StoreInfo.Distance.toFixed(1) + '<br/>mi',
						Selected: '',
						LandingCategory: this.options.LandingCategory,
						StatusDisplay: storeOpenUntil,
						Html: html
					});
				}
				
				locationsDisplayed++;
				if (locationsDisplayed >= this.dataBuffer.limit) {
					break;
				}
				this.dataBuffer['StoresDisplayed'][location.code] = true;
			}
			
			var resultHtml = this.resultContainerTemplate({
				Html: storeListHtml
			});
			if (pageContext.siteId == SiteConstants.BootBarnRspUS && data.InquiryResult.meta.page < data.InquiryResult.meta.pages) {
				resultHtml += '<a href="#" class="load-more">Load More</a>';
			}
			if (this.$storeSearchButton.attr('loading')) {
				this.$storeSearchButton.loading('destroy');
			}
			if (this.$result.attr('loading')) {
				this.$result.loading('destroy');
			}
			if (isLoadMore) {
				this.$result.find('.load-more').remove();
				this.$result.append(resultHtml);
			} else {
				this.$result.html(resultHtml);
			}
		} else if (!isLoadMore) {
			this.$container.attr('result-count', '0');
			this.$result.html('');
		}
	}

	this.updateStoreDetails = function(storeId) {
		if (storeId) {
			$.ajax({
				method: 'POST',
				url: Urls.Stores.SetMyStore,
				contentType: 'application/json',
				data: JSON.stringify({
					StoreId: storeId
				}),
				dataType: 'html',
				success: function(data) {
					$('.my-store-panel').replaceWith(data);
					$('.my-store-modal').hide();
					$('.location-search-dialog').hide();

					$.ajax({
						method: 'GET',
						url: Urls.Stores.GetMyStorePanel,
						dataType: 'html',
						success: function(data) {
							$('.my-store-panel').replaceWith(data);
						}
					});

					$.ajax({
						method: 'GET',
						url: Urls.Stores.GetMyStoreModal,
						dataType: 'html',
						success: function(data) {
							$('.my-store-modal').replaceWith(data);
						}
					});

					$.ajax({
						method: 'GET',
						url: Urls.getStoresPanel,
						dataType: 'html',
						success: function(data) {
							$('#location').html(data);
						}
					});

					$(document).trigger('ShopMyStore.Update', storeId);
				},
				error: function() {
					$('.my-store-modal').hide();
					$('.location-search-dialog').hide();
				}
			});
		}
	}

	this.assignStoreId = function(storeId) {
		if (storeId) {
			$.ajax({
				url : Urls.assignStoreId,
				data : { store_id : storeId },
				method : "POST",
				dataType : "JSON",
				success : function(data) {
					if ('success' in data && data['success'] == true) {
						// TODO: any follow-up needed client-side?
					}
				}
			});
		}
	}
	
	return this;
}
