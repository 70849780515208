'use strict';

var loadingIndicator = require('./LoadingIndicator');

exports.initialize = initialize;

var recommendationsContainers = [];

function initialize(options) {
	if (options.Url && options.Containers && options.Containers.length > 0) {
		for (var index = 0; index < options.Containers.length; index++) {
			recommendationsContainers[recommendationsContainers.length] = new RecommendationsContainer(options.Url, options.Containers[index]).load();
		}
	}
}

function RecommendationsContainer(url, container) {
	var $container;
	var $containerBody;
	var dataBuffer = {};
	var htmlBuffer = {};
	var currentHtmlBufferCount = 0;
	var expectedHtmlBufferCount = 0;

	this.load = function() {
		$container = $(container.Selector);
		$containerBody = $container.find('.accordion-body');
		
		$container.hide();
		
		if ($container.length > 0 && container.ProductId && container.RelationType) {
			$containerBody.loading();
			$container.show();
			
			var requestData = {
				'styles': container.ProductId,
				'relationtype': container.RelationType
			}

			$.ajax({
				method: 'GET',
				url: url,
				data: requestData,
				dataType: 'json',
				success: function(data) {
					if (data && data.products && data.products.length > 0) {
						dataBuffer = data;
						expectedHtmlBufferCount = data.products.length;

						for (var index = 0; index < data.products.length; index++) {
							getProductHitTile((index + 1), data.products[index].productid);
						}
					} else {
						$container.hide();
						finish();
					}
				},
				error: function() {
					$container.hide();
					finish();
				}
			});
		}
	}

	var getProductHitTile = function(position, productId) {
		var requestData = {
			'position': position,
			'pid': productId,
			'showswatches': 'true',
			'showpricing': 'true',
			'showpromotion': 'true',
			'showrating': 'true',
			'showbadges': 'false',
			'showaffirm': 'false',
		}

		$.ajax({
			method: 'GET',
			url: Urls.hitTile,
			data: requestData,
			dataType: 'html',
			success: function (data) {
				if (data) {
					htmlBuffer[productId] = '<li class="slide">' + data + '</li>';
				}
			},
			complete: function () {
				currentHtmlBufferCount++;
				render();
			}
		});
	}

	var render = function() {
		if (currentHtmlBufferCount == expectedHtmlBufferCount) {
			var html = '';

			for (var index = 0; index < dataBuffer.products.length; index++) {
				if (htmlBuffer[dataBuffer.products[index].productid]) {
					html += htmlBuffer[dataBuffer.products[index].productid];
				}
			}

			var $html = $(html);
			$html.find('.product-tile').addClass('product');

			$container.find('.slides').html($html);
			$container.find('.slider-products-primary').trigger('init-slider');
			finish();

			if ('affirm' in window && 'ui' in affirm && 'refresh' in affirm.ui) {
			    affirm.ui.refresh();
			}
		}
	}

	var finish = function() {
		$containerBody.loading('destroy');
		$(document).trigger('RelevantProductRecommendations.Done', $container);
		$(document).trigger('Currency.Update', $container);
	}
}