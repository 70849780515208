'use strict';

var loadingIndicator = require('./LoadingIndicator');
var SiteConstants = require('../../../../bc_sheplersbootbarn_core/cartridge/js/SiteConstants');

exports.initialize = initialize;

var productTileContainers = [];

function initialize(options) {
	if (options.Url && options.Containers && options.Containers.length > 0) {
		for (var index = 0; index < options.Containers.length; index++) {
			var container = options.Containers[index];
			
			productTileContainers[productTileContainers.length] = new ProductTileContainer(options.Url, container).load();
		}
	}
}

function ProductTileContainer(url, container, requestData) {
	var $container;
	var $containerBody;
	var dataBuffer = {};
	var htmlBuffer = {};
	var currentHtmlBufferCount = 0;
	var expectedHtmlBufferCount = 0;

	this.load = function() {
		$container = $(container.Selector);
		$containerBody = $container.find('.accordion-body');
		$container.hide();
		
		if ($container.length > 0) {
			$containerBody.loading();
			$container.show();
						
			$.ajax({
				method: 'POST',
				url: url,
				data: JSON.stringify({
					addProductId: container.addProductId,
					excludeProductId: container.excludeProductId
				}),
				contentType: 'application/json',
				dataType: 'json',
				success: function(data) {
					if (data && data.ProductIds && data.ProductIds.length > 0) {
						dataBuffer = data;
						expectedHtmlBufferCount = data.ProductIds.length;

						for (var index = 0; index < data.ProductIds.length; index++) {
							getProductHitTile(data.ProductIds[index]);
						}
					} else {
						$(document).trigger('RecentlyViewedProducts.Done', $container);
						$container.hide();
						finish();
					}
				},
				error: function() {
					$container.hide();
					finish();
				}
			});
		} else {
			$(document).trigger('RecentlyViewedProducts.Done', $container);
		}
	}

	var getProductHitTile = function(productId) {
		var requestData = {
			pid: productId,
			showswatches: 'true',
			showpricing: 'true',
			showpromotion: 'true',
			showrating: 'true',
			showbadges: 'false',
			showaffirm: 'false',
		}

		$.ajax({
			method: 'GET',
			url: Urls.hitTile,
			data: requestData,
			dataType: 'html',
			success: function (data) {
				if (data) {
					htmlBuffer[productId] = '<li class="slide">' + data + '</li>';
				}
			},
			complete: function () {
				currentHtmlBufferCount++;
				render();
			}
		});
	}

	var render = function() {
		if (currentHtmlBufferCount == expectedHtmlBufferCount) {
			var html = '';

			for (var index = 0; index < dataBuffer.ProductIds.length; index++) {
				if (htmlBuffer[dataBuffer.ProductIds[index]]) {
					html += htmlBuffer[dataBuffer.ProductIds[index]];
				}
			}

			var $html = $(html);
			if (pageContext.site != SiteConstants.SiteIds.CountryOutfittersUS) {
				$html.find('.product-tile').addClass('product');
			}
			$container.find('.slides').html($html);
			$container.find('.slider-products-primary').trigger('init-slider');
			finish();

			if ('affirm' in window && 'ui' in affirm && 'refresh' in affirm.ui) {
			    affirm.ui.refresh();
			}
		}
	}

	var finish = function() {
		$containerBody.loading('destroy');
		$(document).trigger('RecentlyViewedProducts.Done', $container);
		$(document).trigger('Currency.Update', $container);
	}
}