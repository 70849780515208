'use strict';

var dialog = require('../../js/dialog');

var openCarouselVideoModal = function(event) {
    var $videoElement = $('[data-carousel-video-element]').wrap('<div class="video-container"></div>').parent();
    
    dialog.open({
        html: $videoElement[0].outerHTML,
        options: {
            dialogClass: 'fixed carousel-video-modal',
            width: '100%',
            height: '100%',
			draggable: false,
			resizable: false,
            open: function(event, ui) {
                var $this = $(this);
                $this.find('.carousel-video-element')[0].play();
            },
            close: function(event, ui) {
                var $this = $(this);
                $this.find('.carousel-video-element')[0].pause();
            }
        }
    });
}

function initialize() {
	$(document).off('click.CarouselVideo.Play').on('click.CarouselVideo.Play', '.product-primary-image [data-carousel-video]', openCarouselVideoModal);

	$(document).off('click.CarouselVideo.Catch').on('click.CarouselVideo.Catch', '.product-primary-image .carousel-video-link', function(event) {
		event.preventDefault();
	});
}

module.exports.init = initialize;
