'use strict';

exports.PushDataLayerForPage = pushDataLayerForPage;
exports.PushDataLayerForDataLayerType = pushDataLayerForDataLayerType;
exports.PushDataLayerForEvent = pushDataLayerForEvent;

function pushDataLayerForPage(page) {
	window.dataLayer = window.dataLayer || [];

	if (page) {
		if (page.options && page.options.GoogleTagManager && page.options.GoogleTagManager.EnableGoogleAnalytics4) {
			pushDataLayerForPageForGoogleAnalytics4(page);
		}
		pushDataLayerForPageForUniversalAnalytics(page);
	}
}

function pushDataLayerForPageForGoogleAnalytics4(page) {
	window.dataLayer = window.dataLayer || [];

	if (page) {
		if (page.options && page.options.GoogleTagManager && page.options.GoogleTagManager.DataLayerCacheForGoogleAnalytics4) {
			var dataLayer;
			if (page.ns == 'orderconfirmation') {
				dataLayer = page.options.GoogleTagManager.DataLayerCacheForGoogleAnalytics4;
				dataLayer.items = getProductDataLayer('data-product-ga4-json');
				pushDataLayer(dataLayer);
				$(document).trigger('DataLayer.OrderComplete', dataLayer);
			} else {
				// This should be activated when UA is removed.
				// $.ajax({
				// 	url: Urls.GoogleTagManager.GetDataLayer,
				// 	type: 'POST',
				// 	data: {
				// 		DataLayerType: 'General',
				// 		DataLayerVersion: 'GoogleAnalytics4'
				// 	},
				// 	dataType: 'json',
				// 	success: function(data) {
				// 		dataLayer = $.extend(page.options.GoogleTagManager.DataLayerCacheForGoogleAnalytics4, data);
				// 		window.dataLayer.push(dataLayer);
				// 	},
				// 	error: function() {
						
				// 	}
				// });
			}

			// This should be activated when UA is removed.
			// $(document).trigger('DataLayer.OrderComplete', dataLayer);
		}
		
		if (page.ns != 'orderconfirmation') {
			var payload = {};

			var dataLayerPageType;
			if (page.options && page.options.GoogleTagManager && page.options.GoogleTagManager.PageType) {
				dataLayerPageType = page.options.GoogleTagManager.PageType;
			} else {
				dataLayerPageType = page.ns;
			}		
			payload.Page = dataLayerPageType;	

			if (page.options && page.options.GoogleTagManager) {
				payload.Options = JSON.stringify(page.options.GoogleTagManager);
				payload.DataLayerVersion = 'GoogleAnalytics4';
			}
			
			$.ajax({
				url: Urls.GoogleTagManager.GetDataLayer,
				type: 'POST',
				data: payload,
				dataType: 'json',
				success: function(data) {
					if (page.options && page.options.GoogleTagManager && page.options.GoogleTagManager.DataLayerCacheForGoogleAnalytics4) {
						data = $.extend(page.options.GoogleTagManager.DataLayerCacheForGoogleAnalytics4, data);
					}
					if (page.ns == 'product') {
						if (!data.value && data.items && data.items.length > 0) {
							data.currency = data.items[0].currency;
							data.value = data.items[0].price;
						}
					} else if (page.ns == 'search') {
						data.items = getProductDataLayer('data-product-ga4-json', { Container: $('.search-result-content') });
					} else if (page.ns == 'cart' || page.ns == 'onepcheckout' || page.ns == 'onepcheckout_groups') {
						data.items = getProductDataLayer('data-product-ga4-json');
						if (!data.value && data.items && data.items.length > 0) {
							var value = 0;
							for (var itemKey in data.items) {
								var item = data.items[itemKey];
								value += item.price;
							}
							data.value = value;
						}
					}
					pushDataLayer(data);
					$(document).trigger('DataLayer.OrderComplete', data);
				},
				error: function() {
					
				}
			});
		}
	}
}

function pushDataLayerForPageForUniversalAnalytics(page) {
	window.dataLayer = window.dataLayer || [];

	if (page) {
		if (page.options && page.options.GoogleTagManager && page.options.GoogleTagManager.DataLayerCache) {
			var dataLayer;
			if (page.ns == 'orderconfirmation') {
				dataLayer = page.options.GoogleTagManager.DataLayerCache;
				dataLayer.ecommerce = dataLayer.ecommerce || {};
				dataLayer.ecommerce.purchase = dataLayer.ecommerce.purchase || {};
				dataLayer.ecommerce.purchase.products = getProductDataLayer();
				pushDataLayer(dataLayer);
				// $(document).trigger('DataLayer.OrderComplete', dataLayer);
			} else {
				$.ajax({
					url: Urls.GoogleTagManager.GetDataLayer,
					type: 'POST',
					data: {
						DataLayerType: 'General',
						DataLayerVersion: 'UniversalAnalytics'
					},
					dataType: 'json',
					success: function(data) {
						dataLayer = $.extend(page.options.GoogleTagManager.DataLayerCache, data);
						pushDataLayer(dataLayer);
						$(document).trigger('DataLayer.OrderComplete', dataLayer);
					},
					error: function() {
						
					}
				});
			}
		}
		
		if (page.ns != 'orderconfirmation') {
			var payload = {
				Page: page.ns
			}
			
			if (page.options && page.options.GoogleTagManager) {
				payload.Options = JSON.stringify(page.options.GoogleTagManager);
				payload.DataLayerVersion = 'UniversalAnalytics';
			}
			
			$.ajax({
				url: Urls.GoogleTagManager.GetDataLayer,
				type: 'POST',
				data: payload,
				dataType: 'json',
				success: function(data) {
					if (page.ns == 'search') {
						data.ecommerce = data.ecommerce || {};
						data.ecommerce.impressions = getProductDataLayer(null, { Container: $('.search-result-content') });
					} else if (page.ns == 'product') {
						data.ecommerce = data.ecommerce || {};
						data.ecommerce.detail = data.ecommerce.detail || {};
						data.ecommerce.detail.products = getProductDataLayer();
					} else if (page.ns == 'cart' || page.ns == 'onepcheckout' || page.ns == 'onepcheckout_groups') {
						data.ecommerce = data.ecommerce || {};
						data.ecommerce.cartContents = getProductDataLayer();
					}
					pushDataLayer(data);
				},
				error: function() {
					
				}
			});
		}
	}
}

function pushDataLayerForDataLayerType(dataLayerType, dataObject, options) {
	var dataObject = dataObject || {};

	return new Promise(function(resolve, reject) {
		var ajaxCompleteStatus = {
			GoogleAnalytics4: '',
			UniversalAnalytics: ''
		}

		var ajaxComplete = function() {
			if (ajaxCompleteStatus.UniversalAnalytics == 'success' && ajaxCompleteStatus.GoogleAnalytics4 == 'success') {
				resolve();
			} else if (ajaxCompleteStatus.UniversalAnalytics && ajaxCompleteStatus.GoogleAnalytics4 && (ajaxCompleteStatus.UniversalAnalytics == 'error' || ajaxCompleteStatus.GoogleAnalytics4 == 'error')) {
				reject();
			}
		}

		pushDataLayerForDataLayerTypeForDataLayerVersion(dataLayerType, dataObject.UniversalAnalytics || Object.assign({}, dataObject), 'UniversalAnalytics', options).then(function() {
			ajaxCompleteStatus.UniversalAnalytics = 'success';
			ajaxComplete();
		}).catch(function() {
			ajaxCompleteStatus.UniversalAnalytics = 'error';
			ajaxComplete();
		});
		pushDataLayerForDataLayerTypeForDataLayerVersion(dataLayerType, dataObject.GoogleAnalytics4 || Object.assign({}, dataObject), 'GoogleAnalytics4', options).then(function() {
			ajaxCompleteStatus.GoogleAnalytics4 = 'success';
			ajaxComplete();
		}).catch(function() {
			ajaxCompleteStatus.GoogleAnalytics4 = 'error';
			ajaxComplete();
		});;
	});
}

function pushDataLayerForDataLayerTypeForDataLayerVersion(dataLayerType, dataObject, dataLayerVersion, options) {
	var dataLayerType = dataLayerType || 'General';
	var dataLayerVersion = dataLayerVersion || 'UniversalAnalytics';
	var dataObject = dataObject || {};

	return new Promise(function(resolve, reject) {
		if (dataLayerType) {
			$.ajax({
				url: Urls.GoogleTagManager.GetDataLayer,
				type: 'POST',
				data: {
					DataLayerType: dataLayerType,
					DataLayerVersion: dataLayerVersion
				},
				dataType: 'json',
				success: function(data) {
					var dataLayer;
					if (data && !$.isEmptyObject(data)) {
						dataLayer = $.extend(data, dataObject);

						if (dataLayerType == 'AddPaymentInfo') {
							decorateDataLayerWithProductData(dataLayer, {
								DataLayerVersion: dataLayerVersion,
								ContainerSelector: options && options.ContainerSelector ? options.ContainerSelector : ''
							});
						} else if (dataLayerType == 'AddShippingInfo') {
							decorateDataLayerWithProductData(dataLayer, {
								DataLayerVersion: dataLayerVersion,
								ContainerSelector: options && options.ContainerSelector ? options.ContainerSelector : ''
							});
						} else if (dataLayerType == 'AddToCart') {
							if ((dataLayer.event == 'add_to_cart' && (!dataLayer.items || dataLayer.items.length == 0)) || (dataLayer.event == 'addToCart' && (!dataLayer.ecommerce || !dataLayer.ecommerce || !dataLayer.ecommerce.add || !dataLayer.ecommerce.add.products || dataLayer.ecommerce.add.products.length == 0))) {
								decorateDataLayerWithProductData(dataLayer, {
									DataLayerVersion: dataLayerVersion,
									ContainerSelector: options && options.ContainerSelector ? options.ContainerSelector : '',
									FilterSelector: '.pdp-browse',
									UniversalAnalyticsKey: 'add'
								});
							}
							if (dataLayer.items && dataLayer.items.length > 0) {
								if (!dataLayer.items[0].quantity) {
									dataLayer.items[0].quantity = 1;
								}
								dataLayer.currency = dataLayer.items[0].currency;
								dataLayer.value = dataLayer.items[0].price;
							}
						} else if (dataLayerType == 'RemoveFromCart') {
							if (dataLayer.items && dataLayer.items.length > 0) {
								if (!dataLayer.items[0].quantity) {
									dataLayer.items[0].quantity = 1;
								}
								dataLayer.currency = dataLayer.items[0].currency;
								dataLayer.value = dataLayer.items[0].price;
							}
						} else if (dataLayerType == 'SelectPromotion') {
							decorateDataLayerWithProductData(dataLayer, {
								DataLayerVersion: dataLayerVersion,
								Container: options && options.Container ? options.Container : ''
							});
						} else if (dataLayerType == 'ViewItem') {
								decorateDataLayerWithProductData(dataLayer, {
									DataLayerVersion: dataLayerVersion,
									ContainerSelector: options && options.ContainerSelector ? options.ContainerSelector : '',
									FilterSelector: '.pdp-browse',								
									UniversalAnalyticsKey: 'detail'
								});
						} else if (dataLayerType == 'ViewPromotion') {
							decorateDataLayerWithProductData(dataLayer, {
								DataLayerVersion: dataLayerVersion,
								Container: options && options.Container ? options.Container : ''
							});
						}

						pushDataLayer(dataLayer);
					}
					resolve(dataLayer);
				},
				error: function() {
					reject();
				}
			});
		} else {
			reject();
		}
	});
}

function pushDataLayerForEvent(eventName, dataObject, dataLayerType, dataLayerVersion) {
	var dataObject = dataObject || {};

	return new Promise(function(resolve, reject) {
		if (eventName) {
			$.ajax({
				url: Urls.GoogleTagManager.GetDataLayer,
				type: 'POST',
				data: {
					DataLayerType: dataLayerType || 'General',
					DataLayerVersion: dataLayerVersion || 'UniversalAnalytics'
				},
				dataType: 'json',
				success: function(data) {
					var dataLayer = $.extend(dataObject, data);
					dataLayer.event = eventName;
					pushDataLayer(dataLayer);
					resolve(dataLayer);
				},
				error: function() {
					reject();
				}
			});
		} else {
			reject();
		}
	});
}

function decorateDataLayerWithProductData(dataLayer, options) {
	var universalAnalyticsKey = options && options.UniversalAnalyticsKey ? options.UniversalAnalyticsKey : '';

	if (options && options.DataLayerVersion == 'GoogleAnalytics4') {
		dataLayer.items = getProductDataLayer('data-product-ga4-json', options);
	} else {
		dataLayer.ecommerce = dataLayer.ecommerce || {};
		dataLayer.ecommerce[universalAnalyticsKey] = dataLayer.ecommerce[universalAnalyticsKey] || {};
		dataLayer.ecommerce[universalAnalyticsKey].products = getProductDataLayer(null, options);
	}
}

function getProductDataLayer(attributeIdParameter, options) {
	var dataLayerProducts = window.dataLayerProducts || [];
	var attributeId = attributeIdParameter || 'data-product-gtm-json';
	var container = options && options.Container ? options.Container : '';
	var containerSelector = options && options.ContainerSelector ? options.ContainerSelector : '';
	var filterSelector = options && options.FilterSelector ? options.FilterSelector : '';

	var $productCards;
	if (container) {
		$productCards = container.find('[' + attributeId + ']' + filterSelector);
	} else if (containerSelector) {
		$productCards = $(containerSelector).find('[' + attributeId + ']' + filterSelector);
	} else {
		$productCards = $('[' + attributeId + ']' + filterSelector);
	}
	$productCards.each(function(index, element) {
		var $element = $(element);

		var slickIndex = null;
		var isValid = true;
		if ($element.closest('[data-slick-index]').length > 0) {
			slickIndex = parseInt($element.closest('[data-slick-index]').data('slick-index'), 10) + 1;
			if (slickIndex < 1) {
				isValid = false;
			}
		}

		if (isValid) {
			var jsonObject = JSON.parse($element.attr(attributeId));
			if (attributeId == 'data-product-gtm-json') {
				jsonObject.position = index;
			} else {
				jsonObject.index = index;
			}
			dataLayerProducts.push(jsonObject);
		}
	});
		
	return dataLayerProducts;
}

function pushDataLayer(dataLayer) {
	var isCartPage = pageContext && pageContext.options && pageContext.options.isCartPage;
	var isCheckoutPage = pageContext && pageContext.options && pageContext.options.isCheckoutPage;
	var isConfirmationPage = pageContext && pageContext.options && pageContext.options.isConfirmationPage;
	var reactDataLayerPages = pageContext && pageContext.options && pageContext.options.MobileApp && pageContext.options.MobileApp.ReactDataLayerPages ? pageContext.options.MobileApp.ReactDataLayerPages : [];
	var sendReactEventForCart = isCartPage && reactDataLayerPages.indexOf('Cart') > -1;
	var sendReactEventForCheckout = isCheckoutPage && reactDataLayerPages.indexOf('Checkout') > -1;
	var sendReactEventForConfirmation = isConfirmationPage && reactDataLayerPages.indexOf('Confirmation') > -1;

	if ('ReactNativeWebView' in window && (sendReactEventForCart || sendReactEventForCheckout || sendReactEventForConfirmation)) {
		triggerReactWebviewEvent(dataLayer.event, dataLayer);
	} else {
		window.dataLayer.push(dataLayer);
	}
}

function triggerReactWebviewEvent(action, dataLayer) {
	if ('ReactNativeWebView' in window) {
		var message =  {
			action: action
		}
		if (dataLayer) {
			message.dataLayer = dataLayer;
		}

		window.ReactNativeWebView.postMessage(JSON.stringify(message));
	}
}