'use strict';

var RegexValidationPatterns = require('../../js/RegexPatterns').Validation;
var StateToZipCodeMap = require('../../data/StateToZipCodeMap.json');

exports.ValidateRequiredExcludingPunctuation = validateRequiredExcludingPunctuation;
exports.ValidateOrderNumber = validateOrderNumber;
exports.ValidateAddressForPostOfficeBox = validateAddressForPostOfficeBox;
exports.ValidateAddressForPostOfficeBoxOnly = validateAddressForPostOfficeBoxOnly;
exports.ValidatePostalCode = validatePostalCode;
exports.ValidateZipCode = validateZipCode;
exports.ValidateZipCodePrefixForStateCode = validateZipCodePrefixForStateCode;
exports.ValidateZipCodePrefixForStateCodeValues = validateZipCodePrefixForStateCodeValues;
exports.ValidateStateCodeForCaliforniaStateLine = validateStateCodeForCaliforniaStateLine;
exports.ValidateGiftCardNumberLength = validateGiftCardNumberLength;
exports.ValidateGiftCardNumberValues = validateGiftCardNumberValues;

function validateRequiredExcludingPunctuation(value, element) {
    return RegexValidationPatterns.RequiredExcludingPunctuation.test((value + '').trim());
};

function validateOrderNumber(value, element) {
    return RegexValidationPatterns.OrderNumber.test((value + '').trim());
};

function validateAddressForPostOfficeBox(value, element) {
	return !RegexValidationPatterns.PostOfficeBox.test((value + '').trim());
}

function validateAddressForPostOfficeBoxOnly(value, element) {
	var address1 = $(element).closest('form').find('input[name$="_address1"]').val();
	var address2 = $(element).closest('form').find('input[name$="_address2"]').val();

	return (!RegexValidationPatterns.PostOfficeBox.test((address1 + '').trim()) && !RegexValidationPatterns.PostOfficeBox.test((address2 + '').trim())) || (!address1 || !address2);
}

function validatePostalCode(value, element) {
    return RegexValidationPatterns.PostalCode.test((value + '').trim());
};

function validateZipCode(value, element) {
    return RegexValidationPatterns.ZipCode.test((value + '').trim());
};

function validateZipCodePrefixForStateCode(value, element) {
	var zipCodePrefix = value.substr(0,3);
	var stateCode = $(element).closest('form').find('select[name$="_states_state"]').val().toUpperCase();
	if (stateCode && StateToZipCodeMap && StateToZipCodeMap[stateCode]) {
		return StateToZipCodeMap[stateCode][zipCodePrefix];
	}

    return true;
};

function validateZipCodePrefixForStateCodeValues(zipCode, stateCode) {
	var zipCodePrefix = zipCode.substr(0,3);
	if (StateToZipCodeMap && StateToZipCodeMap[stateCode]) {
		return StateToZipCodeMap[stateCode][zipCodePrefix];
	}

    return true;
};

function validateStateCodeForCaliforniaStateLine(value, element) {
	var stateCodeForStore = $(element).closest('[store-state]').attr('store-state');

	if ((value != 'CA' && stateCodeForStore != 'CA') || value == stateCodeForStore) {
		return true;
	}

	return false;
};

function validateGiftCardNumberLength(value, element) {
	var digits = (value + '').trim().match(RegexValidationPatterns.GiftCardNumberLength);
	return digits && digits.length == 19;
};

function validateGiftCardNumberValues(value, element) {
	return RegexValidationPatterns.GiftCardNumberValues.test((value + '').trim());
};