'use strict';

var _ = require('lodash');
var AddressVerificationHelper = require('../../../../../bc_sheplersbootbarn_core/cartridge/js/AddressVerification/AddressVerificationHelper');
var TemplateConstants = require('../../../../../bc_sheplersbootbarn_core/cartridge/js/TemplateConstants');

var options;
var dataBuffer;
var templates;

function initialize(optionsParameter, addressData) {
	options = $.extend({
		successCallback: null,
		errorCallback: null
	}, optionsParameter);
	dataBuffer = $.extend({}, addressData);

	if (addressData && addressData.$field && addressData.$form) {
		templates = {};
		templates.container = _.template($('#template-address-autocomplete-container').html(), TemplateConstants.Lodash.Settings);
		templates.address = _.template($('#template-address-autocomplete-item').html(), TemplateConstants.Lodash.Settings);

		var addressSuggestionsHtml = '';
		if (addressData && addressData.InquiryResult && addressData.InquiryResult.suggestions && addressData.InquiryResult.suggestions.length > 0) {
			for (var addressSuggestionKey in addressData.InquiryResult.suggestions) {
				var addressSuggestion = addressData.InquiryResult.suggestions[addressSuggestionKey];
				addressSuggestionsHtml += templates.address({ Address: buildAddressHtmlFromAddressObject(AddressVerificationHelper.BuildAddressObjectFromAddressAutocompleteSuggestion(addressSuggestion)) });
			}
		}

		var $fieldContainer = addressData.$field.parent();
		if ($fieldContainer.find('.address-autocomplete-dialog').length > 0) {
			$fieldContainer.find('.address-autocomplete-dialog').remove();
		}

		var addressSuggestionsContainerHtml = templates.container({ Addresses: addressSuggestionsHtml });
		$fieldContainer.prepend(addressSuggestionsContainerHtml);

		addressData.$form.off('click.AddressAutocompleteSelect').on('click.AddressAutocompleteSelect', '.address-autocomplete-dialog li', function(event) {
			var $this = $(this);

			addressData.$form.find('.address-autocomplete-dialog').remove();
			$(document).trigger('SetAddressFromAddressAutocomplete', {
				$form: addressData.$form,
				$field: addressData.$field,
				Address: AddressVerificationHelper.BuildAddressObjectFromAddressAutocompleteSuggestion(addressData.InquiryResult.suggestions[$this.index()])
			});
		});

		$(document).off('click.AddressAutocompleteClose').on('click.AddressAutocompleteClose', function(event) {
			var $target = $(event.currentTarget);

			if ($target.closest('.address-autocomplete-dialog').length == 0) {
				$('.address-autocomplete-dialog').remove();
			}
		});
	}
}

function buildAddressHtmlFromAddressObject(addressObject) {
	var addressArray = [];
	if (addressObject.PrimaryLine) {
		addressArray[addressArray.length] = addressObject.PrimaryLine;
	}
	if (addressObject.City || addressObject.StateCode || addressObject.PostalCode) {
		var addressLine = '';
		if (addressObject.City) {
			addressLine += addressObject.City + ', ';
		}
		if (addressObject.StateCode) {
			addressLine += addressObject.StateCode;
			if (addressObject.PostalCode) {
				addressLine += ' ';
			}
		}
		if (addressObject.PostalCode) {
			addressLine += addressObject.PostalCode;
		}

		addressArray[addressArray.length] = addressLine;
	}

	return addressArray.join('<br/>');
}

exports.initialize = initialize;