'use strict';

var dialog = require('../../dialog');
var GoogleTagManagerClient = require('../../../../../bc_sheplersbootbarn_core/cartridge/js/util/GoogleTagManagerClient');
var loadingIndicator = require('../../components/LoadingIndicator');

exports.initialize = initialize;

var options;
var dataBuffer;

function initialize(optionsParameter) {
	dataBuffer = {};
	options = $.extend({
		successCallback: null,
		errorCallback: null
	}, optionsParameter);

	$('#shop-your-store-dialog').find('.store-name').text(options.StoreName);
	$('#shop-your-store-dialog').find('.category-links a').each(function(index, element) {
		var $this = $(this);
		var href = $this.attr('href');
		if (href.indexOf('?') > -1) {
			href = href.substr(0, href.indexOf('?'));
		}
		$this.attr('href', href + '?prefn1=availableStores&prefv1=' + options.StoreId + '&shopmystore=true');
	})
	
	// GoogleTagManagerClient.PushDataLayerForEvent('roadieFormDisplay');
	dialog.open({
		html: $('#shop-your-store-dialog').html(),
		options: {
			dialogClass: 'shop-your-store-dialog',
			width: 380,
			draggable: false,
			resizable: false,
			open: function(event, ui) {
				var $this = $(this);
				var $shopYourStoreToggle = $this.find('.shop-my-store-toggle .checkbox-toggle');
				dataBuffer['toggleTimer'] = setInterval(function() {
					$shopYourStoreToggle.click();
				}, 2000);
            },
            close: function(event, ui) {
				clearInterval(dataBuffer['toggleTimer']);
				$(this).find('.shop-my-store-toggle .checkbox-toggle input').prop('checked', false);
            }
		}
	});
}