'use strict';

var quickview = require('../quickview');
var bonusProductsView = require('../bonus-products-view');
var TPromise = require('promise');
var util = require('../util');
var addresses = SitePreferences.ENABLE_ENHANCED_CHECKOUT ? require('./checkout/opcaddress_enhanced') : require('./checkout/opcaddress');
var formPrepare = require('./checkout/formPrepare');
var cart = require('./cart');
var loadingIndicator = require('../components/LoadingIndicator');
var GiftCard = require('./checkout/GiftCardPaymentPanel');
var GoogleTagManagerClient = require('../../../../bc_sheplersbootbarn_core/cartridge/js/util/GoogleTagManagerClient');
var PaymentVoucherPanel;
var recaptcha = require('../components/Recaptcha');
var RewardsCredit = require('./checkout/RewardsCredit');
var LoyaltyCredit = require('./checkout/LoyaltyCredit');
var CheckoutConstants = require('../../../../bc_sheplersbootbarn_core/cartridge/js/CheckoutConstants');
var PaymentMethodConstants = require('../../../../bc_sheplersbootbarn_core/cartridge/js/PaymentInstrumentConstants').PaymentMethods;
var BopisProductLineItemHelper = require('../bopis/components/ProductLineItemHelper');
var SiteConstants = require('../../../../bc_sheplersbootbarn_core/cartridge/js/SiteConstants');

var cartItemsFormSelector = 'form#cart-items-form';
var billingAddressFormSelector = '#billingAddressForm';
var $billingAddressForm = $(billingAddressFormSelector);
var shippingAddressFormSelector = '#shippingAddressForm';
var $shippingAddressForm = $(shippingAddressFormSelector);
var paymentSectionSelector = '.form-checkout.payment-section';
var $paymentSection = $(paymentSectionSelector);
var paymentFormSelector = '#paymentSection';
var $paymentForm = $(paymentFormSelector);
var creditCardListSelector = '#creditCardList';
var $creditCardList = $(creditCardListSelector);
var creditCardSaveCheckboxContainerSelector = 'div[field="saveCard"]';
var $creditCardSaveCheckboxContainer = $(creditCardSaveCheckboxContainerSelector);
var creditCardSaveCheckboxSelector = '#dwfrm_billing_paymentMethods_creditCard_saveCard';
var $creditCardSaveCheckbox = $(creditCardSaveCheckboxSelector);
var submitPaymentSelector = '#submitOPCPayment';
var $submitPayment = $(submitPaymentSelector);
var basketContainerSelector = '.basket-container';
var $basketContainer = $(basketContainerSelector);

var placeOrderRecaptcha;
var options = {};
var loaded = {};

/**
 * @description Events should be divided to modules. This allows reinitialize events by sections after partial reload 
 * @private
 * @function
 * @description Initializes events for the gift registration
 */
function initializeEvents(options) {
	initializeCartEvents(options);
	initializePaymentMethodsEvents();
	
	renderRecaptcha();
	$(document).on('Recaptcha.Loaded', function(event) {
		if (!loaded.Recaptcha) {
			loaded.Recaptcha = true;
	        renderRecaptcha();
	    }
	});
}

function initializeCartEvents(options) {
	$(document).on('click', 'a.add-to-wishlist', function (e) {
    	/*remove next line to use default dw wishlist functionality*/
    	return false;
    	e.preventDefault();
    	
    	if (Customer.authenticated == true) {
    		// post form here
    	} else {
    		$('html, body').animate({ scrollTop: $('.login-form').eq(0).offset().top}, 1000);
    	}
    });
	
	if (SitePreferences.ALTERNATE_PICKUP_ENABLED) {
		renderAlternatePickup();
	}

    $paymentForm.on('change', '[name$="_creditCard_type"]', function() {
    	$submitPayment.removeAttr('disabled');
    	$(this).closest('div.form-row').find('div.field-wrapper.error').remove();
    });

    $(document).on('click', submitPaymentSelector, function(e) {
    	e.preventDefault();

    	if (!options || !options.Recaptcha || !options.Recaptcha.EnabledForPlaceOrder) {
			submitPayment();	
		}
    });
    
    $(document).on('Checkout.SubmitPayment', function (event, data) {
		if ((options && options.Recaptcha && options.Recaptcha.EnabledForPlaceOrder) || (data && data.SubmitPaymentForBopis)) {
			submitPayment(data);
		}
	});
    
    var getCreditCardInfo = function (id) {
        return TPromise.resolve($.ajax({
            type: 'GET',
            url: util.ajaxUrl(Urls.billingGetCreditCard),
            data: { creditCardUUID: id }
        }));
    };
    
    $creditCardList.on('change', function() {
    	var creditCardUUID = $(this).val();
    	
    	if (creditCardUUID.length <= 0) {
    		return;
    	}
    	
    	getCreditCardInfo(creditCardUUID).then(function (data) {
			if (data && data.hasOwnProperty('maskedNumber')) {
				var $paymentMethodGroup = $('[payment-method-group="' + PaymentMethodConstants.CreditCard + '"]');
				var $paymentMethod = $paymentMethodGroup.find('.payment-method[payment-method="' + data.paymentMethod + '"]');

				var paymentMethodRadioToSelect = data.paymentMethod == PaymentMethodConstants.CreditCard ? data.type : data.paymentMethod;
				$paymentMethodGroup.find('input[type="radio"][value="' + paymentMethodRadioToSelect + '"]').first().prop('checked', true).trigger('change', { doReset: false });
				
				if ($paymentMethod.find('[id*="_creditCard_owner"]').length > 0 && data.hasOwnProperty('holder')) {
					$paymentMethod.find('[id*="_creditCard_owner"]').val(data.holder).valid();
				}

				$('.pt_onepcheckout input[name*="_creditCard_type"]').val(data.type);
				$paymentMethod.find('[id*="_number"]').val(data.maskedNumber).valid();
				
				if ($paymentMethod.find('[id*="_creditCard_expiration_month"]').length > 0 && data.hasOwnProperty('expirationMonth')) {
					$paymentMethod.find('[id*="_creditCard_expiration_month"]').val(data.expirationMonth).trigger('change');
				}
				
				if ($paymentMethod.find('[id*="_creditCard_expiration_year"]').length > 0 && data.hasOwnProperty('expirationYear')) {
					$paymentMethod.find('[id*="_creditCard_expiration_year"]').val(data.expirationYear).trigger('change');
				}
				
				if ($paymentMethod.find('[id*="_creditCard_cvn"]').length > 0) {
					$paymentMethod.find('[id*="_creditCard_cvn"]').val('');
				}

				$creditCardSaveCheckboxContainer.hide();
			}
		}.bind(this));	
    });
    
	if ($creditCardList.length > 0 && ($('.pt_onepcheckout input[name*="_selectedPaymentMethodID"]').val() == PaymentMethodConstants.CreditCard || $('.pt_onepcheckout input[name*="_selectedPaymentMethodID"]').val() == PaymentMethodConstants.AllianceData)) {
		$creditCardList.find('option').eq(1).prop('selected', true);
		$creditCardList.trigger('change');
		$creditCardSaveCheckboxContainer.hide();
	} else {
		$creditCardSaveCheckboxContainer.show();
	}
	
    addresses.init(options);
    cart.init(options);
}

function renderAlternatePickup() {
	$(document).off('click', 'button.alt-pickup-button').on('click', 'button.alt-pickup-button', function (e) {
    	e.preventDefault();
		$('#alt-pickup-modal').addClass('active-modal').show();
		$('fieldset span').removeClass('error-msg');
		$('.one-required').removeClass('error');
    });
    
    $(document).off('click', '#alt-pickup-modal .close-modal').on('click', '#alt-pickup-modal .close-modal', function (e) {
    	e.preventDefault();
		$('#alt-pickup-modal').removeClass('active-modal').hide();
    });
    
    $(document).on('focus', 'input[name="firstName"]', function () {
		$('.firstname-error').removeClass('error-msg');
    });
    
    $(document).on('focus', 'input[name="lastName"]', function () {
		$('.lastname-error').removeClass('error-msg');
    });
    
    $(document).on('focus', 'input[name="email"]', function () {
		$('.email-error').removeClass('error-msg');
		$('.one-required').removeClass('error');
    });
    
    $(document).on('focus', 'input[name="phone"]', function () {
		$('.phone-error').removeClass('error-msg');
		$('.one-required').removeClass('error');
    });
    
    $(document).off('click', 'button#altPickupSubmit').on('click', 'button#altPickupSubmit', function (e) {
    	e.preventDefault();
		
    	var isFirstNameValid = true;
		var firstName = $('input[name="firstName"]').val();
		var regexName = /^[a-z ,.'-]+$/i;
		if (firstName.length == 0 || firstName.match(regexName) == null) {
			isFirstNameValid = false;
			$('.firstname-error').addClass('error-msg');
		}
		
		var isLastNameValid = true;		
		var lastName =$('input[name="lastName"]').val();
		if (lastName.length == 0 || lastName.match(regexName) == null) {
			isLastNameValid = false;
			$('.lastname-error').addClass('error-msg');
		}
		
		var isEmailValid = true;		
		var email = $('input[name="email"]').val();
		var regexEmail = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/g;
		if (email.length > 0 && email.match(regexEmail) == null) {
			isEmailValid = false;
			$('.email-error').addClass('error-msg');
		}

		var isPhoneValid = true;
		var phone = $('input[name="phone"]').val().replace(/\D/g, '');
		var regexPhone = /^\+?\(?([0-9][0-8][0-9])\)?[\-\. ]?([2-9][0-9]{2})[\-\. ]?([0-9]{4})(\s*x[0-9]+)?$/g;
		if (phone.length > 0 && phone.match(regexPhone) == null) {
			isPhoneValid = false;
			$('.phone-error').addClass('error-msg');
		}
		
    	if (email.length == 0 && phone.length == 0) {
    		$('.one-required').addClass('error');
    		isEmailValid = false;
    		isPhoneValid = false;
    	}
    	
    	if (!isEmailValid || !isPhoneValid) {
    		return;
    	}
    	
		if (isFirstNameValid && isLastNameValid && (isEmailValid || isPhoneValid)) {
			var cleanPhone = phone.replace(/\D/g, '');
			var shipmentID = $('.alternate-pickup').closest("form").attr('shipment');
			
			$.ajax({
				url: Urls.setAlternatePickup,
				type: "get",
				data: {
					shipmentID: shipmentID,
					altPickupFirstName: firstName,
					altPickupLastName: lastName,
					altPickupEmail: email,
					altPickupPhone: cleanPhone
				},
				success: function (response) {
					var data = JSON.parse(response);
						
					if (data.status == 'success') {
						$('span.alt-person').attr('data-firstname', firstName).attr('data-lastname', lastName).attr('data-email', email).attr('data-phone', cleanPhone);
						displayAlternatePickup (firstName + ' ' + lastName)
						$('#alt-pickup-modal').removeClass('active-modal').hide();
					}
				},
				error: function(jqXHR, textStatus, errorThrown) {
					console.log(textStatus, errorThrown);
				}
			});
		}
    });
}

function displayAlternatePickup (fullName) {
	$('.alternate-pickup span.alt-person').text(fullName);
	$('span.alt-header').show();
	$('button.alt-pickup-button').text('UPDATE');
}

function renderRecaptcha() {
	if (loaded.Recaptcha && $('#submitOPCPayment').length > 0 && options && options.Recaptcha && options.Recaptcha.EnabledForPlaceOrder && options.Recaptcha.GoogleSiteKey && placeOrderRecaptcha == undefined) {
		placeOrderRecaptcha = grecaptcha.render('submitOPCPayment', {
	        'sitekey': options.Recaptcha.GoogleSiteKey,
	        'callback': submitPayment,
	        'badge': 'bottomleft'
	    });
	}
}

function submitPaymentRecaptchaError() {
	if (loaded.Recaptcha && $('#submitOPCPayment').length > 0 && options && options.Recaptcha && options.Recaptcha.EnabledForPlaceOrder && options.Recaptcha.GoogleSiteKey && placeOrderRecaptcha != undefined) {
		grecaptcha.reset(placeOrderRecaptcha);
		renderRecaptcha();
	}
}

function submitPayment(data) {
	var balanceDue = parseFloat($paymentSection.attr('balance-due'));
	$submitPayment.loading({
		IsImageShown: false,
		Label: Resources.Loading.Checkout.PlacingOrder
	});
	$submitPayment.attr('disabled', true);
	$('#errorBoxOPC').remove();

	$billingAddressForm.validate().form();
	$shippingAddressForm.validate().form();
	
	var $creditCardType = $paymentForm.find('[name$="_creditCard_type"]:checked').val();
	if (balanceDue > 0) {
		$paymentForm.validate().form();
		
		if (typeof $creditCardType === 'undefined') {
    		$paymentForm.find('[name$="_creditCard_type"]').closest('div.form-row').find('div.field-wrapper.error').remove();

    		setTimeout(function(){
    			$paymentForm.find('[name$="_creditCard_type"]').closest('div.form-row').append('<div class="field-wrapper error" style="height:0px;"><span class="error">' + Resources.REQUIRED_FIELD + '</span></div>');
    		}, 50);
		}
	}
	
	// check first if addresses are valid
	var isBillingAddressValid = false;
	var isShippingAddressValid = false;
	
	$billingAddressForm.validate().settings.ignore = [];
	$shippingAddressForm.validate().settings.ignore = [];
	
	if ($billingAddressForm.valid()) {
		isBillingAddressValid = true;
	} else {
		$('.billing-address-data.form-info').hide();
		
		$billingAddressForm.show();
		$billingAddressForm.find('input.required.field.error').eq(0).focus();
	}
	
	if ($shippingAddressForm.valid() || ($shippingAddressForm.find('.formfield.isStore input').prop('checked') && $shippingAddressForm.find('.formfield.myStoreID input').val())) {
		isShippingAddressValid = true;
	} else {
		$('.shipping-address-data.form-info').hide();
		
		$shippingAddressForm.show();
		$shippingAddressForm.find('input.required.field.error').eq(0).focus();
	}
	
	var isBopisValid = false;
	if (data && data.SubmitPaymentForBopis) {
		isBopisValid = data.IsBopisInventoryValid;
	} else {
		var $bopisProductLineItems = $(cartItemsFormSelector).find('.bopis-product-line-item[sku][store]');
		
		if ($bopisProductLineItems.length > 0) {
			BopisProductLineItemHelper.ValidateProductLineItems($(cartItemsFormSelector).find('.bopis-product-line-item[sku][store]'), { SuccessEvent: 'Checkout.SubmitPayment' });
		} else {
			isBopisValid = true;
		}
	}
	
	if (isBillingAddressValid && isShippingAddressValid && isBopisValid && $('#shipping-method-list input:checked').length && (balanceDue == 0 || $paymentForm.valid())) {
		var $paymentFormToSet = $paymentForm.clone();
		var actionName = $submitPayment.attr('name');
		
		var selectedPaymentMethodId = $paymentForm.find('input[name*="selectedPaymentMethodID"]').val();
		$paymentFormToSet.find('div[payment-method]').not('div[payment-method="' + selectedPaymentMethodId + '"]').remove();
		
		$paymentFormToSet.find('select').each(function (index) {
			$(this).val($paymentForm.find('#' + $(this).attr('id')).val());
		});
		
		setOPCPaymentData($paymentFormToSet, actionName).then(function(response) {
			var data;
			try {
				data = JSON.parse(response);
			} catch (e) {
				data = response;
			}

			if (data.hasOwnProperty('status') && data.status === 'success') {
				var dataObject = {
					value: balanceDue,
					payment_type: selectedPaymentMethodId
				}
				GoogleTagManagerClient.PushDataLayerForDataLayerType('AddPaymentInfo', dataObject).then(function() {
					if (options.CheckoutMode == 'MobileApp') {
						window.location.href = Urls.confirmationPageForMobileApp;
					} else {
						window.location.href = Urls.confirmationPage;
					}
				}).catch(function() {
					if (options.CheckoutMode == 'MobileApp') {
						window.location.href = Urls.confirmationPageForMobileApp;
					} else {
						window.location.href = Urls.confirmationPage;
					}
				});
			} else if (data.hasOwnProperty('status') && data.status === 'affirmRedirect') {
				var _affirm_config = {
					public_api_key: data.affirm.PublicApiKey,
					script: data.affirm.Script
				};
				(function(l,g,m,e,a,f,b){var d,c=l[m]||{},h=document.createElement(f),n=document.getElementsByTagName(f)[0],k=function(a,b,c){return function(){a[b]._.push([c,arguments])}};c[e]=k(c,e,"set");d=c[e];c[a]={};c[a]._=[];d._=[];c[a][b]=k(c,a,b);a=0;for(b="set add save post open empty reset on off trigger ready setProduct".split(" ");a<b.length;a++)d[b[a]]=k(c,e,b[a]);a=0;for(b=["get","token","url","items"];a<b.length;a++)d[b[a]]=function(){};h.async=!0;h.src=g[f];n.parentNode.insertBefore(h,n);delete g[f];d(g);l[m]=c})(window,_affirm_config,"affirm","checkout","ui","script","ready");
				
				affirm.checkout(JSON.parse(data.affirm.AffirmCheckout));
				affirm.checkout.post();
				affirm.ui.ready(
					function() {
						affirm.ui.error.on("close", function(){
							// window.location.replace(data.affirm.RedirectUrl);
						});
					}
				);
			} else if (data.hasOwnProperty('status') && data.status === 'shippingRestrictionsError') {
				var popup = require('../popup');
				var payload = JSON.parse(data.payload);

				var list = $.map(payload.list, function (el) {
					return '<li>' + el.name + ':<br>' + el.message + '</li>';
				}).join('');
				
				popup.init({
					title: payload.title,
					content: payload.message + '<ul class="shipping-restrictions-popup__list">' + list + '</ul>',
				}).showPopup();
			} else if (data.hasOwnProperty('status') && data.status === 'BillingAddressError') {
				var message = data.hasOwnProperty('message') ? data.message : Resources.Checkout.Confirm.Error.Generic;
				$submitPayment.after('<div id="errorBoxOPC" class="payment-declined p-2 mt-2"><i class="fa fa-exclamation-triangle"></i> <b>Billing Address Error:</b> ' + message + '</div>');
			} else if (data.hasOwnProperty('status') && data.status === 'ShippingAddressError') {
				var message = data.hasOwnProperty('message') ? data.message : Resources.Checkout.Confirm.Error.Generic;
				$submitPayment.after('<div id="errorBoxOPC" class="payment-declined p-2 mt-2"><i class="fa fa-exclamation-triangle"></i> <b>Shipping Address Error:</b> ' + message + '</div>');
			} else if (data.hasOwnProperty('status') && data.status === 'ValidationError') {
				var message = data.hasOwnProperty('message') ? data.message : Resources.Checkout.Confirm.Error.Generic;
				$submitPayment.after('<div id="errorBoxOPC" class="payment-declined p-2 mt-2" role="alert" tabindex="0"><i class="fa fa-exclamation-triangle"></i> <b>Validation Error:</b> ' + message + '</div>');
			} else {
				var message = data.hasOwnProperty('message') ? data.message : Resources.Checkout.Confirm.Error.Generic;
				$submitPayment.after('<div id="errorBoxOPC" class="payment-declined p-2 mt-2" role="alert" tabindex="0"><i class="fa fa-exclamation-triangle"></i> <b>Payment Declined:</b> ' + message + '</div>');
			}
			
			$submitPayment.loading('destroy');
			$submitPayment.attr('disabled', false);
			submitPaymentRecaptchaError();
		}).catch(function(response) {
			if (response.status == 302 && response.responseText) {
				location.href = response.responseText;
			}
		});
	} else {
		$submitPayment.loading('destroy');
		$submitPayment.attr('disabled', false);
		submitPaymentRecaptchaError();
	}
}

function initializePaymentMethodsEvents() {
	var creditCardListSelector = '.payment-card-list select, .payment-card-list input[type="radio"]';
	var creditCardNumberInput = 'input[name*="_creditCard_number"], input[name*="_privateLabelCredit_number"]';
	
	$(creditCardNumberInput).parent().append('<div class="cc-image"></div>');
	
	$(document).on('change', creditCardListSelector, function (e, options) {	
		var $this = $(this);

		options = options != undefined ? options : {};
		options.doReset = options.doReset != undefined ? options.doReset : true;
		
		var selectedCreditCardType = $this.val();
		
		if (selectedCreditCardType) {
			var selectedPaymentMethodId = $this.attr('payment-method') || $this.find(':selected').attr('payment-method');
			var selectedPaymentCardImageUrl = $this.attr('payment-card-image') || $this.find(':selected').attr('payment-card-image');
			
			$paymentForm.attr('selected-payment-method', selectedPaymentMethodId);
			$this.closest('[payment-method-group]').attr('payment-method', selectedPaymentMethodId);
			
			$('.pt_onepcheckout input[name*="_selectedPaymentMethodID"]').val(selectedPaymentMethodId);

			if (!$('[payment-method="' + selectedPaymentMethodId + '"].payment-method').hasClass('payment-method-expanded')) {
				$('.payment-method').removeClass('payment-method-expanded').hide();
				$('[payment-method="' + selectedPaymentMethodId + '"].payment-method').addClass('payment-method-expanded').show();	
			}
			
			$('.payment-card-list.grid').find('[payment-card]').hide();
			$('.payment-card-list.grid').find('[payment-card="' + selectedCreditCardType + '"]').show();
			
			$(creditCardListSelector).filter('input[value="' + selectedCreditCardType + '"]').prop('checked', true);
			$(creditCardListSelector).find('option[value="' + selectedCreditCardType + '"]').prop('selected', true);

			$paymentForm.find('[payment-method-group="' + PaymentMethodConstants.CreditCard + '"] .cc-image').css('background-image', 'url(' + selectedPaymentCardImageUrl + ')');
			
			if (options.doReset) {
				resetCreditCardFields($('[payment-method="' + selectedPaymentMethodId + '"].payment-method'));
				$creditCardList.val('');
			}
		}
	});
	
	$paymentForm.on('click', '.payment-method-group-selector', function (e) {
	    var paymentMethodGroupId = $(this).val();
	    
	    if (paymentMethodGroupId) {
	    	$paymentForm.attr('selected-payment-method-group', paymentMethodGroupId);
		    
		    $('.pt_onepcheckout input[name*="_selectedPaymentMethodID"]').val($('[payment-method-group="' + paymentMethodGroupId + '"]').attr('payment-method'));
		    
		    $('[payment-method-group]').hide().removeClass('expanded');
		    $('[payment-method-group="' + paymentMethodGroupId + '"]').show().addClass('expanded');
		    
		    if ($('[payment-method-group="' + paymentMethodGroupId + '"]').find('.payment-method-expanded').length < 1) {
		    	var $defaultPaymentMethod = $('[payment-method-group="' + paymentMethodGroupId + '"]').find('.payment-method').first();
		    	
		    	$defaultPaymentMethod.addClass('payment-method-expanded').show();
		    	
		    	if ($defaultPaymentMethod.attr('payment-card-image')) {
		    		$defaultPaymentMethod.find('.cc-image').css('background-image', 'url(' + $defaultPaymentMethod.attr('payment-card-image') + ')');
		    	}
		    }
		    
		    if (window.innerWidth <= 767) {
				$([document.documentElement, document.body]).animate({
					// scrollTop: $('.payment-method-expanded').closest('.payment-method-option').offset().top - $('.top-banner').height() + 20
					scrollTop: $('#paymentSection').offset().top - $('.top-banner').height() - 15
				}, 250);
		    }
		    
		    // addresses.updateSummary();
	    }
	});
	
	$('[payment-method-group="' + PaymentMethodConstants.CreditCard + '"] [credit-card-number-input]').on('keydown', function(e) {
		$creditCardList.val('');
	});

	$('[payment-method-group="' + PaymentMethodConstants.CreditCard + '"] [credit-card-number-input]').on('keyup', function(e) {
		var $this = $(this);
		var creditCardNumber = $this.val();
		
		var selectedCard = util.getCreditCardType(creditCardNumber);
		if (selectedCard) {
			var $selectedCardNumberInput = $('[payment-method-group="' + PaymentMethodConstants.CreditCard + '"] [payment-method="' + selectedCard.PaymentMethod + '"] [credit-card-number-input]');
			
			if ($selectedCardNumberInput.filter(':visible').length < 1) {
				$selectedCardNumberInput.val(creditCardNumber);
			}

			$('.pt_onepcheckout input[name*="_creditCard_type"]').val(selectedCard.PaymentCard);
			$(creditCardListSelector).filter('input[type="radio"][value="' + selectedCard.PaymentCardDisplayKey + '"]').first().prop('checked', true).trigger('change', { doReset: false });
			
			if ($selectedCardNumberInput.filter(':focus').length < 1) {
				$selectedCardNumberInput.focus().val(creditCardNumber);
			}
		} else {
			$('.cc-cardlist-select :input').removeAttr('checked').removeAttr('selected').val('');
			$('.payment-card-list.grid').find('[payment-card]').show();
			$(creditCardListSelector).filter('input').prop('checked', false);
			$(creditCardListSelector).find('option').prop('selected', false);
			$paymentForm.find('[payment-method-group="' + PaymentMethodConstants.CreditCard + '"] .cc-image').css('background-image', '');
		}

		$creditCardSaveCheckboxContainer.show();
	});
	
	$('.add-new-card').on('click', function(e) {
		e.preventDefault();

		resetCreditCardFields($(this).closest('[payment-method]'));
		$('#opc-cc-list :input').removeAttr('checked');
		$('.cc-cardlist-select :input').removeAttr('checked').removeAttr('selected').val('');
		$('.cc-image').css('background-image', 'none');
	});
	
	$(document).on('click', '.payment-method-option.accordion .form-label', function () {
		$(this).closest('.accordion').toggleClass('open');
	});
}

function resetCreditCardFields($paymentMethod) {
	$paymentMethod.find('input[name*="_owner"]').val('');
	$paymentMethod.find('input[name*="_number"]').val('');
	$paymentMethod.find('input[name*="_cvn"]').val('');
	$paymentMethod.find('input[name*="_month"]').val('');
	$paymentMethod.find('input[name*="_year"]').val('');
}

/**
 * @description Make the AJAX request to set cart data
 * @param {Element} form The form element that present address object
 * @param {Element} Action name to apply to form
 * @returns {Promise}
 */
var setOPCPaymentData = function (formSelector, name) {
    var $form = $(formSelector);

    return TPromise.resolve($.ajax({
        type: 'POST',
        url: util.ajaxUrl(Urls.setOpcAddress),
        data: $form.serialize() + '&' + name + '=' + name
    }));
};

exports.init = function (optionsParameter) {
	options = optionsParameter;
	
	if (options && options.Recaptcha && (options.Recaptcha.EnabledForPlaceOrder || options.Recaptcha.EnabledForRewardsCredit || options.Recaptcha.EnabledForGiftCards)) {
    	recaptcha.initialize();
	}
	
	if (options.B2B && options.B2B.Enabled) {
		PaymentVoucherPanel = require('./checkout/PaymentVoucherPaymentPanel');
		PaymentVoucherPanel.initialize();
	}
	GiftCard.initialize(options);
	RewardsCredit.initialize(options);
	LoyaltyCredit.initialize(options);
    initializeEvents(options);
};