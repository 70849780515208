'use strict';

var ValidationSettings = require('../../../../bc_sheplersbootbarn_core/cartridge/js/ValidationConstants').Settings;
var ValidationRules = require('../../../../bc_sheplersbootbarn_core/cartridge/js/ValidationConstants').Rules;
var ValidationHelper = require('../../../../bc_sheplersbootbarn_core/cartridge/scripts/util/ValidationHelper');

exports.initialize = initialize;

function initialize($orderStatusForm) {
	$.validator.addMethod('orderNumber', ValidationHelper.ValidateOrderNumber, Resources.INVALID_ORDER_NUMBER);
	$.validator.addMethod('postalCode', ValidationHelper.ValidatePostalCode, Resources.INVALID_POSTAL_CODE);
	
	var $orderStatusFormOrderNumber = $orderStatusForm.find('input[name$="_orderNumber"]');
	var $orderStatusFormOrderEmail = $orderStatusForm.find('input[name$="_orderEmail"]');
	var $orderStatusFormOrderPostalCode = $orderStatusForm.find('input[name$="_postalCode"]');
	
	var formValidator = $orderStatusForm.validate(ValidationSettings.Global);
	$orderStatusFormOrderNumber.rules('add', ValidationRules.OrderNumber);
	$orderStatusFormOrderEmail.rules('add', ValidationRules.Email);
	$orderStatusFormOrderPostalCode.rules('add', ValidationRules.PostalCode);
}