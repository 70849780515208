'use strict';
var dialog = require('../../dialog');
var SiteConstants = require('../../../../../bc_sheplersbootbarn_core/cartridge/js/SiteConstants');
var util = require('../../util');

var zoomMediaQuery = matchMedia('(min-width: 960px)');

/**
 * @description Enables the zoom viewer on the product detail page
 * @param zmq {Media Query List}
 */
var loadZoom = function (zmq) {
    var $imgZoom = $('#pdpMain .main-image'),
        hiresUrl;
    if (!zmq) {
        zmq = zoomMediaQuery;
    }
    if ($imgZoom.length === 0 || dialog.isActive() || util.isMobile() || !zoomMediaQuery.matches) {
        // remove zoom
        $imgZoom.trigger('zoom.destroy');
        return;
    }
    hiresUrl = $imgZoom.attr('href');

    if (hiresUrl && hiresUrl !== 'null' && hiresUrl.indexOf('noimagelarge') === -1 && zoomMediaQuery.matches) {
        $imgZoom.zoom({
            url: hiresUrl
        });
    }
};

zoomMediaQuery.addListener(loadZoom);

/**
 * @description Sets the main image attributes and the href for the surrounding <a> tag
 * @param {Object} atts Object with url, alt, title and hires properties
 */
var setMainImage = function (atts) {
    $('#pdpMain .primary-image').attr({
        src: atts.url,
        alt: atts.alt,
        title: atts.title
    });
    if (!dialog.isActive() && !util.isMobile()) {
        $('#pdpMain .main-image').attr('href', atts.hires);
    }
    loadZoom();
};

/**
 * @description Replaces the images in the image container, for eg. when a different color was clicked.
 */

var replaceImages = function () {
	
    var $newImages = $('#update-images'),
        $win = $(window),
        $imageContainer,
        $header,
        $promosClone,
        $promosCloneMobile,
        newImage = new Image();
    
    if ($win.width() <= 1024) {
    	$header =  $('#pdpMain .product-image-container').find('header').clone();
    }
    if ($newImages.length === 0) { return; }

    // product container classes to handle styles while image is loading
    $('#pdpMain .product-image-container').addClass('loading-image');
    newImage.src = $newImages.find('.product-primary-image [class*="slide"]').first().find('img').attr('src');
    newImage.onload = function() {
        $('#pdpMain .product-image-container').removeClass('loading-image');
    }
    
    var attachPromosMobile = function() {
        if (pageContext.site === SiteConstants.SiteIds.SheplersUS || pageContext.site === SiteConstants.SiteIds.BootBarnUS) {
            var $imageContainerMobile = $('#pdpMain .product-image-container');
        } else {
            var $imageContainerMobile = $('#pdpMain .product-image-container-mobile');
        }
        var $promoMessagesMobile = $('#pdpMain .product-image-container-mobile .promo-messages'),
            $promosCloneMobile = $promoMessagesMobile.find('.promo-messages').clone();
        
        if ($promoMessagesMobile.is(':empty') && $promosCloneMobile.length > 0) {
            $imageContainerMobile.html($newImages.html());
            $("<div class='promo-messages'></div>").prependTo($imageContainerMobile);
            $promosCloneMobile.prependTo($promoMessagesMobile);
        }
        else {
            $imageContainerMobile.html($newImages.html());
            $promosCloneMobile.prependTo($imageContainerMobile);
        }
    };

    var attachPromosDesktop = function() {
        var $imageContainer = $('#pdpMain .product-image-container'),
            $promoMessages = $('#pdpMain .product-image-container .promo-messages'),
            $promosClone = $promoMessages.clone();
        
        //window.console.log("$promoMessages: ",$promoMessages.length, " $promosClone.length: ", $promosClone.length);
        
        if ($promoMessages.is(':empty') && $promosClone.length > 0) {
            $imageContainer.html($newImages.html());
            $("<div class='promo-messages'></div>").prependTo($imageContainer);
            $promosClone.prependTo($promoMessages);
        }
        else {
            $imageContainer.html($newImages.html());
            $promosClone.prependTo($imageContainer);
        }
    };

    if ($win.width() <= 767 ) {
        attachPromosMobile();
    }
    else {
        attachPromosDesktop();
    }
    
    $newImages.remove();
    loadZoom();
};

/* @module image
 * @description this module handles the primary image viewer on PDP
 **/

/**
 * @description by default, this function sets up zoom and event handler for thumbnail click
 **/
module.exports = function () {
    if (dialog.isActive() || util.isMobile()) {
        $('#pdpMain .main-image').removeAttr('href');
    }
    loadZoom();
    // handle product thumbnail click event
    $('#pdpMain').on('click', '.productthumbnail', function () {
        // switch indicator
        $(this).closest('.product-thumbnails').find('.thumb.selected').removeClass('selected');
        $(this).closest('.thumb').addClass('selected');

        setMainImage($(this).data('lgimg'));
    });
};
module.exports.loadZoom = loadZoom;
module.exports.setMainImage = setMainImage;
module.exports.replaceImages = replaceImages;
