'use strict';

var bootBarnExpressRefinementWizard = require('../BootBarnExpress/components/BootBarnExpressRefinementWizard');
var compareWidget = require('../compare-widget');
var dialog = require('../dialog');
var GoogleTagManagerClient = require('../../../../bc_sheplersbootbarn_core/cartridge/js/util/GoogleTagManagerClient');
var loadingIndicator = require('../components/LoadingIndicator');
var productTile = require('../product-tile');
var page = require('../page');
var sizeRefinements = require('../size-refinements');
var styleCounts = require('../style-counts');
var util = require('../util');
var wishlist = require('../wishlist');
var sliders = require('../sliders');

var ImageConstants;

var RefinementUIVersionConstants = require('../../../../bc_sheplersbootbarn_core/cartridge/js/RefinementConstants').RefinementsUIVersions;
var SiteConstants = require('../../../../bc_sheplersbootbarn_core/cartridge/js/SiteConstants');
var ValidationSettings = require('../../../../bc_sheplersbootbarn_core/cartridge/js/ValidationConstants').Settings;
var ValidationRules = require('../../../../bc_sheplersbootbarn_core/cartridge/js/ValidationConstants').Rules;

var options;
var dataBuffer = [];
var $html = $('html');
var $refinementsInner = $('.refinements');
var refinementsExpanded;
var refinementsViewAll;
var loadingIndicatorSettings;

var $bootFinderEmailForm;
var bootFinderEmailValidator;

/**
 * @private
 * @function
 * @description replaces breadcrumbs, lefthand nav and product listing with ajax and puts a loading indicator over the product listing
 */
function updateProductListing(url, callback, isLoadMore) {
    if (!url || url === window.location.href) {
    	if ($('body').attr('loading')) {
    		$('body').loading('destroy');
    	}
		if ($('.apply-refinements').hasClass('loading')) {
    		$('.apply-refinements').loading('destroy');
    	}
		if (typeof callback == 'function') {
			callback();
		}
        return;
    }

	if (options.StoreUIVersion == '2024Redesign' || (options && options.Pagination && options.Pagination.Mode == 'LoadMore')) {
		$('.btn.load-more').loading({ IsImageShown: false });
	}

    updateInventoryMessages(url);
	saveExpandedRefinements();
	
	$.ajax({
		url: util.appendParamToURL(url, 'format', 'ajax'),
		type: 'GET',
		success: function(data) {
			$('body').removeClass('refinements-mobile-scroll-lock').css('top', '');
			if ($('body').attr('loading') == 'loading') {
				$('body').loading('destroy');
			}

			history.pushState({}, '', url);
			var $content = $(data);
			if (isLoadMore) {
				var $productGrid = $content.find('#search-result-items');
				var productTileCount = $productGrid.find('.grid-tile').length + $('#search-result-items').find('.grid-tile').length;
				var productTotalCount = parseInt($content.find('.search-result-options.bottom .count').text().replace(/\D/g, ''), 10);
				dataBuffer['PaginationCount'] = productTotalCount;
				dataBuffer['PaginationIndex'] += options.Pagination.Size;
				$('#search-result-items').append($productGrid.html());
				$('.search-result-options .display-count').text(productTileCount);

				if (productTileCount >= dataBuffer['PaginationCount']) {
					$('.btn.load-more').hide();
				} else {
					$('.btn.load-more').show();
				}

				if (options.StoreUIVersion == '2024Redesign' || (options && options.Pagination && options.Pagination.Mode == 'LoadMore')) {
					$('.btn.load-more').loading('destroy');
				}
			} else {
				dataBuffer['PaginationIndex'] = 0;
				$('#main').html($content);
				
				if (pageContext.site === SiteConstants.SiteIds.BootBarnUS) {
					$('#grid-sort-header').selectmenu({
						'width': 205
					});
				}

				if ('variantInventoryMessagingUrl' in page && page.variantInventoryMessagingUrl) {
					showVariantInventoryMessaging();
				}

				if (!checkForUpdatedRefinementHandling() && $('#primary').length > 0) {
					$('html,body').animate({
						scrollTop: $('#primary').offset().top
					}, 1000);
				}
			}

			$refinementsInner = $('.refinements');
			redrawUpdatedRefinementHandling($content, url);				
			compareWidget.init();
			productTile.init();
			sizeRefinements.init();
			initializeRefinementControls();			
			redrawShopMyStore();
			sliders.initializeCategorySliders('.main-content .category-landing-slider:not(.slick-initialized)');
			wishlist.renderAllWishlistButtons();

			if ('affirm' in window && 'ui' in affirm && 'refresh' in affirm.ui) {
				affirm.ui.refresh();
			}

			$(document).trigger('CategoryHeaderSliders.Initialize');
			$(document).trigger('EinsteinProductRecommendations.Initialize');
			if ($('#categoryTitle').length > 0) {
				setTimeout(function(){window.top.document.title = $('#categoryTitle').data('title');}, 3000);
			}
	
			initializeThumbDataLayer(url);

			if (options.StyleCountCache) {
				styleCounts.render(options.StyleCountCache);
			}
			
			if (typeof callback == 'function') {
				callback();
			}
		},
		complete: function(data, status, xhr) {
			if ((data.status == 301 || data.status == 302) && data.responseText) {
				location.href = data.responseText;
			}
		}
	});
}

function updateInventoryMessages(url, callback) {
	var urlParams = util.getQueryStringParams(url);

	var inventoryLinkUrl = Urls.getInventoryLink;
	for (var urlParameter in urlParams) {
		if (urlParams[urlParameter]) {
			inventoryLinkUrl = util.appendParamToURL(inventoryLinkUrl, urlParameter, urlParams[urlParameter]);
		}
	}

	$.post(inventoryLinkUrl, function(data) {
		var dataObj = JSON.parse(data);
		
		if (dataObj.status && dataObj.url.length) {
			var newUrl = dataObj.url;
			
			if (urlParams.hasOwnProperty('start') && urlParams.start > 0) {
				newUrl = util.appendParamToURL(newUrl, 'start', urlParams.start);
			}
			
			if (urlParams.hasOwnProperty('sz')) {
				newUrl = util.appendParamToURL(newUrl, 'sz', urlParams.sz);
			}
			
			page.variantInventoryMessagingUrl = newUrl;
		}
		 
		typeof callback == 'function' && callback();
	});
}

function getUrlParams(url) {
	var vars = {};

	var parts = url.replace(/[?&]+([^=&]+)=([^&]*)/gi, function(m, key, value) {
		vars[key] = value;
    });

	return vars;
}

function initializeThumbDataLayer(url) {
	var data = {};
	
	var currentCategoryId = $('.currCGID').val();
	if (currentCategoryId && currentCategoryId != 'null') {
		data.cgid = currentCategoryId;
	}
	
	if (getUrlParams(url)['sz']) {
		data.sz = getUrlParams(url)['sz'];
	}
	
	if (getUrlParams(url)['start']) {
		data.start = getUrlParams(url)['start'];
	}
	
	if (getUrlParams(url)['q']) {
		data.q = decodeURIComponent(getUrlParams(url)['q']);
	}
	
	if (getUrlParams(url)['srule']) {
		data.srule = decodeURIComponent(getUrlParams(url)['srule']);
	}
	
	for (var preferenceIndex = 1; preferenceIndex < 9; preferenceIndex++) {
		if (getUrlParams(url)['prefn' + preferenceIndex]) {
			data['prefn' + preferenceIndex] = decodeURIComponent(getUrlParams(url)['prefn' + preferenceIndex]);
		}
		
		if (getUrlParams(url)['prefv' + preferenceIndex]) {
			data['prefv' + preferenceIndex] = decodeURIComponent(getUrlParams(url)['prefv' + preferenceIndex]);
		}
	}
	
	GoogleTagManagerClient.PushDataLayerForPage(pageContext);
}

function filterRefinements(container, filterText) {
	container.find('.checks label').each(function() {
		var $filter = $(this);
		if (filterText === "") {
			// Reset filter
			$filter.parent().show();
		}
		else {
			var labelText = $filter.html().trim().toUpperCase();
			if (labelText.indexOf(filterText) > -1) {
				$filter.parent().show();
			}
			else {
				$filter.parent().hide();
			}
		}
	});
}

/**
 * @private
 * @function
 * @description Initializes the following search refinement controls:<br/>
 * <p>scroll bar</p>
 * <p>section dropdowns</p>
 * <p>range slider</p>
 * <p>textbox filter</p>
 */
function initializeRefinementControls() {
	if ($('.refinements .refinement:visible').length > 2) {
		$('.refinements .show-all-refinements').addClass('active');
	}
	
	if ($('.refinement input:checked, .refinement [refinement-value].modified:not(:checked)').length > 0) {
		$('.refinements').addClass('refinements-applied');
	}
	
	// handle toggle refinement blocks
	$('.refinement:not(.disabled) .toggle').off('click');
	$('.refinement:not(.disabled) .toggle').on('click', function (event) {
		var $this = $(this);
		var $refinementBody = $this.parent().find('.refinement-body');

		if (!$this.hasClass('expanded')) {
			$this.closest('.refinement').addClass('expanded');
		} else {
			$this.closest('.refinement').removeClass('expanded');
			
		}
		$this.toggleClass('expanded');
		if ($this.hasClass('expanded')) {
		    $refinementBody.stop().show();
        } else {
			$refinementBody.stop().hide();
        }
		
		if ($('.refinement.expanded').length > 0) {
			$('.refinements').addClass('refinement-expanded');
		} else {
			$('.refinements').removeClass('refinement-expanded');
		}
		
		if ($this.closest('.show-all-refinements').length > 0) {
			$this.parent().toggleClass('expanded');
			return;
		}

		saveExpandedRefinements();
		if (options.StoreUIVersion == '2024Redesign') {
			updateSelectedRefinements();
		}
		$(document).trigger('StickyRefinements.Refresh');
		
		setTimeout(function(){
			var cssClass = $this.parent().attr('class');
			var refinementGroup = $.trim(cssClass.replace(/refinement/, ''));
			refGroupsStates.saveState(refinementGroup, $this.hasClass('expanded'));
		}.bind(this), 30);
	});

	// Range Slider
	$('.range-slider').each(function () {
		var minValue = +$(this).data('min'),
			maxValue = +$(this).data('max');
		$(this).slider({
			range: 	true,
			min: 	minValue,
			max: 	maxValue,
			values: [getUrlVars()['pmin'] || minValue, getUrlVars()['pmax'] || maxValue],
			slide: function( event, ui ) {
				$(this).children('.price-min').html('$' + ui.values[0]);
				$(this).children('.price-max').html('$' + ui.values[1]);
			}
		});
	});

	var timeout;
	$('.range-slider').on('slidechange', function( event, ui ) {
		clearTimeout(timeout);
		timeout = setTimeout(function () {
			var cgid = getUrlVars()['cgid'],
				siteLocation = window.location;
			siteLocation.assign( siteLocation.protocol + '//' + siteLocation.hostname + siteLocation.pathname + '?cgid=' + cgid + '&pmin=' + ui.values[0] + '&pmax=' + ui.values[1]);
		}, 2000);
	});

	$(document).ready(function() {
		if ($('.range-slider').length > 0 && getUrlVars()['pmin']) {
			var slider = $('.range-slider');
			slider.children('.price-min').html('$' + getUrlVars()['pmin']);
			slider.children('.price-max').html('$' + getUrlVars()['pmax']);
			$('.slider-toggle').toggleClass('expanded')
								.parent()
								.find('.refinement-body')
								.stop()
								.toggle();
		}

	});

	$('.refinement .form-row input[type=text]').on('input', function() {
		var $input = $(this);

	    window.clearTimeout($input.data("timeout"));
	    $input.data("timeout", setTimeout(function () {
	    	filterRefinements($input.parents('.refinement').first(), $input.val().trim().toUpperCase());
	    }, 1000));
	});

}

/**
 * @private
 * @function
 * @description Initializes events for the following elements:<br/>
 * <p>refinement blocks</p>
 * <p>updating grid: refinements, pagination, breadcrumb</p>
 * <p>item click</p>
 * <p>sorting changes</p>
 */
function initializeEvents() {
	/*
	 * Added a JS for search result page to prevent refinement from having a margin-top even the header is not showing
	 */
	if ((options.RefinementsUIVersion != RefinementUIVersionConstants.Updated) && window.innerWidth < 767) {
		if($(".search-phrase-container").length) {
			$('.pt_product-search-result #secondary .refinements-inner').css({'margin-top': '60px'});
		}
	}

	$(window).on('resize', function() {
		if ((options.RefinementsUIVersion == RefinementUIVersionConstants.Updated) && window.innerWidth > 767) {
			$('.modified').removeClass('modified').prop('checked', !$('.modified').prop('checked'));
		}
	});

	$(document).ready(function() {
		$("body").delegate(".product-swatches-all", "click", function() {
			$(this).parent().find(".swatch-list li").removeClass('hide');
		});
	});

	var $main = $('#main');
    // compare checked
    $main.on('click', 'input[type="checkbox"].compare-check', function () {
        var cb = $(this);
        var tile = cb.closest('.product-tile');

        var func = this.checked ? compareWidget.addProduct : compareWidget.removeProduct;
        var itemImg = tile.find('.product-image a img').first();
        func({
            itemid: tile.data('itemid'),
            uuid: tile[0].id,
            img: itemImg,
            cb: cb
        });
    });

    $(document).off('click.Toggle.Refinements').on('click.Toggle.Refinements', '.toggle-refinements', function(event) {
    	event.preventDefault();
    	
    	if (!$('.refinements-inner-container').hasClass('open')) {
	     	$('.refinements-inner-container').find('.toggle.expanded, .toggle.selected, .refinement.expanded').removeClass('expanded selected');
	     	$('.refinements').removeClass('refinement-expanded');
	     	$('.refinement-body').hide();
    	}
    	
    	toggleBodyScrollLock();
    	$('.refinements-inner-container').toggleClass('open');
    	redrawUpdatedRefinementHandling();
    });
    
    $main.off('click.Toggle.Sorting').on('click.Toggle.Sorting', '.toggle-sort', function(event) {
    	event.preventDefault();
    	
		$('.sort-by.modal').toggle();
		$('.sort-by.modal').toggleClass('open');
		$('#search-result').toggleClass('active');
		
    });
    
    $main.off('click.Select.Sorting').on('click.Select.Sorting', '.sort-by.modal input', function(event) {
    	event.stopImmediatePropagation();
    	
    	var $this = $(this);
		$('.sort-by.modal').toggle();
		$('.sort-by.modal').toggleClass('open');
		$('body').loading(loadingIndicatorSettings);
		updateProductListing($this.val());
    });
    
    // handle events for updating grid
    $main.on('click', '.refinements-head a, .refinements-inner a, .pagination-bar a, .breadcrumb-refinement-value a', function (e) {
		var $this = $(this);

    	if (!$this.hasClass('ref-filter') && checkForUpdatedRefinementHandling()) {
    		e.preventDefault();
    		var refinementValue = $this.attr('value');
    		
    		$this.closest('.refinement-body-inner').find('li[refinement-value="' + refinementValue + '"], a[value="' + refinementValue + '"]').toggleClass('modified');
    		redrawUpdatedRefinementHandling();
    		return;	
    	}
    	
        // don't intercept for category and folder refinements, as well as unselectable
        if ($this.parents('.category-refinement').length > 0 || $this.parents('.folder-refinement').length > 0 || $this.parent().hasClass('unselectable')) {
            return;
        }
        e.preventDefault();

		var activeRefinementsCount = $('[refinement-value].selected').length - $('.swatches[class*="popular-"] [refinement-value].selected').length;
		if ($this.parent().hasClass('selected') && activeRefinementsCount == 1 && options.CategoryLandingPageContent && options.CategoryLandingPageContent.Url) {
			location.href = options.CategoryLandingPageContent.Url;
    		return;
		}

        if ($this.hasClass('breadcrumb-element') && $this.attr('href') != null) {
    		location.href = this.href;
    		return;
        }

        // hide or show/expand all refinement items
        if ($this.hasClass('ref-filter')) {
        	var ref = '.' + $(this).data('ref');
        	$(ref).find('.toggleable').toggleClass('hidden');
        	if ($this.hasClass('more-link')) {
        		$this.parents('li').hide().closest('.refinement').addClass('more-expanded');
        	} else {
        		$(ref).find('.more-link').parents('li').show().closest('.refinement').removeClass('more-expanded');
        	}
			saveExpandedRefinements();
        	return;
        }

		if ($this.length > 0 && !$('html').hasClass('size-mobile')) {
			$('body').loading(loadingIndicatorSettings);
		}

        updateProductListing(this.href);

		var selection = $('div.product-selections ul li');
		var refGroup = $(selection[selection.length-2]).find('span:first').data('refgroup-id');
		if (refGroup && refGroup.length > 0 && refGroupsStates.checkState(refGroup)) {
			$('#refinements-inner .refinement.'+refGroup+' .toggle')[0].click();
		}
    });

    // handle events item click. append params.
    $main.on('click', '.product-tile a:not("#quickviewbutton")', function () {
        var a = $(this);
        // get current page refinement values
        var wl = window.location;

        var qsParams = (wl.search.length > 1) ? util.getQueryStringParams(wl.search.substr(1)) : {};
        var hashParams = (wl.hash.length > 1) ? util.getQueryStringParams(wl.hash.substr(1)) : {};

        // merge hash params with querystring params
        var params = $.extend(hashParams, qsParams);
        if (!params.start) {
            params.start = 0;
        }
        // get the index of the selected item and save as start parameter
        var tile = a.closest('.product-tile');
        var idx = tile.data('idx') ? + tile.data('idx') : 0;

        // convert params.start to integer and add index
        params.start = (+params.start) + (idx + 1);
        // set the hash and allow normal action to continue
        a[0].hash = $.param(params);
    });

    // handle sorting change
    $main.on('change', '.sort-by select', function (e) {
        e.preventDefault();
        
		if ($('html').hasClass('size-mobile')) {
			var isSortingChanged = false;
			var $sortingRule = $('.modal.open .sort-by option:checked');
			if ($sortingRule && $sortingRule.length > 0) {
				isSortingChanged = !$sortingRule.attr('selected');
			}

			if (isSortingChanged || $refinementsInner.find('[refinement-value].modified, .list-selections .value.pending-clear').length > 0) {
				$refinementsInner.addClass('refinements-modified');
				$('.refinements-inner-container-footer .apply-refinements').removeClass('disabled');
			} else {
				$refinementsInner.removeClass('refinements-modified');
				$('.refinements-inner-container-footer .apply-refinements').addClass('disabled');
			}
		} else {
			$('body').loading(loadingIndicatorSettings);		
			var selectedSort = $(this).find('option:selected').val();
			if ($(this).attr('id') == 'mobile-sort'){
				updateProductListing(selectedSort);
			} else {
				if (checkForUpdatedRefinementHandling()) {
					updateProductListing(selectedSort);
				} else {
					document.location.href = selectedSort;
				}
			}
		}
    });

	$('.search-result-page').on('click', '#grid-sort-header-menu li', function () {
		$('.sort-by select').prop('selectedIndex', $(this).index()).first().trigger('change');
	});

    $main.on('change', '.items-per-page select', function () {
        var refineUrl = $(this).find('option:selected').val();
        if (refineUrl === 'INFINITE_SCROLL') {
            $('html').addClass('infinite-scroll').removeClass('disable-infinite-scroll');
        } else {
            $('html').addClass('disable-infinite-scroll').removeClass('infinite-scroll');
            updateProductListing(refineUrl);
        }
	});

	$main.on('click', '.mobile-refinements .toggle-button', function() {
		if (pageContext.site === SiteConstants.SiteIds.SheplersUS && options.RefinementsUIVersion != RefinementUIVersionConstants.Updated) {
			if ($(this).hasClass('sort-by-toggle')) {
				
				//Ensure both sort-by and refinements aren't open simultaneously 
				$('#secondary.refinements').removeClass('active');
				$('.mobile-refinements .refine-toggle').removeClass('active');

				//container for new sorting options
				var sortByOptionsContainer = $('.mobile-refinements .sort-by-options');
				if(sortByOptionsContainer.html().length < 1) {

					//sort-by form for reference and pulling options
					var currentSortByOptions = $('.mobile-refinements #sort-by #grid-sort-header option');

					sortByOptionsContainer.append("<ul class='sort-by-list'></ul>");

					//create new list with data linked to form
					currentSortByOptions.each(function() {
						var selectedValue = $(this).prop('selected');

						if (typeof selectedValue !== typeof undefined && selectedValue !== false) {
							var newOption = '<li class="selectable-option selected" data-option-for="'  + $(this).attr('value') + '">' + $(this).text() + '</li>';
							sortByOptionsContainer.find('ul').append(newOption);
						} else  {
							var newOption = '<li class="selectable-option" data-option-for="'  + $(this).attr('value') + '">' + $(this).text() + '</li>';
							sortByOptionsContainer.find('ul').append(newOption);
						}
					});
					
				}
				//toggle sort-by options to active state
				$('.mobile-refinements .sort-by-options').toggleClass('active');
				$(this).toggleClass('active');
			} else if ($(this).hasClass('refine-toggle')) {
				//Ensure both sort-by and refinements aren't open simultaneously
				$('.mobile-refinements .sort-by-options').removeClass('active');
				$('.mobile-refinements .sort-by-toggle').removeClass('active');
				//toggle refinements options to active state
				$('#secondary.refinements').toggleClass('active');
				$(this).toggleClass('active');
			}
		}	
	});
	$main.on('click', '.sort-by-options .sort-by-list .selectable-option', function() {
		if (pageContext.site === SiteConstants.SiteIds.SheplersUS) {
			//set correct sort-by form option as selected
			var optionValue = $(this).attr('data-option-for');
			$('.mobile-refinements .sort-by#sort-by form #grid-sort-header option[value="'+optionValue+'"]').prop('selected', true);
			//Trigger form change to fire sort-by change watcher
			$('.mobile-refinements .sort-by#sort-by form #grid-sort-header option').change();
		}
	});

    // refinement checkboxes
    $main.on('click', '.refinement .checks input', function (event) {
		var $this = $(this);
		var $refinement = $this.closest('[refinement-value]');
		if ($refinement.length > 0 && !$('html').hasClass('size-mobile')) {
			$('body').loading(loadingIndicatorSettings);
		}

		if ($this.closest('.refinement.sort-by').length > 0) {
			$this.closest('.refinement-body').find('[refinement-value] input').not($this).removeClass('modified').prop('checked', false);
			$this.toggleClass('modified');
		}
    	if (checkForUpdatedRefinementHandling()) {
			var refinementValue = $this.val().replace('"'," INCH");
    		if ($this.closest('[is-price-refinement="true"]').length > 0) {
				$('[is-price-refinement="true"] input').not($this).prop('checked', false).removeAttr('checked').removeClass('modified');
				$refinement.toggleClass('modified');
			} else {
				var $equalValueInputs = $this.closest('.refinement-body').find('[refinement-value="' + refinementValue + '"]');
	    		$equalValueInputs.toggleClass('modified');
	    		$equalValueInputs.not($this).prop('checked', $this.prop('checked'));
    		}
    		redrawUpdatedRefinementHandling();
    		return;	
    	}

		var url = $(this).parents('li').first().find('.visually-hidden > a').first().attr('href');
		updateProductListing(url);
    });

    $main.on('click', '.result-options .switch input[name="field-switch"]', function () {
    	if (checkForUpdatedRefinementHandling()) {
    		updateProductListing($(this).parents('.switch').find('.visually-hidden input[name="field-true"] + a').first().attr('href'));
    	} else {
    		document.location = $(this).parents('.switch').find('.visually-hidden input[name="field-true"] + a').first().attr('href');
    	}
    });

    $(document).on('click', '.list-selections li:not(.pending-selections) a.close-btn, .clear-all .close-btn', function (e) {
    	e.preventDefault();

    	var $this = $(this);
    	if ($this.closest('.value').hasClass('applied') && $this.closest('.modal').length > 0) {
    		$this.closest('.value').toggleClass('pending-clear');
    		redrawUpdatedRefinementHandling();
    		return;	
    	}

		$('body').loading(loadingIndicatorSettings);
		var activeRefinementsCount = $('[refinement-value].selected').length - $('.swatches[class*="popular-"] [refinement-value].selected').length;
		if (activeRefinementsCount == 1 && options.CategoryLandingPageContent && options.CategoryLandingPageContent.Url) {
			location.href = options.CategoryLandingPageContent.Url;
    		return;
		}

    	var href = $this.attr('href');
    	if (href == null || href == 'null') {
    		var search = document.location.search;
    		updateProductListing(document.location.pathname + search.substr(0, search.indexOf('&')));
    	} else {
    		updateProductListing(href);
    	}
	});
    
    $(document).on('click', '.list-selections li.pending-selections a.close-btn', function (e) {
    	e.preventDefault();

    	var pendingRefinementId = $(this).closest('[data-refgroup-id]').attr('data-refgroup-id');
    	var pendingRefinementValue = $(this).closest('span').attr('refinement-value');
		var selector = '[refinement-id="' + pendingRefinementId + '"] ul [refinement-value="' + pendingRefinementValue + '"]';

    	var $pendingRefinementInput = $refinementsInner.find(selector);    	
    	if ($pendingRefinementInput.find('input').length > 0) {
    		$pendingRefinementInput.find('input').click();
    	} else if ($pendingRefinementInput.find('a').length > 0) {
    		$pendingRefinementInput.find('a').click();
    	}
	});
    
    $main.on('click', '.product-swatches-accordion-button', function () {
    	var $accordion = $(this).closest('.accordion');
    	
    	if ($accordion.length > 0) {
    		$accordion.toggleClass('active');
    	}
    });

	$(document).off('click.ShopMyStore', '.shop-my-store .checkbox-toggle input').on('click.ShopMyStore', '.shop-my-store .checkbox-toggle input', function(event) {
		var $this = $(this);

		var url;
		if ($this.prop('checked'))  {
			url = $this.attr('href-refine');
		} else {
			url = $this.attr('href-relax');
			$('body').attr('is-shop-my-store-active', false);
		}

		if (url) {
			$('.shop-my-store .checkbox-toggle').loading({ IsLabelShown: false, IsImageShown: false });
			$('body').loading(loadingIndicatorSettings);

			GoogleTagManagerClient.PushDataLayerForDataLayerType('ShopYourStore', { enabled: $this.prop('checked') });

			url = util.appendParamToURL(url, 'shopmystore', $this.prop('checked'));
			updateProductListing(url);
		} else {
			event.preventDefault();
		}
    });

	$(document).off('ShopMyStore.Update').on('ShopMyStore.Update', function(event, storeId) {
		if ($('.shop-my-store .checkbox-toggle input').prop('checked')) {
			var url = location.protocol + '//' + location.hostname + location.pathname;
			var queryStringParameters = util.getQueryStringParams(location.search);
			for (var queryStringParameterKey in queryStringParameters) {
				var queryStringParameterValue = queryStringParameters[queryStringParameterKey];
				if (queryStringParameterValue && queryStringParameterValue.indexOf('availableStores') > -1) {
					var refinementIndex;
					var refinementIndexMatch = queryStringParameterKey.match(/prefn([0-9])+/);
					if (refinementIndexMatch && refinementIndexMatch.length > 1) {
						refinementIndex = refinementIndexMatch[1];
					}				
				}
			}
	
			if (refinementIndex && storeId) {
				queryStringParameters['prefv' + refinementIndex] = storeId;
			}
	
			for (var queryStringParameterKey in queryStringParameters) {
				var queryStringParameterValue = queryStringParameters[queryStringParameterKey];
				if (queryStringParameterKey != 'start' && queryStringParameterKey != 'sz') {
					url = util.appendParamToURL(url, queryStringParameterKey, queryStringParameterValue);
				}
			}

			$('body').loading({
				IsImageShown: false,
				Content: '<h6>Hold Your Horses!</h6><p>Your selections are loading...</p>',
				CssClass: 'shopyourstore',
				BackgroundColor: 'transparent'
			});

			updateProductListing(url);
		} else {
			redrawShopMyStore();
		}
	});

    searchStores();
    initializeRefinementControls();
	if (pageContext.site === SiteConstants.SiteIds.BootBarnUS) {
		$('#grid-sort-header').selectmenu({
			'width': 205
		});
	}
    
    $main.on('click', '.apply-refinements:not(.disabled)', function(event) {
    	event.preventDefault();
    	
		var pendingRefinementsDelta = $('#secondary [refinement-value]:not(.selected):not([class*="popular"]).modified').length - $('#secondary [refinement-value].selected:not([class*="popular"]).modified').length;
		var activeRefinementsCount = $('#secondary [refinement-value].selected').length - $('#secondary .swatches[class*="popular-"] [refinement-value].selected').length - $('#secondary .list-selections .pending-clear').length + pendingRefinementsDelta;
		if (activeRefinementsCount == 0 && options.CategoryLandingPageContent && options.CategoryLandingPageContent.Url) {
			location.href = options.CategoryLandingPageContent.Url;
    		return;
		}
		
    	$('body').loading(loadingIndicatorSettings);
    	updateProductListing(buildUrlForSelectedRefinements(null, null, false, true));
    });
    
    $main.on('click', '.clear-refinements:not(.disabled)', function(event) {
    	event.preventDefault();
    	
    	var $this = $(this);
    	var href = $this.attr('href');
    	var $refinementsModalProductSelections = $('.refinements-inner-container-header .product-selections');
    	
    	if ($refinementsModalProductSelections.find('.list-selections .value.applied').length > 0) {
    		if (checkForUpdatedRefinementHandling()) {
    			$('.clear-refinements').loading({
    				IsImageShown: false	
    			});
    			updateProductListing($(this).attr('href'));
    		} else {
    			document.location = $(this).attr('href');
    		}
    	} else {
    		$('.refinement[uuid]').find('li[refinement-value]:not(.selected).modified').removeClass('modified');
    		$('.refinement[uuid]').find('li[refinement-value]:not(.selected)').find('input:checked').prop('checked', false);
    		redrawUpdatedRefinementHandling();
    	}
    });

	$(document).on('click', '#main .btn.load-more:not(.disabled)', function(event) {
		event.preventDefault();
		var $loadMoreButton = $(this);

		// Let's disable the button after first click, so that functionality can complete before being re-enabled
		$loadMoreButton.addClass('disabled');

		if (!dataBuffer['PaginationIndex']) {
			dataBuffer['PaginationIndex'] = options.Pagination.Start;
		}
		if (!dataBuffer['PaginationCount']) {
			dataBuffer['PaginationCount'] = options.Pagination.Count;
		}
		
		var url = document.location.href;
		var currentQueryStringParameters = util.getQueryStringParams(util.getQueryString(url));
		if (currentQueryStringParameters['start']) {
			url = util.removeParamFromURL(url, 'start');
		}
		url = util.appendParamToURL(url, 'start', dataBuffer['PaginationIndex'] + options.Pagination.Size);

		updateProductListing(url, function() {
			// Use the callback function to re-enable the Load More button
			$loadMoreButton.removeClass('disabled');
		}, true);
	});
}

function toggleBodyScrollLock() {
	var $body = $('body');
	var bodyScrollLockClass = 'refinements-mobile-scroll-lock';

	if ($body.hasClass(bodyScrollLockClass)) {
		$body.removeClass(bodyScrollLockClass);
		$body.css('top', '');
		document.body.scrollTop = document.documentElement.scrollTop = dataBuffer['scrollTop'];
	} else {
		var scrollTop = document.body.scrollTop || document.documentElement.scrollTop;
    	dataBuffer['scrollTop'] = scrollTop;
    	$body.css('top', 0 - scrollTop);
    	$body.addClass(bodyScrollLockClass);
	}
}

function buildUrlForSelectedRefinements(baseUrl, urlForParameters, includeCategory, includeSort) {
	var refinementValuesBuffer = {};
	
	$refinementsInner.find('[refinement-id]:not(.price-refinement):not(.sort-by)').each(function() {
		var $refinement = $(this);
		var $selectedRefinements = $refinement.find('li[refinement-value]:not(.selected).modified, li[refinement-value].selected:not(.modified)');
		
		if ($selectedRefinements.length > 0) {
    		var refinementId = $refinement.attr('refinement-id');
    		var mappedRefinementId;
			if (refinementId == 'availableStores2' || refinementId == 'availableStores3') {
				mappedRefinementId = 'availableStores';	
			} else {
				mappedRefinementId = refinementId;
			}
    		
    		if (!refinementValuesBuffer[mappedRefinementId]) {
    			refinementValuesBuffer[mappedRefinementId] = [];
    		}
    		
    		$selectedRefinements.each(function () {
    			var value = $(this).attr('refinement-value').replace(' INCH','"');
    			var isNewValue = true;
    			for (var index = 0; index < refinementValuesBuffer[mappedRefinementId].length; index++) {
    				if (refinementValuesBuffer[mappedRefinementId][index] == value) {
    					isNewValue = false;
    				}
				}
				
    			if (isNewValue && $refinementsInner.find('.list-selections [data-refgroup-id="' + refinementId + '"] [value="' + $(this).attr('refinement-value').replace(/["']/g, "") + '"].pending-clear').length == 0) {
					refinementValuesBuffer[mappedRefinementId].push(value);
    			}
    		});
		}
	});
	
	var url = baseUrl || options.SearchUrl;
	var currentCategoryId = $('.currCGID').val();
	if (includeCategory && currentCategoryId && currentCategoryId != 'null') {
		url = util.removeParamFromURL(url, 'cgid');
		url = util.appendParamToURL(url, 'cgid', $('.currCGID').val());
	}
	
	var currentQueryString = util.getQueryString(urlForParameters || document.location.href);
	var currentQueryStringParameters = util.getQueryStringParams(currentQueryString);
	if (currentQueryStringParameters['q']) {
		url = util.removeParamFromURL(url, 'q');
		url = util.appendParamToURL(url, 'q', options.SearchPhrase);
	}
	
	if (currentQueryStringParameters['srule']) {
		url = util.removeParamFromURL(url, 'srule');
		url = util.appendParamToURL(url, 'srule', currentQueryStringParameters['srule']);
	}

	var availableStoresValue;
	var activeRefinementIndex = 1;
	while (url.indexOf('prefn' + activeRefinementIndex) >= 0) {
		if (currentQueryStringParameters['prefn' + activeRefinementIndex] == 'availableStores') {
			availableStoresValue = currentQueryStringParameters['prefv' + activeRefinementIndex];
		}

		url = util.removeParamFromURL(url, 'prefn' + activeRefinementIndex);
		url = util.removeParamFromURL(url, 'prefv' + activeRefinementIndex);
		activeRefinementIndex++;
	}

	var availableStoresRefinementIndex;
	for (var currentQueryStringParameterKey in currentQueryStringParameters) {
		var currentQueryStringParameterValue = currentQueryStringParameters[currentQueryStringParameterKey];
		if (currentQueryStringParameterValue && currentQueryStringParameterValue.indexOf('availableStores') > -1) {
			var refinementIndexMatch = currentQueryStringParameterKey.match(/prefn([0-9])+/);
			if (refinementIndexMatch && refinementIndexMatch.length > 1) {
				availableStoresRefinementIndex = refinementIndexMatch[1];
			}
		}
	}

	if (!availableStoresValue && availableStoresRefinementIndex) {
		availableStoresValue = currentQueryStringParameters['prefv' + availableStoresRefinementIndex];
	}

	if (availableStoresValue && !refinementValuesBuffer['availableStores']) {
		refinementValuesBuffer['availableStores'] = [];
		refinementValuesBuffer['availableStores'].push(availableStoresValue);
	}
	
	var activeRefinementIndex = 1;
	for (var refinementId in refinementValuesBuffer) {
		if (refinementId && refinementValuesBuffer[refinementId].length > 0) {
			url = util.appendParamToURL(url, 'prefn' + activeRefinementIndex, refinementId);
			url = util.appendParamToURL(url, 'prefv' + activeRefinementIndex, refinementValuesBuffer[refinementId].join('|'));
			activeRefinementIndex++;
		}
	}
	
	if ($('[is-price-refinement="true"] input:checked').length > 0) {
		url = util.removeParamFromURL(url, 'pmin');
		url = util.removeParamFromURL(url, 'pmax');
		
		var $refinementPrice = $('[is-price-refinement="true"] input:checked').closest('[is-price-refinement="true"]');
		url = util.appendParamToURL(url, 'pmin', $refinementPrice.attr('pmin'));
		url = util.appendParamToURL(url, 'pmax', $refinementPrice.attr('pmax'));
	}

	if (includeSort && checkForUpdatedRefinementHandling()) {
		url = util.removeParamFromURL(url, 'srule');
		
		var $sortingRule = $refinementsInner.find('.sort-by option:checked, .sort-by input:checked');
		if ($sortingRule && $sortingRule.length > 0) {
			var sortingRule = $sortingRule.attr('sorting-rule');

			if (sortingRule && sortingRule.indexOf('Default') < 0) {
				url = util.appendParamToURL(url, 'srule', sortingRule);
			}
		}
	}

	return url;
}

function checkForUpdatedRefinementHandling() {
	if ((options.RefinementsUIVersion == RefinementUIVersionConstants.Updated) && $html.hasClass('size-mobile') && (pageContext.site != SiteConstants.SiteIds.BootBarnRspUS || options.StoreUIVersion == '2024Redesign')) {
    	return true;
	}
	
	return false;
}

function redrawUpdatedRefinementHandling($contentParameter, urlForParameters) {
	var $content;
	if ($contentParameter && $contentParameter.length > 0) {
		$content = $contentParameter.find('.refinements');
	}
	if (!$content || $content.length == 0) {
		$content = $refinementsInner;
	}

	updateRefinementCounts($content, urlForParameters);
	if (options.StoreUIVersion == '2024Redesign') {
		updateSelectedRefinements();
	}
	updatePendingRefinements();

	var isSortingChanged = false;
	var $sortingRule = $('.modal.open .sort-by option:checked, .modal.open .sort-by input:checked');
	if ($sortingRule && $sortingRule.length > 0) {
		isSortingChanged = !$sortingRule.attr('selected');
	}

	if (isSortingChanged || $content.find('[refinement-value].modified, .list-selections .value.pending-clear').length > 0) {
		$content.addClass('refinements-modified');
		$('.refinements-inner-container-footer .apply-refinements').removeClass('disabled');
	} else {
		$content.removeClass('refinements-modified');
		$('.refinements-inner-container-footer .apply-refinements').addClass('disabled');
	}
	
	if ($('[is-price-refinement="true"] input:checked').length > 0) {
		$content.addClass('price-selected');
	} else {
		$content.removeClass('price-selected');
	}
	
	var $refinementsModalProductSelections = $('.refinements-inner-container-header .product-selections');	
	if (($refinementsModalProductSelections.find('.list-selections .value.applied').length + $refinementsModalProductSelections.find('.list-selections > .pending-selections > span').length) > 0) {
		$refinementsModalProductSelections.show();
		$('.refinements-inner-container-footer .clear-refinements').removeClass('disabled');
	} else {
		$refinementsModalProductSelections.hide();
		$('.refinements-inner-container-footer .clear-refinements').addClass('disabled');
	}
	var $productSelectionContainer;
	if (pageContext.site === SiteConstants.SiteIds.SheplersUS) {
		$productSelectionContainer = $('.primary-content > .sort-and-refine > .product-selections');
	} else {
		$productSelectionContainer = $('.primary-content > .product-selections');
	}

	if ($productSelectionContainer.find('.list-selections .value.applied').length > 0) {
		$productSelectionContainer.show();
	} else {
		$productSelectionContainer.hide();
	}
}

function updateSelectedRefinements() {
	var $refinements = $refinementsInner.find('.refinement[uuid].expanded');
	$refinements.each(function() {
		var $refinement = $(this);

		var $selectedRefinementsContainer = $refinement.find('.toggle').find('.list-selections');
		if (!$selectedRefinementsContainer || !$selectedRefinementsContainer.length) {
			$refinement.find('.toggle').append('<div class="product-selections"><ul class="list-selections"/></div>');
			$selectedRefinementsContainer = $refinement.find('.toggle .list-selections');
		}

		var $selectedRefinementsValues;
		if (options.StoreUIVersion == '2024Redesign') {
			$selectedRefinementsValues = $refinement.find('ul:not(.popular)').find('li[refinement-value].selected');
		} else {
			$selectedRefinementsValues = $refinement.find('ul:not(.popular)').find('li[refinement-value].selected, input:checked:not(.modified)');
		}
		var selectedRefinementsBuffer = [];
		$selectedRefinementsValues.each(function(index, element) {
			var $this = $(this);
			var selectedRefinementId = $this.closest('[refinement-id]').attr('refinement-id');
			var selectedRefinementValue = $this.closest('[refinement-value]').attr('refinement-value');
			var selectedRefinementText = $this.closest('[refinement-value]').attr('display-value') || selectedRefinementValue;
			
			if (!selectedRefinementsBuffer[selectedRefinementId]) {
				selectedRefinementsBuffer[selectedRefinementId] = [];
			}
			
			var isNewValue = true;
			for (var index = 0; index < selectedRefinementsBuffer[selectedRefinementId].length; index++) {
				if (selectedRefinementsBuffer[selectedRefinementId][index].Value == selectedRefinementValue) {
					isNewValue = false;
				}
			}
			
			if (isNewValue) {
				selectedRefinementsBuffer[selectedRefinementId].push({
					Text: selectedRefinementText,
					Value: selectedRefinementValue
				});
			}
		});

		var selectedRefinementsHtml = '';
		for (var selectedRefinementElement in selectedRefinementsBuffer) {
			var selectedRefinement = selectedRefinementsBuffer[selectedRefinementElement];
			
			var selectedRefinementValuesHtml = '';
			for (var selectedRefinementDataElement in selectedRefinement) {
				var selectedRefinementData = selectedRefinement[selectedRefinementDataElement];
				var selectedRefinementText = selectedRefinementData.Text;
				var selectedRefinementValue = selectedRefinementData.Value;
				
				if (selectedRefinementValuesHtml) {
					selectedRefinementValuesHtml += '<span class="delimiter">,</span>';
				}
				
				var selectedRefinementDisplayValue = selectedRefinementValue;
				if (selectedRefinementElement == 'Price') {
					selectedRefinementDisplayValue = $refinementsInner.find('[refinement-id="' + selectedRefinementElement + '"]').attr('display-value');
				} else if (selectedRefinementElement == 'size') {
					selectedRefinementDisplayValue = 'Size ' + selectedRefinementDisplayValue;
				}
				
				selectedRefinementValuesHtml += '<span class="value" refinement-value="' + selectedRefinementValue + '">' +
													selectedRefinementText +
													'<a class="close-btn"><i class="fa fa-close"></i></a>' +
												'</span>';
			}
			
			selectedRefinementsHtml += '<span data-refgroup-id="' + selectedRefinementElement + '">' +
											'<span class="key">' + selectedRefinementElement + '</span>' +
											'<span class="colon">:</span>' +
											selectedRefinementValuesHtml +
										'</span>';
		}
		
		$selectedRefinementsContainer.html(selectedRefinementsHtml);
	});
}

function updatePendingRefinements() {
	var $pendingRefinementsContainer;
	if (options.StoreUIVersion == '2024Redesign') {
		var $selectedRefinementsContainer = $refinementsInner.find('.refinement.expanded .toggle').find('.product-selections');
		if (!$selectedRefinementsContainer || !$selectedRefinementsContainer.length) {
			$refinementsInner.find('.refinement.expanded .toggle').append('<div class="product-selections"><ul class="list-selections"/></div>');
		}

		$pendingRefinementsContainer = $refinementsInner.find('.refinement.expanded .toggle').find('.pending-selections');
		if (!$pendingRefinementsContainer || !$pendingRefinementsContainer.length) {
			$refinementsInner.find('.refinement.expanded .toggle .list-selections').append('<li class="pending-selections"/>');
			$pendingRefinementsContainer = $refinementsInner.find('.refinement.expanded .toggle .list-selections .pending-selections');
		}
	} else {
		$pendingRefinementsContainer = $refinementsInner.find('.pending-selections');
	}
	
	var $pendingRefinementsValues;
	if (options.StoreUIVersion == '2024Redesign') {
		$pendingRefinementsValues = $('.refinement[uuid].expanded ul:not(.popular)').find('li[refinement-value]:not(.selected).modified, input.modified:not([checked="checked"])');
	} else {
		$pendingRefinementsValues = $('.refinement[uuid] ul:not(.popular)').find('li[refinement-value]:not(.selected).modified, input:checked');
	}
	var pendingRefinementsBuffer = [];

	$pendingRefinementsValues.each(function(index, element) {
		var $this = $(this);
		var pendingRefinementId = $this.closest('[refinement-id]').attr('refinement-id');
		var pendingRefinementValue = $this.closest('[refinement-value]').attr('refinement-value');
		var pendingRefinementText = $this.closest('[refinement-value]').attr('display-value') || pendingRefinementValue;
		
		if (!pendingRefinementsBuffer[pendingRefinementId]) {
			pendingRefinementsBuffer[pendingRefinementId] = [];
		}
		
		var isNewValue = true;
		for (var index = 0; index < pendingRefinementsBuffer[pendingRefinementId].length; index++) {
			if (pendingRefinementsBuffer[pendingRefinementId][index].Value == pendingRefinementValue) {
				isNewValue = false;
			}
		}
		
		if (isNewValue) {
			pendingRefinementsBuffer[pendingRefinementId].push({
				Text: pendingRefinementText,
				Value: pendingRefinementValue
			});
		}
	});
	
	var pendingRefinementsHtml = '';
	for (var pendingRefinementElement in pendingRefinementsBuffer) {
		var pendingRefinement = pendingRefinementsBuffer[pendingRefinementElement];
		
		var pendingRefinementValuesHtml = '';
		for (var pendingRefinementDataElement in pendingRefinement) {
			var pendingRefinementData = pendingRefinement[pendingRefinementDataElement];
			var pendingRefinementText = pendingRefinementData.Text;
			var pendingRefinementValue = pendingRefinementData.Value;
			
			if (pendingRefinementValuesHtml) {
				pendingRefinementValuesHtml += '<span class="delimiter">,</span>';
			}
			
			var pendingRefinementDisplayValue = pendingRefinementValue;
			if (pendingRefinementElement == 'Price') {
				pendingRefinementDisplayValue = $refinementsInner.find('[refinement-id="' + pendingRefinementElement + '"]').attr('display-value');
			} else if (pendingRefinementElement == 'size') {
				pendingRefinementDisplayValue = 'Size ' + pendingRefinementDisplayValue;
			}
			
			pendingRefinementValuesHtml += '<span class="value" refinement-value="' + pendingRefinementValue + '">' +
												pendingRefinementText +
												'<a class="close-btn"><i class="fa fa-close"></i></a>' +
											'</span>';
		}
		
		pendingRefinementsHtml += '<span data-refgroup-id="' + pendingRefinementElement + '">' +
										'<span class="key">' + pendingRefinementElement + '</span>' +
										'<span class="colon">:</span>' +
										pendingRefinementValuesHtml +
									'</span>';
	}
	
	$pendingRefinementsContainer.html(pendingRefinementsHtml);
}

function updateRefinementCounts($contentParameter) {
	var url = buildUrlForSelectedRefinements(Urls.getRefinementCounts, null, true);
	var $content = $contentParameter || $(document);
	var $refinements = $content.find('.refinement[uuid]');
	var $refinementProductCount = $content.find('.refinement .toggle .product-count');
	$refinementProductCount.loading();

	var refinementGroups = [];
	var refinementGroupIndex = 0;
	var refinementGroupSizeLimit = 2000;
	var refinementGroupSizeCurrent = 0;
	$.each($refinements, function(index, element) {
		var $refinement = $(element);
		var refinementValueCount = $refinement.find('[refinement-value]').length;
		if ((refinementValueCount + refinementGroupSizeCurrent) >= refinementGroupSizeLimit) {
			refinementGroupIndex++;
			refinementGroupSizeCurrent = 0;
		}

		if (!refinementGroups[refinementGroupIndex]) {
			refinementGroups[refinementGroupIndex] = $refinement;
		} else {
			refinementGroups[refinementGroupIndex] = refinementGroups[refinementGroupIndex].add($refinement);
		}

		refinementGroupSizeCurrent += refinementValueCount;
	});

	dataBuffer['updateRefinementCountsRefinementGroupIndex'] = 0;
	dataBuffer['updateRefinementCountsRefinementGroupCount'] = refinementGroups.length;

	$.each(refinementGroups, function(index, element) {
		var $refinementGroup = $(element);
		updateRefinementCountsForRefinements(url, $refinementGroup);
	});
}

function updateRefinementCountsForRefinements(url, $refinements) {
	var refinementIds = $refinements.map(function(index, element) {
		return $(element).attr('refinement-id');
	}).get();

	if (refinementIds) {
		var $refinementProductCount = $refinements.find('.toggle .product-count');
		$refinementProductCount.loading();

		if (dataBuffer['updateRefinementCountsRefinementGroupCount'] && dataBuffer['updateRefinementCountsRefinementGroupCount'] > 1) {
			url = util.appendParamToURL(url, 'refinements', refinementIds.join());
		}

		$.ajax({
			type: 'get',
			url: url,
			success: function(data) {
				$refinements.each(function(index, element) {
					var $refinement = $(element);
					if (data) {
						var refinementId = $refinement.attr('refinement-id');
						var $refinementValues = $refinement.find('ul li[refinement-value]');
						if (data.Refinements[refinementId]) {
							$.each($refinementValues, function(index, element) {
								var $refinementValue = $(element);
								var refinementValue = $refinementValue.attr('refinement-value');
									
								if ($refinementValue.find('input[type="checkbox"]').prop('checked') || data.Refinements[refinementId][refinementValue]) {
									$refinementValue.attr('refinement-count', data.Refinements[refinementId][refinementValue]).find('.refinement-count').html(data.Refinements[refinementId][refinementValue]);
								} else {
									$refinementValue.attr('refinement-count', 0).find('.refinement-count').html('0');
								}
							});

							var $popularRefinementValues = $refinement.find('ul.popular-sizes, ul.popular').find('li[refinement-value]');
							var $popularRefinementValuesChecksContainer = $refinement.find('.checks-container.popular');
							var hasPopularRefinementValues = $popularRefinementValues.filter(':not([refinement-count="0"])').length > 0 || $popularRefinementValues.filter(':not([refinement-count="0"])').find('input:checked').length > 0;
							if (hasPopularRefinementValues) {
								$popularRefinementValuesChecksContainer.show();
							} else {
								$popularRefinementValuesChecksContainer.hide();
							}
							
							var $normalRefinementValues = $refinement.find('ul:not(.popular-sizes):not(.popular)').find('li[refinement-value]');
							var $normalRefinementValuesChecksContainer = $refinement.find('.checks-container:not(.popular)');
							var hasNormalRefinementValues = $normalRefinementValues.filter(':not([refinement-count="0"])').length > 0 || $normalRefinementValues.filter(':not([refinement-count="0"])').find('input:checked').length > 0;
							if (hasNormalRefinementValues) {
								$normalRefinementValuesChecksContainer.show();
							} else {
								$normalRefinementValuesChecksContainer.hide();
							}

							if (hasNormalRefinementValues || hasPopularRefinementValues) {
								$refinement.removeClass('hidden');
							} else {
								$refinement.addClass('hidden');
							}
						} else {
							$refinement.addClass('hidden');
						}
					} else {
						if ($refinement.find('ul.checks, ul.swatches').find('li').length == 0) {
							$refinement.addClass('hidden');
						} else {
							$refinement.removeClass('hidden');
						}
					}
					$refinement.removeAttr('loading');
					updateMoreLess($refinement);
				});
			},
			complete: function() {
				dataBuffer['updateRefinementCountsRefinementGroupIndex']++;
				if ($refinementProductCount.attr('loading')) {
					$refinementProductCount.loading('destroy');
				}
				updateRefinementCountsComplete();
			}
		});
	}
}

function updateRefinementCountsComplete() {
	if (dataBuffer['updateRefinementCountsRefinementGroupIndex'] == dataBuffer['updateRefinementCountsRefinementGroupCount']) {
		updateProductCountInHeader();
		updateRefinementsInHeader();
	}
}

function updateProductCountInHeader() {
	var $refinementsProductCount = $('.refinements .slot-grid-subheader .product-count span');
	var $headerProductCount = $('.top-banner .slot-grid-subheader .product-count span');

	if ($refinementsProductCount.length > 0 && $headerProductCount.length > 0) {
		$headerProductCount.text($refinementsProductCount.text());
	}
}

function updateRefinementsInHeader() {
	var $refinementsRefinements = $('.primary-content .product-selections');
	var $headerRefinements = $('.top-banner .product-selections');

	if ($refinementsRefinements.length > 0 && $headerRefinements.length > 0) {
		$headerRefinements.html($refinementsRefinements.html());
	}
}

function updateMoreLess($refinement) {
	var refinementId = $refinement.attr('refinement-id');
	var $refinementValuesContainer = $refinement.find('ul:not(.popular-sizes):not(.popular)');
	var $refinementContainer = $refinementValuesContainer.closest('.refinement-body-inner');
	var $moreLink = $refinementValuesContainer.find('.ref-filter.more-link').closest('li').detach().first();
	var $refinementValues = $refinementValuesContainer.find('li[refinement-value]');
	var $refinementValuesVisible = $refinementValues.filter(':not([refinement-count="0"])');

	if ($moreLink.length > 0) {
		var moreLessCount;
		if (pageContext.site == SiteConstants.SiteIds.BootBarnUS && $refinement.attr('refinement-id') == 'size') {
			moreLessCount = 12;
		} else if (pageContext.site == SiteConstants.SiteIds.BootBarnRspUS && options.StoreUIVersion == '2024Redesign') {
			moreLessCount = 100000;
		} else {
			moreLessCount = 9;
		}

		if ($refinementValuesVisible.length > moreLessCount) {
			var moreLessIndex = $refinementValuesVisible.index($refinementValuesVisible.eq(moreLessCount));
			$refinementValuesVisible.slice(0, moreLessIndex).removeClass('toggleable hidden');
			$refinementValuesVisible.slice(moreLessIndex, $refinementValues.length).addClass('toggleable hidden');
			$refinementValuesVisible.eq(moreLessIndex - 1).after($moreLink);
			$refinementValuesContainer.find('.more-link-container').show();
			$refinementContainer.find('.less-refinement').show();
		} else {
			$refinementValuesVisible.removeClass('toggleable hidden');
			$refinementValuesVisible.eq($refinementValuesVisible.length - 1).after($moreLink);
			$refinementValuesContainer.find('.more-link-container').hide();
			$refinementContainer.find('.less-refinement').hide();
		}
	}

	if (refinementsExpanded) {
		if (refinementsExpanded.indexOf(refinementId) > -1) {
			$refinement.addClass('expanded');
			$refinement.find('.toggle').addClass('expanded');
			$refinement.find('.toggle-content').show();
		} else {
			$refinement.removeClass('expanded');
			$refinement.find('.toggle').removeClass('expanded');
			$refinement.find('.toggle-content').hide();
		}
	}
	if ($('.refinement.expanded').length > 0) {
		$('.refinements').addClass('refinement-expanded');
	} else {
		$('.refinements').removeClass('refinement-expanded');
	}

	if (refinementsViewAll && $refinementValuesVisible.length > moreLessCount) {
		if (refinementsViewAll.indexOf(refinementId) > -1) {
			$refinement.addClass('more-expanded');
			$refinement.find('.toggleable').removeClass('hidden');
			$refinement.find('.more-link-container').css('display', 'none');
			$refinement.find('.less-refinement.toggleable').removeClass('hidden');
		} else {
			$refinement.removeClass('more-expanded');
			$refinement.find('.more-link-container').css('display', 'list-item');
			$refinement.find('.less-refinement.toggleable').addClass('hidden');
		}
	}
}

function getUrlVars() {
    var vars = [], hash;
    var hashes = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');
    for(var i = 0; i < hashes.length; i++)
	    {
	        hash = hashes[i].split('=');
	        vars.push(hash[0]);
	        vars[hash[0]] = hash[1];
	    }
    return vars;
}

var refGroupsStates = {
		cookieRefGroupsKey: 'refgroup',
		saveState: function(refgroup, visible) {
			if (this.getCookie('dw_cookies_accepted')) {
				var getRefGroupCookie = this.getCookie(this.cookieRefGroupsKey) || '{}';
				var getRefGroupObj = JSON.parse(getRefGroupCookie);
				getRefGroupObj[refgroup] = visible;
				getRefGroupCookie = JSON.stringify(getRefGroupObj);
				this.setCookie('refgroup', getRefGroupCookie);
			}
		},
		checkState: function(refgroup) {
			if (this.getCookie('dw_cookies_accepted')) {
				var getRefGroupCookie = this.getCookie(this.cookieRefGroupsKey);
				if (getRefGroupCookie) {
					var getRefGroupObj = JSON.parse(getRefGroupCookie);
					if (getRefGroupObj[refgroup] != undefined) {
						return getRefGroupObj[refgroup];
					}
				}
			}
			return true;
		},
		getCookie: function (key) {
		    var cookies = document.cookie.split(';');
		    for (var i = 0; i < cookies.length; i++) {
		        var tokens = cookies[i].split('=');
		        var cookieKey = tokens[0].trim();
		        if (cookieKey === key) {
		            return tokens[1];
		        }
		    }
		    return '';
		},
		setCookie: function(key, value, minutes) {
		    var date = new Date();
		    date.setTime(date + (minutes * 60 * 1000));
		    document.cookie = key + '=' + value + '; expires=' + date.toGMTString() + '; path=/';
		}
}

function searchStores() {
	var searchStoresQuery = $('.search-stores-query');

	$('.go-find-stores').on('click', function (e) {
		var el = $(e.target),
			data = {
				typeKey: el.attr('type')
			};

		if (el.attr('type') === 'geo') {
			if (navigator.geolocation) {
				navigator.geolocation.getCurrentPosition(function(position) {
					data.longitude = position.coords.longitude;
					data.latitude = position.coords.latitude;

					searchStoresAJAX(data);
				});
			} else {
				alert('Your browser not supported geolocation');
			}
		} else {
			data.searchKey = searchStoresQuery.val().trim();

			var geocoder = new google.maps.Geocoder();
			geocoder.geocode( { 'address': searchStoresQuery.val().trim()}, function(results, status) {
				if (status == google.maps.GeocoderStatus.OK) {
				  	data.longitude = results[0].geometry.location.lng();
				  	data.latitude = results[0].geometry.location.lat();

				  	searchStoresAJAX(data);
				} else {
					alert("Geocode was not successful for the following reason: " + status);
				}
		    });
		}
	});
}

function searchStoresAJAX(params) {
	$.ajax({
		type: 'get',
		url: Urls.searchStores,
		data: params,

		success: function (data) {
			var popupList = $('.category-popup-list-stores');

			$('.grid-page-storeselector-popup').dialog('close');
			popupList
				.html(data)
				.dialog({
					width: 450,
					autoOpen: false,
					modal: true,
					resizable: false
				});

			popupList.parent().find('.ui-dialog-title').html(data.match(/Stores near.{0,}/mi)[0].replace('</span>', ''));
			popupList.dialog('open');
		}
	});
}

function showVariantInventoryMessaging() {
  	if (options && options.Bopis && options.Bopis.IsLocationSearchFiltered) {
		return;
	}

  $.ajax({
      dataType: 'json',
      url: page.variantInventoryMessagingUrl,
      async: true
  })
  // success
  .done(function (values) {
	  if (values) {
		  values.forEach(function(val, idx) {
			  var $tile = $('[data-itemid="' + val.masterProductID + '"]');
			  if ($tile.length > 0) {
				  if ( typeof val.urlHash != 'undefined' ) {
					  var filterP = val.urlHash.replace('#','').split('=')[0];
					  var rr = new RegExp("prefn[0-9]="+filterP);
					  if ( location.href.match(rr) ) {
						  if (pageContext.site === SiteConstants.SiteIds.SheplersUS) {
							//Put the inventory message in the product badge if variantWhsInStock is false
							var productBadge = $tile.find('.product-badge.whs-in-stock');
							if (val.variantWhsInStock == 'true') {
								productBadge.show();
							} else if (val.dropshipLead <= 10) {
								productBadge.text(val.inventoryMsg);
								productBadge.removeClass('whs-in-stock').addClass('availability-msg').show();
							}

						  } else {
							var $msg = $('<div />').attr('class', 'availability-msg').text(val.inventoryMsg); 
							$tile.find('.product-pricing').append($msg);
							if (val.variantWhsInStock == 'true') {
								$tile.find('.whs-in-stock').show();
							}
						  }
					  }
				  }
			  }
		  });
	  }
  })
  .fail(function (xhr, textStatus) {
      if (textStatus === 'parsererror') {
          console.log('Inventory Messaging Error ' + Resources.BAD_RESPONSE);
      }
  });
}

function saveScrollPosition() {
	if (sessionStorage) {
		$(document).off('scroll.ScrollPositionSave').on('scroll.ScrollPositionSave', function(event) {
			var categoryId = $('.currCGID').val();
			var sessionStorageScrollPosition;
			try {
				sessionStorageScrollPosition = JSON.parse(sessionStorage.ScrollPosition);
			} catch(exception) {}
			if (!sessionStorageScrollPosition || typeof sessionStorageScrollPosition != 'object' || !sessionStorageScrollPosition[categoryId]) {
				sessionStorageScrollPosition = {};	
			}
			sessionStorageScrollPosition[categoryId] = $(window).scrollTop();
			sessionStorage.ScrollPosition = JSON.stringify(sessionStorageScrollPosition);
		});
	}
}

function loadScrollPosition() {
	if (sessionStorage) {
		var categoryId = $('.currCGID').val();
		var sessionStorageScrollPosition;
		try {
			sessionStorageScrollPosition = JSON.parse(sessionStorage.ScrollPosition);
		} catch(exception) {}
		if (sessionStorageScrollPosition && typeof sessionStorageScrollPosition == 'object' && sessionStorageScrollPosition[categoryId]) {
			$(window).scrollTop(sessionStorageScrollPosition[categoryId]);
		}
	}
}

function initializeStickyRefinements() {
	$(document).off('scroll.StickyRefinements StickyRefinements.Refresh').on('scroll.StickyRefinements StickyRefinements.Refresh', function(event) {
		var $refinements = $('#secondary');
		var $primary = $('#primary');

		if ($('html').hasClass('size-mobile') || window.innerWidth <= 767) {
			$refinements.css('transform', '');
			$refinements.scrollTop(0);
			$refinements.height('auto');
		} else {
			var documentScrollTop = parseInt($(document).scrollTop(), 10);
			var documentScrollDelta = 0;
			if (dataBuffer['documentScrollTop']) {
				documentScrollDelta = documentScrollTop - dataBuffer['documentScrollTop'];
			}
			var windowHeight = parseInt($(window).height(), 10);
			var windowBottom = documentScrollTop + windowHeight;
			var refinementsScrollHeight = parseInt($refinements[0].scrollHeight, 10);
			var refinementsOffsetTop = parseInt($refinements.offset().top, 10);
			var refinementsOffsetBottom = parseInt($refinements.offset().top + refinementsScrollHeight, 10);
			var primaryTop = parseInt($primary.offset().top, 10);
			var primaryBottom = primaryTop + parseInt($primary.outerHeight(), 10);
			var documentScrollTopFromPrimaryTop = documentScrollTop - primaryTop;
            if (documentScrollTopFromPrimaryTop < 0) {
                documentScrollTopFromPrimaryTop = 0;
            }
            var windowBottomFromPrimaryBottom = windowBottom - primaryBottom;
            if (windowBottomFromPrimaryBottom < 0) {
                windowBottomFromPrimaryBottom = 0;
            }
            var refinementsOffsetTopNew;
            var refinementsOffsetBottomNew;
            if (documentScrollDelta) {
                refinementsOffsetTopNew = refinementsOffsetTop + documentScrollDelta;
                refinementsOffsetBottomNew = refinementsOffsetTopNew + refinementsScrollHeight;
            }

			var refinementsTranslateY;
			if (documentScrollDelta >= 0) {
				refinementsTranslateY = refinementsOffsetTop - primaryTop;

				if ($refinements.hasClass('top')) {
					$('body').removeClass('sticky-refinements');
					$refinements.removeClass('top bottom');
					refinementsTranslateY = refinementsTranslateY - documentScrollDelta;
				}

				if (refinementsOffsetBottom >= primaryBottom) {
					$('body').removeClass('sticky-refinements');
					$refinements.removeClass('top bottom');
					refinementsTranslateY = primaryBottom - primaryTop - refinementsScrollHeight;
				} else if (refinementsOffsetBottom < windowBottom && primaryBottom >= windowBottom) {
					if (refinementsScrollHeight > windowHeight) {
						$('body').addClass('sticky-refinements');
						$refinements.removeClass('top bottom');
						$refinements.addClass('bottom');
					} else if (documentScrollTop >= primaryTop) {
						$('body').addClass('sticky-refinements');
						$refinements.removeClass('top bottom');
						$refinements.addClass('top');
					}
				} else if (refinementsOffsetBottom < windowBottom && primaryBottom < windowBottom) {
					if ((documentScrollTop + refinementsScrollHeight) > primaryBottom) {
						$('body').removeClass('sticky-refinements');
						$refinements.removeClass('top bottom');
						refinementsTranslateY = primaryBottom - primaryTop - refinementsScrollHeight;
					} else {
						$('body').addClass('sticky-refinements');
						$refinements.removeClass('top bottom');
						$refinements.addClass('top');
					}
				}
			} else if (documentScrollDelta < 0) {
				refinementsTranslateY = refinementsOffsetTop - primaryTop;

				if ($refinements.hasClass('bottom')) {
					$('body').removeClass('sticky-refinements');
					$refinements.removeClass('top bottom');
					refinementsTranslateY = refinementsTranslateY - documentScrollDelta;
				}

				if (refinementsOffsetTop <= primaryTop && documentScrollTop <= primaryTop) {
					$('body').removeClass('sticky-refinements');
					$refinements.removeClass('top bottom');
					refinementsTranslateY = 0;
				} else if (refinementsOffsetBottom >= primaryBottom && refinementsOffsetTop <= documentScrollTop) {
					$('body').removeClass('sticky-refinements');
					$refinements.removeClass('top bottom');
					refinementsTranslateY = primaryBottom - primaryTop - refinementsScrollHeight;
				} else if (refinementsOffsetTop >= documentScrollTop && refinementsOffsetTop > primaryTop) {
					$('body').addClass('sticky-refinements');
					$refinements.removeClass('top bottom');
					$refinements.addClass('top');
				}
			}

			if (refinementsTranslateY != undefined) {
				$refinements.css('transform', 'translateY(' + refinementsTranslateY + 'px)');
			}

            var refinementsTranslateYNew;
            if (refinementsOffsetBottom > primaryBottom) {
                $('body').removeClass('sticky-refinements');
                $refinements.removeClass('top bottom');
                refinementsTranslateYNew = primaryBottom - primaryTop - refinementsScrollHeight;
            } else if (refinementsOffsetTopNew < primaryTop) {
                $('body').removeClass('sticky-refinements');
                $refinements.removeClass('top bottom');
                refinementsTranslateYNew = 0;
            } else if (($refinements.offset().top + refinementsScrollHeight) < documentScrollTop) {
                if ((documentScrollTop + refinementsScrollHeight) > primaryBottom) {
                    $('body').removeClass('sticky-refinements');
                    $refinements.removeClass('top bottom');
                    refinementsTranslateYNew = primaryBottom - primaryTop - refinementsScrollHeight;
                } else if (primaryBottom > windowBottom) {
                    $('body').addClass('sticky-refinements');
                    $refinements.removeClass('top bottom');
                    $refinements.addClass('bottom');
                } else {
					$('body').addClass('sticky-refinements');
					$refinements.removeClass('top bottom');
					$refinements.addClass('top');					
				}
            }

            if (refinementsTranslateYNew != undefined) {
                $refinements.css('transform', 'translateY(' + refinementsTranslateYNew + 'px)');
            }

			dataBuffer['documentScrollTop'] = documentScrollTop;
		}
	});
}

function initializeStickySubheader() {
	$(document).off('scroll.StickySubheader').on('scroll.StickySubheader', function(event) {
		var $slotGridSubheader = $('.slot-grid-subheader');
		var $slotGridSubheaderFixed = $('.slot-grid-subheader-fixed');
		if ($slotGridSubheader.length > 0 && $slotGridSubheaderFixed.length > 0) {
			var $primary = $('#primary');
			var documentScrollTop = $(document).scrollTop();
			var windowHeight = $(window).height();
			var windowBottom = documentScrollTop + windowHeight;
			var primaryTop = $primary.offset().top;
			var primaryBottom = primaryTop + $primary.outerHeight();

			if (primaryBottom < windowBottom) {
				$slotGridSubheaderFixed.addClass('hidden');
			} else if ($slotGridSubheader.offset().top < documentScrollTop) {
				$slotGridSubheaderFixed.removeClass('hidden');
			} else {
				$slotGridSubheaderFixed.addClass('hidden');
			}
		}
	});
}

function initializeRefinementFilterByText() {
	$(document).off('keyup.RefinementFilterText').on('keyup.RefinementFilterText', '.refinement .refinement-filter-text input', function(event) {
		var $this = $(this);
		var filterText = $this.val();
		var filterTextRegEx;
		var $container = $this.closest('.refinement-filter-text');
		var $reset = $container.find('a.reset');
		var $noResults = $container.find('.no-results');
		var $refinement = $this.closest('.refinement');
		var $refinementValues = $this.closest('.checks-container').find('[refinement-value]:not([refinement-count="0"])');

		if (filterText) {
			filterTextRegEx = new RegExp('^' + filterText + '| ' + filterText, 'gi');
			$reset.addClass('show');
			$refinement.addClass('filter-text');
			$refinementValues.each(function(index, element) {
				var $refinementValue = $(this);
				if ($refinementValue.attr('refinement-value').match(filterTextRegEx)) {
					$refinementValue.addClass('filter-text-show');
				} else {
					$refinementValue.removeClass('filter-text-show').hide();
				}
			});
			if ($refinementValues.filter(':visible').length == 0) {
				$noResults.find('span').text(filterText);
				$noResults.show();
			} else {
				$noResults.hide();
			}
		} else {
			$reset.removeClass('show');
			$refinement.removeClass('filter-text');
			$refinementValues.removeClass('filter-text-show').css('display', '');
			$noResults.hide();
		}
	});

	$(document).off('click.RefinementFilterTextReset').on('click.RefinementFilterTextReset', '.refinement .refinement-filter-text a.reset', function(event) {
		var $this = $(this);
		var $container = $this.closest('.refinement-filter-text');
		var $noResults = $container.find('.no-results');
		var $refinement = $this.closest('.refinement');
		var $refinementValues = $this.closest('.checks-container').find('[refinement-value]');

		$this.removeClass('show');
		$refinement.removeClass('filter-text');
		$refinement.find('.refinement-filter-text input').val('');
		$refinementValues.removeClass('filter-text-show').css('display', '');
		$noResults.hide();
	});
}

function saveExpandedRefinements() {
	refinementsExpanded = $('.refinements [refinement-id].expanded').map(function(index, element) {
		return $(this).attr('refinement-id');
	}).get();

	refinementsViewAll = $('.refinements [refinement-id].more-expanded').map(function(index, element) {
		return $(this).attr('refinement-id');
	}).get();
}

function redrawShopMyStore() {
	$.ajax({
		method: 'GET',
		url: util.appendParamToURL(Urls.Stores.GetShopMyStorePanel + location.search, 'cgid', $('.currCGID').val()),
		dataType: 'html',
		success: function(data) {
			$('.shop-my-store').replaceWith(data);
			updateShopMyStore();
		}
	});
}

function updateShopMyStore() {
	if ($('.shop-my-store-toggle input:checked').length > 0) {
		$('body').attr('is-shop-my-store-active', true);
	} else {
		$('body').attr('is-shop-my-store-active', false);
	}
}

function initializeRangefinderEvents() {
	$(document).off('click.RefinementFilterTextReset').on('click.RefinementFilterTextReset', '.product-tile a', function(event) {
		event.preventDefault();

		var $this = $(this);
		var $product = $this.closest('[data-itemid]');
		if ($product.length > 0) {
			$('body').loading(loadingIndicatorSettings);
			dialog.open({
                url: util.appendParamToURL(Urls.productDetail, 'pid', $product.attr('data-itemid')),
				callback: function() {
					$('body').loading('destroy');
				}
            });
		}
	});
}

function initializeBootFinderEvents() {
	var $bootFinderResultsBanner = $('.bootfinder-resultsbanner');
	if ($bootFinderResultsBanner && $bootFinderResultsBanner.length) {
		$bootFinderEmailForm = $bootFinderResultsBanner.find('form.email');
		bootFinderEmailValidator = $bootFinderEmailForm.validate(ValidationSettings.Global);
		bootFinderEmailValidator.settings.onfocusout = ValidationSettings.Global.onfocusout;
		bootFinderEmailValidator.settings.errorPlacement = ValidationSettings.Global.errorPlacement;
		$bootFinderEmailForm.find('input[name="email"]').rules('add', $.extend(ValidationRules.Email, {
			required: true,
			messages: {
				required: $.validator.format('Please enter an email address.')
			}
		}));
		$bootFinderEmailForm.find('input[name="useragreement"]').rules('add', {
			required: true,
			messages: {
				required: $.validator.format('Please agree to the terms and conditions.')
			}
		});

		$(document).off('click.BootFinderResultsEmail').on('click.BootFinderResultsEmail', '.bootfinder-resultsbanner .btn.send-results-email', function(event) {
			event.preventDefault();

			if ($bootFinderEmailForm && $bootFinderEmailForm.valid()) {
				var $this = $(this);
				var $container = $this.closest('form');
				$container.loading($.extend(loadingIndicatorSettings, {
					IsLabelShown: true,
					Content: 'Sending Email...'
				}));
				var urlParameters = util.getQueryStringParams(window.location.href);
				var bootFinderSendResultsEmailUrl = Urls.BootFinder.SendResultsEmail;
				bootFinderSendResultsEmailUrl = util.appendParamToURL(bootFinderSendResultsEmailUrl, 'cgid', $('.currCGID').val());
				for (var urlParameter in urlParameters) {
					if (urlParameters[urlParameter]) {
						bootFinderSendResultsEmailUrl = util.appendParamToURL(bootFinderSendResultsEmailUrl, urlParameter, urlParameters[urlParameter]);
					}
				}
				var payload = {
					email: $container.find('input[type="email"]').val(),
					url: util.removeParamFromURL(window.location.href, 'bootfinderviewresults')
				}
				$.ajax({
					type: 'post',
					url: bootFinderSendResultsEmailUrl,
					data: payload,
					success: function(data) {
						$container.loading('destroy');
						if (data && data.success) {
							$container.find('.validation-error-container').html('<span class="error">Email sent!</span>');
						} else {
							$container.find('.validation-error-container').html('<span class="error">An error occurred sending the email. Please try again.</span>');
						}
					}
				});
			}
		});
	}
}

exports.init = function (optionsParameter) {
	options = optionsParameter || {};

	ImageConstants = pageContext.site === SiteConstants.SiteIds.CountryOutfittersUS ? require('../../../../app_storefront_countryoutfitters/cartridge/js/ImageConstants') : require('../../../../bc_sheplersbootbarn_core/cartridge/js/ImageConstants');
	
    compareWidget.init();
    if ('variantInventoryMessagingUrl' in page && page.variantInventoryMessagingUrl) {
    	var urlParams = util.getQueryStringParams(window.location.search.substr(1));
    	if(urlParams.hasOwnProperty('start') && urlParams.start > 0){
    		updateInventoryMessages(window.location.href, showVariantInventoryMessaging);
    	} else {
            showVariantInventoryMessaging();
    	}
    }

    productTile.init();
    sizeRefinements.init();
    initializeEvents();
	updateShopMyStore();
	sliders.initializeCategorySliders('.main-content .category-landing-slider:not(.slick-initialized)');
	initializeRefinementFilterByText();
	if (pageContext.site == SiteConstants.SiteIds.BootBarnUS) {
		initializeBootFinderEvents();
	}
	loadScrollPosition();
	saveScrollPosition();
	if (pageContext.site == SiteConstants.SiteIds.CountryOutfittersUS) {
		initializeStickyRefinements();
		// initializeStickySubheader();
	}

	loadingIndicatorSettings = {
		IsLabelShown: false,
		CssClass: 'fullscreen',
		BackgroundColor: 'transparent'
	};

	if (pageContext.site == SiteConstants.SiteIds.Rangefinder) {
		initializeRangefinderEvents();
	}

	if (options) {
		if (options.StyleCountCache) {
			styleCounts.render(options.StyleCountCache);
		}

		if (options.Bopis && options.Bopis.Enabled && options.Bopis.SelectedLocations) {
			bootBarnExpressRefinementWizard.initialize($('#dialog-bootbarnexpress-refinement-wizard'), options);
		}

		if (options.RefinementsUIVersion == RefinementUIVersionConstants.Updated) {
			updateRefinementCounts();
			if (options.StoreUIVersion == '2024Redesign') {
				updateSelectedRefinements();
				updatePendingRefinements();
			}
		
			var $productSelectionContainer;
			if (pageContext.site === SiteConstants.SiteIds.SheplersUS) {
				$productSelectionContainer = $('.primary-content > .sort-and-refine > .product-selections');
			} else {
				$productSelectionContainer = $('.primary-content > .product-selections')
			}

			if ($productSelectionContainer.find('.list-selections .value.applied').length > 0) {
				$productSelectionContainer.show();
			} else {
				$productSelectionContainer.hide();
			}
		}
	}
};
