'use strict';

var imagesLoaded = require('imagesloaded');
var util = require('./util');

exports.syncHeightOfTiles = syncHeightOfTiles;

var windowWidth;

function gridViewToggle() {
    $('.toggle-grid').on('click', function () {
        $('.search-result-content').toggleClass('wide-tiles');
        $(this).toggleClass('wide');
    });
}

/**
 * @private
 * @function
 * @description line clamping for product tile product titles.
 */

 function clampProductTileTitles() {
    $('h6.product-name .name-link').each(function(index, clampTitle) {
        $clamp(clampTitle, {
            clamp: 2,
            useNativeCLamp: false
        });
    });
 }
 
 function initializeSwatches() {
	 $('.grid-tile').each(function(index, element) {
		$(element).find('.yes-alts ul.swatch-list li').each(function(i, elem) {
			var altDisplay = 'show-content';
			if ( i >= 4 ){
				altDisplay = 'hidden-content';
			}
			$(elem).addClass(altDisplay);
		});
		var altHiddenCount = $(element).find('.hidden-content').length;
		var altHiddenToggle = '+ ' + altHiddenCount;
		var altButtonExists = $(element).find('.altButton').length;
		if (altHiddenCount > 0){
			$(element).find('.hidden-content').hide();
			if (altButtonExists == 0){
				$(element).find('.yes-alts ul.swatch-list').append('<button class="altButton">' + altHiddenToggle + '</button>');
			} else {
				$(element).find('.altButton').text(altHiddenToggle);
			}
		}
	});
 }

/**
 * @private
 * @function
 * @description Initializes events on the product-tile for the following elements:
 * - swatches
 * - thumbnails
 */
function initializeEvents() {
    gridViewToggle();
    initializeSwatches();
    
    $(document).ready(function() {
    	$('#search-result-items .grid-tile').each(function() {
    		var $gridItem = $(this),
    			$primaryColor = $gridItem.data('primary-color'),
    			$thumb = $gridItem.find('.product-image .thumb-link img').eq(0),
    			$thumblink = $gridItem.find('.product-image a.thumb-link').eq(0),
    			$namelink = $gridItem.find('.product-content .product-name a.name-link').eq(0);
    		$gridItem.find('.product-content .product-swatches .swatch-list li').each(function() {
    			var $currswatch = $(this),
    				$currhref = $currswatch.find('a.swatch').attr('href'),
    				$currcolor = $currswatch.data('color');
    			if ($primaryColor == $currcolor) {
    				var $data = $currswatch.find('.swatch-image').data('thumb');
    				
    				if ($thumblink.attr('selected-locations')) {
    					$currhref = util.appendParamToURL($currhref, 'selectedLocations', $thumblink.attr('selected-locations'));
    				}
    				
    				$namelink.attr('href', $currhref);
    				$thumblink.attr('href', $currhref);
    				
					if ($data) {
						$thumb.attr({
							src: $data.src,
							alt: $data.alt,
							title: $data.title
						});
					}
    			}
    		})
    	});

		$(window).off('load.ProductTile resize.ProductTile').on('load.ProductTile resize.ProductTile', function () {
            if (windowWidth != $(window).width()) {
			    syncHeightOfTiles();
            }
		});

		imagesLoaded($('#search-result-items .grid-tile')).on('done', function () {
			syncHeightOfTiles();
        });
    });
    
	$('.swatch-list .swatch').on('click', function (event) {
		event.preventDefault();

        // get current thumb details
        var $tile = $(this).closest('.product-tile'),
            $thumb = $tile.find('.product-image .thumb-link img').eq(0),
            data = $(this).children('.swatch-image').filter(':first').data('thumb'),
            current = $thumb.data('current');

        // If this is the first time, then record the current img
        if (!current) {
            $thumb.data('current', {
                src: $thumb[0].src,
                alt: $thumb[0].alt,
                title: $thumb[0].title
            });
        }

        // Set the tile image to the values provided on the swatch data attributes
		if (data) {
			$thumb.attr({
				src: data.src,
				alt: data.alt,
				title: data.title
			});
		}
    });
	
    // check every 2 secs if BV stars has already loaded
    // in case BV is just delayed in showing up, we need to check the interval for at least 5x to prevent infinite loop
    var hideStars = setInterval(function(){
    	var ctr = 0;
    	$("div[id^=BVRRInlineRating]").each(function () {
    		if ($(this).find(".bv-rating-stars-on").width() > 0) { } else { $(this).find(".bv-stars-container").attr("style", "display:none !important"); }
    		if ($(this).find(".bv-rating-stars-on").length) {
    			clearInterval(hideStars);
    		} else { ctr++;	}
    	});
    	if (ctr>10) { clearInterval(hideStars); } //we need to stop the function if BV is not configured.
    }, 2000);

    var attemptsToChangeText = 0;
    var addTextReviewsToCountReviews = setInterval(function(){
    	attemptsToChangeText++;
    	$('div[id^=BVRRInlineRating]').each(function(){
    		var $rating = $(this).find('span.bv-rating-label');
    		if ($rating.length) {
    			var text = $rating.html();
        		if (text.indexOf(Resources.REVIEWS) < 0) {
        			$rating.html(text.replace(')', ' ' + Resources.REVIEWS + ')'));
        			clearInterval(addTextReviewsToCountReviews);
        		}
    		}
    	});
    	if (attemptsToChangeText > 15) {
    		clearInterval(addTextReviewsToCountReviews);
    	}
    }, 500);
    
    $(document).on('click', 'div.bv-compat', function(){
        var $tileBlock = $(this).closest('div.product-tile');
        if ($tileBlock) {
            location.href = $tileBlock.find('a.thumb-link').attr('href');
        }
    });

    clampProductTileTitles();
}

function syncHeightOfTiles() {
	var $tiles = $('.tiles-container .product-tile');
    if ($tiles.length === 0) { return; }

	$tiles.find('.product-image .thumb-link').syncHeightToAverage();
	$tiles.each(function (idx) {
		$(this).data('idx', idx);
	});
}

exports.init = function () {
    windowWidth = $(window).width();

    initializeEvents();
};
