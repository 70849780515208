'use strict';

var SiteConstants = require('../../../../bc_sheplersbootbarn_core/cartridge/js/SiteConstants');
var sizeRefinements = require('../size-refinements');
var sliders = require('../sliders');
var styleCounts = require('../style-counts');
var util = require('../util');

exports.init = function(options) {
	styleCounts.render(options.StyleCountCache);
	sizeRefinements.init();
	sliders.initializeCategorySliders('.main-content .category-landing-slider:not(.slick-initialized)');
};