'use strict';

var loadingIndicator = require('./LoadingIndicator');

exports.initialize = initialize;

var options;
var dataBuffer;
var loadingIndicatorSettings = {
    IsLabelShown: false,
    CssClass: 'fullscreen',
    BackgroundColor: 'transparent'
};

function initialize(optionsParameter) {
	options = $.extend({
		successCallback: null,
		errorCallback: null
	}, optionsParameter);
	dataBuffer = {
		initialized: false
	};

	$(document).off('click.GlobaleInitialize').on('click.GlobaleInitialize', '.country-selector', function(event) {
		if ($('div#globale-script-loader-data').length > 0 && !dataBuffer.initialized) {
			dataBuffer.initialized = true;
			event.preventDefault();
			$('body').loading(loadingIndicatorSettings);
			$('body').append('<script type="text/javascript" id="globale-script-loader" src="' + Urls.Globale.ScriptLoader + '"></script>');
		}
	});

	$(document).off('Globale.Initialized').on('Globale.Initialized', function(event) {
		if (dataBuffer.initialized && $('.pt_checkout_globale').length == 0) {
			dataBuffer.showShippingSwitcherInterval = setInterval(showShippingSwitcher, 250);
		}
	});
}

function showShippingSwitcher() {
	if (GlobalE && GlobalE.ShippingSwitcher && GlobalE.ShippingSwitcher.Show && typeof GlobalE.ShippingSwitcher.Show == 'function') {
		if ($('body').attr('loading')) {
			$('body').loading('destroy');
		}
		clearInterval(dataBuffer.showShippingSwitcherInterval);
		GlobalE.ShippingSwitcher.Show();
	}
}