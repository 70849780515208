'use strict';

var _ = require('lodash');
var addToCartOptionsStoreSearch = require('./AddToCartOptionsStoreSearch');
var dialog = require('../../dialog');
var GoogleTagManagerClient = require('../../../../../bc_sheplersbootbarn_core/cartridge/js/util/GoogleTagManagerClient');
var loadingIndicator = require('../../components/LoadingIndicator');
var TemplateConstants = require('../../../../../bc_sheplersbootbarn_core/cartridge/js/TemplateConstants');
var tooltip = require('../../tooltip');
var ValidationSettings = require('../../../../../bc_sheplersbootbarn_core/cartridge/js/ValidationConstants').Settings;
var ValidationRules = require('../../../../../bc_sheplersbootbarn_core/cartridge/js/ValidationConstants').Rules;
var ValidationHelper = require('../../../../../bc_sheplersbootbarn_core/cartridge/scripts/util/ValidationHelper');
var ZipCodeToStateMap = require('../../../../../bc_sheplersbootbarn_core/cartridge/data/ZipCodeToStateMap.json');

exports.initialize = initialize;
exports.remove = remove;

var options;
var optionsLocalDelivery;
var validator;
var dataBuffer;
var localDeliveryStoreSearch;
var localDeliveryRadius;

function initialize(optionsParameter, selectedLocation, localDeliveryFormData) {
	dataBuffer = {};
	options = $.extend({
		successCallback: null,
		errorCallback: null
	}, optionsParameter);

	if (selectedLocation && selectedLocation.location && selectedLocation.location.local_delivery_vendor && selectedLocation.location.local_delivery_vendor == 'GoLocal') {
		localDeliveryRadius = options.LocalDelivery.RadiusForGoLocal;
	} else if (selectedLocation && selectedLocation.location && selectedLocation.location.local_delivery_vendor && selectedLocation.location.local_delivery_vendor == 'Roadie') {
		localDeliveryRadius = options.LocalDelivery.RadiusForRoadie;
	}

	buildAddressHtmlForLocation(selectedLocation.location);
	$('#dialog-bopis-options').find('.delivery-radius').html(localDeliveryRadius);
	var $optionalMessage = $('#dialog-bopis-options header .optional-message');
	
	if (options.OptionalMessage) {
		if ($optionalMessage.length == 0) {
			$('#dialog-bopis-options header').append('<p class="optional-message">' + options.OptionalMessage + '</p>')
		}
	} else if ($optionalMessage.length > 0) {
		$optionalMessage.remove();
	}
	
	localDeliveryStoreSearch = new addToCartOptionsStoreSearch();
	optionsLocalDelivery = Object.assign(options, {
		LocalDeliveryOnly: true,
		Events: {
			SelectStore: 'Bopis.StoreSearchSelectLocalDelivery'
		},
		Functions: {
			Close: function() {
				$('.dialog-bopis-options').removeClass('hidden');
				$('.ui-widget-overlay').removeClass('hidden');
				$('.bopis-local-delivery-store-search-container').removeClass('show');
			}
		}
	});

	GoogleTagManagerClient.PushDataLayerForEvent('roadieFormDisplay');
	dialog.open({
		html: $('#dialog-bopis-options').html(),
		options: {
			dialogClass: 'dialog-bopis-options',
			width: 320,
			draggable: false,
			resizable: false,
			open: function(event, ui) {
				var $this = $(this);
				var $addressForm = $this.find('form.bopis-delivery-address');
				appendCSRFToken($addressForm);
				
				if (localDeliveryFormData) {
					$addressForm.find('input[name="email"]').val(localDeliveryFormData.Email || '');
					$addressForm.find('input[name="phone"]').val(localDeliveryFormData.Phone || '');
					$addressForm.find('input[name="firstName"]').val(localDeliveryFormData.FirstName || '');
					$addressForm.find('input[name="lastName"]').val(localDeliveryFormData.LastName || '');
					$addressForm.find('input[name="address1"]').val(localDeliveryFormData.Address1 || '');
					$addressForm.find('input[name="address2"]').val(localDeliveryFormData.Address2 || '');
					$addressForm.find('input[name="address3"]').val(localDeliveryFormData.Address3 || '');
					$addressForm.find('input[name="city"]').val(localDeliveryFormData.City || '');
					$addressForm.find('select[name="stateCode"] option[value="' + localDeliveryFormData.State + '"]').prop('selected', true);
					$addressForm.find('input[name="postalCode"]').val(localDeliveryFormData.PostalCode || '');
					$addressForm.find('textarea[name="deliveryNote"]').val(localDeliveryFormData.DeliveryNote || '');
				}

				var addressValidator = $addressForm.validate(ValidationSettings.Global);
				$.validator.addMethod('requiredExcludingPunctuation', ValidationHelper.ValidateRequiredExcludingPunctuation, Resources.INVALID_VALUE);
				$.validator.addMethod('postOfficeBox', ValidationHelper.ValidateAddressForPostOfficeBox, Resources.INVALID_ADDRESS_POBOX);
				$.validator.addMethod('zipCode', ValidationHelper.ValidateZipCode, Resources.INVALID_POSTAL_CODE);
				$.validator.addMethod('stateCodeForCaliforniaStateLine', ValidationHelper.ValidateStateCodeForCaliforniaStateLine, Resources.INVALID_CALIFORNIA_STATE_LINE_LOCAL_DELIVERY);
				
				$addressForm.find('input[name="address1"]').rules('add', ValidationRules.ValidateAddressForPostOfficeBox);
				$addressForm.find('input[name="address2"]').rules('add', ValidationRules.ValidateAddressForPostOfficeBox);
				$addressForm.find('input[name="address3"]').rules('add', ValidationRules.ValidateAddressForPostOfficeBox);
				$addressForm.find('select[name="stateCode"]').rules('add', ValidationRules.StateCodeForCaliforniaStateLine);
			    $addressForm.find('input[name="postalCode"]').rules('add', ValidationRules.ZipCode);
				$addressForm.find('input.required, select.required').rules('add', ValidationRules.RequiredExcludingPunctuation);
				$addressForm.find('input[name="email"]').rules('add', ValidationRules.Email);
				$this.attr('local-delivery-radius', isLocalDeliveryValid(selectedLocation));
				$this.attr('store-state', selectedLocation.location.postal_address.province_or_state);
				
				$this.off('click.SelectDeliveryOption').on('click.SelectDeliveryOption', '.delivery-option label', function (event) {
					var deliveryOption = $(this).find('input').val();

					if (deliveryOption == 'pickup') {
						$this.find('.address-form').hide();
					} else if (deliveryOption == 'delivery') {
						$this.find('.address-form').show();
					}
				});

				$this.off('click.Apply').on('click.Apply', '.btn.bopis-option:not([loading])', function (event) {
					event.preventDefault();

					$this.find('.ui-dialog-footer .error').remove();

					var selectedBopisOption;
					if (options && options.Mode == 'AddToCartOptions') {
						selectedBopisOption = 'delivery';
					} else {
						selectedBopisOption = $this.find('input[name="bopis-option"]:checked').val();
					}
					var eventData = {bopis: true, bopisStore: selectedLocation.location.code, shippingOption: selectedBopisOption, channel: options.Channel, quantityAvailable: selectedLocation.detail.quantity};
					if (selectedBopisOption == 'delivery') {
						var deliveryAddressObject = {};
						var $deliveryAddressForm = $this.find('.bopis-delivery-address');
						var deliveryAddressArray = $deliveryAddressForm.serializeArray();
						$(deliveryAddressArray).each(function(index, element) {
							if (element.value != 'disabled' && $deliveryAddressForm.find('[name="' + element.name + '"]').attr('type') != 'hidden') {
								deliveryAddressObject[element.name] = element.value;
							}
						});
						eventData.deliveryAddress = JSON.stringify(deliveryAddressObject);
						$deliveryAddressForm.find('input[type="hidden"]').each(function () {
							var $this = $(this);
							eventData[$this.attr('name')] = $this.val();
						});
					}

					if (selectedBopisOption != 'delivery' || $addressForm.valid()) {
						if (selectedBopisOption == 'delivery') {
							$.ajax({
					            method: 'POST',
					            url: Urls.Bopis.ValidatePostalCodeForLocations,
					            contentType: 'application/json',
					            data: JSON.stringify({
					                PostalCode: $addressForm.find('input[name="postalCode"]').val(),
					                Locations: [selectedLocation.location.code]
					            }),
					            dataType: 'json',
					            success: function(data) {
					            	if (data) {
										GoogleTagManagerClient.PushDataLayerForEvent('roadieFormSubmit');
					            		$(document).trigger('ProductDetailPage.Bopis.AddToCart', eventData);
					            	} else {
					            		var errorMessage = '<div class="error">The zip code you entered is different than the one you searched with, and the selected location is not in range. Please update your address and try again.</div>'

					            		$this.find('.ui-dialog-footer').prepend(errorMessage);
					            	}
					            }
							});
						} else {
							$(document).trigger('ProductDetailPage.Bopis.AddToCart', eventData);
						}
					}
				});

				$this.off('click.ChangeZip').on('click.ChangeZip', '.btn.change-zip', function (event) {
					$(document).trigger('Bopis.StoreSearch.Reset');
					dialog.close();
				});

				$this.off('click.ChangeStore').on('click.ChangeStore', '.btn.change-store', function (event) {
					dialog.close();
				});

				$this.off('click.SearchStore').on('click.SearchStore', '.search-store', function (event) {
					event.preventDefault();
					event.stopImmediatePropagation();

					$('.dialog-bopis-options').addClass('hidden');
					$('.ui-widget-overlay').addClass('hidden');
					
					localDeliveryStoreSearch.initialize($('.bopis-local-delivery-store-search-container'), optionsLocalDelivery);
				});
            },
            close: function(event, ui) {

            }
		}
	});

	$(document).off('Bopis.StoreSearchSelectLocalDelivery').on('Bopis.StoreSearchSelectLocalDelivery', function (event, store) {
		buildAddressHtmlForLocation(store.location);
		$('.dialog-bopis-options').removeClass('hidden');
		$('.ui-widget-overlay').removeClass('hidden');
		$('.bopis-local-delivery-store-search-container').removeClass('show');
		$('.dialog-bopis-options').find('.dialog-content').attr('local-delivery-radius', isLocalDeliveryValid(store));
		$('.dialog-bopis-options').find('.dialog-content').attr('store-state', store.location.postal_address.province_or_state);
	});
}

function remove() {
	validator.destroy();
	$('.product-add-to-cart button').removeAttr('disabled').removeClass('hidden');
}

function buildAddressHtmlForLocation(location) {
	var locationHtml;
	if (options && options.Mode == 'AddToCartOptions') {
		locationHtml = '<p class="selected-location-name">' + location.postal_address.name + '</p>' + 
								'<p class="selected-location-address">' + location.postal_address.address_line1 + '<br/>' +
								location.postal_address.city + ', ' + location.postal_address.province_or_state + '</p>' +
								'<a class="search-store">Change Store</a>';
	} else {
		locationHtml = location.postal_address.address_line1 + '<br/>' +
		location.postal_address.city + ', ' + location.postal_address.province_or_state;
	}

	$('.ui-dialog.dialog-bopis-options').find('.selected-location-details').html(locationHtml);
	$('#dialog-bopis-options').find('.selected-location-details').html(locationHtml);
}

function isLocalDeliveryValid(record) {
	if (options.LocalDelivery && options.LocalDelivery.Enabled && isLocationInLocalDeliveryRadius(record.location) && record.location.local_delivery_enabled && record.detail && record.detail.quantity > 0) {
		return true;
	}

	return false;
}

function isLocationInLocalDeliveryRadius(location) {
	if (localDeliveryRadius && location.distance && localDeliveryRadius >= location.distance) {
		return true;
	}

	return false;
}

function appendCSRFToken($form) {
	$.ajax({
		method: 'get',
		url: Urls.CSRFToken.Get,
		dataType: 'json',
		success: function(response) {
			$form.append('<input type="hidden" name="' + response.name + '" value="' + response.token + '"/>');
		}
	});
}