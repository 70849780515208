'use strict';

/**
 * @function
 * @description Initializes the alert widget
 */
exports.init = function(options) {
    var type = options.type ? options.type : 'alert-primary';
    var timer = options.timer ? options.timer : 5000;
    var alertElement = '<div class="alert ' + type + '"><div class="alert-content">' + options.content + '</div></div>';

    $('body').append(alertElement).fadeIn('slow');
    var alertTimer = setTimeout(function() {
        $('.alert').fadeOut('slow').remove();
    }, timer);

    $('body').on('click', '.alert', function() {
        $(this).fadeOut('slow').remove();
    });
};
