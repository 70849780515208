'use strict';

exports.initialize = initialize;

var applePaySession;
var options = {};
var buffer = {};

function initialize(optionsParameter) {
	options = optionsParameter || {};

	$('[payment-method-container="DW_APPLE_PAY"]').attr('can-make-payments', 'ApplePaySession' in window && 'canMakePayments' in ApplePaySession && ApplePaySession.canMakePayments());
	$('[payment-method-group-container="DW_APPLE_PAY"]').attr('can-make-payments', 'ApplePaySession' in window && 'canMakePayments' in ApplePaySession && ApplePaySession.canMakePayments());
	
	getApplePayPaymentRequest();
	$(document).off('ApplePay.GetPaymentRequest').on('ApplePay.GetPaymentRequest Cart.UpdateSuccess', function(event) {
		getApplePayPaymentRequest();
	});

	$(document).off('click.ApplePay').on('click.ApplePay', '.submit-applepay-button', function(event) {
		applePaySession = new ApplePaySession(1, buffer['applePayRequest']);
		applePaySession.onvalidatemerchant = validateMerchant;
		applePaySession.onshippingcontactselected = shippingContactSelected;
		applePaySession.onshippingmethodselected = shippingMethodSelected;
		applePaySession.onpaymentmethodselected = paymentMethodSelected;
		applePaySession.oncouponcodechanged = couponCodeChanged;
		applePaySession.onpaymentauthorized = paymentAuthorized;
		applePaySession.oncancel = cancel;
		applePaySession.begin();
	});
}

function validateMerchant(event) {
	fetch(Urls.ApplePay.ValidateMerchant, getFetchOptions('POST', event))
	.then(function(response) { 
		return response.json();
	})
	.then(function(response) {
		applePaySession.completeMerchantValidation(response);
	})
	.catch(function(error) {
		console.error('ApplePay-ValidateMerchant', error);
	});
}

function shippingContactSelected(event) {
	fetch(Urls.ApplePay.ShippingContactSelected, getFetchOptions('POST', event))
	.then(function(response) { 
		return response.json();
	})
	.then(function(response) {
		applePaySession.completeShippingContactSelection(response);
		$(document).trigger('Cart.Update');
	})
	.catch(function(error) {
		console.error('ApplePay-ShippingContactSelected', error);
	});
}

function shippingMethodSelected(event) {
	fetch(Urls.ApplePay.ShippingMethodSelected, getFetchOptions('POST', event))
	.then(function(response) { 
		return response.json();
	})
	.then(function(response) {
		applePaySession.completeShippingMethodSelection(response);
		$(document).trigger('Cart.Update');
	})
	.catch(function(error) {
		console.error('ApplePay-ShippingMethodSelected', error);
	});
}

function paymentMethodSelected(event) {
	fetch(Urls.ApplePay.PaymentMethodSelected, getFetchOptions('POST', event))
	.then(function(response) { 
		return response.json();
	})
	.then(function(response) {
		applePaySession.completePaymentMethodSelection(response);
	})
	.catch(function(error) {
		console.error('ApplePay-PaymentMethodSelected', error);
	});
}

function couponCodeChanged(event) {
	fetch(Urls.ApplePay.CouponCodeChanged)
	.then(function(response) { 
		return response.json();
	})
	.then(function(response) {
		applePaySession.completeCouponCodeChange(response);
	})
	.catch(function(error) {
		console.error('ApplePay-CouponCodeChanged', error);
	});
}

function paymentAuthorized(event) {
	fetch(Urls.ApplePay.PaymentAuthorized, getFetchOptions('POST', event))
	.then(function(response) { 
		return response.json();
	})
	.then(function(response) {
		applePaySession.completePayment(response);
	})
	.then(function(response) {
		if (event.payment) {
			if (event.payment.billingContact) {
				var billingAddressObject = {};
				billingAddressObject.FirstName = event.payment.billingContact.givenName;
				billingAddressObject.LastName = event.payment.billingContact.familyName;
				billingAddressObject.PrimaryLine = event.payment.billingContact.addressLines[0] || '';
				billingAddressObject.SecondaryLine = event.payment.billingContact.addressLines[1] || '';
				billingAddressObject.StateCode = event.payment.billingContact.administrativeArea;
				billingAddressObject.PostalCode = event.payment.billingContact.postalCode;
				billingAddressObject.City = event.payment.billingContact.locality;

				if (event.payment.shippingContact && event.payment.shippingContact.phone) {
					billingAddressObject.Phone = event.payment.shippingContact.phone.replace('+','');
				}

				if (options.isCheckoutPage) {
					$(document).trigger('SetAddressFromAddressVerification', $.extend(billingAddressObject, { AddressType: 'billing' }));
				}
			}

			if (event.payment.shippingContact) {
				var shippingAddressObject = {};
				shippingAddressObject.FirstName = event.payment.shippingContact.givenName;
				shippingAddressObject.LastName = event.payment.shippingContact.familyName;
				shippingAddressObject.PrimaryLine = event.payment.shippingContact.addressLines[0] || '';
				shippingAddressObject.SecondaryLine = event.payment.shippingContact.addressLines[1] || '';
				shippingAddressObject.StateCode = event.payment.shippingContact.administrativeArea;
				shippingAddressObject.PostalCode = event.payment.shippingContact.postalCode;
				shippingAddressObject.City = event.payment.shippingContact.locality;

				if (event.payment.shippingContact.phone) {
					shippingAddressObject.Phone = event.payment.shippingContact.phone.replace('+','');
				}

				if (options.isCheckoutPage) {
					$(document).trigger('SetAddressFromAddressVerification', $.extend(shippingAddressObject, { AddressType: 'shipping' }));
				}
			}
		}

		if (options.isCheckoutPage) {
			$(document).trigger('Cart.Update');
		} else {
			window.location = Urls.onePage;
		}

	})
	.catch(function(error) {
		console.error('ApplePay-PaymentAuthorized', error);
	});
}

function cancel(event) {
	fetch(Urls.ApplePay.Cancel, getFetchOptions('POST'))
	.then(function() {
		// applePaySession.abort();
		if (options.isCheckoutPage) {
			$(document).trigger('Cart.Update');
		}
	})
	.catch(function(error) {
		console.error('ApplePay-Cancel', error);
	});
}

function getApplePayPaymentRequest() {
	fetch(Urls.ApplePay.GetRequest)
	.then(function(response) { 
		return response.json();
	})
	.then(function(applePayRequest) {
		buffer['applePayRequest'] = applePayRequest;
	})
	.catch(function(error) {
		console.error('ApplePay-GetRequest', error);
	});
}

function getFetchOptions(method, body) {
	var fetchOptions = {
		credentials: 'include'
	};
	if (method) {
		fetchOptions.method = method;
	}
	if (body) {
		fetchOptions.headers = {
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		fetchOptions.body = JSON.stringify(filterEvent(body));
	}

	return fetchOptions;
}

function filterEvent(event) {
	var eventFiltered = {};
	for (var property in event) {
		if (!Event.prototype.hasOwnProperty(property)) {
			eventFiltered[property] = event[property];
		}
	}
	return eventFiltered;
}