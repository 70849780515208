'use strict';

var _ = require('lodash');
var dialog = require('../../dialog');
var loadingIndicator = require('../../components/LoadingIndicator');
var LocalDeliveryDialog = require('./LocalDeliveryDialog');
var TemplateConstants = require('../../../../../bc_sheplersbootbarn_core/cartridge/js/TemplateConstants');
var tooltip = require('../../tooltip');
var ValidationSettings = require('../../../../../bc_sheplersbootbarn_core/cartridge/js/ValidationConstants').Settings;
var ValidationRules = require('../../../../../bc_sheplersbootbarn_core/cartridge/js/ValidationConstants').Rules;
var ValidationHelper = require('../../../../../bc_sheplersbootbarn_core/cartridge/scripts/util/ValidationHelper');
var ZipCodeToStateMap = require('../../../../../bc_sheplersbootbarn_core/cartridge/data/ZipCodeToStateMap.json');

exports.initialize = initialize;
exports.search = search;
exports.remove = remove;

var options;
var validator;
var dataBuffer;

var resultContainerTemplate;
var resultItemTemplate;
var localDeliveryDecorator;

var $container;
var $form;
var $result;
var $formInputPostalCode;
var $storeSearchButton;
var $storeSearchButtonContainer;
var $bopisButton;
var $errorMessage;
var $inputTooltip;

function initialize($containerParameter, optionsParameter) {
	dataBuffer = {};
	options = $.extend({
		successCallback: null,
		errorCallback: null
	}, optionsParameter);

	resultContainerTemplate = _.template($('#template-bopis-result-container').html(), TemplateConstants.Lodash.Settings);
	resultItemTemplate = _.template($('#template-bopis-result-item').html(), TemplateConstants.Lodash.Settings);
	localDeliveryDecorator = _.template($('#template-bopis-localdelivery-decorator').html(), TemplateConstants.Lodash.Settings);

	$container = $containerParameter;
	$form = $container.find('form');
	$result = $container.find('.result');
	$formInputPostalCode = $form.find('input[name$="_postalCode"]');
	$storeSearchButton = $container.find('.btn.store-search');
	$storeSearchButtonContainer = $container.find('.btn-store-search-container');
	$bopisButton = $container.find('.btn.bopis');
    $errorMessage = $form.find('span.error');
	$inputTooltip = $formInputPostalCode.parent();

    if (options && options.IsVariantSelected) {
		$('.btn.store-search').removeAttr('disabled');
		$inputTooltip.removeClass('tooltip');
    }

	tooltip.init({
		items: '.store-search-tooltip',
		tooltipClass: 'store-search-tooltip'
	}, $('.store-search-tooltip'));

    $container.attr('state', 'form');
    validator = $form.validate(ValidationSettings.Global);
	$.validator.addMethod('zipCode', ValidationHelper.ValidateZipCode, Resources.INVALID_POSTAL_CODE);
    $formInputPostalCode.rules('add', ValidationRules.ZipCode);

	if ($container.attr('state') === 'form' && validator.checkForm() && !$storeSearchButton.hasClass('ready')) {
		$storeSearchButton.addClass('ready');
    }

    $form.off('click.apply').on('click.apply', '.btn.store-search:not([loading])', function (event) {
		event.preventDefault();
		search();
	});

    $container.off('click.apply').on('click.apply', '.btn.bopis:not([loading])', function (event) {
		event.preventDefault();

		var selectedLocation = dataBuffer['StoresDisplayed'][$('input[name="Bopis_StoreSearch_StoreId"]:checked').val()];
		if (selectedLocation) {
			if (options.LocalDelivery.Enabled) {
				LocalDeliveryDialog.initialize(options, selectedLocation);
			} else {
				$(document).trigger('ProductDetailPage.Bopis.AddToCart', { bopis: true, shippingOption: 'pickup', channel: options.Channel });
			}
		} else {
			$(document).trigger('ProductDetailPage.Bopis.AddToCart', { channel: options.Channel });
		}
	});

    $formInputPostalCode.off('keydown.BopisStoreSearch.ValidationCheck').on('keydown.BopisStoreSearch.ValidationCheck', function (event) {
    	if ($formInputPostalCode.val().length == 5 && parseInt(event.key, 10) == event.key && !window.getSelection().toString()) {
    		event.preventDefault();
    	}
    });

    $formInputPostalCode.off('change.BopisStoreSearch.ValidationCheck').on('change.BopisStoreSearch.ValidationCheck', function (event) {
    	if ($formInputPostalCode.val().length > 5) {
    		$formInputPostalCode.val($formInputPostalCode.val().substr(0, 5));
    	}
    });

    $formInputPostalCode.off('keyup.BopisStoreSearch.ValidationCheck, change.BopisStoreSearch.ValidationCheck').on('keyup.BopisStoreSearch.ValidationCheck, change.BopisStoreSearch.ValidationCheck', function (event) {
    	event.stopImmediatePropagation();

		if ($container.attr('state') === 'form') {
			if (validator.checkForm() && !$storeSearchButton.hasClass('ready')) {
				$storeSearchButton.addClass('ready');
			} else if (!validator.checkForm() && $storeSearchButton.hasClass('ready')) {
				$storeSearchButton.removeClass('ready');
			}
		}

    	if ($container.attr('state') === 'result') {
			$container.attr('state', 'form');
        	$formInputPostalCode.val('');
        }
    });

    $(document).off('mousedown.BopisStoreSearch.ValidationClear').on('mousedown.BopisStoreSearch.ValidationClear', function (event) {
        if (validator && $(event.target).closest($container).length == 0) {
            validator.resetForm();
            $errorMessage.html('').hide();
        }
    });

    $(document).off('Bopis.StoreSearch.Reset').on('Bopis.StoreSearch.Reset', function (event) {
		reset();
    });

    $container.off('click.BopisStoreSearch.Reset').on('click.BopisStoreSearch.Reset', '.reset', function (event) {
		reset();
    });

    $(document).off('ProductDetailPage.Variant.StoreSearch').on('ProductDetailPage.Variant.StoreSearch', function (event) {
        if (options.SelectedLocations) {
			var sizeSelected = $('div.product-variations .size.size-options li.selected');
			var lengthSelected = $('div.product-variations .length.length-options li.selected').length;
			var widthSelected = $('div.product-variations .width.width-options li.selected').length;
			var isSizeSelected = sizeSelected.length || (lengthSelected && widthSelected);
			var isColorSelected = $('div.product-variations .color.color-options li.selected').length;

			if (isSizeSelected && isColorSelected) {
				search();
			}
		}
    });

    $container.off('click.BopisStoreSearch.Selection').on('click.BopisStoreSearch.Selection', 'input[name="Bopis_StoreSearch_StoreId"]', function (event) {
        var bopisStore = $('input[name="Bopis_StoreSearch_StoreId"]:checked').val();

        if (!$bopisButton.hasClass('ready')) {
        	$bopisButton.addClass('ready');
        }

        $('.product-add-to-cart button').attr('disabled', 'disabled').addClass('hidden');

        if (bopisStore) {
        	$bopisButton.attr('state', 'pickup');
        } else {
        	$bopisButton.attr('state', 'ship');
        }
    });
}

function reset() {
	$formInputPostalCode.val('');
    $container.attr('state', 'form');
    $storeSearchButton.removeClass('ready');
    $('.product-add-to-cart button').removeAttr('disabled').removeClass('hidden');
    $bopisButton.removeClass('ready');
}

function search() {
	var sizeSelected = $('div.product-variations .size.size-options li.selected');
	var lengthSelected = $('div.product-variations .length.length-options li.selected').length;
	var widthSelected = $('div.product-variations .width.width-options li.selected').length;
	var isSizeSelected = sizeSelected.length || (lengthSelected && widthSelected);
	var isColorSelected = $('div.product-variations .color.color-options li.selected').length;

    if (!isSizeSelected && !isColorSelected) {
    	$errorMessage.html('Please select a size and color first.').show();
    	return false;
    } else if (!isSizeSelected) {
    	$errorMessage.html('Please select a size first.').show();
    	return false;
    } else if (!isColorSelected) {
    	$errorMessage.html('Please select a color first.').show();
    	return false;
    } else {
    	$errorMessage.html('').hide();
    	$storeSearchButton.removeAttr('disabled');
    }

    if (Urls && Urls.Bopis.GetStoreInventoryForProductForPostalCode && validator.valid()) {
        $storeSearchButtonContainer.loading({
            IsImageShown: false,
			Label: Resources.Loading.Loading,
			BackgroundColor: $storeSearchButton.css('background-color')
        });

		$.ajax({
            method: 'POST',
            url: Urls.Bopis.GetStoreInventoryForProductForPostalCode,
            contentType: 'application/json',
            data: JSON.stringify({
				Channel: options.Channel,
				Source: options.Source,
                ProductId: $('.product-add-to-cart input[name="pid"]').val(),
                PostalCode: $formInputPostalCode.val()
            }),
            dataType: 'json',
            success: function(data) {
				if ($storeSearchButtonContainer.attr('loading')) {
                	$storeSearchButtonContainer.loading('destroy');
				}
                $('.product-add-to-cart button').attr('disabled', 'disabled').addClass('hidden');

                var inquiryResult;
                if (data && data.ServiceSuccessfullyCalled && data.InquiryResult && data.InquiryResult.data && data.InquiryResult.data.length > 0 && data.InquiryResult.data[0].inventory_supplies) {
                    inquiryResult = data.InquiryResult.data[0].inventory_supplies;
                }

                $container.attr('state', 'result');
                if (inquiryResult && inquiryResult.length > 0) {
                	dataBuffer['StoresDisplayed'] = [];
                    $container.attr('result-count', inquiryResult.length);

                    var storeListHtml = '';
                    var isStoreSelected = false;
                    var selectedStoreAttribute;
                    for (var inventoryRecordElement in inquiryResult) {
                    	var inventoryRecord = inquiryResult[inventoryRecordElement];

                    	if (inventoryRecord.detail && inventoryRecord.detail.quantity > 0 && !dataBuffer['StoresDisplayed'][inventoryRecord.location.code]) {
                    		if (options && options.SelectedLocations == inventoryRecord.location.code) {
                    			isStoreSelected = true;
                    			selectedStoreAttribute ='checked';
                    		} else {
                    			selectedStoreAttribute = '';
                    		}

                    		var localDeliveryHtml = '';
                    		if (options.LocalDelivery.Enabled && isLocationInLocalDeliveryRadius(inventoryRecord.location) && inventoryRecord.location.local_delivery_enabled) {
                    			localDeliveryHtml = localDeliveryDecorator();
                    		}

							var labelPrefix;
							if (options && options.IsStoreHoliday) {
								labelPrefix = 'Available At ';
							} else {
								labelPrefix = 'Available TODAY At ';
							}

							if (inventoryRecord.location.postal_address.namePrefix) {
								labelPrefix += '<span class="nowrap">' + inventoryRecord.location.postal_address.namePrefix + '</span> ';
							}

                        	storeListHtml += resultItemTemplate({
                        		StoreId: inventoryRecord.location.code,
                        		QuantityAvailable: inventoryRecord.detail.quantity,
	                        	Html: labelPrefix + inventoryRecord.location.postal_address.name + ',<br/>' + inventoryRecord.location.postal_address.address_line1 + ', ' + inventoryRecord.location.postal_address.city + ', ' + inventoryRecord.location.postal_address.province_or_state,
	                        	Selected: selectedStoreAttribute,
	                        	LocalDelivery: localDeliveryHtml
	                        });
                    	}

                    	dataBuffer['StoresDisplayed'][inventoryRecord.location.code] = inventoryRecord;
                    }
                    storeListHtml += resultItemTemplate({
                    	StoreId: '',
                    	QuantityAvailable: '',
                    	Html: 'Ship Instead',
                    	Selected: '',
                    	LocalDelivery: ''
                    });

                    var resultHtml = resultContainerTemplate({
                    	Html: storeListHtml
                    });

                    if (isStoreSelected) {
                    	$bopisButton.addClass('ready');
                    }

                    $result.html(resultHtml);

					tooltip.init({
			        	items: '.tooltip'
                	}, $result);

                    if (options.successCallback) {
                    	options.successCallback(inquiryResult);
                    }
                } else {
					$('.product-add-to-cart button').removeAttr('disabled').removeClass('hidden');
                    $container.attr('result-count', '0');
                	$result.html('');

                    if (options.errorCallback) {
                    	options.errorCallback();
                    }
                }
            },
            error: function() {
				if ($storeSearchButtonContainer.attr('loading')) {
            		$storeSearchButtonContainer.loading('destroy');
				}

                if (options.errorCallback) {
                	options.errorCallback();
                }
            }
        });
	}
}

function remove() {
	validator.destroy();
	$('.product-add-to-cart button').removeAttr('disabled').removeClass('hidden');
}

function isLocationInLocalDeliveryRadius(location) {
	var localDeliveryRadius;
	if (location.local_delivery_vendor && location.local_delivery_vendor == 'GoLocal') {
		localDeliveryRadius = options.LocalDelivery.RadiusForGoLocal;
	} else {
		localDeliveryRadius = options.LocalDelivery.RadiusForRoadie;
	}	

	if (localDeliveryRadius && location && location.distance && localDeliveryRadius >= location.distance) {
		return true;
	}

	return false;
}