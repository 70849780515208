'use strict';

exports.init = function init () {

    $('.countdown-wrapper').each(function() {
        var $this = $(this),
          countdownStr = $this.data('countdown-date'),
          countdownDate = new Date(countdownStr);

        var ts = countdown(new Date(), countdownDate, countdown.DAYS | countdown.HOURS | countdown.MINUTES);
        var showCountdown = ts.value > 0;

        if (showCountdown) {
          $this.find('.countdown-text').text(ts.toString());
        }


    });

};
