'use strict';

var dialog = require('./dialog');

/* -------------------------
 * LOGIN MODAL
 * -------------------------
 */

var loginDialog = {
    /**
     * @function
     * @description Shows the account-login form in a dialog
     */
    show: function(callback, controller, action, productID, params, completeUrl) {
        $.ajax({
            type: 'POST',
            url: Urls.loginDialog,
            data: JSON.stringify({
                controller: controller,
                action: action,
                passedQueryString: params
            }),
            contentType: 'application/json',
            error: function(jqXHR, textStatus, errorThrown) {
                console.log(errorThrown);
            },
            success: function(response) {
				if (response) {

                    dialog.open({
                        html: response,
                        options: {
                            dialogClass: 'login-dialog',
                            draggable: false,
                            open: function() {
                                $('body').addClass('dialog-open');
                                $(this).scrollTop(0);
                                $(this).find('.login-dialog-forms-container').attr('data-controller', controller).attr('data-action', action).attr('data-product', productID);
                                $(this).find('.login-dialog-forms-container form').each(function(i, form) {
                                    var actionUrl = $(form).attr('action') + '?loginDialog=true';
                                    $(form).attr('action', actionUrl);
                                });
                                $(this).find('.login-dialog-validate-customer').attr('data-next-controller-url', completeUrl);
                                var $passwordReset = $(this).find('.login-dialog-forms-container #password-reset-button');
                                $passwordReset.attr('data-target', $passwordReset.attr('data-target') + '?loginDialog=true');
                                if (callback) {
                                    callback();
                                }
                            },
                            close: function() {
                                $('body').removeClass('dialog-open');
                            }
                        }
                    });
                }
			}
		});
    },
    /**
     * @function
     * @description Closes the account-login dialog
     */
    close: function() {
        dialog.close();
    }
}

module.exports = loginDialog;