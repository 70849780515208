'use strict';

exports.SlickSlider = {
	Options: {
		Single: {
			accessibility:  true,
			infinite: 		true,
			dots: 			false,
			arrows: 		false,
			slidesToShow: 	1,
			slidesToScroll: 1,
			responsive: 	[
				{
					breakpoint: 767,
					settings: 	{
						dots: 	true,
						arrows: true
					}
				},
				{
					breakpoint: 1200
				}
			]
		},
		Thumbnail: {
			accessibility:  true,
			draggable: false,
			infinite: false,
			focusOnSelect: 	false,
			dots: false,
			arrows: false,
			slidesToShow: 7,
			slidesToScroll: 7,
			responsive: 	[
				{
					breakpoint: 767
				},
				{
					breakpoint: 1200,
					settings: {
						slidesToShow: 4,
						slidesToScroll: 4
					}
				}
			]
		},
		CategoryLanding: {
			accessibility:  true,
			draggable: true,
			infinite: true,
			focusOnSelect: 	false,
			dots: false,
			arrows: true,
			slidesToShow: 4,
			slidesToScroll: 4,
			responsive: 	[
				{
					breakpoint: 767
				},
				{
					breakpoint: 1200,
					settings: {
						slidesToShow: 3,
						slidesToScroll: 3
					}
				}
			]
		}
	}
}