'use strict';

var giftCardBalanceInquiryComponent = require('../components/GiftCardBalanceInquiryComponent');

exports.initializeAllFoundComponents = initializeAllFoundComponents;

function initializeAllFoundComponents($containerParameter) {
	var $container = $containerParameter || $(document);
	
	var $balanceInquiryComponents = $container.find('.gift-card-balance-inquiry-component');
	if ($balanceInquiryComponents.length > 0) {
		$balanceInquiryComponents.each(function() {
			giftCardBalanceInquiryComponent.initialize($(this).parent());
		});
	}
}