'use strict';

exports.PaymentMethods = {
	AllianceData: 'Alliance_Data',
	ApplePay: 'DW_APPLE_PAY',
	BillMeLater: 'BML',
	CreditCard: 'CREDIT_CARD',
	GiftCertificate: 'GIFT_CERTIFICATE',
	PayPal: 'PayPal',
	Globale: 'GLOBALE',
	RewardsCredit: 'REWARDS_CREDIT',
	LoyaltyCredit: 'LOYALTY_CREDIT',
	StoredValueSolutions: 'SVS_Gift_Card',
	Affirm: 'Affirm',
	PaymentVoucher: 'B2B_Payment_Voucher',
	ShopPay: 'ShopPay'
};

exports.CreditCards = {
	AmericanExpress: 'Amex',
	BootBarnCredit: 'Boot Barn Credit Card',
	Discover: 'Discover',
	JCB: 'JCB',
	Maestro: 'Maestro',
	Mastercard: 'Master',
	MastercardSeries2: 'Master Series 2',
	Visa: 'Visa'
};