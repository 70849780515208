'use strict';

module.exports = {
	Validation: {
		Email: /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/,
		OrderNumber: /^[a-zA-Z]{0,6}[-._]{0,1}[0-9]{6,12}$/,
		PostalCode: /^[a-zA-Z0-9][a-zA-Z0-9\- ]{0,10}[a-zA-Z0-9]$/,
		PostOfficeBox: /((((p[\s\.]?)\s?[o\s][\.]?)\s?)|(post\s?office\s?)|(postal\s?)|(post\s?))((box|bin|b\.?)?\s?((num|number|no|#)\.?)?\s?\d+)/i,
		ZipCode: /^[0-9]{5}(-[0-9]{4})*$/,
		RequiredExcludingPunctuation: /^[#]*[^_\W]+/,
		GiftCardNumberLength: /(\d)/g,
		GiftCardNumberValues: /^[-\s0-9]*$/,
	}
}

// Email pattern is from W3C spec, same that jQuery Validate uses: https://html.spec.whatwg.org/multipage/input.html#valid-e-mail-address
// Postal Code pattern is from https://stackoverflow.com/questions/578406/what-is-the-ultimate-postal-code-and-zip-regex
// PO Box pattern is modified from https://stackoverflow.com/questions/5680050/po-box-regular-expression-validation