'use strict';

var util = require('../../util');

var ValidationRules = require('../../../../../bc_sheplersbootbarn_core/cartridge/js/ValidationConstants').Rules;

var $authenticationContainer = $('.authentication-form-container');
var $authenticationForm = $authenticationContainer.closest('form');
var $authenticationEmailAddress = $authenticationForm.find('input[name="AuthenticationEmailAddress"]');
var authenticationValidator;

exports.init = function(options) {
	$authenticationContainer.on('click', 'input[type="submit"]', function (event) {
    	event.preventDefault();
    	
	    authenticationValidator = $authenticationForm.validate();
	    $authenticationEmailAddress.rules('add', ValidationRules.Email);
	    authenticationValidator.form();
	    
	    if (authenticationValidator.valid()) {
	    	$('<input />').attr('type', 'hidden').attr('name', 'isMobile').attr('value', $('html.size-mobile').length > 0).appendTo($authenticationForm);
	    	$authenticationForm.submit();
	    }	
    });
};