'use strict';

var Recaptcha = require('../components/Recaptcha');
var RequestHelper = require('../../../../app_storefront_core/cartridge/js/helpers/RequestHelper');
var SiteConstants = require('../../../../bc_sheplersbootbarn_core/cartridge/js/SiteConstants');
var ValidationRules = require('../../../../bc_sheplersbootbarn_core/cartridge/js/ValidationConstants').Rules;

exports.Initialize = initialize;

var $footerEmailForm;
var validator;
var dataBuffer = {};
var options = {};
var loaded = {};


function initialize(page) {
	if (page && page.options) {
		options = page.options;
	}

	var footerEmailFormSelector;
	if (page.site == SiteConstants.SiteIds.CountryOutfittersUS) {
		footerEmailFormSelector = 'form#email-alert-signup';
	} else if (page.site == SiteConstants.SiteIds.BootBarnUS) {
		footerEmailFormSelector = 'form#bootbarn-footer-email-alert-signup';
	} else {
		footerEmailFormSelector = 'form#footer-email-signup';
	}
	
	$footerEmailForm = $(footerEmailFormSelector);
	if ($footerEmailForm.length > 0) {		
		var $validatorErrorMessage = $footerEmailForm.find('span.error');
		
		$footerEmailForm.on('submit', function(event) {
			event.preventDefault();

			if (!options || !options.Recaptcha || !options.Recaptcha.EnabledForFooterEmailSignup) {
				submitEmailSignup();
			}			
		});
		
		$(document).off('mousedown.EmailSignUp.ValidationClear').on('mousedown.EmailSignUp.ValidationClear', function(event) {
			if (validator && $(event.target).closest(footerEmailFormSelector).parent().length == 0) {
				validator.resetForm();
				$validatorErrorMessage.removeClass('signup-success').html('').hide();
	        }
	    });

		$(document).on('Global.EmailSignup', function (event, data) {
			if (options && options.Recaptcha && options.Recaptcha.EnabledForFooterEmailSignup) {
				submitEmailSignup();
			}
		});

		if (options && options.Recaptcha && options.Recaptcha.EnabledForFooterEmailSignup && options.Recaptcha.GoogleSiteKey) {
			Recaptcha.initialize();
			renderRecaptchaForEmailSignup();
			$(document).on('Recaptcha.Loaded', function(event) {
				if (!loaded.Recaptcha) {
					loaded.Recaptcha = true;
					renderRecaptchaForEmailSignup();
				}
			});
		}
	}
}

function submitEmailSignup() {
	var $emailAddress = $footerEmailForm.find('input#email-alert-address');		
	validator = $footerEmailForm.validate();
	$emailAddress.rules('add', ValidationRules.Email);
	validator.form();

	if (validator.valid()) {
		if(SitePreferences.CORDIAL_ENABLED){
			var auth_data = {
				email: $emailAddress.val()
			}
			var contact_data = {
				'channels': {
					'email': {
						'address': $emailAddress.val(),
						'subscribeStatus': 'subscribed'				            
					}
				},				    
				'signup_source': 'footer',
				'welcome_signup': true
			};
			
			var properties = {
				"signup_source": 'footer',
				"welcome_signup": true
			};

			RequestHelper.Validate('Cordial-EmailSignup').then(function() {
				if (typeof crdl !== 'undefined' && typeof crdl === 'function') {
					crdl([
						['contact', auth_data, contact_data],
						['event', 'email_signup', properties]
					]);
				}
			}).catch();
		}
		if (SitePreferences.RETENTION_COM_ENABLED) {
			geq.suppress();
		}

		if ($('#email-alert-address.valid').length > 0 && $('#email-alert-address').val() != '') {
			$('input#email-alert-address').val('');
			$('.signup-success').show();
		}
		setTimeout(function() {
			$('.signup-success').hide();
		}, 5000);
	}
}

function renderRecaptchaForEmailSignup() {
    if (loaded.Recaptcha && $footerEmailForm.length > 0 && options && options.Recaptcha && options.Recaptcha.EnabledForFooterEmailSignup && options.Recaptcha.GoogleSiteKey) {
        if (dataBuffer.EmailSignupRecaptcha) {
            grecaptcha.reset(dataBuffer.EmailSignupRecaptcha);
        }
        dataBuffer.EmailSignupRecaptcha = grecaptcha.render('footer-email-alert-signup-submit', {
            'sitekey': options.Recaptcha.GoogleSiteKey,
            'callback': emailSignup,
            'badge': 'inline'
        });
    }
}