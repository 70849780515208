'use strict';

var dialog = require('../dialog');

var ValidationSettings = require('../../../../bc_sheplersbootbarn_core/cartridge/js/ValidationConstants').Settings;
var ValidationRules = require('../../../../bc_sheplersbootbarn_core/cartridge/js/ValidationConstants').Rules;
var ValidationHelper = require('../../../../bc_sheplersbootbarn_core/cartridge/scripts/util/ValidationHelper');

exports.initialize = initialize;

function initialize($container) {
	$container.find('[email-form]').each(function (index, element) {
		var $form = $(element);
		var formType = $form.attr('email-form');
		appendCSRFToken($form);

		if (formType && typeof handlers[formType] == 'function') {
			handlers[formType]($form);
		}
	});
}

var handlers = {
	'cpra': function($form) {
		var $requiredFields = $form.find('input.required');
		var $formNameFirst = $form.find('input[name$="name_first"]');
		var $formNameLast = $form.find('input[name$="name_last"]');
		var $formEmail = $form.find('input[name$="email"]');
		var $formPhone = $form.find('input[name$="phone"]');
		var $formRequestType = $form.find('input[name$="requestType"]');
		
		var formValidator = $form.validate(ValidationSettings.Global);
		$.validator.addMethod('requiredExcludingPunctuation', ValidationHelper.ValidateRequiredExcludingPunctuation, Resources.INVALID_VALUE);
		$requiredFields.rules('add', ValidationRules.RequiredExcludingPunctuation);
		$formEmail.rules('add', ValidationRules.Email);
		$form.find('span.error').remove();
		
		$form.find('input[type="submit"]').on('click', function(event) {
			event.preventDefault();

			if (formValidator.form()) {
				var data = {
					'form_type': $form.attr('email-form'),
					'name_first': $formNameFirst.val(),
					'name_last': $formNameLast.val(),
					'email': $formEmail.val(),
					'phone': $formPhone.val(),
//					'request_type': $formRequestType.filter(':checked').map(function() { return this.value; }).get().join(' and ')
					'request_type': $formRequestType.val()
				}

				$form.find('input[type="hidden"]').each(function () {
					var $this = $(this);
					data[$this.attr('name')] = $this.val();
				});

				$.ajax({
					url: Urls.EmailForm.Send,
					type: 'POST',
					data: data,
					success: function(data) {
						if (data && data.EmailSent) {
							var $successContent = $('.content.cpra-form-success');
							
							dialog.open({
					            html: $successContent.html(),
					            options: {
					                title: $successContent.attr('title'),
					                dialogClass: 'fixed checkout-terms' + ($successContent.attr('dialog-class') ? ' ' + $successContent.attr('dialog-class') : ''),
					                draggable: false,
					                open: function(event, ui) {
					                	var $this = $(this);
					        
						            	$('body').addClass('dialog-open');
						            	$this.scrollTop(0);
						            },
						            close: function(event, ui) {
						            	$('body').removeClass('dialog-open');
						            }
					            }
					        });
						} else {
							var $errorContent = $('.content.cpra-form-error');
							
							dialog.open({
					            html: $errorContent.html(),
					            options: {
					                title: $errorContent.attr('title'),
					                dialogClass: 'fixed checkout-terms' + ($errorContent.attr('dialog-class') ? ' ' + $errorContent.attr('dialog-class') : ''),
					                draggable: false,
					                open: function(event, ui) {
					                	var $this = $(this);
					        
						            	$('body').addClass('dialog-open');
						            	$this.scrollTop(0);
						            },
						            close: function(event, ui) {
						            	$('body').removeClass('dialog-open');
						            }
					            }
					        });
						}
					},
					error: function() {
						var $errorContent = $('.content.cpra-form-error');
						
						dialog.open({
				            html: $errorContent.html(),
				            options: {
				                title: $errorContent.attr('title'),
				                dialogClass: 'fixed checkout-terms' + ($errorContent.attr('dialog-class') ? ' ' + $errorContent.attr('dialog-class') : ''),
				                draggable: false,
				                open: function(event, ui) {
				                	var $this = $(this);
				        
					            	$('body').addClass('dialog-open');
					            	$this.scrollTop(0);
					            },
					            close: function(event, ui) {
					            	$('body').removeClass('dialog-open');
					            }
				            }
				        });
					}
				});
			}
		});
	}
}

function appendCSRFToken($form) {
	$.ajax({
		method: 'get',
		url: Urls.CSRFToken.Get,
		dataType: 'json',
		success: function(response) {
			$form.append('<input type="hidden" name="' + response.name + '" value="' + response.token + '"/>');
		}
	});
}