'use strict';

exports.ResponseCodes = {
	AlreadyInBasket: 'ALREADY_IN_BASKET'
}

exports.User = {
	SelectedCustomerShippingMethod: 'selectedCustomerShippingMethod',
	SelectedStoreShippingMethod: 'selectedStoreShippingMethod'
}

exports.ShippingMethods = {
	Bopis: 'Bopis',
	LocalDelivery: 'LocalDelivery',
	ShipToStore: 'Ship-to-Store',
	ShipToStorePickup: 'My_Store_Free_Shipping',
	ShipToStorePickupForWhip: 'WHIP FREE Ship-to-store',
	Virtual: 'Virtual'
}