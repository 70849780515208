'use strict';

var _ = require('lodash');
var dialog = require('../../dialog');
var TemplateConstants = require('../../../../../bc_sheplersbootbarn_core/cartridge/js/TemplateConstants');

var options;
var dataBuffer;
var templates;

function initialize(optionsParameter, addressType, addressData) {
	options = $.extend({
		successCallback: null,
		errorCallback: null
	}, optionsParameter);
	dataBuffer = $.extend({}, addressData);
	templates = {};
	templates.address = _.template($('#template-address-verification-address').html(), TemplateConstants.Lodash.Settings);

	dialog.open({
		html: $('#dialog-address-verification').html(),
		options: {
			dialogClass: 'dialog-address-verification',
			width: 320,
			draggable: false,
			resizable: false,
			open: function(event, ui) {
				var $this = $(this);
				var $addresses = $this.find('.addresses');
				$addresses.html('');
				$this.closest('.ui-dialog').attr('status', dataBuffer.Status);

				var addressRecommendedHtml;
				if (dataBuffer.Status == 'deliverable' && !dataBuffer.Match) {
					addressRecommendedHtml = templates.address({
						AddressType: addressType,
						Action: 'recommended',
						Class: 'selected',
						Checked: 'checked',
						Header: 'Recommended Address',
						Address: buildAddressHtmlFromAddressObject(dataBuffer.AddressVerified)
					});
				}

				var addressEnteredHtml;
				addressEnteredHtml = templates.address({
					AddressType: addressType,
					Action: 'entered',
					Class: !addressRecommendedHtml ? 'selected' : '',
					Checked: !addressRecommendedHtml ? 'checked' : '',
					Header: 'Address as Entered',
					Address: buildAddressHtmlFromAddressObject(dataBuffer.AddressOriginal)
				});

				if (addressRecommendedHtml) {
					$addresses.append(addressRecommendedHtml);
				}
				if (addressEnteredHtml) {
					$addresses.append(addressEnteredHtml);
				}

				$this.find('.address-type').text(addressType);
				
				$this.off('click.AddressSelect').on('click.AddressSelect', '.address-container .radio-large', function(event) {
					event.preventDefault();
					var $addressContainer = $(this).closest('.address-container');

					$this.find('.address-container').removeClass('selected');
					$addressContainer.addClass('selected');
					$addressContainer.find('input').prop('checked', true);
				});

				$this.off('click.AddressUse').on('click.AddressUse', '.address-use', function(event) {
					event.preventDefault();
					var addressToUse = $(this).closest('.address-container').find('input').val();
					var addressObject;
					if (addressToUse == 'recommended') {
						addressObject = dataBuffer.AddressVerified;
					} else if (addressToUse == 'entered') {
						addressObject = dataBuffer.AddressOriginal;
					}

					if (addressObject) {
						$(document).trigger('SetAddressFromAddressVerification', $.extend(addressObject, { AddressType: addressType }));
					}
					$this.dialog('close');
				});

				$this.off('click.AddressContinue').on('click.AddressContinue', '.address-continue', function(event) {
					event.preventDefault();
					$(document).trigger('SetAddressFromAddressVerification', $.extend(dataBuffer.AddressOriginal, { AddressType: addressType }));
					$this.dialog('close');
				});
				
				$this.off('click.AddressEdit').on('click.AddressEdit', '.address-edit', function(event) {
					event.preventDefault();
					$this.dialog('close');
				});
            },
            close: function(event, ui) {
				var $this = $(this);
				$this.off('click.SelectAddress');
				$this.off('click.UseEnteredAddress');
				$this.off('click.UseRecommendedAddress');
				$this.off('click.EditShippingAddress');
            }
		}
	});
}

function buildAddressHtmlFromAddressObject(addressObject) {
	var addressArray = [];
	if (addressObject.PrimaryLine) {
		addressArray[addressArray.length] = addressObject.PrimaryLine;
	}
	if (addressObject.SecondaryLine) {
		addressArray[addressArray.length] = addressObject.SecondaryLine;
	}
	if (addressObject.City || addressObject.StateCode || addressObject.PostalCode) {
		var addressLine = '';
		if (addressObject.City) {
			addressLine += addressObject.City + ', ';
		}
		if (addressObject.StateCode) {
			addressLine += addressObject.StateCode;
			if (addressObject.PostalCode) {
				addressLine += ' ';
			}
		}
		if (addressObject.PostalCode) {
			addressLine += addressObject.PostalCode;
		}

		addressArray[addressArray.length] = addressLine;
	}

	return addressArray.join('<br/>');
}

exports.initialize = initialize;