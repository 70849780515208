'use strict';

exports.ServiceSuffixes = {
	ServiceSuite: '/ecomm/0.1',
	Authenticate: '/authenticate/root',
	PurchaseGiftCard: '/fulfillment/0.1/purchaseegift/'
};

exports.MessagePrefixes = {
	NonSuccess: 'Stored Value Solutions returned a result other than OK and/or without data ',
	Error: 'Stored Value Solutions encountered and unexpected exception '
};

exports.TransactionInformation = {
	Success: 'Successful Transaction - No Additional Information',
	Failure: {
		BalanceExceeded: 'Order Failure - Requested Amount Exceeded Gift Card Balance',
		HeldAmountReleased: 'Order Failure - Releasing Amount Held by Pre-Authorization'
	}
}

exports.TransactionTypes = {
	BalanceInquiry: 'Balance Inquiry',
	PreAuthorization: 'Pre-Authorization',
	PreAuthorizationComplete: 'Pre-Authorization Complete'
}

exports.ResponseParsers = {
	balanceInquiryResponse: {
		Path: '/int_svs/cartridge/scripts/BalanceInquiryResponseParser',
		BodyNode: 'balanceInquiryReturn'
	},
	preAuthResponse: {
		Path: '/int_svs/cartridge/scripts/PreAuthorizationResponseParser',
		BodyNode: 'preAuthReturn'
	},
	preAuthCompleteResponse: {
		Path: '/int_svs/cartridge/scripts/PreAuthorizationCompleteResponseParser',
		BodyNode: 'preAuthCompleteReturn'
	}
};

exports.ReturnCodes = {
	Approval: '01',
	InactiveCard: '02',
	InvalidCardNumber: '03',
	InvalidTransactionCode: '04',
	InsufficientFunds: '05',
	NoPreviousAuthorizations: '06',
	InvalidMessage: '07',
	NoCardFound: '08',
	InsufficentFundsDueToOutstandingPreAuthorization: '09',
	DenialNoPreviousAuthorization: '10',
	NoAuthorizationNumber: '11',
	InvalidAuthorizationNumber: '12',
	MaximumSingleRechargeExceeded: '13',
	MaximumWOrkingBalanceExceeded: '14',
	HostUnavailable: '15',
	InvalidCardStatus: '16',
	UnknownDealerStoreCodeSpecialEdit: '17',
	MaximumNumberOfRechargesExceeded: '18',
	InvalidCardVerificationValueOrSecondarySecurityCode: '19',
	InvalidPinNumberOrPinLocked: '20',
	CardAlreadyIssued: '21',
	CardNotIssued: '22',
	CardAlreadyUsed: '23',
	ManualTransactionNotAllowed: '24',
	MagStripeReadNotValid: '25',
	TransactionTypeUnknown: '26',
	InvalidTenderType: '27',
	InvalidCustomerType: '28',
	MaxNumbherOfRedemptionExceeded: '30',
	InvalidCurrencyCode: '31',
	InvalidServerId: '32',
	FrozenCardOrUnknown: '33',
	InvalidAmount: '34',
	TransactionRejectedInvalidTransactionPromotionWideOrInvalidForOriginatingMerchantAndStore: '36',
	TransactionRejectedInvalidMerchantAndStoreCombinationForPromotion: '37',
	TransactionRejectedExceededMaximumNumberOfPromotionCards: '38',
	TransactionDeclinedAmountLessThanMinimum: '42',
	FailureInRetrievingData: '99',
	RateLimitExceeded: '999'
};

exports.ReturnDescriptionMessagesForReturnCodes = {
	'03': 'Invalid Card or PIN Number',
	'08': 'Invalid Card or PIN Number',
	'20': 'Invalid Card or PIN Number'
}

exports.GiftCardTypes = {
	eGift: 'eGift',
	Physical: 'Physical',
	NotApplicable: 'NotApplicable'
};

exports.OrderTypes = {
	Interactive: 'I',
	Web: 'W'
};