'use strict';

var SiteConstants = require('../../../../bc_sheplersbootbarn_core/cartridge/js/SiteConstants');

exports.Initialize = initialize;
exports.CloseNavigation = closeNavigation;

var navDataBuffer = {};

function initialize() {
	var siteName = $('.top-banner-bar').data('header-for') || "";
	var $navSecondary = $('.nav-secondary');
	
	$('.nav-secondary-dropdown').parent().addClass('has-dropdown');
	if (siteName == SiteConstants.SiteIds.BootBarnUS) {
		if (window.innerWidth < 1024) {
			$navSecondary.find('li#location').removeClass('has-dropdown');
		}
	}

	// BootBarn 2020 Full width nav drop down helper
	if ($('.top-banner-bar').data('header-for') == 'bootbarn_us') {
		// main nav hover effects		
		$('html #navigation .has-dropdown').hover(function () {
			if (!$('html').hasClass('size-mobile')) {
				$(this).addClass('active');
				$(this).closest('.top-banner').addClass('nav-active');
				$('html').addClass('desktop-nav-active');
				$('.nav-cover').show();
			}
		}, function () {
			if (!$('html').hasClass('size-mobile')) {
				$('.nav-cover').hide();
				$('.top-banner').removeClass('nav-active');
				$('html').removeClass('desktop-nav-active');
				$(this).removeClass('active');
			}
		});
	}
	
	if ($navSecondary.find('#location-display-caption').length > 0 && $navSecondary.find('#location-display-caption').hasClass('no-store-to-display')) {
		if ($navSecondary.find('#location-display-caption').parents('#location').length > 0) {
			$navSecondary.find('#location-display-caption').parents('#location').removeClass('has-dropdown');
		}
	}	
	
	// collision detection and adjustment for if menu off-screen
	// this is bound to document and .size-desktop to avoid iOS issue with first tap triggering mouseover instead of click - dan@redvan
	$(document).on('mouseover', 'html.size-desktop #navigation .has-dropdown > a', function(event) {
		navigationCollisionDetection($(event.currentTarget));
	});
	
	$('body').on('click', '#navigation .has-dropdown > a, .nav-secondary .has-dropdown > a', function(event) {
		event.preventDefault();
		
		var $this = $(this);
		var $parent = $this.closest('.has-dropdown');
		var $container = $parent.closest('#navigation, .nav-secondary');
		
		if ($('html').hasClass('size-mobile')) {
			var $scrollingContainer = $('#navigation');
			var offsetOpenMenu = 0;
			var scrollTopNew = 0;
			
			if ($('#navigation .has-dropdown.active').first().length > 0 && ($('#navigation .has-dropdown.active').first().offset().top < $this.offset().top)) {
				offsetOpenMenu = $('#navigation .has-dropdown.active').first().find('.level-2, .nav-secondary-dropdown').outerHeight();
			}
			
			scrollTopNew = $this.offset().top - $scrollingContainer.offset().top + $scrollingContainer.scrollTop() - offsetOpenMenu;

			$scrollingContainer.animate({
				scrollTop: scrollTopNew
			}, 150);
		}
		
		if (pageContext.site === SiteConstants.SiteIds.SheplersUS && $('html').hasClass('size-tablet')) {
			if ($parent.hasClass('active')) {
				$parent.removeClass('active');
			} else if (!$parent.hasClass('active')) {
				$('#navigation .has-dropdown').removeClass('active');
				$parent.addClass('active');
			}
		}

		if ((!$('html').hasClass('size-desktop') && pageContext.site !== SiteConstants.SiteIds.SheplersUS) || ($('html').hasClass('size-mobile') && pageContext.site === SiteConstants.SiteIds.SheplersUS)) {
			//close other header menus
			if (siteName == SiteConstants.SiteIds.CountryOutfittersUS) {
				$('.top-banner-bar .nav-secondary .has-dropdown .nav-secondary-dropdown').hide();
			} else {
				$('.top-banner-bar .nav-secondary .has-dropdown .nav-secondary-dropdown').stop().slideUp(150);
			}

			if ($parent.hasClass('active')) {
				if (siteName == SiteConstants.SiteIds.CountryOutfittersUS) {
					$parent.removeClass('active').find('.level-2, .nav-secondary-dropdown').hide();
				} else {
					$parent.removeClass('active').find('.level-2, .nav-secondary-dropdown').stop().slideUp(150);
				}
			} else {
				if (siteName == SiteConstants.SiteIds.CountryOutfittersUS) {
					$('#navigation .has-dropdown.active').removeClass('active').find('.level-2, .nav-secondary-dropdown').hide();
					$parent.addClass('active').find('.level-2, .nav-secondary-dropdown').show();
				} else {
					$('#navigation .has-dropdown.active').removeClass('active').find('.level-2, .nav-secondary-dropdown').stop().slideUp(150);
					$parent.addClass('active').find('.level-2, .nav-secondary-dropdown').stop().slideDown(150);
				}
			}
			
			if ($('#navigation .has-dropdown.active:not(.accordion)').length > 0) {
				$container.attr('active-menu-level', '1');
			} else {
				$container.removeAttr('active-menu-level');
			}
			
			if ($('#navigation .has-dropdown.active').length > 0) {
				$('.menu-toggle').addClass('active');
				$('html').addClass('menu-active');

				if (siteName == SiteConstants.SiteIds.BootBarnUS && $('html').hasClass('size-tablet') && window.innerWidth == 1024) {
					$(this).closest('.top-banner').addClass('nav-active');
					$('html').addClass('desktop-nav-active');
				}
			} else if (window.innerWidth >= 1024) {
				$('.menu-toggle').removeClass('active');
				$('html').removeClass('menu-active');

				if (siteName == SiteConstants.SiteIds.BootBarnUS && $('html').hasClass('size-tablet') && window.innerWidth == 1024) {
					$(this).closest('.top-banner').removeClass('nav-active');
					$('html').removeClass('desktop-nav-active');
				}
			}
		}

		if (!$('html').hasClass('size-mobile')) {
			navigationCollisionDetection($this);
		}
		
		return false;
	})
	.on('click', '.menu-toggle', function (event) {
		event.preventDefault();

		var $this = $(this);
		if ($('html').hasClass('size-mobile') || $('html').hasClass('size-tablet')) {
			var $html = $('html');
			var $body = $('body');
			var $container = $('#wrapper');
			var headerWrapper = $('.header-wrapper');
			var jumbotronContainer = headerWrapper.find('.jumbotron-ajax');
			var logoAndLinks = headerWrapper.find('.logo-and-links-container');
			var navFixedHeaderLimit = 800;
			var topBannerBar = headerWrapper.find('.top-banner-bar');
	
			var tobBannerInnerHeight = window.innerHeight - (parseFloat(topBannerBar.outerHeight()) + parseFloat(jumbotronContainer.outerHeight()));
			var navContainerHeight = window.innerHeight - (parseFloat(topBannerBar.outerHeight()) + parseFloat(logoAndLinks.outerHeight()) + parseFloat(jumbotronContainer.outerHeight())); 
	
			if (!$html.hasClass('menu-active')) {
				var scrollTop = document.body.scrollTop || document.documentElement.scrollTop;
				navDataBuffer['wrapperScrollTop'] = scrollTop;
				navDataBuffer['wrapperMarginTop'] = $container.css('margin-top');
				$container.attr('buffer-scrollTop', scrollTop);
				
				$('.top-banner-inner').css('height', tobBannerInnerHeight);
				$('.top-banner-inner .navigation-and-jumbotron-container').css('height', navContainerHeight);
				
				$html.addClass('menu-active');
				$this.addClass('active');
				$this.attr('aria-label', $this.attr('data-close')); // Change the aria-label on the menu-toggle for screen readers

				if (window.innerWidth <= 1023 && scrollTop > navFixedHeaderLimit) {
					$('.header-wrapper').css('max-height', window.innerHeight);
				}
				$('.my-store-panel.mobile-only .show-store-search').focus();
			} else {
				$html.removeClass('menu-active');
				$this.removeClass('active');
				$this.attr('aria-label', $this.attr('data-open')); // Change the aria-label on the menu-toggle for screen readers
	
				if (navDataBuffer['wrapperScrollTop'] > 0) {
					document.body.scrollTop = document.documentElement.scrollTop = navDataBuffer['wrapperScrollTop'];
				}
				$('#navigation').removeAttr('active-menu-level');
	
				$('.top-banner-inner').css('height', '');
				$('.top-banner-inner .navigation-and-jumbotron-container').css('height', 'auto');
	
				if (window.innerWidth <= 1023) {
					$('.header-wrapper').css('transform', 'none');
					$('.header-wrapper').css('max-height', 'auto');
					$('html, body').animate({ scrollTop: 0 }, 300);
				}
			}
		}

		$('#navigation .level-2').hide();
		$('#navigation .level-1').slideDown();
		$('#navigation .active').removeClass('active');			
		$('.nav-secondary-dropdown').slideUp(150);

		if ($('#mini-cart').hasClass('active')) {
			$('#mini-cart .mini-cart-link').removeClass('active');
			$('#mini-cart').removeClass('active');
		}

		$(document).trigger('SearchBar.Hide');
		
		if ($('.second-mobnav').hasClass('active')) {				
			$('.second-mobnav').removeClass('active')
			$('#second-mobnav').stop().slideUp(150);
		}
	})
	.on('click.MenuItemToggle', '.menu-category li .menu-item-toggle', function (event) {
		event.preventDefault();
		var $parentLi = $(e.target).closest('li');
		$parentLi.siblings('li').removeClass('active').find('.menu-item-toggle').removeClass('fa-chevron-up active').addClass('fa-chevron-right');
		$parentLi.toggleClass('active');
		$(e.target).toggleClass('fa-chevron-right fa-chevron-up active');
	});

	/* --- ADA Compliance: Keyboard main menu navigation ---
	 * ARROW DOWN: Will open flyouts; focus goes to first clickable link in flyout
	 * ARROW UP: Will close flyouts; focus returns to the parent of the flyout
	 * TAB: A focus indicator will show up at time of each tab
	 * ENTER: By default, hitting enter will engage any link that has current focus
	 */
	$(document).on('keyup', 'ul.menu-category li.has-dropdown', function (event) { 
		// Down Arrow: If sub-menu has been opened...
		if (event.which === SiteConstants.KeyCodes.ArrowDown) {
			// BootBarn-specific nav features
			if ($('.top-banner-bar').data('header-for') == 'bootbarn_us') {
				$(this).trigger('mouseenter');
			}
			$(this).find('ul').find('a[href!=""][href]').removeAttr('tabindex'); // Remove tabindex to allow natural tab flow of nav list
			var navLinksPopulated = $(this).find('.level-2 ul:first-of-type li:first-of-type').find('a').filter(function(x, navLink) {
				if ($(navLink).attr('href') && $(navLink).attr('href').trim().length > 0) {
					return navLink;
				}
			});
			$(navLinksPopulated[0]).focus().addClass('focus-visible-indicator'); // Focus on first populated <a> within opened sub-menu and add visual indicator
			$(this).attr('aria-expanded','true').addClass('active keyboard-activated'); // Update expanded attribute and add keyboard class
		}
		// Up Arrow: If sub-menu has been closed...
		if (event.which === SiteConstants.KeyCodes.ArrowUp) {
			// BootBarn-specific nav features
			if ($('.top-banner-bar').data('header-for') == 'bootbarn_us') {
				$(this).trigger('mouseleave');
			}
			$(this).find('ul').find('a[href!=""][href]').attr('tabindex','-1'); // Add negative tabindex to skip nested anchors
			$(this).attr('aria-expanded','false').removeClass('active keyboard-activated').focus().addClass('focus-visible-indicator'); // Update expanded attribute, remove keyboard class and reposition focus indicator
		}
		// Tab: Keyboard logic for nav tabbing...
		if (event.which === SiteConstants.KeyCodes.Tab) {
			if (!$(document.activeElement).hasClass('keyboard-activated') && ($(document.activeElement).closest('li.has-dropdown') && !$(document.activeElement).closest('li.has-dropdown').hasClass('keyboard-activated'))) { // If user tabbed away from activated sub-menu, close the sub-menu
				// BootBarn-specific nav features
				if ($('.top-banner-bar').data('header-for') == 'bootbarn_us') {
					$('li.has-dropdown.keyboard-activated').trigger('mouseleave');
				}
				$('li.has-dropdown.keyboard-activated').find('ul').find('a[href!=""][href]').attr('tabindex','-1'); // Add negative tabindex to skip nested anchors when submenu is closed
				$('li.has-dropdown.keyboard-activated').attr('aria-expanded','false').removeClass('active keyboard-activated'); // Update expanded attribute and remove keyboard class
			}
		}
	})
	.on('keydown', '.menu-toggle', function (event) { 
		if (($('html').hasClass('size-mobile') || $('html').hasClass('size-tablet')) && event.which === SiteConstants.KeyCodes.Enter) {
			$('.my-store-panel.mobile-only a').focus().addClass('focus-visible-indicator');
		}
	})
	.on('mouseover', 'ul.menu-category li.has-dropdown', function (event) {
		$(this).attr('aria-expanded','true'); // CSS-expaned sub-menu occurs on hover. Use JS to update the expanded attribute for mouse users.
	})
	.on('mousemove', function (event) {
		$('.focus-visible-indicator').removeClass('focus-visible-indicator');
		if ($('li.has-dropdown.keyboard-activated')) {
			if ($('.top-banner-bar').data('header-for') == 'bootbarn_us') {
				$('li.has-dropdown.keyboard-activated').trigger('mouseleave');
			}
			$('li.has-dropdown.keyboard-activated').find('ul').find('a[href!=""][href]').attr('tabindex','-1'); // Add negative tabindex to skip nested anchors when submenu is closed
			$('li.has-dropdown.keyboard-activated').attr('aria-expanded','false').removeClass('active keyboard-activated'); // Update expanded attribute and remove keyboard class
		}
	});

	/* --- Document Ready --- */
	$(document).ready(function() {
		var adaSubMenuAttributes = {
			'role': 'menuitem',
			'aria-haspopup': 'true',
			'aria-expanded': 'false'
		}
		$('ul.menu-category.level-1 ul').attr('role','menu').attr('aria-label', function(n, x) {
			return $(this).closest('li').find('.has-sub-menu').first().html();
		});
		$('ul.menu-category.level-1').find('a').filter(function(n, navLink) {
			// Add role="none" to all list items, and then use specificity further down to override if needed
			$(navLink).parents('ul:not(.level-1)').find('li').attr('role','none');
			// Add tabindex="-1" to all nested anchor tags to force tab order using arrow up and down events
			$(navLink).attr('tabindex','-1');
			// IF the href attribute is not empty, apply ADA attributes correctly for a menuitem
			if ($(navLink).attr('href') && $(navLink).attr('href').trim().length > 0) {
				$(navLink).attr('role','menuitem');
			// ELSE, if href attribute is empty, then ensure <a> gets skipped in keyboard navigation and screen readers
			} else {
				$(navLink).parent().attr(adaSubMenuAttributes);
			}
		});
	});

	if (siteName == SiteConstants.SiteIds.BootBarnUS && window.innerWidth == 1024) {		
		$('body').on('click', function(e) {
			if ($('html').hasClass('size-tablet') && $('#navigation .has-dropdown').hasClass('active')) {
				if(e.target.id == '#navigation .has-dropdown') {
					return false;
				}
				if( $(e.target).closest('#navigation .has-dropdown').length > 0 ) {
					return false;
				}

				$('#navigation .has-dropdown').removeClass('active');
				$('html').removeClass('menu-active');
				$('.header-wrapper .top-banner').removeClass('nav-active');
				$('html').removeClass('desktop-nav-active');
			}
		});
	}
	
	
	$('body').on('click', '#navigation .level-3 > li > a.offer-primary', function(event) {
		if (siteName == SiteConstants.SiteIds.CountryOutfittersUS && $('html').hasClass('size-mobile')) {
			event.preventDefault();
			
			var $this = $(this);
			var $parent = $this.closest('li');
			var $container = $parent.closest('.level-2');
			
			$container.find('.level-3 li').not($parent).removeClass('active');
			$parent.toggleClass('active');
		}
	});
}

function navigationCollisionDetection($navigationEventTarget) {
	var $container = $navigationEventTarget.closest('.has-dropdown');
	
	$container.removeClass('collision-right collision-both');
		
	var $menu = $container.find('> .level-2');
	
	if ($menu.length > 0) {
		var menuLeft = $menu.offset().left;
		var menuRight = menuLeft + $menu.outerWidth();
		
		var $boundaryContainer = $('.top-banner-inner');
		
		if ($boundaryContainer.find('> .shell').length > 0) {
			$boundaryContainer = $boundaryContainer.find('> .shell');
		}
		
		var boundaryLeft = $boundaryContainer.offset().left;
		var boundaryRight = boundaryLeft + $boundaryContainer.outerWidth();
		
		var boundaryBuffer = 30;

		if (menuRight >= (boundaryRight + boundaryBuffer)) {
			var buttonRight = $navigationEventTarget.offset().left + $navigationEventTarget.outerWidth();
			
			if (buttonRight - $menu.outerWidth() <= (boundaryLeft - boundaryBuffer)) {
				$container.addClass('collision-both');
			} else {
				$container.addClass('collision-right');
			}
		}
	}
}

function closeNavigation() {
	$('html').removeClass('menu-active');
	$('.menu-toggle').removeClass('active');
	$('#navigation .has-dropdown.active').removeClass('active').find('.level-2, .nav-secondary-dropdown').stop().slideUp(150);
}