'use strict';
/* global Resources, Urls */

var _ = require('lodash');
var loadingIndicator = require('../../components/LoadingIndicator');
var GoogleTagManagerClient = require('../../../../../bc_sheplersbootbarn_core/cartridge/js/util/GoogleTagManagerClient');
var TemplateConstants = require('../../../../../bc_sheplersbootbarn_core/cartridge/js/TemplateConstants');
var ValidationHelper = require('../../../../../bc_sheplersbootbarn_core/cartridge/scripts/util/ValidationHelper');
var ValidationRules = require('../../../../../bc_sheplersbootbarn_core/cartridge/js/ValidationConstants').Rules;
var util = require('../../util');
var SiteConstants = require('../../../../../bc_sheplersbootbarn_core/cartridge/js/SiteConstants');

exports.initialize = initialize;

var $loyaltyPaymentContainer = $('[payment-method="LOYALTY_CREDIT"]');
var $loyaltyPaymentsForm = $loyaltyPaymentContainer.find('form');
var $loyaltyPaymentsApplied = $loyaltyPaymentContainer.find('.loyalty-applied');
var $loyaltyPaymentInput = $loyaltyPaymentContainer.find('#dwfrm_billing_loyalty_loyaltyNumber');
var $loyaltyPhoneNumberInput = $loyaltyPaymentContainer.find('#dwfrm_billing_loyalty_loyaltyPhoneNumber');
var $loyaltyZipCodeInput = $loyaltyPaymentContainer.find('#dwfrm_billing_loyalty_loyaltyZipCode');
var $loyaltyPaymentSecondaryContainer = $loyaltyPaymentContainer.find('.loyalty-secondary');
var $loyaltyPaymentSecondarySelect = $loyaltyPaymentContainer.find('#loyalty-secondary-select');
var $loyaltyPaymentErrorMessage = $loyaltyPaymentContainer.find('span.error');
var loyaltyCreditValidator;
var loyaltyCreditRowTemplate = _.template($('#template-loyalty-credit-row').html(), TemplateConstants.Lodash.Settings);
var loyaltyCreditRecaptcha;
var options = {};
var loaded = {};

function renderRecaptcha() {
	if (loaded.Recaptcha && $('#loyalty-credit-add-button').length > 0 && options && options.Recaptcha && options.Recaptcha.EnabledForloyaltyCredit && options.Recaptcha.GoogleSiteKey) {
		loyaltyCreditRecaptcha = grecaptcha.render('loyalty-credit-add-button', {
	        sitekey: options.Recaptcha.GoogleSiteKey,
	        callback: submitLoyaltyCredit,
	        badge: 'bottomleft'
	    });
	}
}

function initialize(optionsParameter) {
	options = optionsParameter || {};
	var currentSite = '';
	if ($(".logo.shep").length > 0){
    	currentSite = Resources.LoyaltyCredit.NotOptedInSHEP;
    }
	if ($(".logo.co").length > 0){
    	currentSite = Resources.LoyaltyCredit.NotOptedInCO;
    }
	
	renderRecaptcha();
	$(document).on('Recaptcha.Loaded', function(event) {
		if (!loaded.Recaptcha) {
			loaded.Recaptcha = true;
	        renderRecaptcha();
	    }
	});
	
	if ($(".loyalty-row[status='applied']").length > 0){
		$loyaltyPaymentsForm.hide();
	}

	if ($loyaltyPaymentSecondarySelect.closest('.opc-summary').length > 0) {
		$loyaltyPaymentSecondarySelect.selectmenu({
			width: '100%',
			appendTo: '[payment-method="LOYALTY_CREDIT"] .loyalty-secondary',
			change: function(event, ui) {
				$loyaltyPaymentSecondaryContainer.attr('secondary', ui && ui.item && ui.item.value ? ui.item.value : '');
			}
		});
	}
	
	$loyaltyPaymentContainer.on('click', '.reset', function(event) {
		event.preventDefault();
		resetSecondarySelect();
	});

	$loyaltyPaymentContainer.on('click', '.add', function(event) {
		event.preventDefault();
		
        if (!options || !options.Recaptcha || !options.Recaptcha.EnabledForRewardsCredit || !options.Recaptcha.GoogleSiteKey) {
            $(document).trigger('LoyaltyCredit.Add'); 
        }
	});
	
	$(document).on('LoyaltyCredit.Add', function(event, data) {
		event.preventDefault();

		if (parseFloat($('[balance-due]').first().attr('balance-due')) <= 0) {
			$loyaltyPaymentErrorMessage.html(Resources.LoyaltyCredit.NoBalanceDue).show();
		} else {
			var loyaltySecondaryFactor = $loyaltyPaymentSecondaryContainer.attr('secondary');
			loyaltyCreditValidator = $loyaltyPaymentsForm.validate();
            $loyaltyPaymentInput.rules('add', ValidationRules.LoyaltyCredit.Number);

			switch (loyaltySecondaryFactor) {
				case "zipcode":
					$.validator.addMethod('zipCode', ValidationHelper.ValidateZipCode, Resources.INVALID_POSTAL_CODE);
					$loyaltyZipCodeInput.rules('add', ValidationRules.ZipCode);
					break;

				default:
					break;
			}

            loyaltyCreditValidator.form();
			
            if (Urls && Urls.checkLoyalty && loyaltyCreditValidator.valid()) {
            	$loyaltyPaymentsForm.hide();
            	
				var $loyaltyCreditRow = $(loyaltyCreditRowTemplate());
				$loyaltyCreditRow = $loyaltyCreditRow.appendTo($loyaltyPaymentsApplied);
				$loyaltyCreditRow.attr('status', 'pending');
				$loyaltyCreditRow.find('.basketLoyaltyNumber').html(util.getMaskedNumber($loyaltyPaymentInput.val(), 4, 'xxxx... '));
	
				$loyaltyCreditRow.find('.btn').loading({IsLabelShown: false});
				$loyaltyCreditRow.find('.form-controls').loading({Label: Resources.Loading.LoyaltyCredit.Loading});
	
				$.ajax({
					url: Urls.checkLoyalty,
					type: 'POST',
					data: {
						csrf_token:  $loyaltyPaymentContainer.find('input[name="csrf_token"]').val(),
						loyaltyNumber: $loyaltyPaymentInput.val(),
						loyaltyPhoneNumber: $loyaltyPhoneNumberInput.val(),
						loyaltyZipCode: $loyaltyZipCodeInput.val()
					},
					dataType: 'json',
					success: function(data) {
						$loyaltyPaymentErrorMessage.html('').hide();
	
						$loyaltyCreditRow.find('.btn').loading('destroy');
						$loyaltyCreditRow.find('.form-controls').loading('destroy');
	
						if (data.error !== 'undefined' && data.error === true) {
							$loyaltyCreditRow.remove();
							$loyaltyPaymentsForm.show();
							$loyaltyPaymentErrorMessage.html(data.errorMsg).show();
						} else if (!data.optinStatus) {
							$loyaltyPaymentContainer.attr('has-loyaltynumber', 'false');
							$loyaltyCreditRow.attr('status', 'message');
							$loyaltyCreditRow.find('.message').html(currentSite);
						} else if (parseFloat(data.creditAmount) <= 0) {
							$loyaltyPaymentContainer.attr('has-loyaltynumber', 'true');
							$loyaltyCreditRow.attr('status', 'message');
							$loyaltyCreditRow.find('.message').html(Resources.LoyaltyCredit.NoBalanceAvailable);
						} else {
							$loyaltyPaymentContainer.attr('has-loyaltynumber', 'true');
							$loyaltyPaymentContainer.attr('data-loyalty', data.optIn);
							$loyaltyCreditRow.find('.basketLoyaltyAmount').html(data.creditAmountFormatted);
													
							var details = '';					
							var vouchers = data.creditVouchers;
							
							vouchers.reverse().forEach(function(voucher){
								details += '<div class="voucher"><span class="reward-amount"><b>$'+voucher.rewardAmount+'</b></span><span class="expiration"><i>Expires '+voucher.expirationDate+'</i></span></div>';
							});
							
							$loyaltyCreditRow.find('.vouchers').html(details);
						}
						
						if (loyaltyCreditRecaptcha != undefined) {
							grecaptcha.reset(loyaltyCreditRecaptcha);
						}
					},
					error: function() {
						$loyaltyCreditRow.remove();
						$loyaltyPaymentsForm.show();
						$loyaltyPaymentErrorMessage.html(Resources.Error.Generic).show();
						
						if (loyaltyCreditRecaptcha != undefined) {
							grecaptcha.reset(loyaltyCreditRecaptcha);
						}
					},
					complete: function(data, status, xhr) {
						if (data.status == 302 && data.responseText) {
							location.href = data.responseText;
						}
					}
				});
            }
		}
	});

	$loyaltyPaymentContainer.on('click', '.apply', function(event) {
		event.preventDefault();

		var $loyaltyCreditRow = $(this).closest('.loyalty-row');
		
		$loyaltyCreditRow.find('.basketLoyaltyAmount').addClass('hide');
		$loyaltyCreditRow.find('.voucher').addClass('hide');
		$loyaltyCreditRow.find('.btn').loading({IsLabelShown: false});
		$loyaltyCreditRow.find('.form-controls').loading({Label: Resources.Loading.LoyaltyCredit.Applying});

		$.ajax({
			url: Urls.applyLoyalty,
			type: 'POST',
			data: {
				csrf_token:  $loyaltyPaymentContainer.find('input[name="csrf_token"]').val(),
				loyaltyNumber: $loyaltyPaymentInput.val(),
				loyaltyPhoneNumber: $loyaltyPhoneNumberInput.val(),
				loyaltyZipCode: $loyaltyZipCodeInput.val()
			},
			dataType: 'json',
			success: function(data) {
				$loyaltyPaymentErrorMessage.html('').hide();

				$loyaltyCreditRow.find('.btn').loading('destroy');
				$loyaltyCreditRow.find('.form-controls').loading('destroy');

				if (data.error !== 'undefined' && data.error === true) {
					$loyaltyCreditRow.attr('status', 'message');
					$loyaltyPaymentInput.val('');
					$loyaltyCreditRow.remove();
					$loyaltyPaymentsForm.show();
					$loyaltyPaymentErrorMessage.html(data.errorMsg).show();
				} else {
					$loyaltyCreditRow.attr('status', 'applied');
					$loyaltyPaymentsForm.hide();
					$loyaltyCreditRow.find('.voucher').removeClass('hide');
					$loyaltyCreditRow.find('.basketLoyaltyNumber').html(util.getMaskedNumber(data.loyaltyNumber, 4, 'xxxx... '));
					$loyaltyCreditRow.find('.basketLoyaltyAmount').removeClass('hide').html(data.loyaltyCreditUsedFormatted).attr('label', Resources.LoyaltyCredit.RewardApplied);

					var dataObject = {
						value: data.rewardsCreditUsed,
						payment_type: 'Loyalty'
					}
					GoogleTagManagerClient.PushDataLayerForDataLayerType('AddPaymentInfo', dataObject);

					$(document).trigger('Cart.Update');
				}
			},
			error: function() {
				$loyaltyCreditRow.attr('status', 'message');
				$loyaltyPaymentErrorMessage.html(Resources.Error.Generic).show();
				resetForm(true);
			},
			complete: function(data, status, xhr) {
				if (data.status == 302 && data.responseText) {
					location.href = data.responseText;
				}
			}
		});
	});

	$loyaltyPaymentContainer.on('click LoyaltyCredit.Remove', '.remove', function(event, data) {
		event.preventDefault();

		var $loyaltyCreditRow = $(this).closest('.loyalty-row');
		$loyaltyCreditRow.find('.basketLoyaltyAmount').addClass('hide');
		$loyaltyCreditRow.find('.voucher').addClass('hide');
		$loyaltyCreditRow.find('.btn').loading({IsLabelShown: false});
		$loyaltyCreditRow.find('.form-controls').loading({Label: Resources.Loading.LoyaltyCredit.Removing});

		var payload = {
			loyaltyNumber: $('input#dwfrm_billing_loyalty_loyaltyNumber').val()
		}

		if (data && data.ResetSessionData) {
			payload.resetSessionData = true
		}

		$.ajax({
			url: Urls.removeLoyalty,
			type: 'POST',
			dataType: 'json',
			data: payload,
			success: function() {
				$loyaltyPaymentErrorMessage.html('').hide();

				if ($loyaltyCreditRow.find('.btn[loading]').length > 0) {
					$loyaltyCreditRow.find('.btn').loading('destroy');
				}
				if ($loyaltyCreditRow.find('.form-controls[loading]').length > 0) {
					$loyaltyCreditRow.find('.form-controls').loading('destroy');
				}

				if ($loyaltyPaymentContainer.attr('has-loyaltynumber') !== 'true') {
					$loyaltyPaymentInput.val('');
					$loyaltyPaymentsForm.show();
					$loyaltyCreditRow.attr('status', 'form');
					$loyaltyPaymentsApplied.html('');
				} else {
					$loyaltyCreditRow.attr('status', 'pending');
					$loyaltyCreditRow.find('.voucher').removeClass('hide');
					$loyaltyCreditRow.find('.basketLoyaltyAmount').removeClass('hide').attr('label', Resources.LoyaltyCredit.Reward);
				}

				$(document).trigger('Cart.Update');
			},
            error: function() {
				$loyaltyCreditRow.find('.btn').loading('destroy');
				$loyaltyCreditRow.find('.form-controls').loading('destroy');

				$loyaltyPaymentErrorMessage.html(Resources.Error.Generic).show();
			}
		});
	});
	
	$loyaltyPaymentContainer.on('click', '.change', function(event) {
		event.preventDefault();
		
		$loyaltyPaymentContainer.attr('has-loyaltynumber', 'false');
		if ($loyaltyPaymentsApplied.find('.loyalty-row').attr('status') === 'applied') {
			$loyaltyPaymentContainer.find('.remove').trigger('LoyaltyCredit.Remove');
		}
		resetForm();
	});

	$(document).off('mousedown.LoyaltyCreditPaymentPanel').on('mousedown.LoyaltyCreditPaymentPanel', function (event) {
        if (loyaltyCreditValidator && $(event.target).closest($loyaltyPaymentContainer).length == 0) {
            loyaltyCreditValidator.resetForm();
            $loyaltyPaymentErrorMessage.html('').hide();
        }
    });
	
	$(document).on('LoyaltyCredit.Clear', function(event) {
		event.preventDefault();
		resetForm();
	});

	$loyaltyPaymentContainer.on('click', '.reset', function(event) {
		event.preventDefault();
		$loyaltyPaymentsForm.show();
		$loyaltyPhoneNumberInput.val('');
		$loyaltyZipCodeInput.val('');
	});
	
	if ($loyaltyPaymentContainer.attr('has-loyaltynumber') === 'true' && $loyaltyPaymentsApplied.find('.loyalty-row').length == 0 && $('[checkout-mode]').attr('checkout-mode') != 'echo') {
		$(document).trigger('LoyaltyCredit.Add', { Registered: true });
	}
}

function resetForm(showErrorMessage) {
	if (loyaltyCreditValidator) {
		loyaltyCreditValidator.resetForm();
	}
	
	if (!showErrorMessage) {
		$loyaltyPaymentErrorMessage.html('').hide();
	}
	
	var $loyaltyCreditRow = $loyaltyPaymentsApplied.find('.loyalty-row');
	if ($loyaltyPaymentContainer.attr('has-loyaltynumber') !== 'true') {
		$loyaltyPaymentInput.val('');
		$loyaltyPhoneNumberInput.val('');
		$loyaltyZipCodeInput.val('');
		
		$loyaltyPaymentsForm.show();
		$loyaltyPaymentsApplied.html('');
	} else {
		$loyaltyCreditRow.find('.basketLoyaltyAmount').attr('label', Resources.LoyaltyCredit.Reward);
	}

	resetSecondarySelect();
}

function resetSecondarySelect() {
	$loyaltyPaymentSecondarySelect.prop('selectedIndex', 0);
	if ($loyaltyPaymentSecondarySelect.closest('.opc-summary').length > 0) {
		$loyaltyPaymentSecondarySelect.selectmenu('refresh');
	}
	$loyaltyPaymentSecondaryContainer.attr('secondary', '');
}