'use strict';

exports.Events = {
	Cart: {
		AddToCart: {
			Name: 'AddToCart'
		},
		RemoveFromCart: {
			Name: 'RemoveFromCart'
		}
	}
}

exports.EventTypes = {
	Minus: 'minus',
	Plus: 'plus'
}