'use strict';

/* -------------------------
 * REGISTER
 * -------------------------
 */

var alert = require('./alert');
var dialog = require('./dialog');
var loadingIndicator = require('./components/LoadingIndicator');
var wishlist = require('./wishlist');

var RequestHelper = require('./helpers/RequestHelper');
var SiteConstants = require('../../../bc_sheplersbootbarn_core/cartridge/js/SiteConstants');

var options = {};
var loadingIndicatorSettings = {
    IsLabelShown: false,
    CssClass: 'fullscreen',
    BackgroundColor: 'transparent'
};

function errorHandling(data, dialogTitle, dialogHtml) {
    if (data && typeof data === 'object' && 'message' in data) {
        console.log(data.message);
    }
    dialog.open({
        html: dialogHtml,
        options: {
            title: dialogTitle,
            draggable: false,
            open: function(event, ui) {                                    
                $('body').addClass('dialog-open');
            },
            close: function(event, ui) {
                $('body').removeClass('dialog-open');
            }
        }
    });
}

function validateRegistrationForm($form) {	
    var rewardsFormFields = {
        email: $form.find('#dwfrm_rewardsregistration_rewardscustomer_email'),
        firstName: $form.find('#dwfrm_rewardsregistration_rewardscustomer_firstname'),
        lastName: $form.find('#dwfrm_rewardsregistration_rewardscustomer_lastname'),
        phone: $form.find('#dwfrm_rewardsregistration_rewardscustomer_phone'),
        zip: $form.find('#dwfrm_rewardsregistration_rewardscustomeraddress_zipcode').length > 0 ? $form.find('#dwfrm_rewardsregistration_rewardscustomeraddress_zipcode') : $form.find('#dwfrm_rewardsregistration_rewardscustomer_zipcode')
    }
	if ($form.find('input[name*="dwfrm_rewardsregistration_rewardscustomer_password"]').length > 0) {
		rewardsFormFields.password = $form.find('input[name*="dwfrm_rewardsregistration_rewardscustomer_password"]');
	}
    var registrationFormFields = {
        email: $form.find('#dwfrm_profile_customer_email'),
        confirmEmail: $form.find('#dwfrm_profile_customer_emailconfirm'),
        firstName: $form.find('#dwfrm_profile_customer_firstname'),
        lastName: $form.find('#dwfrm_profile_customer_lastname')
    }
	if ($form.find('input[name*="dwfrm_profile_login_password_"]').length > 0) {
		registrationFormFields.password = $form.find('input[name*="dwfrm_profile_login_password_"]');
	}
	if ($form.find('input[name*="dwfrm_profile_login_passwordconfirm"]').length > 0) {
		registrationFormFields.confirmPassword = $form.find('input[name*="dwfrm_profile_login_passwordconfirm"]');
	}
    var checkFormFields = $form.attr('id') == 'dwfrm_rewardsregistration' ? rewardsFormFields : registrationFormFields;
	var formValidation = new Array();
    var regexEmail = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/g;
	var regexPhone = /^\+?\(?([0-9][0-8][0-9])\)?[\-\. ]?([2-9][0-9]{2})[\-\. ]?([0-9]{4})(\s*x[0-9]+)?$/g;
    var regexZip = /^(\d{5})(-\d{4})?$/g;
    var regexPassword = /^(?=.*?[A-z])(?=.*?[0-9])(?=.*?[#?!\=\/\,\.\~\`\(\)\{\}\[\]\\@_$%^&*-]).{6,15}$/g;

    for (var fieldName in checkFormFields) {
        var fieldValue = checkFormFields[fieldName].length ? checkFormFields[fieldName].val().trim() : null;

        if (fieldValue != null && fieldValue.length > 0) {
            if (fieldName == 'email' || fieldName == 'confirmEmail') {
                if (String(fieldValue).match(regexEmail) == null) {
                    formValidation.push(false);
                    checkFormFields[fieldName].addClass('error');
					var emailFormatErrorClass = fieldName == 'email' ? 'span.invalid-email-format' : 'span.invalid-email-confirm-format';
					$('.registration-dialog-error[data-relation="'+fieldName+'"]').find(emailFormatErrorClass).removeClass('d-none');
                }
				if (fieldName == 'confirmEmail' && (fieldValue != checkFormFields['email'].val().trim())) {
                    formValidation.push(false);
                    checkFormFields[fieldName].addClass('error');
					$('.registration-dialog-error[data-relation="'+fieldName+'"]').find('span.invalid-email-confirm-mismatch').removeClass('d-none');
                }
            }
            if (fieldName == 'phone') {
                if (String(fieldValue).match(regexPhone) == null) {
                    formValidation.push(false);
                    checkFormFields[fieldName].addClass('error');
					$('.registration-dialog-error[data-relation="'+fieldName+'"]').find('span.invalid-phone-format').removeClass('d-none');
                }
            }
            if (fieldName == 'zip') {
                if (String(fieldValue).match(regexZip) == null) {
                    formValidation.push(false);
                    checkFormFields[fieldName].addClass('error');
					$('.registration-dialog-error[data-relation="'+fieldName+'"]').find('span.invalid-zip-format').removeClass('d-none');
                }
            }
            if (fieldName == 'password' || fieldName == 'confirmPassword') {
                if (String(fieldValue).match(regexPassword) == null) {
                    formValidation.push(false);
                    checkFormFields[fieldName].addClass('error');
					var passwordFormatErrorClass = fieldName == 'password' ? 'span.invalid-password-format' : 'span.invalid-password-confirm-format';
					$('.registration-dialog-error[data-relation="'+fieldName+'"]').find(passwordFormatErrorClass).removeClass('d-none');
                }
                if (fieldName == 'confirmPassword' && (fieldValue != checkFormFields['password'].val().trim())) {
                    formValidation.push(false);
                    checkFormFields[fieldName].addClass('error');
					$('.registration-dialog-error[data-relation="'+fieldName+'"]').find('span.invalid-password-confirm-mismatch').removeClass('d-none');
                }
            }
        } else {
            formValidation.push(false);
            checkFormFields[fieldName].addClass('error');
			$('.registration-dialog-error[data-relation="'+fieldName+'"]').find('span.invalid-missing-fields').removeClass('d-none');
        }
    }
			
	if (formValidation.length > 0) {
        return false;
    } else {
        $form.find('.registration-dialog-error span').addClass('d-none');
        return true;
    }
}

// Cordial rewards registration tracking (BB)
function rewardsRegisterCordialEvent($form, isRegisterDialog, callback) {
    var rewardsFormFields = {
        email: $form.find('#dwfrm_rewardsregistration_rewardscustomer_email').val().trim(),
        firstName: $form.find('#dwfrm_rewardsregistration_rewardscustomer_firstname').val().trim(),
        lastName: $form.find('#dwfrm_rewardsregistration_rewardscustomer_lastname').val().trim(),
        phone: $form.find('#dwfrm_rewardsregistration_rewardscustomer_phone').val().trim(),
        zip: $form.find('#dwfrm_rewardsregistration_rewardscustomeraddress_zipcode').length > 0 ? $form.find('#dwfrm_rewardsregistration_rewardscustomeraddress_zipcode').val().trim() : $form.find('#dwfrm_rewardsregistration_rewardscustomer_zipcode').val().trim()
    }
    var signupEvent = "";

	var auth_data = {
		email: rewardsFormFields['email']
	}		
	var contact_data = {
		'channels': {
			'email': {
				'address': rewardsFormFields['email']      
			}
		},		    
		'first_name': rewardsFormFields['firstName'],
		'last_name': rewardsFormFields['lastName'],
		'phone': rewardsFormFields['phone'],
		'postal_code': rewardsFormFields['zip'],
		'source_create': 'myaccount',
		'geo_location': {
			'postal_code': rewardsFormFields['zip']
		}
	};			
	var loginProperties = {
		"email": rewardsFormFields['email'],
		"loginType": isRegisterDialog ? "registration-dialog" : "regular-registration"
	};	
	var registerProperties = {
		"email": rewardsFormFields['email'],
		"registerType": isRegisterDialog ? "registration-dialog" : "regular-registration"
	};			
	if ($('input#dwfrm_rewardsregistration_receiveRewardsEmail').is(':checked')) {
		var contact_data = {
			'channels': {
				'email': {
					'address': rewardsFormFields['email'],
					'subscribeStatus': 'subscribed'				            
				}
			},		    
			'first_name': rewardsFormFields['firstName'],
			'last_name': rewardsFormFields['lastName'],
			'phone': rewardsFormFields['phone'],
			'postal_code': rewardsFormFields['zip'],
			'signup_source': 'rewards',
			'source_create': 'myaccount',
			'welcome_signup_rewards_online': true,
			'geo_location': {
				'postal_code': rewardsFormFields['zip']
			}
		};		
		var properties = {
			"signup_source": 'rewards',
			"welcome_signup_rewards_online": true
		};		
		signupEvent = ['event', 'email_signup', properties];
	}	
	RequestHelper.Validate('Cordial-EmailSignup').then(function() {
		if (typeof crdl !== 'undefined' && typeof crdl === 'function') {
			crdl([				
				['contact', auth_data, contact_data],
				['event', 'account_create', registerProperties],
				['event', 'login', loginProperties],
				signupEvent
			]);
		}
		if (callback) callback();
	}).catch();	
}

// Cordial registration tracking (SHP and CO)
function registerCordialEvent($form, isRegisterDialog, callback) {
    var registrationFormFields = {
        email: $form.find('#dwfrm_profile_customer_email').val().trim(),
        firstName: $form.find('#dwfrm_profile_customer_firstname').val().trim(),
        lastName: $form.find('#dwfrm_profile_customer_lastname').val().trim()
    }
	var signupEvent = "";	

	var auth_data = {
		email: registrationFormFields['email']
	}			
	var contact_data = {
		'channels': {
			'email': {
				'address': registrationFormFields['email']			            
			}
		},		    
		'first_name': registrationFormFields['firstName'],
		'last_name': registrationFormFields['lastName'],
		'source_create': 'myaccount'
	};
	var loginProperties = {
		"email": registrationFormFields['email'],
		"loginType": isRegisterDialog ? "registration-dialog" : "registration"
	};		
	var registerProperties = {
		"email": registrationFormFields['email'],
		"registerType": isRegisterDialog ? "registration-dialog" : "registration"
	};						
	if ($('input#dwfrm_profile_customer_addtoemaillist').is(':checked')) {
		var contact_data = {
			'channels': {
				'email': {
					'address': registrationFormFields['email'],
					'subscribeStatus': 'subscribed'
				}
			},		    
			'first_name': registrationFormFields['firstName'],
			'last_name': registrationFormFields['lastName'],
			'signup_source': 'myaccount',
			'source_create': 'myaccount',
			'welcome_signup': true
		};		
		var properties = {
			"signup_source": "myaccount",
			"page": "myaccount",
			"welcome_signup": true
		};
		signupEvent = ['event', 'email_signup', properties];
	}
	RequestHelper.Validate('Cordial-EmailSignup').then(function() {
		if (typeof crdl !== 'undefined' && typeof crdl === 'function') {
			crdl([				
				['contact', auth_data, contact_data],
				['event', 'account_create', registerProperties],
				['event', 'login', loginProperties],
				signupEvent
			]);
		}
		if (callback) callback();
	}).catch();	
}

/**
 * @private
 * @function
 * @description add rules to code input field
 */
function initAddRulesForCodeInput() {
    $('form#verify-code').validate();
    $('#verify-code #enter-code').rules('add', {
        required: true,
        digits: true,
        minlength: 6,
        messages: {
            required: Resources.VALIDATE_CODE,
            minlength: Resources.VALIDATE_CODE
        },
        normalizer: function(value) {
            return $.trim(value);
        }
    });
}

/**
 * @private
 * @function
 * @description init registration button event for registration page and dialog
 */
function initRegistrationButton() {
    $(document).off('click.Registration').on('click.Registration', '.accountRegisterButton, .saveRewardsRegistration.btn, .confirmationRegisterButton', function(event) {
		event.preventDefault();
		event.stopImmediatePropagation();
		var dialogHtml = '<p style="padding: 10px;">Oops, something went wrong with registration. Please try again later.</p>';
        var $registrationButton = $(this);
		var $registrationForm = $registrationButton.closest('form[data-form-type="register"]');
        var formUrl = $registrationForm.attr('action');
		var isConfirmationPage = $registrationButton.hasClass('confirmationRegisterButton');

		// Clear previous form errors
		$registrationForm.find('input').removeClass('error');
		$registrationForm.find('.registration-dialog-error span').addClass('d-none');

		var isValidForm = validateRegistrationForm($registrationForm);
		var isRegisterDialog = formUrl.indexOf('loginDialog') >= 0;
		var formData = $registrationForm.serialize(); 
		formData += '&' + $registrationButton.attr('name') + '=' + $registrationButton.attr('value');
		if (pageContext.options.Token) {
			formData += '&csrf_token=' + pageContext.options.Token;
		}
		
        // Using AJAX instead of default HTML button submit for registration dialog and "Create an Account" page
        if (isValidForm) {
            $('body').loading(loadingIndicatorSettings);
            if (SitePreferences.RETENTION_COM_ENABLED) {
            	geq.suppress();
            }           
            $.ajax({
                type: 'POST',
                url: formUrl,
                data: formData,
                success: function(data, textStatus, jqXHR) {
                    if (typeof data === 'object' && 'result' in data) {
                        if (data.result == 'error') {
							$('body').loading('destroy');
							if (isRegisterDialog || isConfirmationPage) {
								var errorsFound;
								if (data.validationErrors) {
									errorsFound = JSON.parse(data.validationErrors);
								}
								if (errorsFound && Object.keys(errorsFound).length > 0) {
									for (var fieldName in errorsFound) {
										$('[name*="' +fieldName+ '"]').addClass('error');
										$(errorsFound[fieldName]).removeClass('d-none');
									}
								} else {
									errorHandling(data, 'Register - Error', dialogHtml);
								}
							} else {
								window.location.href = data.registerDialogContinueUrl;
							}
                        } else if (data.result == 'success') {
							$('body').loading('destroy');
							// If a rewards account was found in rewards DB, then verify customer and tie newly registered email address to exisitng rewards account
                            if ('validateCustomer' in data && data.validateCustomer) {
                                if (SitePreferences.CORDIAL_ENABLED) {
                                    if ($registrationForm.attr('id') == 'dwfrm_rewardsregistration') {
										rewardsRegisterCordialEvent($registrationForm, isRegisterDialog);
									} else {
										registerCordialEvent($registrationForm, isRegisterDialog);
									}
                                }
								var newAttributes = {
									"data-modal-type": "register",
                                    "data-continue-url": 'registerDialogContinueUrl' in data ? data.registerDialogContinueUrl : '',
                                    "data-first-name": 'customerFirstName' in data ? data.customerFirstName : '',
                                    "data-store-number": 'storeFromProfile' in data ? data.storeFromProfile : '',
                                    "data-cart-quantity": 'cartQuantity' in data ? data.cartQuantity : ''
                                }
                                $('.login-dialog-validate-customer').html(data.validateCustomer);
                                $('.login-dialog-validate-customer').addClass('validate-customer-show follow-account-dialog-path').attr(newAttributes);
                                // For register-dialog because the validation form is only loaded into page via AJAX, so rules can't be added until form is loaded
                                initAddRulesForCodeInput();
                            } else {
								if (isConfirmationPage) {
									// Redirect to My Account if this is a registration from order confirmation page
									data.registerDialogContinueUrl = Urls.account;
								}
								if (SitePreferences.CORDIAL_ENABLED) {
									if ($registrationForm.attr('id') == 'dwfrm_rewardsregistration') {
										rewardsRegisterCordialEvent($registrationForm, isRegisterDialog, function() {
											$(document).trigger('PostAjaxAuthenticationActions.Initialize', { results: data, isAccountDialog: isRegisterDialog, displayMessage: 'register' });
										});
									} else {
										registerCordialEvent($registrationForm, isRegisterDialog, function() {
											$(document).trigger('PostAjaxAuthenticationActions.Initialize', { results: data, isAccountDialog: isRegisterDialog, displayMessage: 'register' });
										});
									}
								} else {
									$(document).trigger('PostAjaxAuthenticationActions.Initialize', { results: data, isAccountDialog: isRegisterDialog, displayMessage: 'register' });
								}
							}
                        } else {
							$('body').loading('destroy');
                        	errorHandling(data, 'Register - Error', dialogHtml);
						}
                    } else {
						$('body').loading('destroy');
						if (isRegisterDialog) {
							errorHandling(data, 'Register - Error', dialogHtml);
						} else {
							window.location.href = location.href;
						}
					}
                },
                error: function(jqXHR, textStatus, errorThrown) {
                    $('body').loading('destroy');
					if (!isRegisterDialog) {
						window.location.href = location.href;
					}
                }
            });
        }  
	});
}

/**
 * @private
 * @function
 * @description displays the registration form in the dialog on button click
 */
function initRegistrationForm() {
	$('body').on('click', '#toggle-registration', function(e) {
		e.preventDefault();
		var $dialog = $(this).parents('.login-dialog');
		var $dialogTitlebar = $dialog.find('.ui-dialog-titlebar');
		var $dialogContent = $dialog.find('.ui-dialog-content');
		var $dialogFormsContainer = $dialog.find('.login-dialog-forms-container');
		var calculatedSpacing = $(window).height() * 0.21;
		var newDialogHeight = $(window).height() - calculatedSpacing;
		var newTopValue = $(window).scrollTop() + (calculatedSpacing * 0.233);

		if ($dialogTitlebar && $dialogTitlebar.is(":visible")) {
			newDialogHeight = newDialogHeight + $dialogTitlebar.outerHeight();
		}

		var paddingTop = '32px';
		var currentSite = pageContext && pageContext.site ? pageContext.site : null;
		if ((currentSite && currentSite == SiteConstants.SiteIds.BootBarnUS) || (pageContext && pageContext.isstoresite)) {
			paddingTop = '28px';
		}

		$('.login-dialog-signin-container, #toggle-registration').hide();
		$('.login-dialog-register-form').addClass('register-form-show');
		$dialog.css({
			'height': newDialogHeight+'px',
			'top': newTopValue+'px',
			'overflow': 'visible'
		  });
		$dialogContent.css({
			'height': '100%',
			'padding': '0 11px 20px',
			'padding-top': paddingTop
		});
		$dialogFormsContainer.css('height','100%');
	});
}

module.exports = {
    init: function (optionsParameter) {
        options = optionsParameter;

		initRegistrationForm();
        initRegistrationButton();
    }
};