'use strict';

var dialog = require('../dialog');

exports.initialize = initialize;

function initialize() {
    dialog.open({
        html: $('#dialog-content-gift-card-balance-inquiry').html(),
        options: {
            title: 'Gift Card Balance Check',
            dialogClass: 'gift-card-balance-inquiry',
            resizable: false,
            draggable: false
        }
    });
}
