'use strict';

var _ = require('lodash');
var addToCartOptionsStoreSearch = require('./AddToCartOptionsStoreSearch');
var dialog = require('../../dialog');
var loadingIndicator = require('../../components/LoadingIndicator');
var LocalDeliveryDialog = require('./LocalDeliveryDialog');
var TemplateConstants = require('../../../../../bc_sheplersbootbarn_core/cartridge/js/TemplateConstants');
var util = require('../../util');
var tooltip = require('../../tooltip');

exports.initialize = initialize;

var options;
var optionsStoreSearch;
var optionsLocalDelivery;
var dataBuffer;
var storeSearch;
var localDeliveryStoreSearch;

var resultContainerTemplate;
var resultItemTemplate;
var localDeliveryDecorator;

var $container;
var $storeSearchButton;
var $storeSearchButtonContainer;
var $addToCartOptions;
var $bopisButton;

function initialize($containerParameter, optionsParameter) {
	dataBuffer = {};
	options = $.extend({
		successCallback: null,
		errorCallback: null
	}, optionsParameter);

	var selectedSize;
	var $selectedSizeInDropdown = $('div.product-variations .size.size-options li.selected');
	var $selectedLength = $('div.product-variations .length.length-options li.selected');
	var $selectedWidth = $('div.product-variations .width.width-options li.selected');
	if ($selectedLength.length && $selectedWidth.length) {
		selectedSize = $selectedLength.find('[data-length-name]').attr('data-length-name') + ' ' + $selectedWidth.find('[data-width-name]').attr('data-width-name');
	} else if ($selectedSizeInDropdown.length > 0) {
		selectedSize = $selectedSizeInDropdown.find('[data-size-name]').attr('data-size-name');
	} else {
		selectedSize = util.getPreferenceValueFromWindowLocation('size');
	}
	storeSearch = new addToCartOptionsStoreSearch();
	localDeliveryStoreSearch = new addToCartOptionsStoreSearch();
	optionsStoreSearch = Object.assign(options);
	optionsLocalDelivery = Object.assign({
		LocalDeliveryOnly: true,
		Events: {
			SelectStore: 'Bopis.LocalDelivery.StoreSelect'
		},
		Functions: {
			Close: function() {
				$('.dialog-bopis-options').removeClass('hidden');
				$('.ui-widget-overlay').removeClass('hidden');
				$('.bopis-local-delivery-store-search-container').removeClass('show');
			}
		},
	}, options);

	resultContainerTemplate = _.template($('#template-bopis-result-container').html(), TemplateConstants.Lodash.Settings);
	resultItemTemplate = _.template($('#template-bopis-result-item').html(), TemplateConstants.Lodash.Settings);
	localDeliveryDecorator = _.template($('#template-bopis-localdelivery-decorator').html(), TemplateConstants.Lodash.Settings);

	$container = $containerParameter;
	$storeSearchButton = $container.find('.btn.store-search');
	$storeSearchButtonContainer = $container.find('.btn-store-search-container');
	$bopisButton = $container.find('.btn.bopis');

	$addToCartOptions = $container.find('.bopis-add-to-cart-options');
	$addToCartOptions.attr('state', 'form');
	initializeTooltips(selectedSize);

	$(document).off('click.SearchByStore.Display').on('click.SearchByStore.Display', '.bopis-add-to-cart-options-container .search-store:not(.disabled)', function(event) {
		event.preventDefault();
		event.stopImmediatePropagation();

		var sizeSelected = $('div.product-variations .size.size-options li.selected').length;
		var lengthSelected = $('div.product-variations .length.length-options li.selected').length;
		var widthSelected = $('div.product-variations .width.width-options li.selected').length;
		var colorSelected = $('div.product-variations .color.color-options li.selected').length;
		var isSizeSelected = (sizeSelected || (lengthSelected && widthSelected)) && colorSelected;

		if (options.StoreUIVersion != '2024Redesign') {
			if (!isSizeSelected && !colorSelected) {
				dialog.open({
					html: 'Please select a size and color.',
					options: {
						dialogClass: 'dialog-alert',
						draggable: false
					}
				});
				return false;
			} else if (!isSizeSelected) {
				dialog.open({
					html: 'Please select a size',
					options: {
						dialogClass: 'dialog-alert',
						draggable: false
					}
				});
				return false;
			} else if (!colorSelected) {
				dialog.open({
					html: 'Please select a color',
					options: {
						dialogClass: 'dialog-alert',
						draggable: false
					}
				});
				return false;
			}
		}

		if ($container.find('input[name="AddToCartOption"]:checked').val() == 'delivery') {
			localDeliveryStoreSearch.initialize($('.bopis-local-delivery-store-search-container'), optionsLocalDelivery);
		} else {
			storeSearch.initialize($('.bopis-add-to-cart-store-search-container'), optionsStoreSearch);
		}
    });

	$(document).off('click.SearchByZipCode.Display').on('click.SearchByZipCode.Display', '.bopis-add-to-cart-options .search-zipcode:not(.disabled)', function (event) {
		event.preventDefault();
		event.stopImmediatePropagation();

		localDeliveryStoreSearch.initialize($('.bopis-local-delivery-store-search-container'), optionsLocalDelivery);
    });

	$(document).off('Bopis.LocalDelivery.StoreSelect').on('Bopis.LocalDelivery.StoreSelect', function (event, store) {
		$.ajax({
			method: 'GET',
			url: Urls.Bopis.GetLocalDeliveryFormData,
			dataType: 'json',
			success: function(localDeliveryFormData) {
				LocalDeliveryDialog.initialize(optionsLocalDelivery, store, localDeliveryFormData);
			},
			error: function() {
				LocalDeliveryDialog.initialize(optionsLocalDelivery, store);
			}
		});
	});

	$(document).off('Bopis.LocalDelivery.Display').on('Bopis.LocalDelivery.Display', function (event, data) {
		var $storeData = $container.find('.selected-store[store-data]');
		if ($storeData.length > 0) {
			if (data) {
				optionsLocalDelivery = $.extend(optionsLocalDelivery, data);
			}

			$.ajax({
				method: 'GET',
				url: Urls.Bopis.GetLocalDeliveryFormData,
				dataType: 'json',
				success: function(localDeliveryFormData) {
					LocalDeliveryDialog.initialize(optionsLocalDelivery, JSON.parse($storeData.attr('store-data')), localDeliveryFormData);
				},
				error: function() {
					LocalDeliveryDialog.initialize(optionsLocalDelivery, JSON.parse($storeData.attr('store-data')));
				}
			});
		}
	});
	
	$(document).off('Bopis.StoreSearch.Select').on('Bopis.StoreSearch.Select', function (event, storeId) {
		dataBuffer['SelectedLocation'] = storeId;
		redraw(true);
	});

	$(document).off('click.Store.Select').on('click.Store.Select', '.bopis-add-to-cart-options-container .select-store', function(event) {
		dataBuffer['SelectedLocation'] = $(this).closest('[store-id]').attr('store-id');
		redraw(true);
	});

	$(document).off('ProductDetailPage.Variant.AddToCartOptions').on('ProductDetailPage.Variant.AddToCartOptions', function() {
		redraw();
	});

	dataBuffer['SelectedLocation'] = options.SelectedLocations;
}

function redraw(saveSelectedLocation) {
	var selectedColor;
	var $selectedColorInSwatch = $('div.product-variations .color.color-options li.selected');;
	if ($selectedColorInSwatch.length > 0) {
		selectedColor = $selectedColorInSwatch.find('[data-color-name]').attr('data-color-id');
	} else {
		selectedColor = util.getPreferenceValueFromWindowLocation('color');
	}

	var selectedSize;
	var $selectedSizeInDropdown = $('div.product-variations .size.size-options li.selected');
	var $selectedLength = $('div.product-variations .length.length-options li.selected');
	var $selectedWidth = $('div.product-variations .width.width-options li.selected');
	if ($selectedLength.length && $selectedWidth.length) {
		selectedSize = $selectedLength.find('[data-length-name]').attr('data-length-name') + ' ' + $selectedWidth.find('[data-width-name]').attr('data-width-name');
	} else if ($selectedSizeInDropdown.length > 0) {
		selectedSize = $selectedSizeInDropdown.find('[data-size-name]').attr('data-size-name');
	} else {
		selectedSize = util.getPreferenceValueFromWindowLocation('size');
	}
	
	var payload = {
		Channel: options.Channel,
		ProductId: options.ProductId,
		SelectedLocation: dataBuffer['SelectedLocation'],
		SaveSelectedLocation: !!saveSelectedLocation,
		SelectedOption: $container.find('input[name="AddToCartOption"]:checked').val()
	}
	payload['dwvar_' + options.ProductId + '_color'] = selectedColor;
	payload['dwvar_' + options.ProductId + '_size'] = selectedSize;

	$.ajax({
		method: 'GET',
		url: Urls.Bopis.AddToCartOptions,
		contentType: 'application/json',
		data: payload,
		dataType: 'html',
		success: function(data) {
			$container.html($(data).children());
			$addToCartOptions = $container.find('.bopis-add-to-cart-options');
			initializeTooltips(selectedSize);
		},
		error: function() {
			
		}
	});
}

function initializeTooltips(selectedSize) {
	if (!selectedSize) {
		$addToCartOptions.find('label.disabled > span').each(function(index, element) {
			var $this = $(this);

			tooltip.init({
				items: $this,
				tooltipClass: 'tooltip-select-size',
				content: 'Select a size to<br />see availability'
			}, $this);
		});
	}

	var localDeliveryRadius;
	var $storeData = $container.find('.selected-store[store-data]');
	if ($storeData && $storeData.length > 0) {
		var storeData = JSON.parse($storeData.first().attr('store-data'));
		if (storeData && storeData.location && storeData.location.local_delivery_vendor && storeData.location.local_delivery_radius) {
			localDeliveryRadius = storeData.location.local_delivery_radius;
		}
	}

	$('.add-to-cart-options-tooltip').find('.delivery-radius').html(localDeliveryRadius);
	tooltip.init({
		items: '.add-to-cart-options-tooltip',
		tooltipClass: 'add-to-cart-options-tooltip'
	}, $('.add-to-cart-options-tooltip'));
}