'use strict';

var bopis = require('../bopis/components/LocationSearch');

exports.init = function (options) {
	var bopisWaysToShop = new bopis();
	var bopisFooter = new bopis();
	
	if ($('.waystoshop .bopis-store-search').length > 0) {
		bopisWaysToShop.initialize($('.waystoshop .bopis-store-search'), $.extend(options.Bopis, { Channel: options.Channel }));
	}
	if ($('.bopis-store-search-container .bopis-store-search').length > 0) {
		bopisFooter.initialize($('.bopis-store-search-container .bopis-store-search'), $.extend(options.Bopis, { Channel: options.Channel }));
	}
};
